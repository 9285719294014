import React, { Component } from 'react'
import { Layout, 
         PageHeader, 
         Row, 
         Col, 
         Breadcrumb, 
         Icon, 
         DatePicker,
         Select, 
         Button, 
         Divider, 
         Input, 
         Table } from 'antd'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

class Reception extends React.Component {
    render(){
        const dateFormat =' DD/MM/YYYY'
        const {Option} = Select
        const { t } = this.props;
        const columns = [
            {
              title: `${t('Date')}`,
              dataIndex: 'Date',
              key: 'Date',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('Number')}`,
              dataIndex: 'Number',
              key: 'Number',
            },
            {
              title: `${t('Supplier')}`,
              dataIndex: 'Supplier',
              key: 'Supplier',
            },
            {
                title: `${t('Due date')}`,
                dataIndex: 'Due date',
                key: 'Due date',
            },
            {
                title: `${t('Status')}`,
                dataIndex: 'Status',
                key: 'Status',
            },
            {
                title: `${t('Total')}`,
                dataIndex: 'Total',
                key: 'Total',
            },
            
          ];

          
        return(
            <Layout.Content>
                <PageHeader style={{border: '1px solid rgb(235,237,340)'}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col span={12}><h2 level={4}>{t('Reception')}</h2></Col>
                        <Col span={12}>
                            <Row type="flex" justify="end">
                                <Breadcrumb >
                                <Breadcrumb.Item>{t('Purchase')}</Breadcrumb.Item>
                                <Breadcrumb.Item href="/purchase-receive">{t('Reception')} </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </PageHeader>

                <div style={{padding:20}}>
                <Row type='flex'justify='space-between'>
                
                <Button href="/purchase-receive/create" style={{margin:10, backgroundColor:"#505458", color:"white"}}><Icon type="plus"/>{t('Create Reception')}</Button>
                <Col span={15} >
                    <DatePicker  style={{width:'25%',margin:10, marginLeft:100}} placeholder={t('Out of Date')} format={dateFormat} />
                    <DatePicker  style={{width:'25%',margin:10 }} placeholder={t('Till Date')} format={dateFormat} />
                    <Select style={{width:'25%',margin:10}} placeholder={t('All Statuses')}>
                    <Option value="Open">{t('Open')}</Option>
                    <Option value="Closed">{t('Closed')}</Option>
                    <Option value="Canceled">{t('Canceled')}</Option>
                  </Select>
                    </Col>
                <Button href="" style={{margin:10, backgroundColor:"#505458", color:"white", width:100}}>Filter</Button>
                
                </Row>
                </div>
                <Divider type="horizontal"/>

                <Row type="flex" justify="space-between" style={{padding:10, margin:10}}>
                    <Col > 
                        <Row type="flex">
                            <Col><h3>Per</h3></Col>
                            <Select defaultValue="25" >
                                <Option value="25">25</Option>
                                <Option value="50">50</Option>
                                <Option value="100">100</Option>
                                <Option value="200">200</Option>
                                <Option value="All">{t('All')}</Option>
                            </Select>
                            <Col><h3>{t('Line')}</h3></Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row type="flex">
                        <Col span={5}><h3>{t('Search')}</h3></Col>
                        <Col span={15}><Input></Input></Col>
                        </Row>
                    </Col>
                </Row>
                <div style={{margin:5, padding:20}}>
                    <Table columns={columns}></Table>
                </div>
            </Layout.Content>
        )
    }
}
export default withTranslation() (Reception)
