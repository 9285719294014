import React, { Component } from 'react'

class SalesReport extends Component{
    render(){
        return(
            <div>
                Dalam Pengembangan
            </div>
        )
    }
}
export default SalesReport