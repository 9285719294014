import React, { Component } from 'react'
import { Layout, Row, Col, Breadcrumb, Typography, Divider, Icon, Button, DatePicker, Select, Card, Input, Table, Pagination } from 'antd'
import { withTranslation } from 'react-i18next'

class Payment extends Component{
    render(){
        const {Title} = Typography
        const dateFormat = 'DD/MM/YYYY'
        const Option = Select
        const {t} = this.props
        const columns = [
            {
              title: `${t('Date')}`,
              dataIndex: 'Tanggal',
              key: 'Tanggal',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('Number')}`,
              dataIndex: 'Nomor',
              key: 'Nomor',
            },
            {
              title: `${t('Suplier')}`,
              dataIndex: 'Suplier',
              key: 'Suplier',
            },
            {
                title: `${t('Due Date')}`,
                dataIndex: 'Tanggal_jatuh_Tempo',
                key: 'Tanggal_jatuh_Tempo',
            },
            {
                title: `${t('Status')}`,
                dataIndex: 'Status',
                key: 'Status',
              },
              {
                title: `${t('Total')}`,
                dataIndex: 'Total',
                key: 'Total',
              },
          ];    
        return(
            <Layout.Content>
                <div style={{margin:10,padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Payment')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Purchase')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/payment">{t('Payment')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider  type="horizontal" style={{marginTop:-10}} />
                <div style={{margin:10,padding:20}}>
                    <Row type="flex" justify="space-between">
                        <Col><Button href="/payment/create" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}><Icon type="plus"/>{t('Create Payment')}</Button></Col>
                        <Col>
                            <Row type="flex">
                                <Col style={{marginRight:10}}><DatePicker placeholder={t('From Date')}  format={dateFormat} /></Col>
                                <Col style={{marginRight:10}}><DatePicker placeholder={t('To Date')}  format={dateFormat} /></Col>
                                <Col><Select mode="multiple"  placeholder={t('All Status')} style={{width:180}}>
                                        <Option value="Open">Open</Option>
                                        <Option value="Close">Close</Option>
                                        <Option value="Canceled">Canceled</Option>
                                    </Select></Col>
                            </Row>
                        </Col>
                        <Col><Button style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Filter')}</Button></Col>
                    </Row>
                    <Card style={{borderWidth:2,borderRadius:10,marginTop:10}}>
                    <Row type="flex" justify="space-between">
                            <Col type="flex">
                                <Row type="flex">
                                    <Col type="flex"><h3 style={{fontWeight:'bold',marginRight:3}}>Per</h3></Col>
                                    <Col type="flex">
                                        <Select defaultValue="25" style={{marginRight:3}}>
                                            <Option value="25">25</Option>
                                            <Option value="50">50</Option>
                                            <Option value="100">100</Option>
                                            <Option value="200">200</Option>
                                            <Option value="All">All</Option>
                                        </Select>
                                    </Col>
                                    <Col type="flex"><h3 style={{fontWeight:'bold'}}>{t('Line')}</h3></Col>
                                </Row>
                            </Col>
                            <Col type="flex">
                                <Row type="flex">
                                    <Col><h3 style={{fontWeight:'bold',marginRight:3}}>{t('Search')}</h3></Col>
                                    <Col><Input  style={{width:200}} /></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Table style={{marginTop:10}} columns={columns}/>
                        <div style={{marginTop:20}}>
                            <Pagination 
                                total={85}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                pageSize={1}
                                defaultCurrent={1}
                                />
                        
                        </div>
                    </Card>
                </div>
                
                
            </Layout.Content>
        )
    }
}
export default withTranslation()(Payment) 