import React, { Component } from 'react'
import { Row, Col, Form, Input, Icon, Button, Dropdown, Menu } from 'antd'
import { login } from '../stores/actions/authActions'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { setLanguage } from '../stores/actions/languageActions'

export class Login extends Component {

    state = {
        email: '',
        password: ''
    }
  
    changeLang = (lang) => {
        const { i18n } = this.props;
        i18n.changeLanguage(lang);
        this.props.setLanguage(lang)
    }

    handleChange = (name) => (e) => {
        this.setState({
            ...this.state,
            [name]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.login(this.state)
    }

    render() {
        const { fetching, errors, language, t }  = this.props

        if (sessionStorage.getItem('token'))
            return <Redirect to="/" />
        return (
            <Row type="flex" align="middle">
                <Col span={12}>
                    <Row type="flex" justify="center" align="middle" className="bg-login" style={{ minHeight: '100vh' }}>
                        <img src={require('../assets/img/logo2.png')} className="img-logo" />
                        <Col className="login-left-section">
                            <h1>{t('Welcome Back')}</h1>
                            <p>
                                {t('Please sign to your account')}
                                <br />
                               {t('Don\'t have account yet? Just Register a new one')}
                            </p>
                            <Link to="/register"><button className="button-outline-login">{t('Register')}</button></Link>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
                        <div className="language-select">
                            <Dropdown overlay={
                                <Menu>
                                    <Menu.Item>
                                    <span onClick={() => this.changeLang('en')}>
                                        English
                                    </span>
                                    </Menu.Item>
                                    <Menu.Item>
                                    <span onClick={() => this.changeLang('id')}>
                                        Bahasa Indonesia
                                    </span>
                                    </Menu.Item>
                                </Menu>
                                }>
                                <span  className="ant-dropdown-link pointer">
                                    { !sessionStorage.getItem('language') ? language : sessionStorage.getItem('language') }
                                </span>
                            </Dropdown>
                        </div>
                        <Col className="login-right-section" span={14}>
                        <h1>{t('Login')}</h1>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Item validateStatus={ errors && errors.errors.email ? 'error' : ''} help={ errors && errors.errors.email && errors.errors.email[0]}>
                                <Input size="large" prefix={
                                    <Icon type="user" />
                                } type="email" onChange={this.handleChange('email')} placeholder={t('Email or Username')} />
                            </Form.Item>
                            <Form.Item required validateStatus={ errors && errors.errors.password ? 'error' : ''} help={ errors && errors.errors.password && errors.errors.password[0]}>
                                <Input size="large" prefix={
                                    <Icon type="lock" />
                                } type="password" onChange={this.handleChange('password')} placeholder={t('Password')} />
                            </Form.Item>
                            <Col>                        
                                <Button type="link">{t('Forgot your password?')}</Button>
                            </Col>
                            <Col>
                            {
                                fetching ? (
                                    <button className="button-outline-register mt-10 button-disabled"><Icon type="loading" className="mr-10" />{t('Login')}</button>
                                ) : (
                                    <button type="submit" className="button-outline-register mt-10">{t('Login')}</button>
                                )
                            }
                            </Col>
                        </Form>
            
                        </Col>
                    </Row>
                </Col>
            </Row>
      
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        fetching: state.login.fetching,
        fetched: state.login.fetched,
        errors: state.login.errors,
        lang: state.lang.lang,
        language: state.lang.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        login: (data) => dispatch(login(data)),
        setLanguage: (lang) => dispatch(setLanguage(lang))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Login))
