import React, { Component } from 'react'
import { Layout, Typography, Input, Row, Col, Breadcrumb, Divider, Select, Button, Form, notification, PageHeader, message } from 'antd'
import { Link } from 'react-router-dom'
import { saveCustomer } from '../../stores/actions/customerActions'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

const { Title } = Typography
const { TextArea } = Input
const { Option } = Select

class AddCustomer extends Component{

    state = {
        code: '',
        name: '',
        city: '',
        province: '',
        address: '',
        address_billing: '',
        zip_code: '',
        country: '',
        phone_1: '',
        phone_2: '',
        phone_3: '',
        npwp_number: '',
        npwp_address: '',
        account_receivable_id: '',
        account_payable_id: '',
        contact_person: ''
    }

    handleChange = (name) => (e) => {
        this.setState({
            ...this.state,
            [name]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.saveCustomer(this.state)
    }

    componentDidUpdate = (prevProps) => {
        const { t } = this.props
        // if (this.props.saved !== prevProps.saved) {
            if (this.props.saved) {
                this.props.history.push('/customer')
            }

            if (this.props.errors !== prevProps.errors) {

                if (this.props.errors) {

                    message.error(`${t('Error')}! This is an error message`);

                }
            }
        // }
    }

    render(){
        const {
            code,
            name,
            city,
            province,
            address,
            address_billing,
            zip_code,
            country,
            phone_1,
            phone_2,
            phone_3,
            npwp_number,
            npwp_address,
            account_receivable_id,
            account_payable_id,
            contact_person
        } = this.state

        const {
            errors, fetching, t
        } = this.props


        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 8 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        }
        
        return(
            <Layout.Content>
                <Row  type="flex" justify="space-around" align="middle" className="mt-20">
                    <Col span={12}><Title level={4}>{t('Customer')}</Title></Col>
                    <Col span={12} >
                        <Row type="flex" justify="end">
                            <Breadcrumb>
                                <Breadcrumb.Item>{t('Other Data')}</Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link to="/customer">{t('Customer')}</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>{t('Add Customer')}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Col>
                    <Divider />
                </Row>
            

                <Row type="flex">
                    <Col span={24}>
                        <Form layout="horizontal" onSubmit={this.handleSubmit}>
                            <Row type="flex" gutter={24}>
                                
                                <Col span={12}>
                                    <Form.Item {...formItemLayout} label={t('Code')} required validateStatus={ errors && errors.errors.code ? 'error' : ''} help={ errors && errors.errors.code && errors.errors.code[0]} >
                                        <Input placeholder={t('Customer Code')} onChange={this.handleChange('code')} value={code} />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} label={t('Name')} required validateStatus={ errors && errors.errors.name ? 'error' : ''} help={ errors && errors.errors.name && errors.errors.name[0]}>
                                        <Input placeholder={t('Customer\'s name')} onChange={this.handleChange('name')} value={name} />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} label={t('City')}>
                                        <Input placeholder={t('e.g: Jakarta')} onChange={this.handleChange('city')} value={city} />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} label={t('Province')}>
                                        <Input placeholder={t('e.g: West Java')} onChange={this.handleChange('province')} value={province} />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} label={t('Shipping address')}>
                                        <TextArea rows={5} placeholder={t('Street Name, Street Number, Building Name / Village / Village, District / District')} onChange={this.handleChange('address')} value={address} />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} label={t('Billing Address')}>
                                        <TextArea rows={5} placeholder={t('Street Name, Street Number, Building Name / Village / Village, District / District')} onChange={this.handleChange('address_billing')} value={address_billing} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...formItemLayout} label={t('Postal code')}>
                                        <Input placeholder={t('Postal code')} onChange={this.handleChange('zip_code')} value={zip_code} />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} label={t('Country')}>
                                        <Input placeholder={t('e.g: Indonesia')} onChange={this.handleChange('country')} value={country} />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} label={t('Mobile phone number')}>
                                        <Input placeholder={t('Mobile phone number')} onChange={this.handleChange('phone_1')} value={phone_1} />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} label={t('Phone number')}>
                                        <Input placeholder={t('Phone number')} onChange={this.handleChange('phone_2')} value={phone_2} />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} label={t('Fax Number')}>
                                        <Input placeholder={t('Fax Number')} onChange={this.handleChange('phone_3')} value={phone_3} />
                                    </Form.Item>
                                    <Divider />
                                    <Form.Item {...formItemLayout} label={t('TIN number')}>
                                        <Input placeholder={t('TIN number')} onChange={this.handleChange('npwp_number')} value={npwp_number} />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} label={t('NPWP address')}>
                                        <TextArea rows={5} placeholder={t('Street Name, Street Number, Building Name / Village / Village, District / District')} onChange={this.handleChange('npwp_address')} value={npwp_address} />
                                    </Form.Item>
                                </Col>
                                <Divider />
                                <Col span={12}>
                                    <Form.Item {...formItemLayout} label={t('Accounts Receivable')}>
                                        <Select placeholder={t('Select a receivable account')} onChange={this.handleChange('account_receivable_id')} />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} label={t('Debt Account')}>
                                        <Select placeholder={t('Choose a debt account')} onChange={this.handleChange('account_payable_id')} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...formItemLayout} label={t('Personal Contact')}>
                                        <Input placeholder={t('Personal Contact')} onChange={this.handleChange('contact_person')} value={contact_person} />
                                    </Form.Item>
                                </Col>
                                <Col span={24} className="mb-20">
                                    <Divider />
                                    <Row type="flex" justify="end">
                                        <Button type="default" htmlType="reset" className="mr-10">{t('Reset')}</Button>
                                        <Button type="primary" htmlType="submit" loading={fetching}>{t('Save')}</Button>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    
                </Row>
            </Layout.Content>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        fetching: state.customer.fetching,
        fetched: state.customer.fetched,
        errors: state.customer.errors,
        message: state.customer.message,
        saved: state.customer.saved
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveCustomer: (data) => dispatch(saveCustomer(data))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AddCustomer))