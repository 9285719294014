const  initState = {
    fetching: false,
    fetched: false,
    errors: null,
    menu: null
}

const menuReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_MENU_PENDING':
            return {
                ...state,
                fetching: true
            }
        case 'FETCH_MENU_SUCCESS' :
            return {
                ...state,
                fetching: false,
                fetched: true,
                menu: action.data,
                errors: null
            }
        case 'FETCH_MENU_FAILED':
            return {
                ...state,
                fetching: false,
                fetched: true,
                errors: action.errors
            }
    default:
        return state
    }
}

export default menuReducer