import React, { Component } from 'react'
import { Layout, Row, Col, Breadcrumb, Divider, Typography, Card, Input, Select, Button } from 'antd'
import { withTranslation } from 'react-i18next'

class CourseCreate extends Component{
    render(){
        const {Title} = Typography
        const {t} = this.props
        const Option = Select
        const {TextArea} = Input
        return(
            <Layout.Content>
                <div style={{padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Create Course')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Training')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <a href="/course">Master Course</a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <a href="/course/create">{t('Create Course')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider type="horizontal" style={{marginTop:0}} />
                <Card style={{borderWidth:2,borderRadius:10}}>
                    <Row type="flex" justify="space-between">
                        <Col span={12}>
                            <Row type="flex">
                                <Col style={{fontSize:14}} span={8}>{t('Course')}</Col>
                                <Col span={15}><Input/></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col style={{fontSize:14}} span={8}>{t('Type')}</Col>
                                <Col span={15}>
                                <Select style={{width:'100%'}} placeholder="Pilih">
                                    <Option value="internal">{t('Internal')}</Option>
                                    <Option value="eksternal">{t('External')}</Option>
                                </Select>
                                </Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col style={{fontSize:14}} span={8}>{t('Description')}</Col>
                                <Col span={15}><TextArea rows={4} /></Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row type="flex">
                                <Col style={{fontSize:14}} span={8}>{t('File')}</Col>
                                <Col span={15}><Input style={{backgroundColor:'#ffc069'}} value="Add File di skip" /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col style={{fontSize:14}} span={8}>{t('Video')}</Col>
                                <Col span={15}><Input style={{backgroundColor:'#ffc069'}} value="Add video di skip" /></Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
                <Row type="flex" style={{marginTop:15}}>
                    <Button style={{backgroundColor:'#fa8c16',color:'white',marginRight:10}}>{t('Cancel')}</Button>
                    <Button style={{backgroundColor:'#096dd9',color:'white'}}>{t('Save')}</Button>
                </Row>
            </Layout.Content>
        )
    }
}
export default withTranslation()(CourseCreate)