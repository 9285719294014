import React, { Component } from 'react'
import { Layout, Row, Col, Breadcrumb, Divider, Typography, Select, Input, DatePicker, Button, Icon, Card, Table, Pagination } from 'antd'
import { withTranslation } from 'react-i18next'

class Event extends Component{
    render(){
        const {t} = this.props
        const {Title} = Typography
        const {Option} = Select 
        const dateFormat = 'DD/MM/YYYY'
        return(
            <Layout.Content>
                <div style={{padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Master Event')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Training')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/course">{t('Master Event')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider type="horizontal" style={{marginTop:-10}} />
                <Row type="flex" justify="space-around">
                    <Col span={3}>
                        <Select style={{width:'100%'}} defaultValue="Running">
                            <Option value="Running">{t('Running')}</Option>
                            <Option value="Done">{t('Done')}</Option>
                            <Option value="Canceled">{t('Canceled')}</Option>
                        </Select>
                    </Col>
                    <Col span={3}><Input placeholder={t('Event Name')} /></Col>
                    <Col>
                        <Row type="flex">
                            <Col><DatePicker placeholder={t('Date From')}  /></Col>
                            <Col><Button style={{backgroundColor:'#ffc53d',color:'white'}}>{t('To')}</Button></Col>
                            <Col><DatePicker placeholder={t('Date To')}  /></Col>
                        </Row>
                    </Col>
                    <Col span={3}>
                        <Select style={{width:'100%'}} defaultValue="Internal">
                            <Option value="Internal">{t('Internal')}</Option>
                            <Option value="Eksternal">{t('External')}</Option>
                        </Select>
                    </Col>
                    <Col><Button style={{backgroundColor:'#ffc53d',color:'white'}}>{t('Filter')}</Button></Col>
                    <Col><Button href="/event/create" style={{backgroundColor:'#1890ff',color:'white'}}><Icon type="plus" />{t('Create')}</Button></Col>
                </Row>
                <Card style={{borderRadius:10,borderWidth:2, marginTop:20}}>
                    <Row type="flex" justify="space-between">
                        <Col type="flex" span={12}>
                            <Row type="flex">
                                <Col type="flex"><h3 style={{marginRight:5,marginTop:5}}>Per</h3></Col>
                                <Col type="flex" span={4}>
                                    <Select defaultValue="25" style={{width:'100%'}}>
                                        <Option value="25">25</Option>
                                        <Option value="50">50</Option>
                                        <Option value="100">100</Option>
                                        <Option value="200">200</Option>
                                        <Option value="All">{t('All')}</Option>
                                    </Select>
                                </Col>
                                <Col type="flex"><h3 style={{marginTop:5,marginLeft:5}}>{t('Line')}</h3></Col>
                            </Row>
                        </Col>
                        <Col type="flex">
                            <Row type="flex">
                                <Col><h3 style={{marginRight:5,marginTop:5}}>{t('Search')}</h3></Col>
                                <Col><Input  style={{width:200}} /></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Table style={{marginTop:10}} columns={[
                        {
                            title:`${t('Event Name')}`
                        },
                        {
                            title:`${t('Instructor')}`
                        },
                        {
                            title:`${t('Date')}`
                        },
                        {
                            title:`${t('City')}`
                        },
                        {
                            title:`${t('Status')}`
                        },
                    ]}/>
                    <div style={{marginTop:20}}>
                        <Pagination 
                            total={85}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                            pageSize={1}
                            defaultCurrent={1}
                            />
                    
                    </div>
                </Card>
            </Layout.Content>
        )
    }
}
export default withTranslation()(Event) 