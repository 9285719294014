import React from 'react'
import { withTranslation } from 'react-i18next'
import { Typography, Row, Col, Breadcrumb, Button, Layout, PageHeader, Icon, Tabs, Checkbox, Select, Input, Table, Modal,Upload, message, Divider} from 'antd'

const { Option } = Select
class Product extends React.Component {
    state = { visible: false };

    showModal = () => {
      this.setState({
        visible: true,
      });
    };
  
    handleOk = e => {
      console.log(e);
      this.setState({
        visible: false,
      });
    };
  
    handleCancel = e => {
      console.log(e);
      this.setState({
        visible: false,
      });
    };
    render() {
        const { Title } = Typography
        const { t } = this.props;
        const { TabPane } = Tabs
        const props = {
            name: 'file',
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            headers: {
              authorization: 'authorization-text',
            },
            onChange(info) {
              if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            },
          };
        const columns = [
            { 
              title: `${t("Code")}`,
              dataIndex: 'kode',
              key: 'kode',
            },
            {
              title: `${t("Name")}`,
              dataIndex: 'nama',
              key: 'nama',
            },
            {
              title: `${t("Categories")}`,
              dataIndex: 'kategori',
              key: 'kategori',
            },
            {
              title: `${t("Stock")}`,
              dataIndex: 'stok',
              key: 'stok',
            },
            {
              title: `${t("Unit")}`,
              dataIndex: 'satuan',
              key: 'satuan',
            },
            {
              title: `${t("Selling Price")}`,
              dataIndex: 'jual',
              key: 'jual',
            },
            {
              title: `${t("Buying Price")}`,
              dataIndex: 'beli',
              key: 'beli',
            },
          ];
            const columns2 = [
                { 
                title: `${t("Categories")}`,
                dataIndex: 'kategori',
                key: 'kategori',
                },
            ];
            const columns3 = [
                { 
                title: `${t("Unit")}`,
                dataIndex: 'satuan',
                key: 'satuan',
                },
            ];

            const data = [
                {
                  key: '1',
                  kategori: 'Raw Material',
                },
                {
                  key: '2',
                  kategori: 'Finish Good',
                },
                {
                  key: '3',
                  kategori: 'Produk',
                },
                {
                  key: '4',
                  kategori: 'Proyek',
                },
                {
                  key: '5',
                  kategori: 'Resource',
                },
              ];

              const data2 = [
                {
                  key: '1',
                  satuan: 'Pcs',
                },
                {
                  key: '2',
                  satuan: 'Kg',
                },
                {
                  key: '3',
                  satuan: 'Meter',
                },
                {
                  key: '4',
                  satuan: 'Sheet',
                },
                {
                  key: '5',
                  satuan: 'Roll',
                },
                {
                    key: '6',
                    satuan: 'Pack',
                },
                {
                    key: '7',
                    satuan: 'Lot',
                },
                {
                    key: '8',
                    satuan: 'Piece',
                },
              ];

        return (
            <Layout.Content>
              {/* Modal */}
              <Modal 
                title="Upload Produk"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                >
                <Upload {...props}>
                    <Button>
                    <Icon type="upload" /> Click to Upload
                    </Button>
                </Upload>
            </Modal>

                <Row style={{marginLeft:10, marginTop:20}} type="flex" justify="space-around">
                    <Col span={12}><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}>{t("Product")}</h1></Col>
                      <Col span={12} >
                          <Row type="flex" justify="end" style={{fontWeight:'bold'}}>
                              <Breadcrumb>
                                  <Breadcrumb.Item> {t("Other Data")} </Breadcrumb.Item>
                                  <Breadcrumb.Item><a href="/item">{t("Product")}</a></Breadcrumb.Item>
                              </Breadcrumb>
                          </Row>
                      </Col>
                </Row>
                <Divider></Divider>
                <div style={{fontWeight:'bold'}}>
                <Tabs defaultActiveKey="1"> 
                  <TabPane tab={t("Product List")} key="1">
                    <div style={{margin:1, borderWidth:5, borderColor:'red'}}>
                            <Row type="flex" justify="space-between">
                                <Col span={12}>
                                      <Button className="mr-10" type="primary" href="/item/create" style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold'}}>
                                        <Icon type="plus" /> {t("Add Product")} </Button>
                                      <Button className="mr-10" type="primary" style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold'}}>
                                        <Icon type="stock" /> Stok Opname
                                      </Button>
                                      <Button className="mr-10" type="primary" onClick={this.showModal} style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold'}}>
                                        <Icon type="upload" /> Upload {t("Product")} 
                                      </Button>
                                  </Col>

                                <Col span={12}>
                                  <Row type="flex" justify="end">
                                    <Col style={{marginTop:5, marginRight:20}}> <Checkbox>{t("Show Archive")}</Checkbox></Col>
                                    <Col>
                                      <Select defaultValue={t("All Categories")}>
                                        <Option value="Semua Kategori">{t("All Categories")}</Option>
                                        <Option value="Raw Material">Raw Material</Option>
                                        <Option value="Finish Good">Finish Good</Option>
                                        <Option value="Produk">{t("Product")}</Option>
                                        <Option value="Proyek">{t("Project")}</Option>
                                        <Option value="Resource">Resource</Option>
                                      </Select>
                                    </Col>
                                  </Row>
                                </Col> 
                                
                            </Row>
                            </div>
                            <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
                                <Row type="flex" justify="space-between"> 
                                    <Col>
                                        <Row type="flex">
                                        <Col><h3 style={{marginRight:4, fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>Per</h3></Col>
                                            <Col><Select defaultValue="25" size='small'>
                                                    <Option value="25">25</Option>
                                                    <Option value="50">50</Option>
                                                    <Option value="100">100</Option>
                                                    <Option value="200">200</Option>
                                                    <Option value="All">All</Option>
                                                </Select>
                                            </Col>
                                            <Col><h3 style={{marginLeft:4, fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>{t("Line")}</h3></Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row type="flex">
                                        <Col style={{marginRight:5, }}><h3 style={{ fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>{t('Search')}</h3></Col>
                                            <Col><Input size="small"></Input></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div style={{marginTop:20, border: '1px solid #ebedf0'}}>
                                    <Table columns={columns} ></Table>
                                </div>
                            </PageHeader>
                        
                        </TabPane>
                    <TabPane tab={t("Categories")} key="2">
                    <Row type="flex" >
                                <Col style={{marginLeft:10}}><Button type="primary" onClick={this.showModal} style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold'}}><Icon type="plus" /> {t("Add Categories")} </Button></Col>
                                <Modal 
                                    title="Buat Kategori"
                                    visible={this.state.visible}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                    >
                                    <Row>
                                            <Row type="flex" justify="space-between">
                                                <Col span={8} style={{fontWeight:'bold'}}><label>Kategori</label></Col>
                                                <Col span={16}><Input/></Col>
                                            </Row>
                                            <Row type="flex" style={{marginTop:20}}>
                                                <Col span={8} style={{fontWeight:'bold'}}><label>No Order</label></Col>
                                                <Col span={16}><Input/></Col>
                                            </Row>
                                    </Row>
                                </Modal>
                            </Row>
                            <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
                                <Row type="flex" justify="space-between"> 
                                <Col>
                                        <Row type="flex">
                                        <Col><h3 style={{marginRight:4, fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>Per</h3></Col>
                                            <Col><Select defaultValue="25" size='small'>
                                                    <Option value="25">25</Option>
                                                    <Option value="50">50</Option>
                                                    <Option value="100">100</Option>
                                                    <Option value="200">200</Option>
                                                    <Option value="All">All</Option>
                                                </Select>
                                            </Col>
                                            <Col><h3 style={{marginLeft:4, fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>{t("Line")}</h3></Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row type="flex">
                                        <Col style={{marginRight:5, }}><h3 style={{ fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>{t('Search')}</h3></Col>
                                            <Col><Input size="small"></Input></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div style={{marginTop:10, border: '1px solid #ebedf0'}}>
                                    <Table columns={columns2} dataSource={data}>
                                    </Table>
                                </div>
                            </PageHeader>
                    </TabPane>
                    <TabPane tab={t("Unit")} key="3">
                        <Row type="flex">
                                    <Col style={{marginLeft:10}}><Button type="primary" onClick={this.showModal} style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold'}}><Icon type="plus" /> {t("Add Unit")} </Button></Col>
                                        <Modal 
                                            title="Buat Satuan"
                                            visible={this.state.visible}
                                            onOk={this.handleOk}
                                            onCancel={this.handleCancel}
                                            >
                                            <Row>
                                                    <Row type="flex" justify="space-between">
                                                        <Col span={8} style={{fontWeight:'bold'}}><label>Satuan</label></Col>
                                                        <Col span={16}><Input/></Col>
                                                    </Row>
                                                    <Row type="flex" style={{marginTop:20}}>
                                                        <Col span={8} style={{fontWeight:'bold'}}><label>No Order</label></Col>
                                                        <Col span={16}><Input/></Col>
                                                    </Row>
                                            </Row>
                                        </Modal>
                        </Row>
                        <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
                                <Row type="flex" justify="space-between"> 
                                    <Col>
                                        <Row type="flex">
                                        <Col><h3 style={{marginRight:4, fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>Per</h3></Col>
                                            <Col><Select defaultValue="25" size='small'>
                                                    <Option value="25">25</Option>
                                                    <Option value="50">50</Option>
                                                    <Option value="100">100</Option>
                                                    <Option value="200">200</Option>
                                                    <Option value="All">All</Option>
                                                </Select>
                                            </Col>
                                            <Col><h3 style={{marginLeft:4, fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>{t("Line")}</h3></Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row type="flex">
                                        <Col style={{marginRight:5, }}><h3 style={{ fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>{t('Search')}</h3></Col>
                                            <Col><Input size="small"></Input></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                  <div style={{marginTop:10, border: '1px solid #ebedf0'}}>
                                        <Table columns={columns3} dataSource={data2}>
                                        </Table>
                                  </div>
                    </PageHeader>
                    </TabPane>
                </Tabs>
                </div>
            </Layout.Content>
        )
    }
}


export default withTranslation() (Product)