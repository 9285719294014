import React, { Component } from 'react'
import { Row, Typography, Col, Breadcrumb, Layout, Divider, Input, Select, DatePicker, PageHeader, Button, Icon, Table, Checkbox } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next';

class AddReport4 extends Component{
    render(){
        const { t } = this.props;
        const {Title} = Typography
        const Option = Select
        const { RangePicker } = DatePicker
        const dateFormat = ['DD/MM/YYYY', 'DD/MM/YYYY']
        const {TextArea} = Input
        const columns = [
            {
              title: `${t('Supplier/Product Code')}`,
              dataIndex: 'Suplier',
              key: 'Suplier',
            },
            {
              title: `${t('Product Name')}`,
              dataIndex: 'Nama Produk',
              key: 'Nama Produk',
            },
            {
                title: `${t('Unit')}`,
                dataIndex: 'Satuan',
                key: 'Satuan',
            },
            {
                title: `${t('Quantity')}`,
                dataIndex: 'Kuantitas',
                key: 'Kuantitas',
            },
          ];
        return(
            <Layout.Content>
            <PageHeader style={{ border: '1px solid #ebedf0'}}>
                <div>
                    <Row type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Purchase Report Per Supplier')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Purchase')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/purchase-report">{t('Purchase Report')}</a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/purchase-report/create5">{t('Purchase of Shipping')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                </PageHeader>
                <PageHeader style={{ border: '1px solid #ebedf0'}}>
                    <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                        <Row type="flex">
                            <Col span={16}>
                                <Row type="flex" > 
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Filter by Period')}</label></Col>
                                    <Col span={15}> <RangePicker defaultValue={[moment('2015/01/01', dateFormat), moment('2015/01/01', dateFormat)]}format={dateFormat}/></Col>
                                </Row>
                                <Row type="flex" style={{marginTop:20}}>
                                    <Col span={7} style={{fontWeight:'bold'}}><label>Filter Per</label></Col>
                                    <Col span={15} > <Select placeholder={t('--Select--')} style={{width:'100%'}}>
                                            <Option value="pel">{t('Customer')}</Option>
                                            <Option value="pen">{t('Purchase of Shipping')}</Option>
                                            <Option value="pro">{t('Product')}</Option>
                                        </Select>
                                    </Col>     
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row type="flex" justify="end">
                                    <Col style={{marginRight:10,textAlign:'right'}}><Button style={{backgroundColor:'#0d1933',color:'white',fontWeight:'bold'}}>Filter</Button></Col>
                                </Row>
                            </Col>
                        </Row>
                        </div>
                    </PageHeader>        
                    <PageHeader style={{ border: '1px solid #ebedf0' ,marginTop:10}}>
                    <Row type="flex" style={{fontWeight:'bold'}} >
                        <Col span={24}>
                            <Row justify="space-around" type="flex">
                                <Col>PT. REKADAYA MULTI ADIPRIMA</Col>
                            </Row>
                            <Row justify="space-around" type="flex" style={{fontSize:22}}>
                                <Col>{t('Purchase of Shipping')}</Col>
                            </Row>
                            <Row justify="space-around" type="flex">
                                <Col>21/02/2020 - 21/02/2020</Col>
                            </Row>
                            <Row justify="space-around" type="flex">
                                <Col>{t('in IDR')}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <PageHeader style={{ border: '1px solid #ebedf0' ,marginTop:10}}>
                        <Row type="flex">
                            <Col><Button style={{backgroundColor:'white',color:'#595959',fontWeight:'bold'}}><Icon type="file-excel" />CSV</Button></Col>
                            <Col><Button style={{backgroundColor:'white',color:'#595959',fontWeight:'bold'}}><Icon type="file-excel" />Excel</Button></Col>
                            <Col><Button style={{backgroundColor:'white',color:'#595959',fontWeight:'bold'}}><Icon type="file-pdf" />{t('View PDF')}</Button></Col>

                        </Row>    
                        <Table columns ={columns}></Table>
                    </PageHeader>
                    </PageHeader>
            </Layout.Content> 
        )
    }
}
export default withTranslation()(AddReport4)