import React from 'react'
import { Layout, 
         Row, 
         Col, 
         Breadcrumb, 
         Typography, 
         PageHeader, 
         DatePicker,
         Button, 
         Icon, 
         Select, 
         Input, 
         Divider, 
         Table, 
         Modal,
         Upload,
         message,
         visible} from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next';

class AddOrder  extends React.Component {
    state = { visible: false };

    showUpload = () => {
      this.setState({
        visible: true,
      });
    };
    render(){
        const {Title, Text} = Typography
        const {TextArea} = Input
        const { t } = this.props
        const dateFormat =' DD/MM/YYYY'
        const {Option} = Select
        const props = {
            name: 'file',
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            headers: {
              authorization: 'authorization-text',
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                  console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                  message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                  message.error(`${info.file.name} file upload failed.`);
                }
              },
          };
        const columns = [
            {
              title: `${t('Product')}`,
              dataIndex: 'Product',
              key: 'Product',
            },
            {
              title: `${t('Description')}`,
              dataIndex: 'Description',
              key: 'DeskrDescriptionipsi',
            },
            {
              title: `${t('Quantity')}`,
              dataIndex: 'Quantity',
              key: 'Quantity',
            },
            {
              title: `${t('Unit price')}`,
              dataIndex: 'Unit price',
              key: 'Unit price',
            },
            {
              title: `${t('Discount')}`,
              dataIndex: 'Discount',
              key: 'DisDiscountkon',
            },
            {
              title: `${t('Amount')}`,
              dataIndex: 'Amount ',
              key: 'Amount ',
            },
            {
              title: `${t('Tax')}`,
              dataIndex: 'Tax ',
              key: 'Tax ',
              },
          ];
        return (
            <Layout.Content>
                <PageHeader style={{border: '1px solid rgb(235,237,340)'}}>
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Create Order')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Sales')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="">{t('Sales Orders')} </a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="">{t('Create Order')} </a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                </PageHeader>

                <div style={{margin:10,padding:20,borderRadius:10}}>                            
                    <Row type="flex" justify="space-around">
                        <Col span={12} >
                            <Row type="flex" >
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Supplier')}</label></Col>
                                <Col span={15}><Select placeholder={t('Select')} style={{width:'100%'}}>
                                                    <Option value="Pilih--">{t('Select')}</Option>
                                                    <Option value=""><Input></Input></Option>
                                                    <Option value="ASTRA DAIHATSU MOTOR - (KAP)">ASTRA DAIHATSU MOTOR - (KAP)</Option>
                                                    <Option value="5ASTRA DAIHATSU MOTOR - (SAP)">ASTRA DAIHATSU MOTOR - (SAP)</Option>
                                                    <Option value="ASTRA DAIHATSU MOTOR - (APC)">ASTRA DAIHATSU MOTOR - (APC)</Option>
                                                    <Option value="PT.KOTOBUKIYA INDO CLASSIC INDUSTRIES - (KIC)">PT.KOTOBUKIYA INDO CLASSIC INDUSTRIES - (KIC)</Option>
                                                    <Option value="PT.APM ARMADA AUTOPARTS - (AAA)">PT.APM ARMADA AUTOPARTS - (AAA)</Option>
                                                    <Option value="PT.ARMSTRONG - (AMS)">PT.ARMSTRONG - (AMS)</Option>
                                                    <Option value="PT.BONECOM TRICOM  - (BTI)">PT.BONECOM TRICOM  - (BTI)</Option>
                                                    <Option value="PT.DASA WINDU AGUNG - (DWA)">PT.DASA WINDU AGUNG - (DWA)</Option>
                                                    <Option value="PT. ECHO ADVANCED TECHNOLOGY INDONESIA - (ETI)">PT. ECHO ADVANCED TECHNOLOGY INDONESIA - (ETI)</Option>
                                                    <Option value="PT. HINO MOTORS MANUFACTURING INDONESIA - (HMI)">PT. HINO MOTORS MANUFACTURING INDONESIA - (HMI)</Option>
                                                    <Option value="PT. HOWA INDONESIA - (HWI)">PT. HOWA INDONESIA - (HWI)</Option>
                                                    <Option value="PT. INOAC POLYTECHNO INDONESIA - (IPI)">PT. INOAC POLYTECHNO INDONESIA - (IPI)</Option>
                                                    <Option value="PT. INTIPOLY METAL - (IPM)">PT. INTIPOLY METAL - (IPM)</Option>
                                                    <Option value="PT. IRC INOAC INDONESIA - (IRC)">PT. IRC INOAC INDONESIA - (IRC)</Option>
                                                    <Option value="PT. JAYA VICTORY CEMERLANG - (JVC)">PT. JAYA VICTORY CEMERLANG - (JVC)</Option>
                                                    <Option value="PT. KARYA BAHAN BERLIN - (KBB)">PT. KARYA BAHAN BERLIN - (KBB)</Option>
                                                    <Option value="PT. MAJU BERSANA PERSADA DAYAMU - (MBP)">PT. MAJU BERSANA PERSADA DAYAMU - (MBP)</Option>
                                                    <Option value="PT. MEIWA INDONESIA - (MWI)">PT. MEIWA INDONESIA - (MWI)</Option>
                                                    <Option value="PT. METINDO ERASAKTI - (MES)">PT. METINDO ERASAKTI - (MES)</Option>
                                                    <Option value="PT. MUTHIA ABADI PERIZKY - (MAP)">PT. MUTHIA ABADI PERIZKY - (MAP)</Option>
                                                    <Option value="PT. NITTO MATERIALS - (NTI)">PT. NITTO MATERIALS - (NTI)</Option>
                                                    <Option value="PT. NUSA KEIHIN INDONESIA - (NKI)">PT. NUSA KEIHIN INDONESIA - (NKI)</Option>
                                                    <Option value="PT. SANKO GOSEI - (SGI)">PT. SANKO GOSEI - (SGI)</Option>
                                                    <Option value="PT. SARI TAKAGI ELOK PRODUK - (STP)">PT. SARI TAKAGI ELOK PRODUK - (STP)</Option>
                                                    <Option value="PT. SUGITY CREATIVES - (SGT)">PT. SUGITY CREATIVES - (SGT)</Option>
                                                    <Option value="PT. SURYA GEMILANG PERKASA - (SGP)">PT. SURYA GEMILANG PERKASA - (SGP)</Option>
                                                    <Option value="PT. SUZUKI INDOMOBIL MOTOR - (SIM)">PT. SUZUKI INDOMOBIL MOTOR - (SIM)</Option>
                                                    <Option value="PT. TOYOTA BOSHOKU INDONESIA - (TBI)">PT. TOYOTA BOSHOKU INDONESIA - (TBI)</Option>
                                                    <Option value="PT. TUFFINDO NITTTOKU AUTONEUM - (TNA)">PT. TUFFINDO NITTTOKU AUTONEUM - (TNA)</Option>
                                                    <Option value="PT. USRA TAMPI - (ust)">PT. USRA TAMPI - (ust)</Option>
                                                    <Option value="PT. VUTEQ INDONESIA - (VQI)">PT. VUTEQ INDONESIA - (VQI)</Option>
                                                </Select>
                                </Col>
                            </Row>
                            <Row type="flex" style={{marginTop:10}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Billing Address')}</label></Col>
                                <Col span={15}><TextArea rows={4} style={{width:'100%'}}></TextArea></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:10}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Addressed to')}</label></Col>
                                <Col span={15}><Input rows={4} style={{width:'100%'}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:10}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Order Title')}</label></Col>
                                <Col span={15}><Input rows={4} style={{width:'100%'}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:10}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Original Warehouse')}</label></Col>
                                <Col span={15} ><Select placeholder={t('Select')} style={{width:'100%'}}>
                                                    <Option value=""><Input></Input></Option>
                                                    <Option value="(GDG-0003) - Gudang Finished Goods">(GDG-0003) - Gudang Finished Goods</Option>
                                                    <Option value="(GDG-0001) - GUDANG Raw Material">(GDG-0001) - GUDANG Raw Material</Option>
                                                </Select>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={12} >
                            <Row type="flex" style={{marginTop:10}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Order Number')}</label></Col>
                                <Col span={15}><Input rows={4} style={{width:'100%'}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Order Dat')}</label></Col>
                                <Col span={15}><DatePicker style={{width:'100%'}} defaultValue={moment('1/2/2020',dateFormat)} format={dateFormat} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Valid until')}</label></Col>
                                <Col span={15}><DatePicker style={{width:'100%'}} defaultValue={moment('1/3/2020',dateFormat)} format={dateFormat} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Terms of payment')}</label></Col>
                                <Col span={15}><Select placeholder={t('Select')} style={{width:'100%'}}>
                                                    <Option value=""><Input></Input></Option>
                                                    <Option value="Cash On Delivery">Cash On Delivery</Option>
                                                    <Option value="NET 15">NET 15</Option>
                                                    <Option value="NET 30">NET 30</Option>
                                                    <Option value="NET 60">NET 60</Option>
                                                </Select>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    </div>
                    <Col >
                        <div style={{margin:5, padding:10}}>
                            <Table columns={columns}></Table>
                        </div>
                        <Row type='flex'justify='space-between'>
                        <Button href="" type="primary" style={{margin:10, backgroundColor:"#9966ff", color:"white"}}>
                            <Icon type="plus"/>{t('Add Line')}</Button>
                    </Row>
                    </Col>
                    
                    <Divider type="horizontal"/>
                    <Row type="flex" justify="space-between">
                        <Col span={14}>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={18}><TextArea placeholder="Pesan" rows={4} style={{width:'100%'}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={5}><Text>File</Text></Col>
                                <Row type='flex'>
                                    <Upload {...props}>
                                        <Button>
                                            Choose File
                                        </Button> 
                                    </Upload>
                                    <Text>No File Chosen</Text>
                                </Row>
                                <Col span={15} onClick={this.showUpload} type="primary"></Col>
                            </Row>
                        </Col>
                        <Col span={10}>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={5}><Text>Subtotal</Text></Col>
                                <Col span={15} justify="end" placeholder="Rp. 0,00"></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={5}><Text>{('Tax')}</Text></Col>
                                <Col span={15} justify="end" placeholder="Rp. 0,00"></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={5}><Text>Total</Text></Col>
                                <Col span={15} justify="end" placeholder="Rp. 0,00"></Col>
                            </Row>
                        </Col>
                    </Row>

                    <Divider type="horizontal"/>
                    <Row type="flex" style={{marginBottom:30}}>
                        <Col style={{marginTop:15,marginLeft:10,marginRight:10}}><Button href="/sales-order" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                        <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Create Order')} </Button></Col>
                    </Row>
            </Layout.Content>
        )
    }
}

export default withTranslation() (AddOrder)