import React, { Component } from 'react'
import { Layout, Row, Col, Breadcrumb, Divider, Typography, Card, Input, Select, DatePicker, Button } from 'antd'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

class CoaCreate extends Component{
    render(){
        const {t} = this.props
        const {Title} = Typography
        const {TextArea} = Input
        const {Option} = Select
        const dateFormat= 'DD/MM/YYYY'
        return(
            <Layout.Content>
                <div style={{padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Create New Account')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Accounting')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>{t('Register Account')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/course">{t('Create New Account')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider type="horizontal" style={{marginTop:-10}}/>
                <Card style={{borderWidth:2,borderRadius:10}}>
                    <Row type="flex" justify="space-between">
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={8}>{t('Account Name')}</Col>
                                <Col span={15}><Input/></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}>{t('Account Code')}</Col>
                                <Col span={15}><Input/></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}>{t('Description')}</Col>
                                <Col span={15}><TextArea rows={4} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}>{t('Catagory')}</Col>
                                <Col span={15}>
                                    <Select style={{width:'100%'}} defaultValue="Asset">
                                        <Option value="Asset">{t('Asset')}</Option>
                                        <Option value="Asset Kas/Bank">{t('Asset Kas/Bank')}</Option>
                                        <Option value="Modal">{t('Modal')}</Option>
                                        <Option value="Cost/Expense">{t('Cost/Expense')}</Option>
                                        <Option value="Income">{t('Income')}</Option>
                                        <Option value="Dept">{t('Dept')}</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}>{t('Sub Account From')}</Col>
                                <Col span={15}>
                                    <Select style={{width:'100%'}} defaultValue="Nothing">
                                        <Option value="Nothing">{t('Nothing')}</Option>
                                        <Option value="Asset">{t('Asset')}</Option>
                                        <Option value="......Harga Lancar">{t('......Harga Lancar')}</Option>
                                        <Option value=".........Bank">{t('.........Bank')}</Option>
                                        <Option value=".........Kas">{t('.........Kas')}</Option>
                                        <Option value="............Kas Bank POS">{t('............Kas Bank POS')}</Option>
                                        <Option value="............Kas POS">{t('............Kas POS')}</Option>
                                        <Option value=".........Persediaan Barang">{t('.........Persediaan Barang')}</Option>
                                        <Option value=".........Piutang">{t('.........Piutang')}</Option>
                                        <Option value=".........Piutang Belum Ditagih">{t('.........Piutang Belum Ditagih')}</Option>
                                        <Option value=".........PPN Masukan">{t('.........PPN Masukan')}</Option>
                                        <Option value="......Harga Tetap">{t('......Harga Tetap')}</Option>
                                        <Option value=".........Akumulasi Penyusutan Peralatan">{t('.........Akumulasi Penyusutan Peralatan')}</Option>
                                        <Option value=".........Peralatan Kantor">{t('.........Peralatan Kantor')}</Option>
                                        <Option value=".........Sewa Dibayar Dimuka">{t('.........Sewa Dibayar Dimuka')}</Option>
                                        <Option value="Hutang">{t('Hutang')}</Option>
                                        <Option value="......Hutang Gaji Karyawan">{t('......Hutang Gaji Karyawan')}</Option>
                                        <Option value="......Hutang Lainnya">{t('......Hutang Lainnya')}</Option>
                                        <Option value="......Hutang Usaha">{t('......Hutang Usaha')}</Option>
                                        <Option value="......PPH Pasal 21">{t('......PPH Pasal 21')}</Option>
                                        <Option value="......PPH Pasal 23">{t('......PPH Pasal 23')}</Option>
                                        <Option value="......PPN Keluaran">{t('......PPN Keluaran')}</Option>
                                        <Option value="Modal">{t('Modal')}</Option>
                                        <Option value="......Ekuitas Saldo Awal">{t('......Ekuitas Saldo Awal')}</Option>
                                        <Option value="......Prive">{t('......Prive')}</Option>
                                        <Option value="......Saldo Awal">{t('......Saldo Awal')}</Option>
                                        <Option value="Pendapatan">{t('Pendapatan')}</Option>
                                        <Option value="......Pendapatan Jasa">{t('......Pendapatan Jasa')}</Option>
                                        <Option value="......Pendapatan Penjualan">{t('......Pendapatan Penjualan')}</Option>
                                        <Option value="......Penjualan Belum Ditagih">{t('......Penjualan Belum Ditagih')}</Option>
                                        <Option value="Potogan Penjualan">{t('Potogan Penjualan')}</Option>
                                        <Option value="Biaya">{t('Biaya')}</Option>
                                        <Option value="......Biaya Gaji">{t('......Biaya Gaji')}</Option>
                                        <Option value="......Biaya Lainnya">{t('......Biaya Lainnya')}</Option>
                                        <Option value="......Biaya Penyusutan dan Amortisasi">{t('......Biaya Penyusutan dan Amortisasi')}</Option>
                                        <Option value="......Biaya Sewa">{t('......Biaya Sewa')}</Option>
                                        <Option value="......Biaya Transportasi">{t('......Biaya Transportasi')}</Option>
                                        <Option value="......Harga Pokok Penjualan">{t('......Harga Pokok Penjualan')}</Option>
                                    </Select>
                                </Col>
                               
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Card style={{borderWidth:2,borderRadius:10,padding:-10}}>
                                <p style={{fontWeight:'bold',fontSize:18}}>{t('FIRST SALDO')}</p>
                                <Row type="flex">
                                    <Col span={8}>{t('Saldo Amount')}</Col>
                                    <Col span={15}><Input style={{textAlign:'right'}} defaultValue="Rp. " /></Col>
                                </Row>
                                <Row type="flex" style={{marginTop:15}}>
                                    <Col span={8}>{t('By Date')}</Col>
                                    <Col span={15}><DatePicker style={{width:'100%'}} defaultValue={moment('02/03/2020', dateFormat)} format={dateFormat} /></Col>
                                </Row>
                                <Row type="flex" style={{marginTop:15}}>
                                    <Col span={8}>{t('Transfer To')}</Col>
                                    <Col span={15}>
                                        <Select style={{width:'100%'}} defaultValue="Nothing">
                                            <Option value="Nothing">{t('Nothing')}</Option>
                                            <Option value="Asset">{t('Asset')}</Option>
                                            <Option value="......Harga Lancar">{t('......Harga Lancar')}</Option>
                                            <Option value=".........Bank">{t('.........Bank')}</Option>
                                            <Option value=".........Kas">{t('.........Kas')}</Option>
                                            <Option value="............Kas Bank POS">{t('............Kas Bank POS')}</Option>
                                            <Option value="............Kas POS">{t('............Kas POS')}</Option>
                                            <Option value=".........Persediaan Barang">{t('.........Persediaan Barang')}</Option>
                                            <Option value=".........Piutang">{t('.........Piutang')}</Option>
                                            <Option value=".........Piutang Belum Ditagih">{t('.........Piutang Belum Ditagih')}</Option>
                                            <Option value=".........PPN Masukan">{t('.........PPN Masukan')}</Option>
                                            <Option value="......Harga Tetap">{t('......Harga Tetap')}</Option>
                                            <Option value=".........Akumulasi Penyusutan Peralatan">{t('.........Akumulasi Penyusutan Peralatan')}</Option>
                                            <Option value=".........Peralatan Kantor">{t('.........Peralatan Kantor')}</Option>
                                            <Option value=".........Sewa Dibayar Dimuka">{t('.........Sewa Dibayar Dimuka')}</Option>
                                            <Option value="Hutang">{t('Hutang')}</Option>
                                            <Option value="......Hutang Gaji Karyawan">{t('......Hutang Gaji Karyawan')}</Option>
                                            <Option value="......Hutang Lainnya">{t('......Hutang Lainnya')}</Option>
                                            <Option value="......Hutang Usaha">{t('......Hutang Usaha')}</Option>
                                            <Option value="......PPH Pasal 21">{t('......PPH Pasal 21')}</Option>
                                            <Option value="......PPH Pasal 23">{t('......PPH Pasal 23')}</Option>
                                            <Option value="......PPN Keluaran">{t('......PPN Keluaran')}</Option>
                                            <Option value="Modal">{t('Modal')}</Option>
                                            <Option value="......Ekuitas Saldo Awal">{t('......Ekuitas Saldo Awal')}</Option>
                                            <Option value="......Prive">{t('......Prive')}</Option>
                                            <Option value="......Saldo Awal">{t('......Saldo Awal')}</Option>
                                            <Option value="Pendapatan">{t('Pendapatan')}</Option>
                                            <Option value="......Pendapatan Jasa">{t('......Pendapatan Jasa')}</Option>
                                            <Option value="......Pendapatan Penjualan">{t('......Pendapatan Penjualan')}</Option>
                                            <Option value="......Penjualan Belum Ditagih">{t('......Penjualan Belum Ditagih')}</Option>
                                            <Option value="Potogan Penjualan">{t('Potogan Penjualan')}</Option>
                                            <Option value="Biaya">{t('Biaya')}</Option>
                                            <Option value="......Biaya Gaji">{t('......Biaya Gaji')}</Option>
                                            <Option value="......Biaya Lainnya">{t('......Biaya Lainnya')}</Option>
                                            <Option value="......Biaya Penyusutan dan Amortisasi">{t('......Biaya Penyusutan dan Amortisasi')}</Option>
                                            <Option value="......Biaya Sewa">{t('......Biaya Sewa')}</Option>
                                            <Option value="......Biaya Transportasi">{t('......Biaya Transportasi')}</Option>
                                            <Option value="......Harga Pokok Penjualan">{t('......Harga Pokok Penjualan')}</Option>
                                        </Select>
                                    </Col>
                               
                            </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row type="flex" style={{marginTop:15,marginBottom:10}}>
                        <Button style={{marginRight:5,backgroundColor:'#ffc53d',color:'white'}}>{t('Back')}</Button>
                        <Button style={{marginRight:5,backgroundColor:'#1890ff',color:'white'}}>{t('Save Journal')}</Button>
                        <Button style={{marginRight:5,backgroundColor:'#1890ff',color:'white'}}>{t('Save & Input Again')}</Button>
                    </Row>
                </Card>
            </Layout.Content>
        )
    }
}
export default withTranslation()(CoaCreate) 