import React, { Component } from 'react';
import {Typography,Layout, Row,Col,Breadcrumb,Button,Icon, PageHeader,Select,Input,Table,DatePicker,Modal,Upload, message} from 'antd';
import { withTranslation } from 'react-i18next';

class Position extends Component{
    
    state = { visible: false };

    showModal = () => {
      this.setState({
        visible: true,
      });
    };
  
    handleOk = e => {
      console.log(e);
      this.setState({
        visible: false,
      });
    };
  
    handleCancel = e => {
      console.log(e);
      this.setState({
        visible: false,
      });
    };

  render(){
    const { t } = this.props;
    const {Title} = Typography
    const {Option} = Select
    const { Dragger } = Upload;
    const props = {
      name: 'file',
      multiple: true,
      action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    const columns = [
      {
        title: `${t('Name')}`,
        dataIndex: 'Nama',
        key: 'Nama',
      },
      {
        title: `${t('Obtained Benefits')}`,
        dataIndex: 'Tunjangan_Didapat',
        key: 'Tunjangan_Didapat',
      },
      {
        title: `${t('Supplier')}`,
        dataIndex: 'Pemasok',
        key: 'Pemasok',
      },
      {
        title: `${t('Amount of Allowance')}`,
        dataIndex: 'Jumlah_Tunjangan',
        key: 'Jumlah_Tunjangan',
      },
      {
        title: `${t('Description')}`,
        dataIndex: 'Deskripsi',
        key: 'Deskripsi',
      },
      {
        title: `${t('Total Employee')}`,
        dataIndex: 'Jumlah_Pegawai',
        key: 'Jumlah_Pegawai', 
      },
    ];
      return(
        <Layout.Content>
          <PageHeader style={{ border: '1px solid #ebedf0'}}>
            <Row style={{margin:1}} type="flex" justify="space-around">
              <Col  span={12}><Title level={4}>{t('Positions & Benefits')}</Title></Col>
                <Col span={12} >
                  <Row type="flex" justify="end">
                    <Breadcrumb>
                      <Breadcrumb.Item>{t('Personnel')}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <a href="">{t('Positions & Benefits')}</a>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                  </Row>
                </Col>
            </Row>
          </PageHeader>  
          
          <PageHeader>
            <Col>
              <Row >
                <Button href="/position/create" style={{backgroundColor:'#0d1933',color:'white',fontWeight:'bold'}}><Icon type="plus" /> {t('Add Position')} </Button>
                <Button type="primary" onClick={this.showModal} style={{backgroundColor:'#0d1933',color:'white',fontWeight:'bold',marginLeft:10}}> Import </Button>
                    <Modal
                        title="Impor data Jabatan dan Gaji"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon"><Icon type="inbox" /></p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">Format berkas *.xlsx, Ukuran maksimal 1MB</p>
                        </Dragger>
                    </Modal>
                <Button style={{backgroundColor:'#0d1933',color:'white',fontWeight:'bold',marginLeft:10}}> Ekspor </Button>
              </Row>
            </Col>
          </PageHeader>

          <div style={{margin:10,borderWidth:5,borderColor:'red',padding:20}}>
            <Row type="flex" justify="space-between"> 
              <Col>
                <Row type="flex">
                  <Col style={{marginRight:10}}><h3>Per</h3></Col>
                    <Col>
                      <Select style={{width:80}}defaultValue="25" >
                        <Option value="25">25</Option>
                        <Option value="50">50</Option>
                        <Option value="100">100</Option>
                        <Option value="200">200</Option>
                        <Option value="All">All</Option>
                      </Select>
                    </Col>
                    <Col style={{marginLeft:10}}><h3>{t('Line')}</h3></Col>
                </Row>
              </Col>
              <Col>
                <Row type="flex">
                  <Col style={{marginRight:10}}><h3>{t('Search')}</h3></Col>
                  <Col><Input></Input></Col>
                </Row>
              </Col>
              </Row>
                <div style={{marginTop:20}}>
                  <Table columns={columns} ></Table>
                </div>
              </div>
        </Layout.Content>
      )
  }
}
export default withTranslation()(Position)