import React from 'react'
import {Row, Col, Breadcrumb, Layout, PageHeader, Button, DatePicker, Select, Input, Icon, Divider, Table} from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

class AddBooking extends React.Component{
    render() {
        const Option = Select
        const dateFormat = 'DD/MM/YYYY'
        const {TextArea} = Input
        const { t } = this.props;
        const columns = [
            { 
              title: `${t('Product')}`,
              dataIndex: 'produk',
              key: 'produk',
            },
            {
              title: `${t('Description')}`,
              dataIndex: 'deskripsi',
              key: 'deskripsi',
            },
            {
              title: `${t('Quantity')}`,
              dataIndex: 'kuantitas',
              key: 'kuantitas',
            },
            {
              title: `${t('Unit')}`,
              dataIndex: 'satuan',
              key: 'satuan',
            },
            {
              title: `${t('Price per Unit')}`,
              dataIndex: 'harga',
              key: 'harga',
            },
            {
              title: `${t('Amount')}`,
              dataIndex: 'jumlah',
              key: 'jumlah',
            },
            {
              title: `${t('Tax')}`,
              dataIndex: 'pajak',
              key: 'pajak',
            }
          ];

        return (
            <Layout.Content>
                <Row style={{marginLeft:10, marginTop:20}} type="flex" justify="space-around">
                    <Col span={12}><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}>{t('Add Order')}</h1></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end" style={{fontWeight:'bold'}}>
                                <Breadcrumb>
                                    <Breadcrumb.Item><a href="">{t('Selling')}</a></Breadcrumb.Item>
                                    <Breadcrumb.Item><a href="/purchase-order">{t('Order')}</a></Breadcrumb.Item>
                                    <Breadcrumb.Item><a href="">{t('Add Order')}</a></Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                </Row>

            <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
                    <Row>
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={7} style={{fontWeight:'bold'}}><label>{t('Supplier')}</label></Col>
                                <Col span={16}><Select placeholder={t('Choose')} style={{width:'100%'}}>
                                        <Option value="HWI">(HWI) - PT.HOWA INDONESIA</Option>
                                        <Option value="PSI">(PSI) - PT.POSMI STEEL INDONESIA</Option>
                                        <Option value="SP">(SP-001-AAA) - PT.APM ARMADA AUTOPARTS</Option>
                                    </Select></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={7} style={{fontWeight:'bold'}}><label>{t('Address')}</label></Col>
                                <Col span={16}><TextArea rows={4} style={{width:'100%', height:60}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={7} style={{fontWeight:'bold'}}><label>{t('To')}</label></Col>
                                <Col span={16}><Input/></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Divider type="horizontal"/>
                                </Col>
                            </Row>
                            <Row type="flex" >
                                <Col span={7} style={{fontWeight:'bold'}}><label>{t('Project')}</label></Col>
                                <Col span={16}><Select placeholder={t('Choose')} style={{width:'100%'}}>
                                        <Option value="disabled" disabled>{t('Notfound')}</Option>
                                    </Select></Col>
                            </Row>
                        </Col>   
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={8} style={{fontWeight:'bold', marginLeft:20}}><label>{t('Booknum')}</label></Col>
                                <Col span={15}><Input></Input></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold', marginLeft:20}}><label>{t('Referencenum')}</label></Col>
                                <Col span={15}><Input></Input></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold', marginLeft:20}}><label>{t('Transdate')}</label></Col>
                                <Col span={15}>
                                    <div>
                                        <DatePicker style={{marginRight:15}} defaultValue={moment('01/02/2020', dateFormat)} format={dateFormat} />
                                    </div></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold', marginLeft:20}}><label>{t('Validuntil')}</label></Col>
                                <Col span={15}>
                                    <div>
                                        <DatePicker defaultValue={moment('29/02/2020', dateFormat)} format={dateFormat} />
                                    </div></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold', marginLeft:20}}><label>{t('Mainpay')}</label></Col>
                                <Col span={15}><Select defaultValue="Cash on delivery" style={{width:'100%'}}>
                                        <Option value="15">Net 15</Option>
                                        <Option value="30">Net 30</Option>
                                        <Option value="60">Net 60</Option>
                                    </Select></Col>
                            </Row>
                        </Col> 
                    </Row>
                    <Col>
                        <Divider type="horizontal"/>
                    </Col>
                    <h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}>{t('Product')}</h1>
                    <div style={{marginTop:20, border: '1px solid #ebedf0'}}>
                            <Table columns={columns}></Table>
                    </div>
                    <Col span={12}>
                            <Button style={{marginTop:20, backgroundColor:'#391085',color:'white',fontWeight:'bold',marginBottom:10}} ><Icon type="plus"/>Tambah Data</Button>
                            <Col>
                                <Row>
            
                                    <Col><TextArea placeholder={t("Message")} rows={4} style={{width:'50%'}} /></Col>
                                </Row>
                            </Col>
                        </Col>
                        
                        <Col span={12}>
                            <Row type="flex" justify="end"style={{marginTop:20}} >
                            </Row>
                            <Row type="flex" justify="end"style={{marginTop:20}} >
                            </Row>
                            <Row type="flex" justify="end"style={{marginTop:20}} >
                                <Col span={5} style={{fontWeight:'bold'}}><label>Sub Total</label></Col>
                                <Col span={10}><Input/></Col>
                            </Row>

                            <Row type="flex" justify="end"style={{marginTop:10}} >
                                <Col span={5} style={{fontWeight:'bold'}}><label>{t("Tax")}</label></Col>
                                <Col span={10}>
                                    <Select placeholder={t("Choose")} style={{width:'100%'}}>
                                        <Option value="10">PPN 10%</Option>
                                    </Select>
                                </Col>                
                            </Row>

                            <Row type="flex" justify="end"style={{marginTop:10}} >
                                <Col span={5} style={{fontWeight:'bold'}}><label>Total</label></Col>
                                <Col span={10}><Input/></Col>
                            </Row>
                        </Col>       
            </PageHeader>
                <Row type="flex" style={{marginBottom:30}}>
                    <Col style={{marginTop:15,marginRight:10}}><Button href="/purchase-order" style={{backgroundColor:'#545961',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                    <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Add Order')}</Button></Col>
                </Row>
            </Layout.Content>
        )
    }
}
export default withTranslation() (AddBooking)
