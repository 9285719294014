import React, { Component } from 'react'
import { Layout, Col, Row, Button, Icon, Input, Dropdown, Menu, Avatar } from 'antd'
import '../assets/css/navbar.css'
import { withTranslation } from 'react-i18next'
import { setLanguage } from '../stores/actions/languageActions'
import { connect } from 'react-redux'
const { Header } = Layout

export class HeaderComponent extends Component {

    changeLang = (lang) => {
      const { i18n } = this.props;
      i18n.changeLanguage(lang);
      this.props.setLanguage(lang)
    }

    handleLogout = () => {
      sessionStorage.removeItem('token')
      this.props.history.push('/login')
    }

    render() {
        const { collapsed, toggleCollapsed, language } = this.props
        return (
            <Header className="header">
            <Row type="flex" justify="space-between" style={{ padding: '0px 20px' }}>
              <Col>
                <Row>
                  <Button size="large" className="btn-custom" onClick={toggleCollapsed}>
                    <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} />
                  </Button>
                  <span><strong>PT. KELOLA BISNIS INDONESIA</strong></span>
                </Row>
              </Col>
              
              <Col>
              <Row type="flex" gutter={20}>
                
                  <Col>
                    <Input.Search placeholder="Search" />
                  </Col>

                  <Col>
                  <Dropdown overlay={
                    <Menu>
                        <Menu.Item>
                          <span onClick={() => this.changeLang('en')}>
                            English
                          </span>
                        </Menu.Item>
                        <Menu.Item>
                          <span onClick={() => this.changeLang('id')}>
                            Bahasa Indonesia
                          </span>
                        </Menu.Item>
                      </Menu>
                    }>
                      <span  className="ant-dropdown-link pointer">
                        { !sessionStorage.getItem('language') ? language : sessionStorage.getItem('language') }
                      </span>
                    </Dropdown>
                  </Col>
                  
                  <Col className="stacked">
                    
                    <span><strong>{sessionStorage.getItem('name')}</strong></span>
                    <br />
                    <sup>{sessionStorage.getItem('email')}</sup>
                    
                  </Col>
                  
                  <Col>
                    <Dropdown overlay={
                      <Menu>
                        <Menu.Item>
                          <a target="_blank" rel="noopener noreferrer" href="http://www.alipay.com/">
                            <Icon type="user" className="mr-10" />My Profile
                          </a>
                        </Menu.Item>
                        <Menu.Item>
                          <a target="_blank" rel="noopener noreferrer" href="http://www.taobao.com/">
                            <Icon type="setting" className="mr-10" />Settings
                          </a>
                        </Menu.Item>
                        <Menu.Item>
                          <span className="pointer" onClick={this.handleLogout}>
                            <Icon type="logout" className="mr-10" />Logout
                          </span>
                        </Menu.Item>
                      </Menu>
                    }>
                      <span  className="ant-dropdown-link pointer">
                        {
                          sessionStorage.getItem('photo_url') !== 'null' ? (
                              <Avatar size="large" src={sessionStorage.getItem('photo_url')} />
                          ) : (

                            <Avatar size="large" icon="user" />
                          )
                        }
                      </span>
                    </Dropdown>
                  </Col>
                  
                  
              </Row>
              </Col>
            </Row>
          </Header>
        )
    }
}

const mapStateToProps = state => {
  return {
      ...state,
      lang: state.lang.lang,
      language: state.lang.language
  }
}

const mapDispatchToProps = dispatch => {
  return {
      setLanguage: (lang) => dispatch(setLanguage(lang))
  }
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(HeaderComponent))
