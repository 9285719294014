import DefaultLayoutComponent from "./components/DefaultLayoutComponent";
import Dashboard from "./views/Dashboard";
import Product from "./views/Product/Product";
import AddProduct from "./views/Product/AddProduct";
import TransferWarehouse from "./views/TransferWarehouse/TransferWarehouse";
import AddTransfer from "./views/TransferWarehouse/AddTransfer";
import Warehouse from "./views/Warehouse/Warehouse";
import AddWarehouse from "./views/Warehouse/AddWarehouse";
import StockOpname from "./views/StockOpname/StockOpname";
import AddStock from "./views/StockOpname/AddStock";
import Request from "./views/PermintaanPembelian/Request";
import AddRequest from "./views/PermintaanPembelian/AddRequest";
import Suplier from "./views/Suplier/Suplier";
import SuplierCreate from "./views/Suplier/SuplierCreate";
import Payment from "./views/Payment/Payment";
import PaymentCreate from "./views/Payment/PaymentCreate";
import Reception from "./views/Penerimaan/Reception";
import AddReception from "./views/Penerimaan/AddReception";
import Return from "./views/Return/Return";
import ReturnCreate from "./views/Return/ReturnCreate";
import Employee from "./views/Employee/Employee";
import EmployeeCreate from "./views/Employee/EmployeeCreate";
import EmployeeEdit from "./views/Employee/EmployeeEdit";
import EmployeeShow from "./views/Employee/EmployeeShow";
import AddClass from "./views/ClassAndBasicSalary/AddClass";
import Booking from "./views/Booking/Booking";
import AddBooking from "./views/Booking/AddBooking";
import WorkArea from "./views/WorkArea/WorkArea";
import AddWorkArea from "./views/WorkArea/AddWorkArea";
import Calender from "./views/Calender/Calender";
import Position from "./views/Jabatan&Tunjangan/Position";
import AddPosition from "./views/Jabatan&Tunjangan/AddPosition";
import Order from "./views/Order/Order";
import AddOrder from "./views/Order/AddOrder";
import Pay from "./views/Pay/Pay";
import AddPay from "./views/Pay/AddPay";

import Delivery from "./views/Delivery/Delivery";
import AddDelivery from "./views/Delivery/AddDelivery";
import Customer from "./views/Customers/Customer";
import Pos from "./views/Pos/Pos";

import ReturnTrans from "./views/ReturnTrans/ReturnTrans";
import AddReturnTrans from "./views/ReturnTrans/AddReturnTrans";

import Offer from "./views/Penawaran&Penjualan/Offer";
import AddOffer from "./views/Penawaran&Penjualan/AddOffer";
import Invoice from "./views/Faktur/Invoice";
import AddInvoice from "./views/Faktur/AddInvoice";
import ProductionList from "./views/ProductionList/ProductionList";
import ProjectStatus from "./views/ProjectStatus/ProjectStatus";
import ProjectStatusCreate from "./views/ProjectStatus/ProjectStatusCreate";
import RoleMember from "./views/RoleMember/RoleMember";
import Setting from "./views/Setting/Setting";
import ProsesPayment from "./views/Setting/ProsesPayment";

import MyJob from "./views/MyJob/MyJob";
import User from "./views/User/User";

import AddUser from "./views/User/AddUser";
import ClassAndBasicSalary from "./views/ClassAndBasicSalary/ClassAndBasicSalary";
import BuktiOrder from "./views/BillOfMaterial/BuktiOrder";
import AddBukti from "./views/BillOfMaterial/AddBukti";
import UserGroup from "./views/GrupPengguna/UserGroup";
import AddUserGroup from "./views/GrupPengguna/AddUserGroup";
import Menu1 from "./views/GrupPengguna/Menu1";
import Proof from "./views/BuktiOrderBarang/Proof";
import AddProof from "./views/BuktiOrderBarang/AddProof";
import Project from "./views/Daftar Proyek/Project";
import AddProject from "./views/Daftar Proyek/AddProject";
import Report from "./views/LaporanPembelian/Report";
import AddReport1 from "./views/LaporanPembelian/AddReport1";
import AddReport2 from "./views/LaporanPembelian/AddReport2";
import AddReport3 from "./views/LaporanPembelian/AddReport3";
import AddReport4 from "./views/LaporanPembelian/AddReport4";
import AddReport5 from "./views/LaporanPembelian/AddReport5";
import AddReport6 from "./views/LaporanPembelian/AddReport6";
import AddReport7 from "./views/LaporanPembelian/AddReport7";
import AddReport8 from "./views/LaporanPembelian/AddReport8";
import FullLayoutComponent from "./components/FullLayoutComponent";
import Login from "./views/Login";

import AddCustomer from "./views/Customers/AddCustomer";
import DashboardSdm from "./views/DashboardSDM/DashboardSdm";
import DashboardBussiness from "./views/DashboardBussines/DashboardBussiness";
import SalesReport from "./views/SalesReport/SalesReport";
import DashboardPurchase from "./views/DashboardPurchase/DashboardPurchase";
import Register from "./views/Register";
import Journal from "./views/Journal/Journal";
import AddJournal from "./views/Journal/AddJournal";
import Presence from "./views/Presence/Presence";

import Cashbank from "./views/Cash & Bank/Cashbank";
import Transfer from "./views/Cash & Bank/Transfer";
import Receive from "./views/Cash & Bank/Receive";
import Send from "./views/Cash & Bank/Send";
import Reconciliation from "./views/Cash & Bank/Reconciliation"
import Rekonsiliasi from "./views/Bank Reconcile/Rekonsiliasi";
import AddRekonsiliasi from "./views/Bank Reconcile/AddRekonsiliasi";
import AddReimburse from "./views/Reimburse/AddReimburse";
import Reimburse from "./views/Reimburse/Reimburse";
import Leave from "./views/Leave/Leave";
import AddLeave from "./views/Leave/AddLeave";
import ProfitLoss from "./views/ProfitLoss/ProfitLoss";
import AddProfitLoss from "./views/ProfitLoss/AddProfitLoss";
import Overtime from "./views/Overtime/Overtime";
import AddOvertime from "./views/Overtime/AddOvertime";
import EmployeeProfile from "./views/EmployeeProfile/EmployeeProfile";
import Course from "./views/Course/Course";
import CourseCreate from "./views/Course/CourseCreate";
import TrainingReport from "./views/TrainingReport/TrainingReport";
import Evaluation from "./views/Evaluation/Evaluation";
import EvaluationCreate from "./views/Evaluation/EvaluationCreate";
import DashboardTraining from "./views/DashboardTraining/DashboardTraining";
import EventCreate from "./views/DashboardTraining/EventCreate";
import BalanceSheet from "./views/BalanceSheet/BalanceSheet";
import Event from "./views/Event/Event";
import AccountList from "./views/AccountList/AccountList";
import JournalCreate from "./views/AccountList/JournalCreate";
import CoaCreate from "./views/AccountList/CoaCreate";

export default [
    {
        path: '/',
        exact: true,
        layout: DefaultLayoutComponent,
        component: Dashboard
    },
    {
        path: '/item',
        exact: true,
        layout: DefaultLayoutComponent,
        component: Product
    },
    {
        path: '/item/create',
        exact: true,
        layout: DefaultLayoutComponent,
        component: AddProduct
    },
    {
        path: '/warehouse',
        exact: true,
        layout: DefaultLayoutComponent,
        component: Warehouse
    },
    {
        path: '/warehouse/create',
        exact: true,
        layout: DefaultLayoutComponent,
        component: AddWarehouse
    },
    {
        path: '/warehouse-transfer',
        exact: true,
        layout: DefaultLayoutComponent,
        component: TransferWarehouse
    },
    {
        path: '/warehouse-transfer/create',
        exact: true,
        layout: DefaultLayoutComponent,
        component: AddTransfer
    },
    {
        path: '/opname',
        exact: true,
        layout: DefaultLayoutComponent,
        component : StockOpname
    },
    {
        path : '/stock/create',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddStock
    },
    {    
        path : '/supplier',
        exact : true,
        layout : DefaultLayoutComponent,
        component : Suplier
    },
    {
        path : '/supplier/create',
        exact : true,
        layout : DefaultLayoutComponent,
        component : SuplierCreate
    },
    {
        path : '/purchase-payment',
        exact: true,
        layout : DefaultLayoutComponent,
        component : Payment
    },
    {
        path : '/payment/create',
        exact : true,
        layout : DefaultLayoutComponent,
        component : PaymentCreate
    },
    {
        path : '/purchase-receive',
        exact : true,
        layout: DefaultLayoutComponent,
        component: Reception
    },
    {
        path : '/purchase-receive/create',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddReception
    },
    {   path : '/purchase-return',
        exact : true,
        layout : DefaultLayoutComponent,
        component : Return
    },
    {
        path: '/return/create',
        exact : true,
        layout : DefaultLayoutComponent,
        component : ReturnCreate
    },
    {
        path: '/employee',
        exact : true,
        layout : DefaultLayoutComponent,
        component : Employee
    },
    {
        path : '/employee/create',
        exact : true,
        layout : DefaultLayoutComponent,
        component : EmployeeCreate
    },
    {
        path : '/employee/edit',
        exact : true,
        layout : DefaultLayoutComponent,
        component : EmployeeEdit
    },
    {
        path : '/employee/show',
        exact : true,
        layout : DefaultLayoutComponent,
        component : EmployeeShow
    },
    {
        path: '/purchase-request',
        exact: true,
        layout: DefaultLayoutComponent,
        component: Request
    },
    {
        path: '/purchase-request/create',
        exact: true,
        layout: DefaultLayoutComponent,
        component: AddRequest
    },
    {
        path : '/position',
        exact : true,
        layout: DefaultLayoutComponent,
        component: Position
    },
    {
        path : '/position/create',
        exact : true,
        layout: DefaultLayoutComponent,   
        component: AddPosition
    },
    {
        path: '/purchase-order',
        exact: true,
        layout: DefaultLayoutComponent,
        component: Booking
    },
    {
        path : '/purchase-order/create',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddBooking
    },
    {
        path : '/work-unit',
        exact : true,
        layout: DefaultLayoutComponent,
        component: WorkArea
    },
    {
        path : '/work-unit/create',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddWorkArea
    },
    {
        path : '/calendar',
        exact : true,
        layout: DefaultLayoutComponent,
        component: Calender
    },
    {
        path : '/sales-quotation',
        exact : true,
        layout: DefaultLayoutComponent,
        component: Offer
    },
    {
        path : '/sales-quotation/create',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddOffer
    },
    {
        path : '/sales-invoice',
        exact : true,
        layout: DefaultLayoutComponent,
        component: Invoice
    },
    {
        path : '/sales-invoice/create',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddInvoice
    },
    {
        path : '/sales-delivery',
        exact : true,
        layout: DefaultLayoutComponent,
        component: Delivery
    },
    {
        path : '/sales-delivery/create',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddDelivery
    },
    {
        path : '/sales-return',
        exact : true,
        layout: DefaultLayoutComponent,
        component: ReturnTrans
    },
    {
        path : '/sales-return/create',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddReturnTrans,
    },
    {
        path : '/sales-order',
        exact : true,
        layout: DefaultLayoutComponent,
        component: Order
    },
    {
        path : '/sales-order/create',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddOrder
    },
    {
        path : '/sales-payment',
        exact : true,
        layout: DefaultLayoutComponent,
        component: Pay
    },
    {
        path : '/sales-payment/create',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddPay
    },
    {
        path : '/customer',
        exact : true,
        layout : DefaultLayoutComponent,
        component : Customer
    },
    {
        path : '/customer/create',
        exact : true,
        layout : DefaultLayoutComponent,
        component : AddCustomer
    },
    {
        path : '/pos',
        exact : true,
        layout : DefaultLayoutComponent,
        component : Pos
    },
    {
        path:'/production',
        exact : true,
        layout : DefaultLayoutComponent,
        component: ProductionList
    },
    {
        path : '/project-status',
        exact : true,
        layout : DefaultLayoutComponent,
        component : ProjectStatus
    },
    {
        path : '/projectstatus/create',
        exact : true,
        layout : DefaultLayoutComponent,
        component : ProjectStatusCreate
    },
    {
        path : '/project-member-role',
        exact : true,
        layout : DefaultLayoutComponent,
        component : RoleMember
    },
    {
        path : '/settings',
        exact : true,
        layout : DefaultLayoutComponent,
        component : Setting
    },
    {
        path : '/setting/prosespayment',
        exact : true,
        layout : DefaultLayoutComponent,
        component : ProsesPayment
    },
    {
        path : '/group',
        exact : true,
        layout: DefaultLayoutComponent,
        component: ClassAndBasicSalary
    },
    {
        path : '/group/create',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddClass
    },
    {
        path : '/bob',
        exact : true,
        layout: DefaultLayoutComponent,
        component: Proof
    },
    {
        path : '/bob/create',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddProof
    },
    {
        path : '/project',
        exact : true,
        layout: DefaultLayoutComponent,
        component: Project
    },
    {
        path : '/project/create',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddProject
    },
    {
        path : '/purchase-report',
        exact : true,
        layout: DefaultLayoutComponent,
        component: Report
    },
    {
        path : '/purchase-report/create1',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddReport1
    },
    {
        path : '/purchase-report/create2',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddReport2
    },
    {
        path : '/purchase-report/create3',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddReport3
    },
    {
        path : '/purchase-report/create4',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddReport4
    },
    {
        path : '/purchase-report/create5',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddReport5
    },
    {
        path : '/purchase-report/create6',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddReport6
    },
    {
        path : '/purchase-report/create7',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddReport7
    },
    {
        path : '/purchase-report/create8',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddReport8
    },
    {
        path : '/login',
        exact : true,
        layout: FullLayoutComponent,
        component: Login
    },
    {
        path : '/bom',
        exact : true,
        layout: DefaultLayoutComponent,
        component: BuktiOrder
    },
    {
        path : '/bom/create',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddBukti
    },
    {
        path : '/role',
        exact : true,
        layout: DefaultLayoutComponent,
        component: UserGroup
    },
    {
        path : '/role/create',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddUserGroup
    },
    {
        path : '/menu1',
        exact : true,
        layout: DefaultLayoutComponent,
        component: Menu1
    },
    {
        path : '/Task',
        exact : true,
        layout: DefaultLayoutComponent,
        component: MyJob
    },
    {
        path : '/user',
        exact : true,
        layout: DefaultLayoutComponent,
        component: User
    },
    {
        path : '/user/create',
        exact : true,
        layout: DefaultLayoutComponent,
        component: AddUser
    },
    {
        path:'/dashboard-hris',
        exact:true,
        layout:DefaultLayoutComponent,
        component:DashboardSdm
    },
    {
        path:'/dashboard-business',
        exact:true,
        layout:DefaultLayoutComponent,
        component:DashboardBussiness
    },
    {
        path:'/dashboard-sales',
        exact:true,
        layout:DefaultLayoutComponent,
        component:SalesReport
    },
    {
        path:'/dashboard-purchase',
        exact:true,
        layout:DefaultLayoutComponent,
        component: DashboardPurchase
    },
    {
        path:'/register',
        exact:true,
        layout:FullLayoutComponent,
        component: Register
    },
    {
        path:'/journal',
        exact:true,
        layout:DefaultLayoutComponent,
        component: Journal
    },
    {
        path:'/journal/create',
        exact:true,
        layout:DefaultLayoutComponent,
        component: AddJournal
    },
    {
        path:'/presence',
        exact:true,
        layout:DefaultLayoutComponent,
        component: Presence
    },
    {
        path:'/coa',
        exact:true,
        layout:DefaultLayoutComponent,
        component: Cashbank
    },
    {
        path:'/coa/transfer',
        exact:true,
        layout:DefaultLayoutComponent,
        component: Transfer
    },
    {
        path:'/coa/deposit',
        exact:true,
        layout:DefaultLayoutComponent,
        component: Receive
    },
    {
        path:'/coa/withdrawal',
        exact:true,
        layout:DefaultLayoutComponent,
        component: Send
    },
    {
        path:'/coa/reconcile',
        exact:true,
        layout:DefaultLayoutComponent,
        component: Reconciliation
    },
    {
        path:'/reconcile',
        exact:true,
        layout:DefaultLayoutComponent,
        component: Rekonsiliasi
    },
    {
        path:'/reconcile/create',
        exact:true,
        layout:DefaultLayoutComponent,
        component: AddRekonsiliasi
    },
    ,
    {
        path:'/reimburse',
        exact:true,
        layout:DefaultLayoutComponent,
        component: Reimburse
    },
    {
        path:'/reimburse/create',
        exact:true,
        layout:DefaultLayoutComponent,
        component: AddReimburse
    },
    {
        path:'/leave',
        exact:true,
        layout:DefaultLayoutComponent,
        component: Leave
    },
    {
        path:'/leave/create',
        exact:true,
        layout:DefaultLayoutComponent,
        component: AddLeave
    },
    {
        path:'/profit-loss',
        exact:true,
        layout:DefaultLayoutComponent,
        component: ProfitLoss
    },
    {
        path:'/profit-loss/create',
        exact:true,
        layout:FullLayoutComponent,
        component: AddProfitLoss
    },
    {
        path:'/overtime',
        exact:true,
        layout:DefaultLayoutComponent,
        component: Overtime
    },
    {
        path:'/overtime/create',
        exact:true,
        layout:DefaultLayoutComponent,
        component: AddOvertime
    },
    {
        path:'/profile',
        exact:true,
        layout:DefaultLayoutComponent,
        component: EmployeeProfile
    },
    {
        path:'/course',
        exact:true,
        layout:DefaultLayoutComponent,
        component: Course
    },
    {
        path:'/course/create',
        exact:true,
        layout:DefaultLayoutComponent,
        component: CourseCreate
    },
    {
        path:'/report-training',
        exact:true,
        layout:DefaultLayoutComponent,
        component: TrainingReport
    },
    {
        path:'/evaluation-question',
        exact:true,
        layout:DefaultLayoutComponent,
        component: Evaluation
    },
    {
        path:'/evaluation-question/create',
        exact:true,
        layout:DefaultLayoutComponent,
        component: EvaluationCreate
    },
    {
        path:'/dashboard-training',
        exact:true,
        layout:DefaultLayoutComponent,
        component: DashboardTraining
    },
    {
        path:'/event/create',
        exact:true,
        layout:DefaultLayoutComponent,
        component: EventCreate
    },
    {
        path:'/balance-sheet',
        exact:true,
        layout:DefaultLayoutComponent,
        component: BalanceSheet
    },
    {
        path:'/event',
        exact:true,
        layout:DefaultLayoutComponent,
        component: Event
    },
    {
        path:'/account-list',
        exact:true,
        layout:DefaultLayoutComponent,
        component: AccountList
    },
    {
        path:'/journal/create',
        exact:true,
        layout:DefaultLayoutComponent,
        component: JournalCreate
    },
    {
        path:'/coa/create',
        exact:true,
        layout:DefaultLayoutComponent,
        component: CoaCreate
    }

]