import React, { Component } from 'react'
import { Layout, 
         Row, 
         Col, 
         Breadcrumb, 
         Typography, 
         PageHeader, 
         DatePicker,
         Button, 
         Icon, 
         Select, 
         Input, 
         Divider, 
         Table, 
         Modal,
         Upload,
         message,
         visible } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next';

class AddPay extends React.Component {
    state = { visible: false };

    showUpload = () => {
      this.setState({
        visible: true,
      });
    };
    render(){
        const {Title, Text} = Typography
        const { t } = this.props;
        const dateFormat =' DD/MM/YYYY'
        const {TextArea} = Input
        const {Option} = Select
        const props = {
            name: 'file',
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            headers: {
              authorization: 'authorization-text',
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                  console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                  message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                  message.error(`${info.file.name} file upload failed.`);
                }
              },
          };
        const columns = [
            {
              title: `${t('Payment Number')}`,
              dataIndex: 'Nomor Pembayaran',
              key: 'Nomor Pembayaran',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('Invoice Number')}`,
              dataIndex: 'Nomor Invoice',
              key: 'Nomor Invoice',
            },
            {
              title: `${t('Date of maturity')}`,
              dataIndex: ' Tgl Jatuh Tempo',
              key: ' Tgl Jatuh Tempo',
            },
            {
                title: `${t('Not yet paid')}`,
                dataIndex: 'Belum Dibayar',
                key: 'Belum Dibayar',
            },
            {
                title: `${t('Paid')}`,
                dataIndex: 'Dibayar',
                key: 'Dibayar',
            },
            {
                title: 'Total',
                dataIndex: 'Total',
                key: 'Total',
            },
            
          ];
        return(
            <Layout.Content>
                <PageHeader style={{border: '1px solid rgb(235,237,340)'}}>
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Receive payment')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Sales')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/sales-payment">{t('Payment')} </a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="">{t('Receive payment')} </a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                </PageHeader>

                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                <Divider type="horizontal"/>
                    <Row type="flex" >
                        <Col span={12}>
                            <Row type="flex" style={{marginTop:20}}>
                            <Col span={6} ><h3>{t('Customer')}</h3></Col>
                            <Col span={15}>
                                <Select style={{width:'100%',fontSize:14}} defaultValue= {t('Select')}>
                                        <Option value=""><Input></Input></Option>
                                        <Option value="(KAP) - ASTRA DAIHATSU MOTOR" >(KAP) - ASTRA DAIHATSU MOTOR</Option>
                                        <Option value="(SAP) - ASTRA DAIHATSU MOTOR" >(SAP) - ASTRA DAIHATSU MOTOR</Option>  
                                        <Option value="(APC) - ASTRA DAIHATSU MOTOR">(APC) - ASTRA DAIHATSU MOTOR</Option>
                                        <Option value="(KIC) - PT. KOTOBUKIYA INDO CLASSIC INDUSTRIES">(KIC) - PT. KOTOBUKIYA INDO CLASSIC INDUSTRIES</Option>
                                        <Option value="(AAA) - PT. APM ARMADA AUTOPARTS">(AAA) - PT. APM ARMADA AUTOPARTS</Option>      
                                        <Option value="(AMS) - PT. ARMSTRONG">(AMS) - PT. ARMSTRONG</Option>
                                        <Option value="(BTI) - PT. BONECOM TRICOM">(BTI) - PT. BONECOM TRICOM</Option>
                                        <Option value="(DWA) - PT. DASA WINDU AGUNG">(DWA) - PT. DASA WINDU AGUNG</Option>
                                        <Option value="(ETI) - PT. ECHO ADVANCHED TECHNOLOGY INDONESIA">(ETI) - PT. ECHO ADVANCHED TECHNOLOGY INDONESIA</Option>
                                        <Option value="(HMI) - PT. HINO MOTORS MANUFACTURING INDONESIA">(HMI) - PT. HINO MOTORS MANUFACTURING INDONESIA</Option>
                                        <Option value="(HWI) - PT. HOWA INDONESIA">(HWI) - PT. HOWA INDONESIA</Option>
                                        <Option value="(IPI) - PT. INOAC POLYTECHNO INDONESIA">(IPI) - PT. INOAC POLYTECHNO INDONESIA</Option>
                                        <Option value="(IPM) - PT. INTIPOLY METAL">(IPM) - PT. INTIPOLY METAL</Option>
                                        <Option value="(IRC) - PT. IRC INOAC INDONESIA">(IRC) - PT. IRC INOAC INDONESIA</Option>
                                        <Option value="(JVC) - PT. JAYA VICTORY CEMERLANG">(JVC) - PT. JAYA VICTORY CEMERLANG</Option>
                                        <Option value="(KBB) - PT. KARYA BAHAN BERLIAN">(KBB) - PT. KARYA BAHAN BERLIAN</Option>
                                        <Option value="(MBP) - PT. MAJU BERSAMA PERSADA DAYAMU">(MBP) - PT. MAJU BERSAMA PERSADA DAYAMU</Option>
                                        <Option value="(MWI) - PT. MEIWA INDONESIA">(MWI) - PT. MEIWA INDONESIA</Option>
                                        <Option value="(MES) - PT. METINDO ERASAKTI">(MES) - PT. METINDO ERASAKTI</Option>
                                        <Option value="(MAP) - PT MUTIHA ABADI PERIZKY">(MAP) - PT MUTIHA ABADI PERIZKY</Option>
                                        <Option value="(NTI) - PT. NITTO MATERIALS">(NTI) - PT. NITTO MATERIALS</Option>
                                        <Option value="(NKI) - PT. NUSA KEIHIN INDONESIA">(NKI) - PT. NUSA KEIHIN INDONESIA</Option>
                                        <Option value="(SGI) - PT. SANKO GOSEI">(SGI) - PT. SANKO GOSEI</Option>
                                        <Option value="(STP) - PT. SARI TAKAGI ELOK PRODUK">(STP) - PT. SARI TAKAGI ELOK PRODUK</Option>
                                        <Option value="(SGT) - PT. SUGITY CREATIVES">(SGT) - PT. SUGITY CREATIVES</Option>
                                        <Option value="(SGP) - PT. SURYA GEMILANG PERKASA">(SGP) - PT. SURYA GEMILANG PERKASA</Option>
                                        <Option value="(SIM) - PT. SUZUKI INDOMOBIL MOTOR">(SIM) - PT. SUZUKI INDOMOBIL MOTOR</Option>
                                        <Option value="(TBI) - PT. TOYOTA BOSHOKU INDONESIA">(TBI) - PT. TOYOTA BOSHOKU INDONESIA</Option>
                                        <Option value="(TNA) - PT. TUFFINDO NITTOKU AUTONEUM">(TNA) - PT. TUFFINDO NITTOKU AUTONEUM</Option>
                                        <Option value="(ust) - PT. USRA TAMPI">(ust) - PT. USRA TAMPI</Option>
                                        <Option value="(VQI) - PT. VUTEQ INDONESIA">(VQI) - PT. VUTEQ INDONESIA</Option>
                                </Select>
                            </Col>  
                            </Row>
                            
                            <Row type="flex" style={{marginTop:20}}>
                            <Col span={6} ><h3>{t('Payment method')}</h3></Col>
                            <Col span={15}>
                                <Select style={{width:'100%',fontSize:14}} defaultValue= {t('Checks & Giro')}>
                                        <Option value=""><Input></Input></Option>
                                        <Option value="Cek & Giro" >{t('Checks & Giro')}</Option>
                                        <Option value="Kartu Kredit" >{t('Credit card')}</Option>
                                        <Option value="Kas Tunai" >{t('Cash Cash')}</Option>
                                        <Option value="Transfer Bank" >{t('Bank transfer')}</Option>        
                                </Select>
                            </Col>  
                            </Row>
                        </Col>

                        <Col span={12}>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={6} ><h3>{t('Deposit To')}</h3></Col>
                                <Col span={15}>
                                    <Select style={{width:'100%',fontSize:14}} defaultValue= "(1-0000) - Asset">
                                            <Option value=""><Input></Input></Option>
                                            <Option value="(1-0000) - Asset" >(1-0000) - Asset</Option>
                                            <Option value="(1-1000) - Harta Lancar" >(1-1000) - {t('Current assets')}</Option>        
                                            <Option value="(1-2000) - Harta tetap" >(1-2000) -{t('Fixed assets')}</Option> 
                                            <Option value="(1-1100) - Kas" >(1-1100) - {t('Cash')}</Option> 
                                            <Option value="(1-1110) - Kas POS" >(1-1110) - {t('Cash POS')}</Option> 
                                            <Option value="(1-1120) - Kas Bank POS" >(1-1120) - {t('Bank POS Cash')}</Option> 
                                            <Option value="(1-1200) - Bank" >(1-1200) - {t('Bank')}</Option>
                                            <Option value="(1-1300) - Piutang" >(1-1300) - {t("Receivables")}</Option>
                                            <Option value="(1-1299) - Piutang Belum Ditagih" >(1-1299) -{t('Unpaid Accounts Receivable')}</Option>
                                            <Option value="(1-1400) - PPN Masukan" >(1-1400) - {t('PPN Input')}</Option>
                                            <Option value="(1-1500) - Persediaan Barang" >(1-1500) - {t('Equipment Goods')}</Option>
                                            <Option value="(1-2100) - Peralatan Kantor" >(1-2100) - {t('Office equipment')}</Option> 
                                            <Option value="(1-2200) - Akulasi Penyusutan Peralatan" >(1-2200) - {t('Accumulation of Depreciation of Equipment')}</Option>
                                            <Option value="(1-2300) - Sewa Dibayar Dimuka" >(1-2300) - {t('Prepaid lease')}</Option>                 
                                    </Select>
                            </Col>  
                            </Row>

                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={6} ><h3>{t('Payment date')}</h3></Col>
                                <Col span={15}><DatePicker style={{width:'100%'}} defaultValue={moment('17/2/2020',dateFormat)} format={dateFormat} /></Col>
                            </Row>
                        </Col>
                    </Row>

                    <Divider type="horizontal" />

                    <div style={{margin:5, padding:20}}>
                        <Table columns={columns} ></Table>
                    </div>
                </div>

                <Divider type="horizontal"/>
                    <Row type="flex" justify="space-between">
                        <Col span={14}>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={18}><TextArea placeholder={t('Message')} rows={4} style={{width:'100%'}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={5}><Text>File</Text></Col>
                                <Row type='flex'>
                                    <Upload {...props}>
                                        <Button>
                                            Choose File
                                        </Button> 
                                    </Upload>
                                    <Text>No File Chosen</Text>
                                </Row>
                                <Col span={15} onClick={this.showUpload} type="primary"></Col>
                            </Row>
                        </Col>
                        <Col span={10}>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={6} ><h3>Potongan</h3></Col>
                                <Col span={10}>
                                    <Select style={{width:'100%',fontSize:14}} defaultValue= "(1-0000) - Asset">
                                            <Option value=""><Input></Input></Option>
                                            <Option value="(1-0000) - Asset" >(1-0000) - Asset</Option>
                                            <Option value="(1-1000) - Harta Lancar" >(1-1000) - {t('Current assets')}</Option>        
                                            <Option value="(1-2000) - Harta tetap" >(1-2000) -{t('Fixed assets')}</Option> 
                                            <Option value="(1-1100) - Kas" >(1-1100) - {t('Cash')}</Option> 
                                            <Option value="(1-1110) - Kas POS" >(1-1110) - {t('Cash POS')}</Option> 
                                            <Option value="(1-1120) - Kas Bank POS" >(1-1120) - {t('Bank POS Cash')}</Option> 
                                            <Option value="(1-1200) - Bank" >(1-1200) - {t('Bank')}</Option>
                                            <Option value="(1-1300) - Piutang" >(1-1300) - {t("Receivables")}</Option>
                                            <Option value="(1-1299) - Piutang Belum Ditagih" >(1-1299) -{t('Unpaid Accounts Receivable')}</Option>
                                            <Option value="(1-1400) - PPN Masukan" >(1-1400) - {t('PPN Input')}</Option>
                                            <Option value="(1-1500) - Persediaan Barang" >(1-1500) - {t('Equipment Goods')}</Option>
                                            <Option value="(1-2100) - Peralatan Kantor" >(1-2100) - {t('Office equipment')}</Option> 
                                            <Option value="(1-2200) - Akulasi Penyusutan Peralatan" >(1-2200) - {t('Accumulation of Depreciation of Equipment')}</Option>
                                            <Option value="(1-2300) - Sewa Dibayar Dimuka" >(1-2300) - {t('Prepaid lease')}</Option>       
                                    </Select>
                                </Col>  
                                <Col span={6}><Input style={{marginLeft:5}} placeholder="Rp. 0,00" /></Col>
                            </Row>
                        </Col>
                    </Row>

                    <Divider type="horizontal"/>
                    <Row type="flex" style={{marginBottom:30}}>
                        <Col style={{marginTop:15,marginLeft:10,marginRight:10}}><Button href="/sales-payment" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                        <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Receive payment')} </Button></Col>
                    </Row>
            </Layout.Content>
        )
    }
}
export default withTranslation() (AddPay)