import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import routes from './routes';
import { Error404 } from './views/Errors/Error404';
import { withTranslation } from 'react-i18next';

export class App extends Component {

  render() {
    const { t } = this.props
    return (
      <BrowserRouter>
        <Switch>
            {
              routes.map((route, index) => {
                return (<Route
                      key={index} 
                      path={route.path} 
                      exact={route.exact} 
                      component={(props) => {
                        return (
                          <route.layout {...props} >
                            <route.component {...props} />
                          </route.layout>
                        );
                      }}
                  />
                )
              })
            }
            <Route component={(props) => { return <Error404 {...props} t={t} /> }} />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default withTranslation()(App)
