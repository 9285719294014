import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Row, 
            Typography, 
            Col, 
            Breadcrumb, 
            Layout, 
            Divider, 
            Input, 
            Select, 
            DatePicker, 
            Button, 
            Icon, 
            Table,
            Radio
         } from 'antd'
import moment from 'moment'

class AddReception extends Component{
    render(){
        const {Title} = Typography
        const Option = Select
        const dateFormat =' DD/MM/YYYY'
        const {TextArea} = Input
        const { t } = this.props;
        const columns = [
            {
              title: `${t('Product')}`,
              dataIndex: 'Product',
              key: 'Product',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('Description')}`,
              dataIndex: 'Description',
              key: 'Description',
            },
            {
              title: `${t('Unit')}`,
              dataIndex: 'Unit',
              key: 'Unit',
            },
            {
                title: `${t('Order Amount')}`,
                dataIndex: 'Order Amount',
                key: 'Order Amount',
            },
            {
                title: `${t('Already Received')}`,
                dataIndex: 'Already Received',
                key: 'Already Received',
            },
            {
                title: `${t('The Rest Of It')}`,
                dataIndex: 'The Rest Of It',
                key: 'The Rest Of It',
            },
          ];
        return(
            <Layout.Content>
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Create Reception')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Purchase')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/purchase-receive">{t('Reception')} </a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/purchase-receive/create">{t('Create Reception')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider type="horizontal"/>
                <div  style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                    <Row>
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Method')}</label></Col>
                                <Col span={8}>
                                <Radio.Group defaultValue={0}>
                                    <Radio value={0}>{t('From Booking')}</Radio>
                                    <Radio value={1}>{t('From Booking')}</Radio>
                                </Radio.Group>
                                </Col>
                            </Row>
                            <Row type="flex" style={{marginTop:10}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Reference number')}</label></Col>
                                <Col span={15}><Select style={{width:'100%',fontSize:16}} placeholder={t('Select')}>
                                                 <Option value="" ><Input></Input></Option>
                                               </Select></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Receipt Number')}</label></Col>
                                <Col span={15}><Input rows={4} style={{width:'100%'}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Receipt date')}</label></Col>
                                <Col span={15}><DatePicker style={{width:'100%'}} defaultValue={moment('1/2/2020',dateFormat)} format={dateFormat} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Warehouse')}</label></Col>
                                <Col span={15}><Select style={{width:'100%',fontSize:16}} defaultValue="(GDG-0001)- Gudang Raw Material">
                                        <Option value="(GDG-0001)- Gudang Raw Material" >(GDG-0001)- Gudang Raw Material</Option>
                                        <Option value="(GDG-0003) - Gudang Finished Goods)" >(GDG-0003) - Gudang Finished Goods)</Option>
                                    </Select></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Supplier')}</label></Col>
                                <Col span={15}><Input readOnly rows={4} style={{width:'100%', backgroundColor:' #e0e0d1'}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Alamat Pemasok')}</label></Col>
                                <Col span={15}><TextArea rows={4} style={{width:'100%'}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Addressed to')}</label></Col>
                                <Col span={15}><Input rows={4} style={{width:'100%'}} /></Col>
                            </Row>
                            
                        </Col>
                        <Col span={12}>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label> {t('Order Number')}</label></Col>
                                <Col span={15}><Input readOnly rows={4} style={{width:'100%', backgroundColor:' #e0e0d1'}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Order date')}</label></Col>
                                <Col span={15}><Input readOnly rows={4} style={{width:'100%', backgroundColor:' #e0e0d1'}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Expired date')}</label></Col>
                                <Col span={15}><Input readOnly rows={4} style={{width:'100%', backgroundColor:' #e0e0d1'}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Payment status')}</label></Col>
                                <Col span={15}><Input readOnly rows={4} style={{width:'100%', backgroundColor:' #e0e0d1'}} /></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider type="horizontal" style={{marginTop:40}} />
                    <Col style={{padding:20, color:'#505458', fontWeight:'bold'}}><h3>{t('Product')}</h3></Col>
                    <Table columns ={columns}></Table>
                    <Row type="flex" style={{marginTop:20}}>
                        <Col span={10}><TextArea placeholder={t('Message')} rows={4} style={{width:'100%'}} /></Col>
                    </Row>
                </div>
                <Row type="flex" style={{marginBottom:30}}>
                    <Col style={{marginTop:15,marginLeft:10,marginRight:10}}><Button href="/purchase-receive" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                    <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Make Adjustments')} </Button></Col>
                </Row>

                
                
            </Layout.Content>
            
        )
    }

}
export default withTranslation() (AddReception)