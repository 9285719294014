import React from 'react'
import {Row, Col, Breadcrumb, Divider, Layout, PageHeader, Button, Select, Input, Table} from 'antd'
import { withTranslation } from 'react-i18next';

class MyJob extends React.Component {
    render(){
        const Option = Select
        const { t } = this.props;
        const columns = [
            { 
              title: `${t("Name")} ${t("Project")}` ,
              dataIndex: 'nama',
              key: 'nama',
            },
            {
              title: `${t("Task")}`,
              dataIndex: 'tugas',
              key: 'tugas',
            },
            {
              title: `${t("Name")} Milestone`,
              dataIndex: 'milestone',
              key: 'milestone',
            },
            {
              title: `${t("To")}`,
              dataIndex: 'untuk',
              key: 'untuk',
            },
            {
              title: `${t("Due")}`,
              dataIndex: 'jt',
              key: 'jt',
            },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
            },
            {
              title: `Progress ${t("Task")}`,
              dataIndex: 'progres',
              key: 'progres',
            }
        ]
        return(
            <Layout.Content>
                <Row style={{marginLeft:10, marginTop:20}} type="flex" justify="space-around">
                    <Col span={12}><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}>{t("Task List")}</h1></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end" style={{fontWeight:'bold'}}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t("Project Management")}</Breadcrumb.Item>
                                    <Breadcrumb.Item><a href="/task">{t("Task")}</a></Breadcrumb.Item>
                                    <Breadcrumb.Item>{t("Task List")}</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                </Row>
                <Divider type="horizontal"></Divider>
                <Row type="flex" justify="space-between">
                    <Col><h1 style={{fontSize:16, fontWeight:"bold", color:'#8c8c8c'}}>{t("Search")} {t("Project")}</h1></Col>
                    <Col style={{marginLeft:50, width:800}}><Input placeholder={t("All Project")}></Input></Col>
                    <Col style={{marginLeft:10}}><Button type="ghost" style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold', width:100}}> Filter</Button></Col>
                </Row>
                <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
                    <Row type="flex" justify="space-between"> 
                                <Col>
                                    <Row type="flex">
                                        <Col><h3 style={{marginRight:4, fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>Per</h3></Col>
                                                <Select defaultValue="10" size='small' style={{width:65}}>
                                                    <Option value="10">10</Option>
                                                    <Option value="25">25</Option>
                                                    <Option value="50">50</Option>
                                                    <Option value="100">100</Option>
                                                </Select>
                                        <Col><h3 style={{marginLeft:4, fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>{t("Line")}</h3></Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row type="flex">
                                        <Col style={{marginRight:5, }}><h3 style={{ fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>{t("Search")} {t("Task")}</h3></Col>
                                        <Col><Input size='small'></Input></Col>
                                    </Row>
                                </Col>
                    </Row>
                    <div style={{marginTop:20, border: '1px solid #ebedf0'}}>
                        <Table columns={columns}></Table>
                    </div>
                    <Row type="flex" justify="end" style={{marginTop:5}}>
                        <Col><Button type="disabled" disabled style={{width:70, backgroundColor:'white',color:'#8c8c8c',fontWeight:'bold',}}>{t("Early")}</Button></Col>
                        <Col><Button type="disabled" disabled style={{width:80, backgroundColor:'white',color:'#8c8c8c',fontWeight:'bold',}}>{t("Back off")}</Button></Col>
                        <Col><Button type="disabled" disabled style={{width:70, backgroundColor:'white',color:'#8c8c8c',fontWeight:'bold',}}>{t("Onward")}</Button></Col>
                        <Col><Button type="disabled" disabled style={{width:70, backgroundColor:'white',color:'#8c8c8c',fontWeight:'bold',}}>{t("End")}</Button></Col>
                    </Row>
                </PageHeader>
            </Layout.Content>
        )
    }
}

export default withTranslation() (MyJob)