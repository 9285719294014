import React, { Component } from 'react'
import { Layout, 
         Row, 
         Col, 
         Breadcrumb, 
         Typography, 
         PageHeader, 
         DatePicker,
         Button, 
         Icon, 
         Select, 
         Input,  
         Table, } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next';

class Rekonsiliasi extends React.Component {
    render(){
        const {Title, Text} = Typography
        const {Option} = Select
        const { t } = this.props;
        const columns = [
            {
              title: `${t('Date')}`,
              dataIndex: 'Date',
              key: 'Date',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('Final Reconciliation')}`,
              dataIndex: 'Final Reconciliation',
              key: 'Final Reconciliation',
            },
            {
              title: `${t(' Account Name')}`,
              dataIndex: ' Account Name',
              key: ' Account Name',
            },
            {
                title: 'Beginning balance',
                dataIndex: 'Beginning balance',
                key: 'Beginning balance',
            },
            {
                title: 'Ending balance',
                dataIndex: 'Ending balance',
                key: 'Ending balance',
            },
            {
                title: 'Target Balance',
                dataIndex: 'Target Balance',
                key: 'Target Balance',
            },
            {
                title: 'Difference',
                dataIndex: 'Difference',
                key: 'Difference',
            },
            
          ];
        return(
            <Layout.Content>
                <PageHeader style={{border: '1px solid rgb(235,237,340)'}}>
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Bank of Reconciliation')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Accounting')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/reconcile">{t('Bank of Reconciliation')} </a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                </PageHeader>

                <div style={{padding:20}}>
                <Row type='flex'justify='space-between'>
                <Button href="/reconcile/create" style={{margin:10, backgroundColor:"#505458", color:"white"}}><Icon type="plus"/>{t('Create a Bank Reconciliation')}</Button>
                
                </Row>
                </div>

                <Row type="flex" justify="space-between" style={{padding:10, margin:10}}>
                    <Col > 
                        <Row type="flex">
                            <Col><h3>Per</h3></Col>
                            <Select defaultValue="25" >
                                <Option value="25">25</Option>
                                <Option value="50">50</Option>
                                <Option value="100">100</Option>
                                <Option value="200">200</Option>
                                <Option value="All">{t('All')}</Option>
                            </Select>
                            <Col><h3>{t('Line')}</h3></Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row type="flex">
                        <Col span={5} style={{marginRight:10}}><h3>{t('Search')}</h3></Col>
                        <Col span={15}><Input></Input></Col>
                        </Row>
                    </Col>
                </Row>
                <div style={{margin:5, padding:20}}>
                    <Table columns={columns}></Table>
                </div>
            </Layout.Content>
        )
    }

}
export default withTranslation() (Rekonsiliasi)