import React from 'react'
import {Row, Col, Icon, Breadcrumb, Layout, PageHeader, Button, DatePicker, Select, Input, Divider, Table} from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next';

class Send extends React.Component{
    render(){
    const Option = Select
    const dateFormat = 'DD/MM/YYYY'
    const {TextArea} = Input
    const { t } = this.props
    const columns = [
        { 
          title: `${t("Payment for")}`,
          dataIndex: 'produk',
          key: 'produk',
        },
        {
          title: `${t("Description")}`,
          dataIndex: 'deskripsi',
          key: 'deskripsi',
        },
        {
          title: `${t("Amount")}`,
          dataIndex: 'dikembalikan',
          key: 'dikembalikan',
        }
      ];
        return(
            <Layout.Content>
                <Row style={{marginLeft:10, marginTop:20}} type="flex" justify="space-around">
                    <Col span={12}><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}>{t("Send Money")}</h1></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end" style={{fontWeight:'bold'}}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t("Accounting")}</Breadcrumb.Item>
                                    <Breadcrumb.Item><a href="/coa">{t("Bank cash")}</a></Breadcrumb.Item>
                                    <Breadcrumb.Item>{t("Send Money")}</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                </Row>
            <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
                    <Row>
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("Pay From")}</label></Col>
                                <Col span={14}><Select defaultValue="1-1200 - Bank" style={{width:'100%'}}>
                                                    <Option value="01">1-1200 - Bank</Option>
                                                    <Option value="02">1-1100 - Kas</Option>
                                                    <Option value="03">1-1120 - Kas Bank POS</Option>
                                                    <Option value="04">1-1110 - Kas POS</Option>
                                                </Select></Col>                                
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("Receiver")}</label></Col>
                                <Col span={14}><Input/></Col>                                
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>Per {t("Date")}</label></Col>
                                <Col span={14}><div>
                                                    <DatePicker defaultValue={moment('02/03/2020', dateFormat)} style={{width:308}} format={dateFormat} />
                                                </div></Col>                                
                            </Row>
                        </Col>   
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={9} style={{fontWeight:'bold', marginLeft:20}}><label>Memo</label></Col>
                                <Col span={14}><TextArea style={{height:83}}></TextArea></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={9} style={{fontWeight:'bold', marginLeft:20}}><label>{t("Referencenum")}</label></Col>
                                <Col span={14}><Input/></Col>                                
                            </Row>
                        </Col> 
                    </Row>
                    <Col>
                        <Divider type="horizontal"/>
                    </Col>
                    <div style={{marginTop:20, border: '1px solid #ebedf0'}}>
                            <Table columns={columns}></Table>
                    </div>
                    <Col span={12}>
                            <Divider/>
                            <Col>
                                <Row>
                                <Button style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold'}}><Icon type="plus" /> {t("Add Data")} </Button>
                                </Row>
                            </Col>
                        </Col>
            </PageHeader>
                <Row type="flex" style={{marginBottom:30}}>
                <Row type="flex" style={{marginBottom:30}}>
                    <Col style={{marginTop:15,marginRight:10}}><Button href="/coa" style={{backgroundColor:'#545961',color:'white',fontWeight:'bold'}}>{t("Cancel")}</Button></Col>
                    <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t("Save Shipping")} </Button></Col>
                </Row>
                </Row>
            </Layout.Content>
        )
    }
}

export default withTranslation() (Send)