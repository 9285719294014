const initState = {
    error: null,
    validPersonal: null,
    fetching: false,
    fetched: false,
    message: null,
    current: 0
}

const validateRegisterReducer = (state = initState, action) => {
    switch(action.type){
        case 'VALIDATE_PERSONAL_PENDING':
            return {
                ...state,
                fetching: true
            }
        case 'VALIDATE_PERSONAL_SUCCESS':
            return {
                ...state,
                fetching: false,
                fetched: true,
                validPersonal: action.valid,
                current: state.current + 1
            }
        case 'VALIDATE_PERSONAL_FAILED':
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: action.error,
                validPersonal: false,
                message: action.error.data.message
            }
        default:
            return state
    }
}

export default validateRegisterReducer