import React, { Component } from 'react'
import { Layout, Row, Col, Breadcrumb, Divider, Typography, Icon, Button, DatePicker, Input, Card, Select, Table, Pagination } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

class Return extends Component{
    render(){
        const {Title} = Typography
        const {RangePicker} = DatePicker
        const dateFormat = 'DD/MM/YYYY'
        const Option = Select
        const {t} = this.props
        const columns = [
            {
              title: `${t('Date')}`,
              dataIndex: 'Tanggal',
              key: 'Tanggal',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('Number')}`,
              dataIndex: 'Nomor',
              key: 'Nomor',
            },
            {
              title: `${t('Suplier')}`,
              dataIndex: 'Suplier',
              key: 'Suplier',
            },
            {
                title: `${t('Due Date')}`,
                dataIndex: 'Tanggal_Jatuh_Tempo',
                key: 'Tanggal_Jatuh_Tempo',
            },
            {
                title: `${t('Status')}`,
                dataIndex: 'Status',
                key: 'Status',
            },
            {
                title: `${t('Total')}`,
                dataIndex: 'Total',
                key: 'Total',
            },
          ];    
        return(
            <Layout.Content>
                 <div style={{margin:10,padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Return of Purchase')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Purchase')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/suplier">{t('Return of Purchase')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider  type="horizontal" style={{marginTop:-10}} />
                <div style={{margin:10,padding:20}}>
                    <Row type="flex" justify="space-between" >
                        <Col ><Button href="/return/create" style={{backgroundColor:'#595959',fontWeight:'bold',color:'white'}} ><Icon type="plus"/>{t('Create Return of Purchase')}</Button></Col>
                        <Col>
                            <Row type="flex">
                                <Col style={{marginRight:5}}><RangePicker defaultValue={[moment('01/01/2018',dateFormat),moment('31/12/2018',dateFormat)]} format={dateFormat} /></Col>
                                <Col><Input placeholder={t('All Status')} /></Col>
                            </Row>
                        </Col>
                        <Col><Button  style={{backgroundColor:'#595959',fontWeight:'bold',color:'white'}} >{t('Filter')}</Button></Col>
                    </Row>
                
                    <Card style={{borderWidth:2,borderRadius:10,marginTop:10}}>
                        <Row type="flex" justify="space-between">
                            <Col type="flex">
                                <Row type="flex">
                                    <Col type="flex"><h3 style={{fontWeight:'bold',marginRight:3}}>Per</h3></Col>
                                    <Col type="flex">
                                        <Select defaultValue="25" style={{marginRight:3}}>
                                            <Option value="25">25</Option>
                                            <Option value="50">50</Option>
                                            <Option value="100">100</Option>
                                            <Option value="200">200</Option>
                                            <Option value="All">All</Option>
                                        </Select>
                                    </Col>
                                    <Col type="flex"><h3 style={{fontWeight:'bold'}}>{t('Line')}</h3></Col>
                                </Row>
                            </Col>
                            <Col type="flex">
                                <Row type="flex">
                                    <Col><h3 style={{fontWeight:'bold',marginRight:3}}>{t('Search')}</h3></Col>
                                    <Col><Input  style={{width:200}} /></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Table columns={columns} style={{marginTop:10}}/>
                        <div style={{marginTop:20}}>
                            <Pagination 
                                total={85}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                pageSize={1}
                                defaultCurrent={1}
                                />
                        
                        </div>
                    </Card>
                </div>
            </Layout.Content>
        )
    }
}
export default withTranslation()(Return) 