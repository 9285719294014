import React, { Component } from 'react'
import { Layout, Row, Col, Breadcrumb, Divider, Typography, Card, Input, Button } from 'antd'
import { withTranslation } from 'react-i18next'

class ProjectStatusCreate extends Component{
    render(){
        const {Title} = Typography
        const {t} = this.props
        return(
            <Layout.Content>
                <div style={{marginTop:30}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Add Data')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Project Management')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/productionlist">{t('Add Data')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>
                    <Card style={{borderWidth:2,borderRadius:10}}>
                        <Row type="flex">
                            <Col span={18}>
                                <Row type="flex">
                                    <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Status Name')}</Col>
                                    <Col span={15}><Input style={{height:40}} placeholder={t('Status Name')} /></Col>
                                </Row>
                                <Row style={{marginTop:10}}>
                                    <Col span={8}>
                                    <Button style={{marginRight:10,width:110,fontWeight:'bold',color:'white',backgroundColor:'#8c8c8c',marginBottom:10}}>{t('Cancel')}</Button>
                                    <Button style={{fontWeight:'bold',color:'white',backgroundColor:'#13c2c2',marginBottom:10}}>{t('Add Data')}</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </Layout.Content>
        )
    }
}
export default withTranslation()(ProjectStatusCreate) 