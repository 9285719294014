import React, { Component } from 'react'
import { Layout, Typography, Select, Input, Row, Col, Breadcrumb, Divider, Card, Button, Icon, Table, Radio, notification, Modal } from 'antd'
import { withTranslation } from 'react-i18next';

class Pos extends Component{
    state = { visible: false };
    showModal = () => {
        this.setState({
          visible: true,
        });
      };
    handleOk = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };
    handleCancel = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
      };
    render(){
        const {Title} = Typography
        const Option = Select
        const {TextArea} = Input
        const {t} = this.props
        const columns = [
            {
              title: `${t('Code')}`,
              dataIndex: 'Kode',
              key: 'Kode',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('Product Name')}`,
              dataIndex: 'Nama_Produk',
              key: 'Nama_Produk',
            },
            {
              title: `${t('Quantity')}`,
              dataIndex: 'Kuantitas',
              key: 'Kuantitas',
            },
            {
                title: `${t('Price')}`,
                dataIndex: 'Harga',
                key: 'Harga',
            },
            {
                title: `${t('Amount')}`,
                dataIndex: 'Jumlah',
                key: 'Jumlah',
            },
          ];  
          const openNotification = () => {
            notification.open({
              message: 'Mohon Lengkapi Form yang diperlukan',
              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
          };  
          
        return(
            <Layout.Content>
                <div style={{margin:10,padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Point of Sales')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('The Sale')} </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/pos">POS</a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/pos">{t('Create POS')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider  type="horizontal" style={{marginTop:-10}} />
                <div >
                    <Card style={{borderWidth:2,borderRadius:10}}>
                        <Row type="flex" justify="space-between">
                            <Col span={13}>
                                <Row type="flex">
                                    <Col span={20}><Input placeholder={t('Search for Product Code or Product Name')} /></Col>
                                    <Col span={4}><Button onClick={this.showModal} style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold',width:'100% '}} ><Icon type="search" /></Button></Col>
                                </Row>
                                <Modal
                                    title={t('Product List')}
                                    visible={this.state.visible}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                >
                                    <Table
                                        columns={[
                                            {
                                                title:`${t('Code')}`,
                                                dataIndex:'Kode',
                                                key:'Kode'
                                            },
                                            {
                                                title:`${t('Product Name')}`,
                                                dataIndex:'Nama_Produk',
                                                key:'Nama_Produk'
                                            },
                                            {
                                                title:`${t('Price')}`,
                                                dataIndex:'Harga',
                                                key:'Harga'
                                            },
                                        ]}
                                    />
                                </Modal>
                                <Table style={{marginTop:20}} columns={columns} />
                                
                            </Col>
                            <Col span={10} >
                                <Row>
                                    <Col span={7} style={{fontSize:14,fontWeight:'bold'}}>{t('Total Price')}</Col>
                                    <Col span={16}><Input style={{backgroundColor:'#f5f5f5',textAlign:'right'}} readOnly placeholder="Rp. 0,00" /></Col>
                                </Row>
                                <Row style={{marginTop:15}}>
                                    <Col span={7} style={{fontSize:14,fontWeight:'bold'}}>PPN</Col>
                                    <Col span={16}><Input style={{backgroundColor:'#f5f5f5',textAlign:'right'}} readOnly placeholder="Rp. 0,00" /></Col>
                                </Row>
                                <Row style={{marginTop:15}}>
                                    <Col span={7} style={{fontSize:14,fontWeight:'bold'}}>Grand Total</Col>
                                    <Col span={16}><Input style={{backgroundColor:'#f5f5f5',textAlign:'right'}} readOnly placeholder="Rp. 0,00" /></Col>
                                </Row>
                                <Row style={{marginTop:15}}>
                                    <Col span={7} style={{fontSize:14,fontWeight:'bold'}}>{t('Pay')}</Col>
                                    <Col span={16}><Input style={{backgroundColor:'#f5f5f5',textAlign:'right'}}  placeholder="Rp. 0,00" /></Col>
                                </Row>
                                <Row style={{marginTop:15}}>
                                    <Col span={7} style={{fontSize:14,fontWeight:'bold'}}>{t('Back')}</Col>
                                    <Col span={16}><Input style={{backgroundColor:'#f5f5f5',textAlign:'right'}} readOnly placeholder="Rp. 0,00" /></Col>
                                </Row>
                                <Row style={{marginTop:15}}>
                                    <Col span={7} style={{fontSize:14,fontWeight:'bold'}}>{t('Payment Method')}</Col>
                                    <Col span={16}>
                                        <Radio.Group>
                                            <Radio value={1}>{t('Cash')}</Radio>
                                            <Radio value={2}>{t('Credit')} </Radio>
                                        </Radio.Group>
                                    </Col>
                                </Row>
                                <Row style={{marginTop:15}}>
                                    <Col span={7} style={{fontSize:14,fontWeight:'bold'}}>{t('Note')}</Col>
                                    <Col span={16}><TextArea rows={4} /></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    <Row type="flex" style={{marginTop:10,marginLeft:20}}>
                        <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('New')}</Button></Col>
                        <Col style={{marginTop:15,marginRight:10}}><Button onClick={openNotification} style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Save')} </Button></Col>
                        <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Print Receipt')}</Button></Col>
                        <Col style={{marginTop:15}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Print Nota')}</Button></Col>
                    </Row>
                </div>
            </Layout.Content>
        )
    }
}
export default withTranslation()(Pos) 