import React, { Component } from 'react'
import { Row, Typography, Col, Breadcrumb, Layout, Divider, Input, Select, DatePicker, PageHeader, Button, Icon, Table } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next';

class AddOffer extends Component{
    render(){
        const { t } = this.props;
        const {Title} = Typography
        const Option = Select
        const dateFormat ='DD/MM/YYYY'
        const {TextArea} = Input
        const columns = [
            {
              title: `${t('Product')}`,
              dataIndex: 'Produk',
              key: 'Produk',
            },
            {
              title: `${t('Description')}`,
              dataIndex: 'Deskripsi',
              key: 'Deskripsi',
            },
            {
              title: `${t('Quantity')}`,
              dataIndex: 'Kuantitas',
              key: 'Kuantitas',
            },
            {
                title: `${t('Unit Price')}`,
                dataIndex: 'Harga_Satuan',
                key: 'Harga_Satuan',
            },
            {
                title: `${t('Discount')}`,
                dataIndex: 'Diskon',
                key: 'Diskon',
            },
            {
                title: `${t('Total')}`,
                dataIndex: 'Jumlah',
                key: 'Jumlah',
            },
            {
                title: `${t('Tax')}`,
                dataIndex: 'Pajak',
                key: 'Pajak',
            },
          ];
        return(
            <Layout.Content>
            <PageHeader style={{ border: '1px solid #ebedf0'}}>
                <div>
                    <Row type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Make a Offer')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Sales')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/sales-quotation">{t('Sales Offer')}</a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/sales-quotation/create">{t('Make a Offer')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                </PageHeader>
                <PageHeader style={{ border: '1px solid #ebedf0'}}>
                    <Divider type="horizontal" style={{margin:-10}}/>
                    <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                        <Row>
                            <Col span={12}>
                                <Row type="flex">
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Customer')}</label></Col>
                                    <Col span={15} >  <Select placeholder={t('Select')} style={{width:'100%'}}>
                                            <Option value="kap">ASTRA DAIHATSU MOTOR - (KAP)</Option>
                                            <Option value="sap">ASTRA DAIHATSU MOTOR - (SAP)</Option>
                                            <Option value="apc">ASTRA DAIHATSU MOTOR - (APC)</Option>
                                            <Option value="kic">PT. KOTOBUKIYA INDO CLASSIC INDUSTRIES - (KIC)</Option>
                                            <Option value="aaa">PT. APM ARMADA AUTOPAERTS - (AAA)</Option>
                                            <Option value="ams">PT. ARMSTRONG - (AMS)</Option>
                                            <Option value="bti">PT. BONECOM TRICOM - (BTI)</Option>
                                            <Option value="dwa">PT. DASA WINDU AGUNG - (DWA)</Option>
                                            <Option value="eti">PT. ECHO ADVANCED TECHNOLOGY INDONESIA - (ETI)</Option>
                                            <Option value="hmi">PT. HINO MOTORS MANUFACTURING INDONESIA - (HMI)</Option>
                                            <Option value="hwi">PT. HOWA INDONESIA - (HWI)</Option>
                                            <Option value="ipi">PT. INOAC POLYTECHNO INDONESIA - (IPI)</Option>
                                            <Option value="ipm">PT. INTIPOLY MENTAL - (IPM)</Option>
                                            <Option value="irc">PT. IRC INOAC INDONESIA - (IRC)</Option>
                                            <Option value="jvc">PT. JAYA VICTORY CEMERLANG - (JVC)</Option>
                                            <Option value="kbb">PT. KARYA BAHAN BERLIAN - (KBB)</Option>
                                            <Option value="mbp">PT. MAJU BERSAMA PERSADA DAYAMU - (MBP)</Option>
                                            <Option value="mwi">PT. MEIWA INDONESIA - (MWI)</Option>
                                            <Option value="mes">PT. METINDO ERASAKTI - (MES)</Option>
                                            <Option value="map">PT. MUTIHA ABADI PERIZKY - (MAP)</Option>
                                            <Option value="nti">PT. NITTO MATERIALS - (NTI)</Option>
                                            <Option value="nki">PT. NUSA KEIHIN INDONESIA - (NKI)</Option>
                                            <Option value="sgi">PT. SANKO GOSEI - (SGI)</Option>
                                            <Option value="stp">PT. SARI TAGEKI ELOK PRODUK - (STP)</Option>
                                            <Option value="sgt">PT. SUGITY CREATIVES - (SGT)</Option>
                                            <Option value="sgp">PT. SURYA GEMILANG PERKASA - (SGP)</Option>
                                            <Option value="sim">PT. SUZUKI INDOMOBIL MOTOR - (SIM)</Option>
                                            <Option value="tbi">PT. TOYOTA BOSHOKU INDONESIA - (TBI)</Option>
                                            <Option value="tna">PT. TUFFINDO NITTOKU AUTONEUM - (TNA)</Option>
                                            <Option value="ust">PT. USRA TAMPI - (UST)</Option>
                                            <Option value="vqi">PT. VUTEG INDONESIA - (VQI)</Option>
                                        </Select>
                                    </Col>     
                                </Row>
                                <Row type="flex" style={{marginTop:20}}>
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Billing Address')}</label></Col>
                                    <Col span={15}><TextArea rows={4} style={{width:'100%'}} /></Col>
                                </Row>
                                <Row type="flex" style={{marginTop:20}}>
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Addressed to')}</label></Col>
                                    <Col span={15}><Input/></Col>
                                </Row>
                                <Row type="flex" style={{marginTop:20}}>
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Title of Offer')}</label></Col>
                                    <Col span={15}><Input/></Col>
                                </Row>
                                
                            </Col>
                            <Col span={12}>
                                <Row type="flex">
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Original Warehouse')}</label></Col>
                                    <Col span={15} >  <Select placeholder={t('Select')} style={{width:'100%'}}>
                                            <Option value="grm">(GDG-0001) - Gudang Raw Material</Option>
                                            <Option value="gfg">(GDG-0003) - Gudang Finished Goods</Option>
                                        </Select>
                                    </Col>     
                                </Row>
                                <Row type="flex" style={{marginTop:20}}> 
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Offer Number')}</label></Col>
                                    <Col span={15}><Input/></Col>
                                </Row>
                                <Row type="flex" style={{marginTop:20}}> 
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Date of Offer')}</label></Col>
                                    <Col span={15}> <DatePicker defaultValue={moment('18/02/2020', dateFormat)} format={dateFormat} /></Col>
                                </Row>
                                <Row type="flex" style={{marginTop:20}}>
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Valid Until')}</label></Col>
                                    <Col span={15}> <DatePicker defaultValue={moment('19/03/2020', dateFormat)} format={dateFormat} /></Col>
                                </Row>
                                <Row type="flex" style={{marginTop:20}}>
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Terms of Payment')}</label></Col>
                                    <Col span={15} >  <Select placeholder={t('Select')} style={{width:'100%'}}>
                                            <Option value="cash">Cash On Delivery</Option>
                                            <Option value="15">Net 15</Option>
                                            <Option value="30">Net 30</Option>
                                            <Option value="60">Net 60</Option>
                                        </Select>
                                    </Col>     
                                </Row>
                            </Col>
                        </Row>
                        
                        <Divider type="horizontal" style={{marginTop:40}} />
                        <Table columns ={columns}></Table>
                        
                        <Col span={12}>
                            <Col>
                                <Row>
                                    <Col><TextArea placeholder={t('Message')} rows={4} style={{width:'80%', marginTop:20}} /></Col>
                                </Row>
                            </Col>
                            <Button style={{marginTop:20, backgroundColor:'#0d1933',color:'white',fontWeight:'bold',marginBottom:10}} ><Icon type="plus"/>{t('Add Line')}</Button>
                        </Col>
                        
                        <Col span={12}>
                            <Row type="flex" justify="end"style={{marginTop:20}} >
                                <Col span={5} style={{fontWeight:'bold'}}><label>Sub Total</label></Col>
                                <Col span={10}><Input/></Col>
                            </Row>

                            <Row type="flex" justify="end"style={{marginTop:10}} >
                                <Col span={5} style={{fontWeight:'bold'}}><label>{t('Tax')}</label></Col>
                                <Col span={10}><Input/></Col>                
                            </Row>

                            <Row type="flex" justify="end"style={{marginTop:10}} >
                                <Col span={5} style={{fontWeight:'bold'}}><label>Total</label></Col>
                                <Col span={10}><Input/></Col>
                            </Row>
                        </Col>       
                    </div>
                </PageHeader>
                
                <Row type="flex" style={{marginBottom:30}}>
                    <Col style={{marginTop:15,marginLeft:43,marginRight:20}}><Button href="/sales-quotation" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                    <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Make a Offer')}</Button></Col>
                </Row> 

            </Layout.Content> 
        )
    }
}
export default withTranslation()(AddOffer)