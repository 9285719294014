import React from 'react'
import {Row, Col, Breadcrumb, Layout, PageHeader, Button, Select, Input, Divider, Radio} from 'antd'
import { withTranslation } from 'react-i18next';

class AddUser extends React.Component{
    state = {
        value: 1,
      };
    
      onChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
          value: e.target.value,
        });
      };
    render(){
        const Option = Select
        const { t } = this.props
        const dateFormat = 'DD/MM/YYYY'
        const {TextArea} = Input
        
        return(
            <Layout.Content>
                    <Row type="flex" justify="space-around" style={{marginTop:30}}>
                        <Col span={12}><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}>{t("Add User")}</h1></Col>
                            <Col span={12} >
                                <Row type="flex" justify="end" style={{fontWeight:'bold'}}>
                                    <Breadcrumb>
                                        <Breadcrumb.Item>{t("Setting")}</Breadcrumb.Item>
                                        <Breadcrumb.Item>{t("User")}</Breadcrumb.Item>
                                        <Breadcrumb.Item>{t("Add User")}</Breadcrumb.Item>
                                    </Breadcrumb>
                                </Row>
                            </Col>
                    </Row>
            <Divider></Divider>
            <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
            <div style={{marginRight:10,borderRadius:10}}>
                    <Row>
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={9} style={{fontWeight:'bold'}}><label>Email {t("User")}</label></Col>
                                <Col span={14} style={{marginLeft:20}}><Input/></Col>                   
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={9} style={{fontWeight:'bold'}}><label>Group {t("User")}</label></Col>
                                <Col span={14} style={{marginLeft:20}}><Select defaultValue="Administrator" style={{width:'100%'}}>
                                                    <Option value="01">Administrator</Option>
                                                    <Option value="02">{t("Employee")}</Option>
                                                    <Option value="03">PPIC</Option>
                                                    <Option value="04">Marketing</Option>
                                                    <Option value="05">Accounting & Finance</Option>
                                                    <Option value="06">Purchasing & HRD</Option>
                                                    <Option value="07">Finance</Option>
                                                    <Option value="08">gfd</Option>
                                                </Select></Col>                              
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={9} style={{fontWeight:'bold'}}><label>{t("Active Status")}</label></Col>
                                <Col span={14} style={{marginLeft:20}}> <Radio.Group onChange={this.onChange} value={this.state.value}>
                                                    <Radio value={1}>{t("Active")}</Radio>
                                                    <Radio value={2}>{t("No")}</Radio>
                                                </Radio.Group></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={9} style={{fontWeight:'bold', marginBottom:20}}><label>{t("Employees Who Use")}</label></Col>
                                <Col span={14} style={{marginLeft:20}}><Select defaultValue="Not Set" style={{width:'100%'}}>
                                                    <Option value="01"></Option>
                                                </Select></Col>                                
                            </Row>
                        </Col>   
                    </Row>
                </div>
            </PageHeader>
                <Row type="flex" style={{marginBottom:30}}>
                    <Col style={{marginTop:15,marginRight:10}}><Button href="/user" style={{backgroundColor:'#545961',color:'white',fontWeight:'bold'}}>{t("Back")}</Button></Col>
                    <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t("Send Invitation")}</Button></Col>
                </Row>
            </Layout.Content>
        )
    }
}

export default withTranslation() (AddUser)