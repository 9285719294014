const  initState = {
    fetching: false,
    fetched: false,
    errors: null,
    customers: [],
    total: 0,
    message: null,
    deleted: false,
    saved: false
}

const customerReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_CUSTOMER_PENDING':
            return {
                ...state,
                fetching: true
            }
        case 'FETCH_CUSTOMER_SUCCESS':
            return {
                ...state,
                fetching: false,
                fetched: true,
                customers: action.data,
                total: action.total,
                errors: null,
                saved: false,
                deleted: false
            }
        case 'FETCH_CUSTOMER_FAILED':
            return {
                ...state,
                fetching: false,
                fetching: true,
                errors: action.errors
            }
        case 'DELETE_CUSTOMER_PENDING':
            return {
                ...state,
                fetching: true
            }
        case 'DELETE_CUSTOMER_SUCCESS':
            return {
                ...state,
                fetching: false,
                fetched: true,
                message: action.message,
                deleted: action.deleted
            }
        case 'DELETE_CUSTOMER_FAILED':
            return {
                ...state,
                fetching: false,
                fetched: true,
                errors: action.errors
            }
        case 'SAVE_CUSTOMER_PENDING':
            return {
                ...state,
                fetching: true
            }
        case 'SAVE_CUSTOMER_SUCCESS':
            return {
                ...state,
                fetching: false,
                fetched: true,
                message: action.message,
                saved: action.saved,
                errors: null
            }
        case 'SAVE_CUSTOMER_FAILED':
            return {
                ...state,
                fetching: false,
                fetched: true,
                errors: action.errors
            }
        
        default: 
            return state
        
    }
}

export default customerReducer