import React, { Component } from 'react';
import {Typography,Layout, Row,Col,Breadcrumb,Button,Icon, PageHeader,Select,Input,Table,DatePicker,Di} from 'antd';
import { withTranslation } from 'react-i18next';

class Proof extends Component{
  render(){
    const { t } = this.props;
    const {Title} = Typography
    const {Option} = Select
    const dateFormat = 'DD/MM/YYYY'
    const columns = [
      {
        title: `${t('Date')}`,
        dataIndex: 'Tanggal',
        key: 'Tanggal',
      },
      {
        title: `${t('Number')}`,
        dataIndex: 'Nomor',
        key: 'Nomor',
      },
      {
        title: `${t('Warehouse Name')}`,
        dataIndex: 'Nama_Gudang',
        key: 'Nama_Gudang',
      },
      {
        title: `${t('Addressed to')}`,
        dataIndex: 'Ditujukan_Kepada',
        key: 'Ditujukan_Kepada',
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
      },
      {
        title: 'Total',
        dataIndex: 'Total',
        key: 'Total', 
      },
    ];
      return(
        <Layout.Content>
          <PageHeader style={{ border: '1px solid #ebedf0'}}>
            <Row style={{margin:1}} type="flex" justify="space-around">
              <Col  span={12}><Title level={4}>{t('Proof of Order')}</Title></Col>
                <Col span={12} >
                  <Row type="flex" justify="end">
                    <Breadcrumb>
                      <Breadcrumb.Item>{t('Production')}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <a href="">{t('Proof of Order')}</a>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                  </Row>
                </Col>
            </Row>
          </PageHeader>  
          
          <PageHeader>
            <Col>
              <Row type="flex" justify="space-between">
                <Button href="/bob/create" style={{backgroundColor:'#0d1933',color:'white',fontWeight:'bold'}}><Icon type="plus" /> {t('Add')} </Button>
                <DatePicker style={{marginLeft:150}} placeholder={t('Start')} format={dateFormat} />
                <DatePicker placeholder={t('End')} format={dateFormat} />
                <Button style={{ backgroundColor:'#0d1933', color:'white',fontWeight:'bold', width:100, marginLeft:35}}>Filter</Button>
              </Row>
            </Col>
          </PageHeader>

          <div style={{margin:10,borderWidth:5,borderColor:'red',padding:20}}>
            <Row type="flex" justify="space-between"> 
              <Col>
                <Row type="flex">
                  <Col style={{marginRight:10}}><h3>Per</h3></Col>
                    <Col>
                      <Select style={{width:80}}defaultValue="25" >
                        <Option value="25">25</Option>
                        <Option value="50">50</Option>
                        <Option value="100">100</Option>
                        <Option value="200">200</Option>
                        <Option value="All">All</Option>
                      </Select>
                    </Col>
                    <Col style={{marginLeft:10}}><h3>{t('Line')}</h3></Col>
                </Row>
              </Col>
              <Col>
                <Row type="flex">
                  <Col style={{marginRight:10}}><h3>{t('Search')}</h3></Col>
                  <Col><Input></Input></Col>
                </Row>
              </Col>
              </Row>
                <div style={{marginTop:20}}>
                  <Table columns={columns} ></Table>
                </div>
              </div>
        </Layout.Content>
      )
  }
}
export default withTranslation()(Proof)