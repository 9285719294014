import React, { Component } from 'react'
import { Layout, Row, Col, Breadcrumb, Divider, Typography, Input, Select, Button, Icon, Upload, Card, Avatar, Dropdown, Menu, Checkbox } from 'antd'
import { withTranslation } from 'react-i18next'

class EmployeeProfile extends Component{
    render(){
        const {Title} = Typography
        const Option = Select
        const {t} = this.props
        
        return(
            <Layout.Content>
                <div style={{margin:10,padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Employee Profile')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>Personalia</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/profile">{t('Employee Profile')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider  type="horizontal" style={{marginTop:-10}} />
                <div style={{marginLeft:10,paddingLeft:20,paddingRight:20,marginRight:10}}>
                    <Row type="flex" justify="space-between">
                        <Col span={22}>
                            <Row type="flex">
                                <Col style={{marginTop:5,marginRight:10,fontSize:15}}> Cari :</Col>
                                <Col span={5} style={{marginRight:10}}><Input  placeholder={t('All Employee Name')} /></Col>
                                <Col span={5} style={{marginRight:10}}><Select style={{width:'100%'}} mode="multiple" placeholder={t('All Position')}>
                                        <Option value="CEO">CEO</Option>
                                    </Select>
                                </Col>
                                <Col span={5}><Select style={{width:'100%'}} mode="multiple" placeholder={t('All Status')}>
                                        <Option value="Kontrak">Kontrak</Option>
                                        <Option value="Permanen">Permanen</Option>
                                        <Option value="Percobaan">Percobaan</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                        <Col><Button  style={{backgroundColor:'#ffc53d',fontWeight:'bold'}} >{t('Filter')}</Button></Col>
                    </Row>
                    <Divider type="horizontal"/>
                    <Card style={{borderWidth:2,borderRadius:5,borderColor:'#ffc53d',width:250}}>
                        <Row type="flex" justify="space-around">
                            <Col ><Avatar style={{width:150,height:150}} src="https://library.kissclipart.com/20181114/oke/kissclipart-avatar-profile-png-clipart-computer-icons-avatar-ed68f86e7eead50a.jpg" /></Col>
                            <Col style={{fontWeight:'bold',color:'blue',marginTop:10,fontSize:18}}>HIDAYATURRAHMAN</Col>  
                        </Row>
                        <Row type="flex" justify="space-around">
                            <Col style={{fontSize:12,fontWeight:'bold'}}>1 Tahun 6 Bulan</Col>
                        </Row>
                        <Row type="flex" justify="space-around">
                            <Col style={{fontSize:18,fontWeight:'bold'}}>CEO</Col>
                        </Row>
                        <Row type="flex" justify="space-around">
                        <   Col style={{fontSize:18,fontWeight:'bold'}}>BOD</Col>
                        </Row>

                    </Card>
                </div>
            </Layout.Content>
        )
    }
}
export default withTranslation()(EmployeeProfile) 