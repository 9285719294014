import React from 'react'
import {Row, Col, Breadcrumb, Layout, PageHeader, Button, DatePicker, Select, Input} from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next';

class Transfer extends React.Component{
    render(){
    const Option = Select
    const dateFormat = 'DD/MM/YYYY'
    const {TextArea} = Input
    const { t } = this.props
        return(
            <Layout.Content>
                <Row style={{marginLeft:10, marginTop:20}} type="flex" justify="space-around">
                    <Col span={12}><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}>{t("Transfer of Money")}</h1></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end" style={{fontWeight:'bold'}}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t("Accounting")}</Breadcrumb.Item>
                                    <Breadcrumb.Item><a href="/coa">{t("Bank cash")}</a></Breadcrumb.Item>
                                    <Breadcrumb.Item>{t("Transfer of Money")}</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                </Row>
            <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
                    <Row>
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("Transfer From")}</label></Col>
                                <Col span={14}><Select defaultValue="1-1200 - Bank" style={{width:'100%'}}>
                                                    <Option value="01">1-1200 - Bank</Option>
                                                    <Option value="02">1-1100 - Kas</Option>
                                                    <Option value="03">1-1120 - Kas Bank POS</Option>
                                                    <Option value="04">1-1110 - Kas POS</Option>
                                                </Select></Col>                                
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("Deposit to")}</label></Col>
                                <Col span={14}><Select defaultValue="1-1200 - Bank" style={{width:'100%'}}>
                                                    <Option value="01">1-1200 - Bank</Option>
                                                    <Option value="02">1-1100 - Kas</Option>
                                                    <Option value="03">1-1120 - Kas Bank POS</Option>
                                                    <Option value="04">1-1110 - Kas POS</Option>
                                                </Select></Col>                                
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("Amount")}</label></Col>
                                <Col span={14}><Input placeholder="Rp. 0,00" style={{textAlign:'end'}}></Input></Col>                                
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>Per {t("Date")}</label></Col>
                                <Col span={14}><div>
                                                    <DatePicker defaultValue={moment('02/03/2020', dateFormat)} style={{width:308}} format={dateFormat} />
                                                </div></Col>                                
                            </Row>
                            
                        </Col>   
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={9} style={{fontWeight:'bold', marginLeft:20}}><label>Memo</label></Col>
                                <Col span={14}><TextArea></TextArea></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={9} style={{fontWeight:'bold', marginLeft:20}}><label>{t("Referencenum")}</label></Col>
                                <Col span={14}><Input/></Col>                                
                            </Row>
                        </Col> 
                    </Row>
            </PageHeader>
                <Row type="flex" style={{marginBottom:30}}>
                    <Col style={{marginTop:15,marginRight:10}}><Button href="/coa" style={{backgroundColor:'#545961',color:'white',fontWeight:'bold'}}>{t("Cancel")}</Button></Col>
                    <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t("Save Transfer")} </Button></Col>
                </Row>
            </Layout.Content>
        )
    }
}

export default withTranslation() (Transfer)