import React from 'react'
import {Row, Col, Breadcrumb, Layout, PageHeader, Button, Icon, Divider,Calendar, Modal, Select, DatePicker, Input} from 'antd'
import { withTranslation } from 'react-i18next'

//Calendar
function onPanelChange(value, mode) {
    console.log(value, mode);
  }
//EndCalendar

class Calender extends React.Component{
//Modal
    state = { visible: false };

    showModal = () => {
      this.setState({
        visible: true,
      });
    };
    handleOk = e => {
      console.log(e);
      this.setState({
        visible: false,
      });
    };
    handleCancel = e => {
      console.log(e);
      this.setState({
        visible: false,
      });
    };
//EndModal

    render(){
        const Option = Select
        const { t } = this.props
        const dateFormat = 'DD/MM/YYYY'
        return(
            <Layout.Content>
                <Row style={{marginLeft:10, marginTop:20}} type="flex" justify="space-around">
                    <Col span={12}><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}>{t("Work Calendar")}</h1></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end" style={{fontWeight:'bold'}}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t("Personnel")}</Breadcrumb.Item>
                                    <Breadcrumb.Item>{t("Work Calendar")}</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                </Row>
                <Divider type="horizontal"></Divider>
                <Row type="flex" justify="space-between">
                    <Col><Button type="primary" onClick={this.showModal} style={{backgroundColor:'#122142',color:'white',fontWeight:'bold'}}><Icon type="plus" /> {t("Add Calendar")} </Button></Col>
                    <Modal
                        title={t("Add Calendar")} 
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        >
                        <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                            <Row>
                                <Col span={12}>
                                    <Row type="flex">
                                        <Col span={10} style={{fontWeight:'bold'}}><label>{t("Type")}</label></Col>
                                        <Col span={14}><Select defaultValue={t("National holiday")} style={{width:300, marginLeft:10}}>
                                                <Option value="libur">{t("National holiday")}</Option>
                                                <Option value="cuti">{t("Mass Leave")}</Option>
                                            </Select></Col>
                                    </Row>
                                    <Row type="flex" style={{marginTop:20}}>
                                        <Col span={10} style={{fontWeight:'bold'}}><label>{t("Date")}</label></Col>
                                        <Col span={14}><DatePicker style={{width:300, marginLeft:10}} format={dateFormat} /></Col>
                                    </Row>
                                    <Row type="flex" style={{marginTop:20}}>
                                        <Col span={10} style={{fontWeight:'bold'}}><label>{t("Description")}</label></Col>
                                        <Col span={14}><Input style={{width:300, marginLeft:10}} /></Col>
                                    </Row>
                                </Col>
                            </Row>   
                        </div>                         
                    </Modal>
                    <Col><h3 style={{marginLeft:600, fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>{t("Note")} :</h3></Col>
                        <Col><Button size="small" style={{backgroundColor:'#722ed1',color:'white',fontWeight:'bold'}}>{t("Paid Leave")}</Button></Col>
                        <Col><Button size="small" style={{backgroundColor:'#fa541c',color:'white',fontWeight:'bold'}}>{t("Leave")}</Button></Col>
                        <Col><Button size="small" style={{backgroundColor:'#13c2c2',color:'white',fontWeight:'bold'}}>{t("Business Trip")}</Button></Col>
                </Row>
                <Row>
                    <Col>
                        <Divider type="horizontal"/>
                    </Col>
                </Row>
                <Row>
                        <Calendar onPanelChange={onPanelChange} />
                </Row>
            </Layout.Content>
        )
    }
}

export default withTranslation() (Calender)