import React, { Component } from 'react'
import { Layout, 
         Row, 
         Col, 
         Breadcrumb, 
         Typography, 
         PageHeader, 
         Button, 
         Select, 
         Input, 
         Divider} from 'antd'
import { withTranslation } from 'react-i18next';

class AddUserGroup extends React.Component {
    render(){
        const {Title,Text} = Typography
        const {Option} = Select
        const { t } = this.props
        return(
            <Layout.Content>
                <PageHeader style={{border: '1px solid rgb(235,237,340)'}}>
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Create User Group')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Settings')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/role">{t('User Group')} </a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/role/create">{t('Create User Group')} </a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                </PageHeader>

                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                <Divider type="horizontal"/>
                    <Row type="flex" >
                        <Col span={12}>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={10} ><Text style={{size:14, fontWeight:'bold'}}>{t('Group Name')}</Text></Col>
                                <Col span={14}><Input></Input> </Col>  
                            </Row>
                            
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={10} ><Text style={{size:14, fontWeight:'bold'}}>{t('Start Page (After Entering)')}</Text></Col>
                                <Col span={14}>
                                    <Select style={{width:'100%',fontSize:14}} placeholder= {t('Select')}>
                                            <Option value=""><Input></Input></Option>
                                            <Option value="Dasbor Bisanis" >{t('Business Dashboard')}</Option>
                                            <Option value="Daftar Akun " >{t('Register Account')}</Option> 
                                            <Option value="Kas & Bank" >{t('Bank cash')}</Option> 
                                            <Option value="Jurnal Umum" >{t('General journal')}</Option> 
                                            <Option value="Rekonsiliasi Bank" >{t('Bank Reconciliation')}</Option> 
                                            <Option value="Buku Besar" >{t('Ledger')}</Option> 
                                            <Option value="Neraca Saldo" >{t('Trial balance')}</Option> 
                                            <Option value="Laba Rugi" >{t('Profit and loss')}</Option> 
                                            <Option value="Dasbor SDM " >{t('HR Dashboard')}</Option> 
                                            <Option value="Data pegawai " >{t('Employee data')}</Option>
                                            <Option value="Jabatan & Tunjangan " >{t('Position & Benefits')}</Option>
                                            <Option value="Golongan & Gaji Pokok" >{t('Basic & Salary Groups')}</Option>
                                            <Option value="Area Kerja " >{t('Work area')}</Option>
                                            <Option value="Kalender Kerja " >{t('Work Calendar')}</Option>               
                                            <Option value="Bewara " >{t('Bewara')}</Option>
                                            <Option value="Presensi " >{t('Presence')}</Option>
                                            <Option value="Profil Pegawai " >{t('Employee Profile')}</Option>
                                            <Option value="Perjalanan Dinas " >{t('Business trip')}</Option>
                                            <Option value="Penggantian Uang" >{t('Money Reimbursement ')}</Option>
                                            <Option value="Pengajuan Absen" >{t('Absent Submission')}</Option>
                                            <Option value="Pengajuan Lembur" >{t('Submission Overtime')}</Option>
                                            <Option value="Penggajian Pegawai" >{t('Employee Payroll')}</Option>
                                            <Option value="Data Pelamar " >{t('Applicant Data')}</Option>
                                            <Option value="Lowongan Pekerjaan " >{t('Job vacancy')}</Option>
                                            <Option value="Master pertannyaan " >{t('The question master')}</Option>
                                            <Option value="Dasbor Training " >{t('Training Dashboard')}</Option>
                                            <Option value="Master Event " >{t('Event Master')} </Option>
                                            <Option value="Master Course " >{t('Master Course')}</Option>
                                            <Option value="Pertanyaan Evaluasi " >{t('Evaluation Questions')}</Option>
                                            <Option value="Laporan Training " >{t('Training Report')}</Option>
                                            <Option value="Pelanggan " >{t('Customer')}</Option>
                                            <Option value="Penawaran Penjualan " >{t('Sales Offer')}</Option>
                                            <Option value="Pemesanan " >{t('Booking')}</Option>
                                            <Option value="Pengiriman " >{t('Delivery')}</Option>
                                            <Option value="Faktur " >{t('Invoice')}</Option>
                                            <Option value="Pembayaran " >{t('Payment')}</Option>
                                            <Option value="POS " >{t('POST')}</Option>
                                            <Option value="Pegembalian " >{t('Change')}</Option>
                                            <Option value="Laporan Penjualan " >{t('Sales report')}</Option>
                                            <Option value="Dasbor Penjualan " >{t('Sales Dashboard')}</Option>
                                            <Option value="Pemasok " >{t('The supplier')}</Option>
                                            <Option value="Permintaan Pembelian " >{t('Purchase Request')}</Option>
                                            <Option value="Pemesanan " >{t('Booking')}</Option>
                                            <Option value="Penerimaan " >{t('Reception')}</Option>
                                            <Option value="Pembayaran " >{t('Payment')}</Option>
                                            <Option value="Pengembalian" >{t('Return')}</Option>
                                            <Option value="Daftar Barang" >{t('List of items')}</Option>
                                            <Option value="Daftar Gudang " >{t('Warehouse List')}</Option>
                                            <Option value="Stock Opname " >{t('Inventory taking')}</Option>
                                            <Option value="Dasbor Pembelian " >{t('Purchase Dashboard')}</Option>
                                            <Option value="Laporan Pembelian " >{t('Purchase Report')}</Option>
                                            <Option value="Daftar Proyek " >{t('List of Projects')}</Option>
                                            <Option value="Tugas Saya " >{t('My job')}</Option>
                                            <Option value="Peran Anggota " >{t('Role of Members')}</Option>
                                            <Option value="Status Proyek " >{t('Project Status')}</Option>
                                            <Option value="Laporan Harian " >{t('Daily Report')}</Option>
                                            <Option value="Grup Pengguna " >{t('User Group')}</Option>
                                            <Option value="Pengguna" >{t('The user')}</Option>
                                            <Option value="Pengaturan Produksi " >{t('Production Management')}</Option>
                                            <Option value="Bukti Order Barang " >{t('Proof of Order of Goods')}</Option>
                                            <Option value="Bill Of Material " >{t('Bill Of Material')}</Option>
                                    </Select>
                            </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>
                </div>

                    <Row type="flex" style={{marginBottom:30}}>
                        <Col style={{marginTop:15,marginLeft:10,marginRight:10}}><Button href="/role" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                        <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Save Group')} </Button></Col>
                    </Row>
            </Layout.Content>
        )
    }
}
export default withTranslation() (AddUserGroup)