import React, { Component } from 'react';
import {Typography,Layout, Row,Col,Breadcrumb,Button,PageHeader} from 'antd';
import { withTranslation } from 'react-i18next';


class Report extends Component{
  render(){
    const {Title,Text} = Typography
    const { t } = this.props;
    return(
        <Layout.Content>
            <PageHeader style={{ border: '1px solid #ebedf0'}}>
                <Row style={{margin:1}} type="flex" justify="space-around">
                    <Col  span={12}><Title level={4}>{t('Purchase Report')}</Title></Col>
                    <Col span={12} >
                        <Row type="flex" justify="end">
                            <Breadcrumb>
                                <Breadcrumb.Item>{t('Sales')}</Breadcrumb.Item>
                                <Breadcrumb.Item>
                                <a href="">{t('Purchase Report')}</a>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Col>
                </Row>
            </PageHeader>  
          
            <PageHeader>
                <Col span={12}>
                    <Row type="flex"> 
                        <Title level={4}>{t('Purchase List')}</Title>
                        <Col span={24}><Text type="secondary">{t('Displays a chronological list for all your purchases and payments for the selected date range.')}</Text></Col>
                        <Button href="/purchase-report/create1" style={{marginTop:20, backgroundColor:'#595959',color:'white',fontWeight:'bold',marginBottom:10}} >{t('See Report')}</Button>
                    </Row>
                    <Row type="flex" style={{marginTop:20}}>
                        <Title level={4}>{t('Supplier Debt Report')}</Title>
                        <Col span={24}> <Text type="secondary">{t('Displays the amount of value that you owe to each Supplier.')}</Text></Col>
                        <Button href="/purchase-report/create2" style={{marginTop:20, backgroundColor:'#595959',color:'white',fontWeight:'bold',marginBottom:10}} >{t('See Report')}</Button>
                    </Row>
                    <Row type="flex" style={{marginTop:20}}>
                        <Title level={4}>{t('Expense Details')}</Title>
                        <Col span={24}><Text type="secondary">{t('SThis report details expenses, and is classified in their respective categories within the timeframe you specify.ales')}</Text></Col>
                        <Button href="/purchase-report/create3" style={{marginTop:20, backgroundColor:'#595959',color:'white',fontWeight:'bold',marginBottom:10}} >{t('See Report')}</Button>
                    </Row>
                    <Row type="flex" style={{marginTop:20}}> 
                        <Title level={4}>{t('Delivery Report')}</Title>
                        <Col span={24}><Text type="secondary">{t('Displays all products recorded that were sent for purchase transactions in a period, grouped by supplier.')}</Text></Col>
                        <Button href="/purchase-report/create4" style={{marginTop:20, backgroundColor:'#595959',color:'white',fontWeight:'bold',marginBottom:10}} >{t('See Report')}</Button>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row type="flex"> 
                        <Title level={4}>{t('Purchases Per Supplier')}</Title>
                        <Col span={24}><Text  type="secondary">{t('Displays each purchase and the amount for each Supplier.')}</Text></Col>
                        <Button href="/purchase-report/create5" style={{marginTop:20, backgroundColor:'#595959',color:'white',fontWeight:'bold',marginBottom:10}} >{t('See Report')}</Button>
                    </Row>
                    <Row type="flex" style={{marginTop:20}}>
                        <Title level={4}>{t('Expenditures List')}</Title>
                        <Col span={24}><Text type="secondary">{t('A list of all expenses with information for the timeframe specified.')}</Text></Col>
                        <Button href="/purchase-report/create6" style={{marginTop:20, backgroundColor:'#595959',color:'white',fontWeight:'bold',marginBottom:10}} >{t('See Report')}</Button>
                    </Row>
                    <Row type="flex" style={{marginTop:20}}>
                        <Title level={4}>{t('Age of Debt')}</Title>
                        <Col span={24}><Text type="secondary">{t('This report provides a summary of your debt, showing each of your vendors on a monthly basis, as well as the total amount over time. This is practical to help track your debt.')}</Text></Col>
                        <Button href="/purchase-report/create7" style={{marginTop:20, backgroundColor:'#595959',color:'white',fontWeight:'bold',marginBottom:10}} >{t('See Report')}</Button>
                    </Row>
                    <Row type="flex" style={{marginTop:20}}>
                        <Title level={4}>{t('Per Product Purchases')}</Title>
                        <Col span={24}><Text type="secondary">{t('Displays a list of purchase quantities per product, including the number of returns, net purchases, and average purchase price.')}</Text></Col>
                        <Button href="/purchase-report/create8" style={{marginTop:20, backgroundColor:'#595959',color:'white',fontWeight:'bold',marginBottom:10}} >{t('See Report')}</Button>
                    </Row>
                </Col>
            </PageHeader>
        </Layout.Content>
    )
  }
}
export default withTranslation()(Report)