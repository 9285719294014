import React from 'react'
import {Row, Col, Breadcrumb, Layout, PageHeader, Divider, Button, Icon, DatePicker, Select, Input, Table} from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next';

class ReturnTrans extends React.Component{
    render(){
        const { RangePicker } = DatePicker;
        const Option = Select;
        const { t } = this.props;
        const columns = [
            { 
              title: `${t("Date")}`,
              dataIndex: 'tanggal',
              key: 'tanggal',
            },
            {
              title: `${t("Number")}`,
              dataIndex: 'nomor',
              key: 'nomor',
            },
            {
              title: 'Customer',
              dataIndex: 'customer',
              key: 'customer',
            },
            {
              title: `${t("Due")}`,
              dataIndex: 'tjt',
              key: 'tjt',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
              },
              {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
              }
          ];

        function onChange(dates, dateStrings) {
            console.log('From: ', dates[0], ', to: ', dates[1]);
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
          }
        return(
            <Layout.Content>
                <Row style={{marginLeft:10, marginTop:20}} type="flex" justify="space-around">
                    <Col span={12}><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}>{t("Sales Return")}</h1></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end" style={{fontWeight:'bold'}}>
                                <Breadcrumb>
                                    <Breadcrumb.Item><a href="">{t("Selling")}</a></Breadcrumb.Item>
                                    <Breadcrumb.Item><a href="">{t("Sales Return")}</a></Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                </Row>
                <Divider type="horizontal"></Divider>
                <Row type="flex" justify="space-between">
                    <Col><Button href="/sales-return/create" style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold'}}><Icon type="plus" />{t("Add Sales Return")}</Button></Col>
                    <Col style={{marginLeft:290}}>
                    <div style={{width:200}}>
                        <RangePicker
                        ranges={{
                            Today: [moment(), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        onChange={onChange}
                        />
                    </div>
                    </Col>
                    <Col style={{marginLeft:10}}>
                        <Select placeholder={t("Stats")} style={{width:175}}>
                            <Option value="Open">Open</Option>
                            <Option value="Closed">Closed</Option>
                            <Option value="Canceled">Canceled</Option>
                        </Select>
                    </Col>
                    <Col style={{marginLeft:100}}><Button href="" style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold', width:100}}> Filter</Button></Col>
                </Row>
                <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
                    <Row type="flex" justify="space-between"> 
                                <Col>
                                    <Row type="flex">
                                        <Col><h3 style={{marginRight:4, fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>Per</h3></Col>
                                                <Select defaultValue="25" size='small'>
                                                    <Option value="25">25</Option>
                                                    <Option value="50">50</Option>
                                                    <Option value="100">100</Option>
                                                    <Option value="200">200</Option>
                                                    <Option value="All">All</Option>
                                                </Select>
                                        <Col><h3 style={{marginLeft:4, fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>{t("Line")}</h3></Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row type="flex">
                                        <Col style={{marginRight:5, }}><h3 style={{ fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>{t("Search")}</h3></Col>
                                        <Col><Input size='small'></Input></Col>
                                    </Row>
                                </Col>
                    </Row>
                    <div style={{marginTop:20, border: '1px solid #ebedf0'}}>
                        <Table columns={columns}></Table>
                    </div>
                </PageHeader>
                <Row type="flex" justify="end">
                    <Col><Button type="disabled" disabled style={{width:70}}>{t("Early")}</Button></Col>
                    <Col><Button type="disabled" disabled style={{width:80}}>{t("Back off")}</Button></Col>
                    <Col><Button type="disabled" disabled style={{width:70}}>{t("Onward")}</Button></Col>
                    <Col><Button type="disabled" disabled style={{width:70}}>{t("End")}</Button></Col>
                </Row>
            </Layout.Content>
        )
    }
}

export default withTranslation() (ReturnTrans)