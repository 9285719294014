import { combineReducers } from "redux"
import customerReducer from "./customerReducer"
import loginReducer from "./loginReducer"
import menuReducer from "./menuReducer"
import languageReducer from "./languageReducer"
import validateRegisterReducer from "./validateRegisterReducer"

const rootReducer = combineReducers({
    customer: customerReducer,
    login: loginReducer,
    menu: menuReducer,
    lang: languageReducer,
    validate: validateRegisterReducer
})

export default rootReducer