import React from 'react'
import { Component } from 'react';
import {Typography, Row, Col, Breadcrumb, Button, Layout, Icon, DatePicker, Select, Input, Table, Divider, Pagination} from 'antd';
import moment from 'moment'
import { withTranslation } from 'react-i18next';

class StockOpname extends Component{
    render(){
        const {Title} = Typography
        const dateFormat = 'DD/MM/YYYY'
        const Option = Select
        const {t} = this.props
        const columns = [
            {
              title: `${t('Date')}`,
              dataIndex: 'Tanggal',
              key: 'Tanggal',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('Number')}`,
              dataIndex: 'Nomor',
              key: 'Nomor',
            },
            {
              title: `${t('Warehouse Name')}`,
              dataIndex: 'Nama_Gudang',
              key: 'Nama_Gudang',
            },
            {
                title: `${t('Information')}`,
                dataIndex: 'Keterangan',
                key: 'Keterangan',
            },
          ];
          
        
          
        return(
            <Layout.Content>
                <div style={{margin:10,padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Stock Opname')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Inventory')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/Stock">{t('Stock Opname')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider  type="horizontal" style={{marginTop:-10}} />
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20}} >
                    <Row type="flex" justify="space-between">
                        <Col>
                            <Row type="flex" justify="start">
                                <Button href="/Stock/Create" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}><Icon type="plus" />{t('Create Adjustment')} </Button>
                            </Row>
                        </Col>
                        
                        <Col>
                            <div>
                                <DatePicker style={{marginRight:15}} defaultValue={moment('01/02/2020', dateFormat)} format={dateFormat} />
                                <DatePicker defaultValue={moment('29/02/2020', dateFormat)} format={dateFormat} />
                            </div>
                        
                        </Col>
                        <Col><Button style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Filter')}</Button></Col>
                    </Row>
                </div>
                
                <div style={{margin:10,borderWidth:5,borderColor:'red',padding:20}}>
                    <Row type="flex" justify="space-between"> 
                        <Col>
                            <Row type="flex">
                                <Col><h3>Per</h3></Col>
                                <Col><Select defaultValue="50">
                                        <Option value="50">50</Option>
                                        <Option value="100">100</Option>
                                        <Option value="200">200</Option>
                                        <Option value="All">All</Option>
                                    </Select>
                                </Col>
                                <Col><h3>{t('Line')}</h3></Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row type="flex">
                                <Col style={{marginRight:5}}><h3>{t('Search')}</h3></Col>
                                <Col><Input></Input></Col>
                            </Row>
                        </Col>
                    </Row>
                    <div style={{marginTop:20}}>
                        <Table columns={columns} />
                        <Pagination 
                            total={85}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                            pageSize={1}
                            defaultCurrent={1}
                            />
                        
                    </div>
                </div>
                
            </Layout.Content>
        )
    }
}
export default withTranslation()(StockOpname) 
