import React from 'react'
import {Row, Col, Breadcrumb, Layout, PageHeader, Button, Icon, DatePicker, Select, Input, Table, Divider} from 'antd'
import { withTranslation } from 'react-i18next';

class Booking extends React.Component{
    state = {
        startValue: null,
        endValue: null,
        endOpen: false,
      };
    
      disabledStartDate = startValue => {
        const { endValue } = this.state;
        if (!startValue || !endValue) {
          return false;
        }
        return startValue.valueOf() > endValue.valueOf();
      };
    
      disabledEndDate = endValue => {
        const { startValue } = this.state;
        if (!endValue || !startValue) {
          return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
      };
    
      onChange = (field, value) => {
        this.setState({
          [field]: value,
        });
      };
    
      onStartChange = value => {
        this.onChange('startValue', value);
      };
    
      onEndChange = value => {
        this.onChange('endValue', value);
      };
    
      handleStartOpenChange = open => {
        if (!open) {
          this.setState({ endOpen: true });
        }
      };
    
      handleEndOpenChange = open => {
        this.setState({ endOpen: open });
      };


    render(){
        const { startValue, endValue, endOpen } = this.state;
        const Option = Select;
        const { t } = this.props;
        const columns = [
            { 
              title: `${t('Date')}`,
              dataIndex: 'tanggal',
              key: 'tanggal',
            },
            {
              title: `${t('Number')}`,
              dataIndex: 'nomor',
              key: 'nomor',
            },
            {
              title: 'Supplier',
              dataIndex: 'supplier',
              key: 'supplier',
            },
            {
              title: `${t('Due')}`,
              dataIndex: 'tjt',
              key: 'tjt',
            },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
            },
            {
              title: 'Total',
              dataIndex: 'total',
              key: 'total',
            },
          ];

        return (
            <Layout.Content>
                <Row style={{marginLeft:10, marginTop:20}} type="flex" justify="space-around">
                    <Col span={12}><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}> {t('Order')} </h1></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end" style={{fontWeight:'bold', marginRight:11}}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Buying')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>{t('Order')}</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                </Row>
                <Divider type="horizontal"></Divider>
                    <Row type="flex" justify="space-between">
                        <Col><Button href="/purchase-order/create" style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold'}}><Icon type="plus" /> {t('Add Order')}</Button></Col>
                        <Col style={{marginLeft:100}}>
                            <DatePicker
                            disabledDate={this.disabledStartDate}
                            showTime
                            format="YYYY-MM-DD HH:mm:ss"
                            value={startValue}
                            placeholder="Start"
                            onChange={this.onStartChange}
                            onOpenChange={this.handleStartOpenChange}
                            />
                        </Col>
                        <Col style={{marginLeft:10}}>
                            <DatePicker
                            disabledDate={this.disabledEndDate}
                            showTime
                            format="YYYY-MM-DD HH:mm:ss"
                            value={endValue}
                            placeholder="End"
                            onChange={this.onEndChange}
                            open={endOpen}
                            onOpenChange={this.handleEndOpenChange}
                            />
                        </Col>
                        <Col style={{marginLeft:10}}>
                            <Select placeholder={t('Stats')} style={{width:175}}>
                                <Option value="Open">Open</Option>
                                <Option value="Closed">Closed</Option>
                                <Option value="Canceled">Canceled</Option>
                            </Select>
                        </Col>
                        <Col style={{marginLeft:100}}><Button href="" style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold', width:100}}> Filter</Button></Col>
                    </Row>
                    <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
                        <Row type="flex" justify="space-between"> 
                                    <Col>
                                        <Row type="flex">
                                            <Col><h3 style={{marginRight:4, fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>Per</h3></Col>
                                                    <Select defaultValue="25" size='small'>
                                                        <Option value="25">25</Option>
                                                        <Option value="50">50</Option>
                                                        <Option value="100">100</Option>
                                                        <Option value="200">200</Option>
                                                        <Option value="All">All</Option>
                                                    </Select>
                                            <Col><h3 style={{marginLeft:4, fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>{t('Line')}</h3></Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row type="flex">
                                            <Col style={{marginRight:5, }}><h3 style={{ fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>{t('Search')}</h3></Col>
                                            <Col><Input size='small'></Input></Col>
                                        </Row>
                                    </Col>
                        </Row>
                        <div style={{marginTop:20, border: '1px solid #ebedf0'}}>
                            <Table columns={columns}></Table>
                        </div>
                    </PageHeader>
              </Layout.Content>
        )
    }
}

export default withTranslation() (Booking)