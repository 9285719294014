import React, { Component } from 'react'
import { Result, Button } from 'antd'
import { Link } from 'react-router-dom'

export class Error404 extends Component {
    render() {
        const { t } = this.props
        console.log(this.props)
        return (
            <Result
                status="404"
                title="404"
                subTitle={t('Sorry, the page you visited does not exist.')}
                extra={<Link to="/"><Button type="primary">{t('Go to app')}</Button></Link>}
            />
        )
    }
}

export default Error404
