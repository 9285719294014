import React, { Component } from 'react'
import { Row, Typography, Col, Breadcrumb, Layout, Divider, Input, Select, DatePicker, PageHeader, Button, Icon, Table } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next';

class AddProject extends Component{
    render(){
        const { t } = this.props;
        const {Title} = Typography
        const Option = Select
        const dateFormat ='DD/MM/YYYY'
        const {TextArea} = Input
        return(
            <Layout.Content>
            <PageHeader style={{ border: '1px solid #ebedf0'}}>
                <div>
                    <Row type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Add Project')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Projects & Tasks')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/project">{t('Project')}</a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/project/create">{t('Add Project')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                </PageHeader>
                <PageHeader style={{ border: '1px solid #ebedf0'}}>
                    <Divider type="horizontal" style={{margin:-10}}/>
                    <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                        <Row>
                            <Col span={12}>
                                
                                <Row type="flex">
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Project Name')}</label></Col>
                                    <Col span={15}><Input placeholder={t('Project Name')}/></Col>
                                </Row>
                                <Row type="flex" style={{marginTop:20}}>
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Customer')}</label></Col>
                                    <Col span={15} >  <Select placeholder={t('Select')} style={{width:'100%'}}>
                                            <Option value=""><Input></Input></Option>
                                            <Option value="hwi">PT. HOWA INDONESIA</Option>
                                            <Option value="scs">PT. SUGITY CREATIVES</Option>
                                            <Option value="aaa">PT. APM ARMADA AUTOPARTS</Option>
                                            <Option value="dwa">PT. DASA WINDU AGUNG</Option>
                                            <Option value="ipi">PT. INOAC POLYTECHNO INDONESIA</Option>
                                            <Option value="mai">PT. MEIWA INDONESIA</Option>
                                            <Option value="iml">PT. INTIPOLY METAL</Option>
                                            <Option value="tbi">PT. TOYOTA BOSHOKU INDONESIA</Option>
                                            <Option value="adm">ASTRA DAIHATSU MOTOR </Option>
                                            <Option value="kici">PT. KOTOBUKIYA INDO CLASSIC INDUSTRIES</Option>
                                            <Option value="uti">PT. USRA TAMPI</Option>
                                            <Option value="eati">PT. ECHO ADVANCED TECHNOLOGY INDONESIA</Option>
                                            <Option value="btm">PT. BONECOM TRICOM</Option>
                                            <Option value="step">PT. SARI TAKAGI ELOK PRODUK</Option>
                                            <Option value="sim">PT. SUZUKI INDOMOBIL MOTOR</Option>
                                            <Option value="hmmi">PT. HINO MOTORS MANUFACTURING INDONESIA</Option>
                                            <Option value="mei">PT. METINDO ERASAKTI</Option>
                                            <Option value="via">PT. VUTEQ NDONESIA</Option>
                                            <Option value="iii">PT. IRC INOAC INDONESIA</Option>
                                            <Option value="nms">PT. NITTO MATERIALS</Option>
                                            <Option value="tna">PT. TUFFINDO NITTOKU AUTONEUM</Option>
                                            <Option value="jvc">PT. JAYA VICTORY CEMERLANG</Option>
                                            <Option value="nki">PT. NUSA KEIHIN INDONESIA</Option>
                                            <Option value="map">PT. MUTIHA ABADI PERIZKY</Option>
                                            <Option value="arm">PT. ARMSTRONG</Option>
                                            <Option value="mbpd">PT. MAJU BERSAMA PERSADA DAYAMU</Option>
                                            <Option value="kbb">PT. KARYA BAHAN BERLIAN</Option>
                                            <Option value="sgi">PT. SANKO GOSEI</Option>
                                            <Option value="sgp">PT. SURYA GEMILANG PERKASA</Option>
                                        </Select>
                                    </Col>     
                                </Row>
                                <Row type="flex" style={{marginTop:20}}> 
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Project owner')}</label></Col>
                                    <Col span={15} >  <Select placeholder={t('Select')} style={{width:'100%'}}>
                                            <Option value=""><Input></Input></Option>
                                            <Option value="eri">Eriza Muhammad</Option>
                                        </Select>
                                    </Col>    
                                </Row>
                                <Row type="flex" style={{marginTop:20}}> 
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Start')}</label></Col>
                                    <Col span={15}> <DatePicker defaultValue={moment('18/02/2020', dateFormat)} format={dateFormat} /></Col>
                                </Row>
                                <Row type="flex" style={{marginTop:20}}>
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('End')}</label></Col>
                                    <Col span={15}> <DatePicker defaultValue={moment('19/03/2020', dateFormat)} format={dateFormat} /></Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row type="flex">
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('DEscription')}</label></Col>
                                    <Col span={15}><TextArea rows={4} placeholder="Deskripsi Project" style={{width:'100%'}} /></Col> 
                                </Row>
                                <Row type="flex" style={{marginTop:20}}>
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Project Requirements')}</label></Col>
                                    <Col span={15} >  <Select placeholder={t('Select')} style={{width:'100%'}}>
                                            <Option value=""><Input></Input></Option>
                                            <Option value="onp">On Plan</Option>
                                            <Option value="ini">Initiation</Option>
                                            <Option value="mai">Main Development</Option>
                                            <Option value="onh">On Hold</Option>
                                            <Option value="ove">Overtime</Option>
                                            <Option value="clo">Closing</Option>
                                            <Option value="gua">Guarantee</Option>
                                            <Option value="com">Completed</Option>
                                            <Option value="ter">Terminated</Option>
                                        </Select>
                                    </Col>     
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </PageHeader>
                
                <Row type="flex" style={{marginBottom:30}}>
                    <Col style={{marginTop:15,marginLeft:43,marginRight:20}}><Button href="/project" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                    <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Add Project')}</Button></Col>
                </Row> 

            </Layout.Content> 
        )
    }
}
export default withTranslation()(AddProject)