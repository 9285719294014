import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Layout, Row, Col, Breadcrumb, Divider, Typography, Select, DatePicker, Button, Card, List } from 'antd'
import moment from 'moment'

class BalanceSheet extends Component{
    render(){
        const {t} = this.props
        const {Title} = Typography
        const {Option} = Select
        const dateFormat = 'DD/MM/YYYY'
        return(
            <Layout.Content>
                <div style={{paddingTop:20,paddingBottom:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Balance Sheet')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Accounting')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/course">{t('Balance Sheet')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider type="horizontal" style={{marginTop:-10}}/>
                <Row type="flex" justify="space-between">
                    <Col span={12}>
                        <Row type="flex">
                            <Col style={{fontSize:14,marginRight:10,marginTop:5}}>{t('Comparison')}</Col>
                            <Col span={6} style={{marginRight:10}}>
                                <Select style={{width:'100%'}} defaultValue="tidak ada">
                                    <Option value="tidak ada">{t('Nothing')}</Option>
                                    <Option value="Mingguan">{t('Weekly')}</Option>
                                    <Option value="Bulanan">{t('Monthly')}</Option>
                                    <Option value="Tahunan">{t('Annual')}</Option>
                                </Select>
                            </Col>
                            <Col span={6}>
                                <Select style={{width:'100%'}} defaultValue="tidak ada">
                                    <Option value="tidak ada">{t('Nothing')}</Option>
                                    <Option value="1 periode">{t('1 Period')}</Option>
                                    <Option value="2 periode">{t('2 Period')}</Option>
                                    <Option value="3 periode">{t('3 Period')}</Option>
                                    <Option value="4 periode">{t('4 Period')}</Option>
                                    <Option value="5 periode">{t('5 Period')}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row type="flex">
                            <Col style={{fontSize:14,marginRight:10,marginTop:5}}>{t('By Date')}</Col>
                            <Col style={{marginRight:10}}><DatePicker format={dateFormat} defaultValue={moment('02/03/2020', dateFormat)} /></Col>
                            <Col><Button style={{backgroundColor:'#ffc53d',color:'white'}}>{t('Filter')}</Button></Col>
                        </Row>
                    </Col>
                </Row>
                <Divider type="horizontal"/>
                <Row type="flex" justify="space-around">
                    <Col span={24} style={{fontSize:20,textAlign:'center'}}>PT. REKADAYA MULTI ADIPRIMA</Col>
                    <Col span={24} style={{fontSize:24,textAlign:'center',fontWeight:'bold'}}>{t('Balance Sheet')}</Col>
                    <Col span={24} style={{textAlign:'center'}} >Per tanggal 02 March 2020</Col>
                </Row>
                <Card style={{borderWidth:2,borderRadius:10,marginTop:10,marginBottom:20}}>
                    <List
                        header={ 
                            <Row type="flex" justify="space-between" style={{backgroundColor:'#ffc53d',padding:10,color:'white'}}>
                                <Col>Activa</Col>
                                <Col>2020-03-02</Col>
                            </Row>
                        }
                        footer={
                            <Row type="flex"  style={{padding:10}}>
                                <Col span={18} style={{textAlign:'right'}}>Total Aktiva</Col>
                                <Col span={6} style={{textAlign:'right'}}>0,00</Col>
                            </Row>
                        }
                    />
                    <List
                        header={ 
                            <Row type="flex"  style={{backgroundColor:'#ffc53d',padding:10,color:'white'}}>
                                <Col>{t('Capital and Liabilities')}</Col>
                            </Row>
                        }
                    />
                </Card>
                
            </Layout.Content>
        )
    }
}
export default withTranslation()(BalanceSheet)