import Axios from "axios"
import { url } from "../../config/global"

const fetchCustomer = (filter) => {
    return (dispatch, getState) => {
        const {
            keyword,
            perpage,
            page,
            sort,
            order
        } = filter

        dispatch({
            type: 'FETCH_CUSTOMER_PENDING'
        })

        Axios.get(`${url}/customer`,{
            params: {
                keyword,
                perpage,
                page,
                sort,
                order
            },
            headers: {
                Authorization:  `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dispatch({
                type: 'FETCH_CUSTOMER_SUCCESS',
                data: res.data.data,
                total: res.data.total

            })
        }).catch(err => {
            
            if (err.response.status === 401) {
                sessionStorage.removeItem('token')
            }

            dispatch({
                type: 'FETCH_CUSTOMER_FAILED',
                errors: err.response.data,
            })
        })
    }
}

const deleteCustomer = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'DELETE_CUSTOMER_PENDING',
        })

        Axios.delete(`${url}/customer/${id}`, {
            headers: {
                Authorization:  `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dispatch({
                type: 'DELETE_CUSTOMER_SUCCESS',
                message: res.data.message,
                deleted: true
            })
        }).catch(err => {
            dispatch({
                type: 'DELETE_CUSTOMER_FAILED',
                errors: err.response.data
            })
        })
    }
}

const saveCustomer = (data) => {
    return (dispatch, getState) => {
        const {
            code,
            name,
            city,
            province,
            address,
            address_billing,
            zip_code,
            country,
            phone_1,
            phone_2,
            phone_3,
            npwp_number,
            npwp_address,
            account_receivable_id,
            account_payable_id,
            contact_person
        } = data

        dispatch({
            type: 'SAVE_CUSTOMER_PENDING'
        })

        Axios.post(`${url}/customer`, {
            code,
            name,
            city,
            province,
            address,
            address_billing,
            zip_code,
            country,
            phone_1,
            phone_2,
            phone_3,
            npwp_number,
            npwp_address,
            account_receivable_id,
            account_payable_id,
            contact_person
        }, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dispatch({
                type: 'SAVE_CUSTOMER_SUCCESS',
                message: res.data.message,
                saved: true
            })
        }).catch(err => {
            dispatch({
                type: 'SAVE_CUSTOMER_FAILED',
                errors: err.response.data,
            })
        })

    } 
}

export { fetchCustomer, deleteCustomer, saveCustomer }