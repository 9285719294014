import React, { Component } from 'react'
import { Layout, Row, Col, Breadcrumb, Divider, Typography, Button, Icon, Calendar, Card, Select, Table, Input, Pagination } from 'antd'
import { withTranslation } from 'react-i18next'

class DashboardTraining extends Component{
    
    render(){
        const {Title} = Typography
        const {t} = this.props
        const {Option} = Select
        function onPanelChange(value, mode) {
            console.log(value.format('DD-MM-YYYY'), mode);
        }
        return(
            <Layout.Content>
                <div style={{padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Dashboard Training')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Dashboard')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/course">{t('Dashboard Training')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider type="horizontal" style={{marginTop:-10}} />
                <Button href="/event/create" style={{backgroundColor:'#ffc53d',color:'white',height:40}}><Icon type="plus"/>{t('Add Event Training')}</Button>
                <Divider type="horizontal"/>
                <Row type="flex">
                    <Col>{t('Information')}</Col>
                    <Col><Button style={{backgroundColor:'#ffc53d',color:'white',marginLeft:10 ,fontSize:12,marginTop:-5,height:20,width:40,padding:0}}>{t('Open')}</Button></Col>
                    <Col><Button style={{backgroundColor:'#bae637',color:'white',marginLeft:5 ,fontSize:12,marginTop:-5,height:20,width:40,padding:0}}>{t('Done')}</Button></Col>
                    <Col><Button style={{backgroundColor:'#ff7a45',color:'white',marginLeft:5 ,fontSize:12,marginTop:-5,height:20,width:50,padding:0}}>{t('Cancel')}</Button></Col>
                </Row>
                <Calendar onPanelChange={onPanelChange} />
                <Card style={{borderRadius:10,borderWidth:2}}>
                    <p style={{fontWeight:'bold'}}>{t('Evaluation Data')}</p>
                    <Row type="flex" justify="space-between">
                        <Col type="flex" span={12}>
                            <Row type="flex">
                                <Col type="flex"><h3 style={{marginRight:5,marginTop:5}}>Per</h3></Col>
                                <Col type="flex" span={4}>
                                    <Select defaultValue="25" style={{width:'100%'}}>
                                        <Option value="25">25</Option>
                                        <Option value="50">50</Option>
                                        <Option value="100">100</Option>
                                        <Option value="200">200</Option>
                                        <Option value="All">{t('All')}</Option>
                                    </Select>
                                </Col>
                                <Col type="flex"><h3 style={{marginTop:5,marginLeft:5}}>{t('Line')}</h3></Col>
                            </Row>
                        </Col>
                        <Col type="flex">
                            <Row type="flex">
                                <Col><h3 style={{marginRight:5,marginTop:5}}>{t('Search')}</h3></Col>
                                <Col><Input  style={{width:200}} /></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Table style={{marginTop:10}} columns={[
                        {
                            title:`${t('Training Name')}`
                        },
                        {
                            title:`${t('Course')}`
                        },
                        {
                            title:`${t('Training Date')}`
                        },
                        {
                            title:`${t('Date of Repeat Training')}`
                        },
                        {
                            title:`${t('Score')}`
                        },
                        {
                            title:`${t('Action')}`
                        },
                    ]}/>
                    <div style={{marginTop:20}}>
                        <Pagination 
                            total={85}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                            pageSize={1}
                            defaultCurrent={1}
                            />
                    
                    </div>
                </Card>
            </Layout.Content>
        )
    }
}
export default withTranslation()(DashboardTraining) 