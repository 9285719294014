import React, { Component } from 'react'
import { Layout, Typography, Row, Col, Breadcrumb, Divider, Card, DatePicker, Input, Checkbox, List, Table, Select, Button } from 'antd'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

class JournalCreate extends Component{
    render(){
        const {t} = this.props
        const {Title} = Typography
        const dateFormat = 'DD/MM/YYYY'
        const {TextArea} = Input
        const {Option} = Select 
        return(
            <Layout.Content>
                <div style={{padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Create Journal')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Accounting')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>{t('General Journal')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/course">{t('Create Journal')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider type="horizontal" style={{marginTop:-10}} />
                <Card style={{borderRadius:10,borderWidth:2}}>
                    <Row type="flex" justify="space-between">
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={8}>{t('By Date')}</Col>
                                <Col span={15}><DatePicker style={{width:'100%'}} defaultValue={moment('02/03/2020', dateFormat)} format={dateFormat} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}>{t('Reference Number')}</Col>
                                <Col span={15}><Input/></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}>{t('Project')}</Col>
                                <Col span={15}><Input/></Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row type="flex" >
                                <Col span={8}>{t('Memo')}</Col>
                                <Col span={15}><TextArea rows={4} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}></Col>
                                <Col span={15}><Checkbox>{t('Show Estimates of Previous Transactions')}</Checkbox></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Table
                        style={{marginTop:15}}
                        columns={[
                            {title :`${t('Account Name')}`},
                            {title :`${t('Description')}`},
                            {title :`${t('Debit')}`},
                            {title :`${t('Credit')}`},
                        ]}
                    />
                    <Row type="flex" justify="space-between" style={{marginTop:20}}>
                        <Col span={4}>
                            <Select style={{width:'100%'}} defaultValue="add line" allowClear>
                                <Option  value="add line">{t('Add Line')}</Option>
                                <Option value="add line 2">{t('Add Line')} (2x)</Option>
                                <Option value="add line 5">{t('Add Line')} (5x)</Option>
                                <Option value="add line 10">{t('Add Line')} (10x)</Option>
                            </Select>
                        </Col>
                        <Col style={{fontSize:18}}>
                            <Row type="flex">
                                <Col style={{marginRight:10}}>{t('Difference')} :</Col>
                                <Col style={{color:'red',fontWeight:'bold'}}>0.00</Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
                <Row type="flex" style={{marginTop:15,marginBottom:10}}>
                    <Button style={{marginRight:5,backgroundColor:'#ffc53d',color:'white'}}>{t('Back')}</Button>
                    <Button style={{marginRight:5,backgroundColor:'#1890ff',color:'white'}}>{t('Save Journal')}</Button>
                    <Button style={{marginRight:5,backgroundColor:'#1890ff',color:'white'}}>{t('Save & Input Again')}</Button>
                </Row>
            </Layout.Content>
        )
    }
}
export default withTranslation()(JournalCreate)
