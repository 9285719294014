import React, { Component } from 'react'
import { Layout, Row, Col, Breadcrumb, Divider, Typography, Select, Input, Table, Pagination, Button, Icon, Card } from 'antd'
import { withTranslation } from 'react-i18next'

class ProjectStatus extends Component{
    render(){
        const {Title} = Typography
        const Option = Select
        const {t} = this.props
        const columns = [
            {
              title: 'ID',
              dataIndex: 'ID',
              key: 'ID',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('Status Name')}`,
              dataIndex: 'Nama_Status',
              key: 'Nama_Status',
            }
          ];
        return(
            <Layout.Content>
                <div style={{margin:10,padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Project Status')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Project Management')} </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/productionlist">{t('Project Status')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>
                    <Button href="/projectstatus/create" style={{fontWeight:'bold',color:'white',backgroundColor:'#13c2c2',marginBottom:10}}><Icon type="plus" />{t('Create Status')}</Button>
                    <Card style={{borderWidth:2,borderRadius:10}}>
                        <Row type="flex" justify="space-between"> 
                            <Col>
                                <Row type="flex">
                                    <Col><h3>Per</h3></Col>
                                    <Col><Select style={{width:100}} defaultValue="10">
                                            <Option value="10">10</Option>
                                            <Option value="25">25</Option>
                                            <Option value="50">50</Option>
                                            <Option value="100">100</Option>
                                            <Option value="200">200</Option>
                                            <Option value="All">All</Option>
                                        </Select>
                                    </Col>
                                    <Col><h3>{t('Line')}</h3></Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row type="flex">
                                    <Col style={{marginRight:5}}><h3>{t('Search')}</h3></Col>
                                    <Col><Input/></Col>
                                </Row>
                            </Col>
                        </Row>
                        <div style={{marginTop:10}}>
                            <Table columns={columns} />
                            <Pagination 
                                total={85}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                pageSize={1}
                                defaultCurrent={1}
                                />
                            
                        </div>
                    </Card>
                    
                </div>
            </Layout.Content>
        )
    }
}
export default withTranslation()(ProjectStatus) 