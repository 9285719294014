import React from 'react'
import {Row, Col, Breadcrumb, Layout, PageHeader, Button, Icon, Select, Input, Table, Divider} from 'antd'
import { withTranslation } from 'react-i18next';

class User extends React.Component{
    render(){
        const Option = Select
        const { t } = this.props;
        const columns = [
            { 
              title: 'Email',
              dataIndex: 'email',
              key: 'email',
            },
            {
              title: `${t("Employees Who Use")}`,
              dataIndex: 'peg',
              key: 'peg',
            },
            {
              title: `Group ${t("User")}`,
              dataIndex: 'group',
              key: 'group',
            },
            {
              title: `${t("Active Status")}`,
              dataIndex: 'aktif',
              key: 'aktif',
            },
            {
              title: `${t("Last Login")}`,
              dataIndex: 'last',
              key: 'last',
            },
            {
              title: `${t("Email Confirmation")}`,
              dataIndex: 'confirm',
              key: 'confirm',
            }
        ]
        return(
            <Layout.Content>
                    <Row type="flex" justify="space-around" style={{marginTop:20, marginLeft:10}}>
                        <Col span={12}><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}>{t("User")}</h1></Col>
                            <Col span={12} >
                                <Row type="flex" justify="end" style={{fontWeight:'bold'}}>
                                    <Breadcrumb>
                                        <Breadcrumb.Item>{t("Setting")}</Breadcrumb.Item>
                                        <Breadcrumb.Item>{t("User")}</Breadcrumb.Item>
                                    </Breadcrumb>
                                </Row>
                            </Col>
                    </Row>
            <Divider></Divider>
                <Row type="flex" justify="space-between">
                    <Col><Button href="/user/create" style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold'}}><Icon type="plus" />{t("Add User")}</Button></Col>
                </Row>
                <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
                    <Row type="flex" justify="space-between"> 
                                <Col>
                                    <Row type="flex">
                                        <Col><h3 style={{marginRight:4, fontWeight:'bold', color:'#8c8c8c', fontSize:14, marginTop:5}}>Per</h3></Col>
                                            <Col style={{fontWeight:'bold'}}>
                                                <Select defaultValue="10" style={{width:80}}>
                                                    <Option value="10">10</Option>
                                                    <Option value="25">25</Option>
                                                    <Option value="50">50</Option>
                                                    <Option value="100">100</Option>
                                                    <Option value="200">200</Option>
                                                    <Option value="All">All</Option>
                                                </Select>
                                            </Col>
                                        <Col><h3 style={{marginLeft:4, fontWeight:'bold', color:'#8c8c8c', fontSize:14, marginTop:5}}>{t("Line")}</h3></Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row type="flex">
                                        <Col style={{marginRight:5, }}><h3 style={{ fontWeight:'bold', color:'#8c8c8c', fontSize:14, marginTop:5}}>{t("Search")}</h3></Col>
                                        <Col><Input></Input></Col>
                                    </Row>
                                </Col>
                    </Row>
                    <div style={{marginTop:20, border: '1px solid #ebedf0'}}>
                        <Table columns={columns}></Table>
                    </div>
                </PageHeader>
            </Layout.Content>
        )
    }
}

export default withTranslation() (User)