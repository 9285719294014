import React, { Component } from 'react'
import { Layout, Row, Col, Breadcrumb, Divider, Typography, Select, Input, Table, Pagination } from 'antd'
import { withTranslation } from 'react-i18next'

class ProductionList extends Component{
    render(){
        const {Title} = Typography
        const Option = Select
        const {t}= this.props
        const columns = [
            {
              title: `${t('Code')}`,
              dataIndex: 'Kode',
              key: 'Kode',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('Name')}`,
              dataIndex: 'Nama',
              key: 'Nama',
            },
            {
              title: `${t('Category')}`,
              dataIndex: 'Kategori',
              key: 'Kategori',
            },
            {
                title: `${t('Update Date')}`,
                dataIndex: 'Tanggal_Update',
                key: 'Tanggal_Update',
            },
            {
                title: `${t('Stock')}`,
                dataIndex: 'Stok',
                key: 'Stok',
            },
          ];
        return(
            <Layout.Content>
                <div style={{margin:10,padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Production Inventory')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Production System')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/productionlist">{t('Production Inventory')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>
                    <Row type="flex" justify="space-between"> 
                        <Col>
                            <Row type="flex">
                                <Col><h3>Per</h3></Col>
                                <Col><Select defaultValue="10">
                                        <Option value="10">10</Option>
                                        <Option value="25">25</Option>
                                        <Option value="50">50</Option>
                                        <Option value="100">100</Option>
                                        <Option value="200">200</Option>
                                        <Option value="All">All</Option>
                                    </Select>
                                </Col>
                                <Col><h3>{t('Line')}</h3></Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row type="flex">
                                <Col style={{marginRight:5}}><h3>{t('Search')}</h3></Col>
                                <Col><Input/></Col>
                            </Row>
                        </Col>
                    </Row>
                    <div style={{marginTop:10}}>
                        <Table columns={columns} />
                        <Pagination 
                            total={85}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                            pageSize={1}
                            defaultCurrent={1}
                            />
                        
                    </div>
                </div>
            </Layout.Content>
        )
    }
}
export default withTranslation()(ProductionList) 