import React, { Component } from 'react'
import { Layout, Row, Col, Breadcrumb, Divider, Typography, Input, Select, Button, Icon, Upload, Card, Avatar, Dropdown, Menu, Checkbox } from 'antd'
import { withTranslation } from 'react-i18next'

class Employee extends Component{
    render(){
        const {Title} = Typography
        const Option = Select
        const {t} = this.props
        const menu = (
            <Menu>
              <Menu.Item>
                <a target="_blank" href="/employee/show" >
                 {t('Show')}
                </a>
              </Menu.Item>
              <Menu.Item>
                <a target="_blank" href="/employee/edit">
                {t('Edit')}
                </a>
              </Menu.Item>
            </Menu>
          );
        return(
            <Layout.Content>
                <div style={{margin:10,padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Employee Data')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>Personalia</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/employee">{t('Employee Data')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider  type="horizontal" style={{marginTop:-10}} />
                <div style={{marginLeft:10,paddingLeft:20,paddingRight:20,marginRight:10}}>
                    <Row type="flex" justify="space-between">
                        <Col span={22}>
                            <Row type="flex">
                                <Col span={5} style={{marginRight:10}}><Input  placeholder={t('All Employee Name')} /></Col>
                                <Col span={5} style={{marginRight:10}}><Select style={{width:'100%'}} mode="multiple" placeholder={t('All Position')}>
                                        <Option value="CEO">CEO</Option>
                                    </Select>
                                </Col>
                                <Col span={4} style={{marginRight:10}}><Select style={{width:'100%'}} mode="multiple" placeholder={t('All Group')}>
                                        <Option value="BOD">BOD</Option>
                                    </Select>
                                </Col>
                                <Col span={3}><Select style={{width:'100%'}} mode="multiple" placeholder={t('All Status')}>
                                        <Option value="Kontrak">Kontrak</Option>
                                        <Option value="Permanen">Permanen</Option>
                                        <Option value="Percobaan">Percobaan</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                        <Col><Button  style={{backgroundColor:'#595959',fontWeight:'bold',color:'white'}} >{t('Filter')}</Button></Col>
                    </Row>
                    <Row type="flex" justify="space-between" style={{marginTop:20}}>
                        <Col><Button href="/employee/create" style={{backgroundColor:'#595959',fontWeight:'bold',color:'white'}} >{t('Create New Employee')}</Button></Col>
                        <Col>
                            <Row type="flex">
                                <Col><Button href="/employee" style={{backgroundColor:'#595959',fontWeight:'bold',color:'white'}} ><Icon type="reload"/>{t('Refresh')}</Button></Col>
                                <Col style={{marginLeft:10}}>
                                    <Upload>
                                        <Button style={{backgroundColor:'#595959',fontWeight:'bold',color:'white'}}><Icon type="upload" />{t('Upload')}</Button>
                                    </Upload>
                                </Col>
                                <Col style={{marginLeft:10}}><Button style={{backgroundColor:'#595959',fontWeight:'bold',color:'white'}}><Icon type="download" />{t('Download')}</Button></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>
                    <Card style={{borderWidth:2,borderRadius:5,borderColor:'#1890ff',width:250}}>
                        <Row type="flex" justify="end">
                            <Col><Dropdown overlay={menu}>
                                <Icon style={{fontWeight:'bold',fontSize:20}}  type="dash"/>
                            </Dropdown>
                            </Col>
                        </Row>
                        <Row type="flex" justify="space-around">
                            <Col ><Avatar style={{width:150,height:150}} src="https://library.kissclipart.com/20181114/oke/kissclipart-avatar-profile-png-clipart-computer-icons-avatar-ed68f86e7eead50a.jpg" /></Col>
                            <Col style={{fontWeight:'bold',color:'blue',marginTop:10,fontSize:18}}>HIDAYATURRAHMAN</Col>  
                        </Row>
                        <Row type="flex" justify="space-around">
                            <Col style={{fontSize:12,fontWeight:'bold'}}>1 Tahun 6 Bulan</Col>
                        </Row>
                        <Row type="flex" justify="space-around">
                            <Col style={{fontSize:18,fontWeight:'bold'}}>CEO</Col>
                        </Row>
                        <Row type="flex" justify="space-around">
                        <   Col style={{fontSize:18,fontWeight:'bold'}}>BOD</Col>
                        </Row>

                    </Card>
                    <Divider type="horizontal" />
                    <Row type="flex" justify="space-around">
                        <Col><Checkbox style={{fontWeight:'bold'}}>{t('Show Employee Not Active')}</Checkbox></Col>
                    </Row>
                </div>
            </Layout.Content>
        )
    }
}
export default withTranslation()(Employee) 