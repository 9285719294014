import React, { Component } from 'react'
import { Layout, 
         Row, 
         Col, 
         Breadcrumb, 
         Typography, 
         PageHeader, 
         DatePicker,
         Button, 
         Icon, 
         Select, 
         Input,  
         Table, } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next';

class Reimburse extends React.Component {
    render(){
        const {Title, Text} = Typography
        const {Option} = Select
        const dateFormat =' DD/MM/YYYY'
        const { t } = this.props;
        const columns = [
            {
              title: `${t('Date')}`,
              dataIndex: 'Date',
              key: 'Date',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('Amount')}`,
              dataIndex: 'Amount',
              key: 'Amount',
            },
            {
              title: `${t(' Description')}`,
              dataIndex: ' Description',
              key: ' Description',
            },
            {
                title: 'Submitted by',
                dataIndex: 'Submitted by',
                key: 'Submitted by',
            },
            {
                title: 'File',
                dataIndex: 'File',
                key: 'File',
            },
            {
                title: 'Approval',
                dataIndex: 'Approval',
                key: 'Approval',
            },
            
          ];
        return(
            <Layout.Content>
                <PageHeader style={{border: '1px solid rgb(235,237,340)'}}>
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Reimbursement')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Personnel')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/reimburse">{t('Reimbursement')} </a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                </PageHeader>

                <div style={{padding:20}}>
                <Row type='flex'justify='space-between'>
                    <Col>
                        <Button href="/reimburse/create" style={{margin:10, backgroundColor:"#505458", color:"white"}}><Icon type="plus"/>{t('Make a Refund')}</Button>
                    </Col>
                    <Col>
                        <Select style={{margin: 10,fontSize:16}} defaultValue={t('Choose All')}>
                            <Option value=""><Input></Input></Option>
                            <Option value="Pilih Semua">{t('Choose All')}</Option>
                            <Option value="Menunggu">{t('Waiting')}</Option>
                            <Option value="Disetujui">{t('Approved')}</Option>
                            <Option value="Ditolak">{t('Rejected')}</Option>
                        </Select>
                    
                        <DatePicker style={{margin:10, }} placeholder={t('From Date')} defaultValue={moment('1/2/2020',dateFormat)} format={dateFormat} />
                    
                        <DatePicker style={{margin:10 , }} placeholder={t('Until Date')} defaultValue={moment('1/2/2020',dateFormat)} format={dateFormat} />
                    
                        <Button href="" style={{ margin:10, backgroundColor:"#505458", color:"white"}}><Icon type="search"/>Filter</Button>
                    </Col>
                
                </Row>
                </div>

                <Row type="flex" justify="space-between" style={{padding:10, margin:10}}>
                    <Col > 
                        <Row type="flex">
                            <Col><h3>Per</h3></Col>
                            <Select defaultValue="25" >
                                <Option value="25">25</Option>
                                <Option value="50">50</Option>
                                <Option value="100">100</Option>
                                <Option value="200">200</Option>
                                <Option value="All">{t('All')}</Option>
                            </Select>
                            <Col><h3>{t('Line')}</h3></Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row type="flex">
                        <Col span={5} style={{marginRight:10}}><h3>{t('Search')}</h3></Col>
                        <Col span={15}><Input></Input></Col>
                        </Row>
                    </Col>
                </Row>
                <div style={{margin:5, padding:20}}>
                    <Table columns={columns}></Table>
                </div>
            </Layout.Content>
        )
    }

}
export default withTranslation() (Reimburse)