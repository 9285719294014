import React, { Component } from 'react'
import { Layout, Icon, Row, Col, Breadcrumb, Typography, Divider, Button, Card, List } from 'antd'
import { withTranslation } from 'react-i18next'

class AccountList extends Component{
    render(){
        const {Title} = Typography
        const {t} = this.props
        return(
            <Layout.Content>
                <div style={{padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Account List')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Dashboard')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/course">{t('Account List')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider type="horizontal" style={{marginTop:-10}} />
                <Row type="flex" justify="space-between">
                    <Col span={12}>
                        <Row type="flex">
                            <Button href="/journal/create" style={{backgroundColor:'#ffc53d',color:'white',marginRight:5}}><Icon type="plus" />{t('Create General Journal')}</Button>
                            <Button href="/coa/create" style={{backgroundColor:'#1890ff',color:'white'}}><Icon type="plus" />{t('Create New Account')}</Button>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row type="flex" justify="end">
                            <Button style={{backgroundColor:'#ffc53d',color:'white',marginRight:5}}><Icon type="copy" />{t('Book Closing')}</Button>
                            <Button style={{backgroundColor:'#1890ff',color:'white',marginRight:5}}><Icon type="download" />{t('Import')}</Button>
                            <Button style={{backgroundColor:'#1890ff',color:'white'}}><Icon type="upload" />{t('Export')}</Button>
                        </Row>
                    </Col>
                </Row>
                <Row type="flex" justify="space-between" style={{marginTop:15}}>
                    <Col span={11} >
                        <Card style={{borderWidth:2,borderRadius:10}}>
                            <List
                                header={
                                    <p style={{padding:10,backgroundColor:'#ffc53d',fontWeight:'bold'}}>Aktiva/Assets</p>
                                }
                            />
                        </Card>
                        <Card style={{borderWidth:2,borderRadius:10,marginTop:15}}>
                            <List
                                header={
                                    <p style={{padding:10,backgroundColor:'#ffc53d',fontWeight:'bold'}}>{t('Obligation')}</p>
                                }
                            />
                        </Card>
                        <Card style={{borderWidth:2,borderRadius:10,marginTop:15}}>
                            <List
                                header={
                                    <p style={{padding:10,backgroundColor:'#ffc53d',fontWeight:'bold'}}>{t('Equity')}</p>
                                }
                            />
                        </Card>
                    </Col>
                    <Col span={11}>
                        <Card style={{borderWidth:2,borderRadius:10}}>
                            <List
                                header={
                                    <p style={{padding:10,backgroundColor:'#ffc53d',fontWeight:'bold'}}>{t('Income')}</p>
                                }
                            />
                        </Card>
                        <Card style={{borderWidth:2,borderRadius:10,marginTop:15}}>
                            <List
                                header={
                                    <p style={{padding:10,backgroundColor:'#ffc53d',fontWeight:'bold'}}>{t('Less expenses')}</p>
                                }
                            />
                        </Card>
                    </Col>
                </Row>
            </Layout.Content>
        )
    }
}
export default withTranslation()(AccountList) 