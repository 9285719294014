import React, { Component } from 'react';
import {Typography,Layout, Row,Col,Breadcrumb,Button,Icon, PageHeader,Select,Input,Table} from 'antd';
import { withTranslation } from 'react-i18next';

class Project extends Component{
  render(){
    const { t } = this.props;
    const {Title} = Typography
    const {Option} = Select
    const columns = [
      {
        title: `${t('Owner')}`,
        dataIndex: 'Pemilik',
        key: 'Pemilik',
      },
      {
        title: `${t('Project Name')}`,
        dataIndex: 'Nama_Proyek',
        key: 'Nama_Proyek',
      },
      {
        title: 'Progress',
        dataIndex: 'Progress',
        key: 'Progress',
      },
      {
          title: `${t('Customer')}`,
          dataIndex: 'Pelanggan',
          key: 'Pelanggan',
      },
      {
        title: `${t('Estimated time')}`,
        dataIndex: 'Estimasi_Waktu',
        key: 'Estimasi_Waktu',
      },
      {
        title: `${t('Time Used')}`,
        dataIndex: 'Waktu_Yang_Digunakan',
        key: 'Waktu_Yang_Digunakan',
      },
      {
        title: `${t('Total Tasks')}`,
        dataIndex: 'Total_Tugas',
        key: 'Total_Tugas',
      },
      {
        title: `${t('Completed task')}`,
        dataIndex: 'Tugas_Selesai',
        key: 'Tugas_Selesai',
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
      },
    ];
      return(
        <Layout.Content>
          <PageHeader style={{ border: '1px solid #ebedf0'}}>
            <Row style={{margin:1}} type="flex" justify="space-around">
              <Col  span={12}><Title level={4}>{t('List Project')}</Title></Col>
                <Col span={12} >
                  <Row type="flex" justify="end">
                    <Breadcrumb>
                      <Breadcrumb.Item>{t('Project & Tasks')}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <a href="">{t('List Project')}</a>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                  </Row>
                </Col>
            </Row>
          </PageHeader>  
          
          <PageHeader>
            <Row>
              <Col><Button href="/project/create" style={{backgroundColor:'#0d1933',
                  color:'white',fontWeight:'bold'}}><Icon type="plus" />{t('Create Program')}</Button></Col>
            </Row>
          </PageHeader>

          <div style={{margin:10,borderWidth:5,borderColor:'red',padding:20}}>
            <Row type="flex" justify="space-between"> 
              <Col>
                <Row type="flex">
                  <Col style={{marginRight:10}}><h3>Per</h3></Col>
                    <Col>
                      <Select defaultValue="10" >
                        <Option value="10">10</Option>
                        <Option value="25">25</Option>
                        <Option value="50">50</Option>
                        <Option value="100">100</Option>
                        <Option value="200">200</Option>
                        <Option value="All">All</Option>
                      </Select>
                    </Col>
                    <Col style={{marginLeft:10}}><h3>{t('Line')}</h3></Col>
                </Row>
              </Col>
              <Col>
                <Row type="flex">
                  <Col style={{marginRight:10}}><h3>{t('Search of Project')}</h3></Col>
                  <Col><Input></Input></Col>
                </Row>
              </Col>
              </Row>
                <div style={{marginTop:20}}>
                  <Table columns={columns} ></Table>
                </div>
              </div>
        </Layout.Content>
      )
  }
}
export default withTranslation()(Project)