import React, { Component } from 'react'
import { Layout, 
         Row, 
         Col, 
         Breadcrumb, 
         Typography, 
         PageHeader, 
         DatePicker,
         Button, 
         Icon, 
         Select, 
         Input, 
         Divider, 
         Table } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

class AddBukti extends React.Component {
    render(){
        const {Title, Text} = Typography
        const dateFormat =' DD/MM/YYYY'
        const {Option} = Select
        const { t } = this.props
        const columns = [
            {
              title: 'Material',
              dataIndex: 'Material',
              key: 'Material',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('Description')}`,
              dataIndex: 'Description',
              key: 'Description',
            },
            {
              title: ' Unit',
              dataIndex: ' Unit',
              key: ' Unit',
            },
            {
                title: `${t('Purchase price')}`,
                dataIndex: 'Purchase price',
                key: 'Purchase price',
            },
            {
                title: 'Qty',
                dataIndex: 'Qty',
                key: 'Qty',
            },
            {
                title: 'Total',
                dataIndex: 'Total',
                key: 'Total',
            },
            
          ];
        return(
            <Layout.Content>
                <PageHeader style={{border: '1px solid rgb(235,237,340)'}}>
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Create Proof of Material Order')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Production')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/bom">{t('Proof of Material Order')}</a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/bom/create">{t('Create Proof of Material Order')} </a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                </PageHeader>

                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                <Divider type="horizontal"/>
                    <Row type="flex" >
                        <Col span={12}>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={6} ><h3>{t('Transaction Number')}</h3></Col>
                                <Col span={15}><Input readOnly defaultValue="BO2020020001" style={{backgroundColor:"#e6e6e6", marginLeft:10}}></Input> </Col>  
                            </Row>
                            
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={6} ><h3>{t('Transaction Date')}</h3></Col>
                                <Col span={15}><DatePicker style={{width:'100%', marginLeft:10}} defaultValue={moment('21/2/2020',dateFormat)} format={dateFormat} /></Col>
                            </Row>
                        </Col>
                        
                        <Col span={12}>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={6} ><h3>{t('Deposit To')}</h3></Col>
                                <Col span={15}>
                                    <Select style={{width:'100%',fontSize:14}} defaultValue= "(GDG-0001) - Gudang Raw Material">
                                            <Option value=""><Input></Input></Option>
                                            <Option value="(GDG-0001) - Gudang Raw Material" >(GDG-0001) - Gudang Raw Material</Option>
                                            <Option value="(GDG-0003) - Gudang Finished Goods " >(GDG-0003) - Gudang Finished Goods</Option>               
                                    </Select>
                            </Col>  
                            </Row>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>
                </div>

                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                    <Row type="flex" justify="space-between">
                        <Col span={12}>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={6} ><h3>{t('Product')}</h3></Col>
                                <Col span={15}>
                                    <Select style={{width:'100%',fontSize:14}} placeholder= {t('Select')}>
                                            <Option value=""><Input></Input></Option>
                                    </Select>
                                </Col>  
                            </Row>
                        </Col>

                        <Col span={12}>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={6} ><h3>Qty</h3></Col>
                                <Col span={15}><Input style={{marginLeft:5}} placeholder="0.00" /></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>
                </div>

                    <Col >
                        <div style={{margin:5, padding:10}}>
                            <Table columns={columns}></Table>
                        </div>
                        <Row type='flex'justify='space-between'>
                        <Button href="" type="primary" style={{margin:10, backgroundColor:"#9966ff", color:"white"}}>
                            <Icon type="plus"/>{t('Add Line')}</Button>
                    </Row>
                    </Col>

                    <Row type="flex" style={{marginBottom:30}}>
                        <Col style={{marginTop:15,marginLeft:10,marginRight:10}}><Button href="/bom" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                        <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Save')} </Button></Col>
                    </Row>
            </Layout.Content>
        )
    }
}
export default withTranslation() (AddBukti)