import React, { Component } from 'react'
import { Layout, Divider, Row, Col, Breadcrumb, Typography, Button, Icon, Input, Select, Card, Table, Pagination } from 'antd'
import { withTranslation } from 'react-i18next'

class Course extends Component{
    render(){
        const {t} = this.props
        const {Title} = Typography
        const Option = Select
        return(
            <Layout.Content>
                <div style={{padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>Master Course</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Training')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/course">Master Course</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider type="horizontal" style={{marginTop:0}} />
                <Row type="flex" justify="space-around">
                    <Button href="/course/create" style={{fontSize:16,backgroundColor:'#ffa940',color:'white'}}><Icon type="plus" />{t('Add Course')}</Button>
                    <Col style={{marginTop:5}} >{t('Course Name')} :</Col>
                    <Input style={{width:'20%'}} />
                    <Col style={{marginTop:5}}>{t('Type')} :</Col>
                    <Select style={{width:'20%'}} placeholder="Pilih">
                        <Option value="internal">{t('Internal')}</Option>
                        <Option value="eksternal">{t('External')}</Option>
                    </Select>
                    <Button style={{backgroundColor:'#ffa940',color:'white'}}>{t('Filter')}</Button>
                </Row>
                <Card style={{borderWidth:2,borderRadius:10,marginTop:10}}>
                    <Row type="flex" justify="space-between">
                            <Col type="flex">
                                <Row type="flex">
                                    <Col type="flex"><h3 style={{marginRight:5,marginTop:5}}>Per</h3></Col>
                                    <Col type="flex">
                                        <Select defaultValue="25" style={{width:'100%'}}>
                                            <Option value="25">25</Option>
                                            <Option value="50">50</Option>
                                            <Option value="100">100</Option>
                                            <Option value="200">200</Option>
                                            <Option value="All">All</Option>
                                        </Select>
                                    </Col>
                                    <Col type="flex"><h3 style={{marginTop:5,marginLeft:5}}>{t('Line')}</h3></Col>
                                </Row>
                            </Col>
                            <Col type="flex">
                                <Row type="flex">
                                    <Col><h3 style={{marginRight:5,marginTop:5}}>{t('Search')}</h3></Col>
                                    <Col><Input  style={{width:200}} /></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Table style={{marginTop:10}} columns={[
                            {
                                title:`${t('Course Name')}`
                            },
                            {
                                title:`${t('Type')}`
                            },
                            {
                                title:`${t('Description')}`
                            },
                            {
                                title:`${t('Total View')}`
                            },
                            {
                                title:`${t('Total Download')}`
                            },
                        ]}/>
                        <div style={{marginTop:20}}>
                            <Pagination 
                                total={85}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                pageSize={1}
                                defaultCurrent={1}
                                />
                        
                        </div>
                    </Card>
            </Layout.Content>
        )
    }
}
export default withTranslation()(Course) 