import React, { Component } from 'react'
import { Layout, Row, Col, Breadcrumb, Typography, Divider, Select, Input, DatePicker, Tabs, Table, Button, Icon } from 'antd'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

class EventCreate extends Component{
    render(){
        const {Title} = Typography
        const {t} = this.props
        const {Option} = Select
        const {TextArea} = Input
        const dateFormat = 'DD/MM/YYYY'
        const {TabPane} = Tabs
        return(
            <Layout.Content>
                <div style={{paddingTop:20,paddingBottom:20}}>
                    <Row type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Create New Event')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Training')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>{t('Master Event')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/course">{t('Create New Event')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider type="horizontal" style={{marginTop:-10}} />
                <Row type="flex" justify="space-between">
                    <Col span={12}>
                        <Row type="flex">
                            <Col span={8} style={{fontSize:14}}>{t('Course')}</Col>
                            <Col span={15}>
                                <Select style={{width:'100%'}} placeholder="Pilih">
                                    <Option value="none">{t('No Result Found')}</Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row type="flex" style={{marginTop:15}}>
                            <Col span={8} style={{fontSize:14}}>{t('Event Name')}</Col>
                            <Col span={15}><Input/></Col>
                        </Row>
                        <Row type="flex" style={{marginTop:15}}>
                            <Col span={8} style={{fontSize:14}}>{t('Event Type')}</Col>
                            <Col span={15}>
                                <Select style={{width:'100%'}} defaultValue="Internal">
                                    <Option value="Internal">{t('Internal')}</Option>
                                    <Option value="Eksternal">{t('External')}</Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row type="flex" style={{marginTop:15}}>
                            <Col span={8} style={{fontSize:14}}>{t('Address')}</Col>
                            <Col span={15}><TextArea rows={4} /></Col>
                        </Row>
                        <Row type="flex" style={{marginTop:15}}>
                            <Col span={8} style={{fontSize:14}}>{t('City')}</Col>
                            <Col span={15}><Input  /></Col>
                        </Row>
                        <Row type="flex" style={{marginTop:15}}>
                            <Col span={8} style={{fontSize:14}}>{t('Room')}</Col>
                            <Col span={15}><Input  /></Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row type="flex" >
                            <Col span={8} style={{fontSize:14}}>{t('Date Start')}</Col>
                            <Col span={15}><DatePicker style={{width:'100%'}} defaultValue={moment('02/03/2020', dateFormat)} format={dateFormat} /></Col>
                        </Row>
                        <Row type="flex" style={{marginTop:15}} >
                            <Col span={8} style={{fontSize:14}}>{t('Date End')}</Col>
                            <Col span={15}><DatePicker style={{width:'100%'}} defaultValue={moment('02/03/2020', dateFormat)} format={dateFormat} /></Col>
                        </Row>
                        <Row type="flex" style={{marginTop:15}}>
                            <Col span={8} style={{fontSize:14}}>{t('Time Start')}</Col>
                            <Col span={15}><Input defaultValue="8:00" /></Col>
                        </Row>
                        <Row type="flex" style={{marginTop:15}}>
                            <Col span={8} style={{fontSize:14}}>{t('Time End')}</Col>
                            <Col span={15}><Input defaultValue="17:00" /></Col>
                        </Row>
                        <Row type="flex" style={{marginTop:15}}>
                            <Col span={8} style={{fontSize:14}}>{t('Total Question')}</Col>
                            <Col span={15}><Input  /></Col>
                        </Row>
                        <Row type="flex" style={{marginTop:15}}>
                            <Col span={8} style={{fontSize:14}}>{t('Min. Grade')}</Col>
                            <Col span={15}><Input  /></Col>
                        </Row>
                        <Row type="flex" style={{marginTop:15}}>
                            <Col span={8} style={{fontSize:14}}>{t('Status')}</Col>
                            <Col span={15}>
                                <Select style={{width:'100%'}} placeholder={t('Choice')}>
                                    <Option value="Running">{t('Running')}</Option>
                                    <Option value="Done">{t('Done')}</Option>
                                    <Option value="Canceled">{t('Canceled')}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Tabs defaultActiveKey="1">
                    <TabPane key="1" tab={t('Participant')}>
                        <Table columns={[
                            {
                                title:`${t('Name')}`
                            },
                            {
                                title:`${t('Phone')}`
                            },
                            {
                                title:`${t('Email')}`
                            },
                            {
                                title:`${t('Department')}`
                            }
                        ]}
                        style={{marginTop:10}}
                        />
                        <Button style={{marginTop:10,backgroundColor:'#1890ff',color:'white'}} ><Icon type="plus"/>{t('New')}</Button>
                    </TabPane>
                    <TabPane tab={t('Trainer')} key="2">
                        <Table columns={[
                            {
                                title:`${t('Trainer Type')}`
                            },
                            {
                                title:`${t('Name')}`
                            },
                            {
                                title:`${t('Phone')}`
                            },
                            {
                                title:`${t('Email')}`
                            },
                            {
                                title:`${t('Department/Company')}`
                            }
                        ]}
                        style={{marginTop:10}}
                        />
                        <Button style={{marginTop:10,backgroundColor:'#1890ff',color:'white'}} ><Icon type="plus"/>{t('New')}</Button>
                    
                    </TabPane>
                </Tabs>
                <Divider type="horizontal"/>
                <Row type="flex" style={{marginTop:15}}>
                    <Button style={{marginRight:10,backgroundColor:'#8c8c8c',color:'white'}}>{t('Cancel')}</Button>
                    <Button style={{marginRight:10,backgroundColor:'#ffc53d',color:'white'}}>{t('Save')}</Button>
                </Row>
            </Layout.Content>
        )
    }
}
export default withTranslation()(EventCreate) 