import React from 'react'
import {Row, Col, Breadcrumb, Layout, PageHeader, Button, DatePicker, Select, Input, Table, Divider, Checkbox} from 'antd'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

class AddJournal extends React.Component{

    render(){
    const { t } = this.props
    const dateFormat = 'DD/MM/YYYY'
    const Option = Select
    const {TextArea} = Input
    const columns = [
        { 
          title: `${t("Name Account")}`,
          dataIndex: 'Name Account',
          key: 'Name Account',
        },
        {
          title: `${t("Description")}`,
          dataIndex: 'deskripsi',
          key: 'deskripsi',
        },
        {
          title: 'Debit',
          dataIndex: 'Debit',
          key: 'Debit',
        },
        {
          title: `${t("Credit")}`,
          dataIndex: 'Credit',
          key: 'Credit',
        },
      ];
        return(
            <Layout.Content>
                <Row style={{marginLeft:10, marginTop:20}} type="flex" justify="space-around">
                    <Col span={12}><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}>{t("Add Journal")}</h1></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end" style={{fontWeight:'bold'}}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t("Accounting")}</Breadcrumb.Item>
                                    <Breadcrumb.Item><a href="/journal">{t("General journal")}</a></Breadcrumb.Item>
                                    <Breadcrumb.Item>{t("Add Journal")}</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                </Row>
                <Divider type="horizontal"></Divider>

                <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
                    <Row>
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={8} style={{fontWeight:'bold'}}><label>Per {t("Date")}</label></Col>
                                <div>
                                    <DatePicker defaultValue={moment('29/02/2020', dateFormat)} style={{width:308}} format={dateFormat} />
                                </div>

                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("Referencenum")}</label></Col>
                                <Col span={14}><Input/></Col>                                
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("Project")}</label></Col>
                                <Col span={14}><Input/></Col>
                            </Row>
                        </Col>   
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={8} style={{fontWeight:'bold', marginLeft:20}}><label>Memo</label></Col>
                                <Col span={14}><TextArea></TextArea></Col>
                            </Row>
                            <Row type="flex">
                                <Col span={8} style={{marginLeft:20,marginTop:20}}></Col>
                                <Col span={14} style={{marginTop:10, fontWeight:'bold'}}><Checkbox> {t("Show Estimates of Previous Transactions")} </Checkbox></Col>
                            </Row>
                        </Col> 
                    </Row>
                    <Col>
                        <Divider type="horizontal"/>
                    </Col>
                    <div style={{marginTop:20, border: '1px solid #ebedf0'}}>
                            <Table columns={columns}></Table>
                    </div>
                    <Col style={{marginTop:20}}>
                    <Select placeholder={t("Add Line")} style={{width:150}}>
                                <Option value="x2">{t("Add Line")} (2X)</Option>
                                <Option value="x5">{t("Add Line")} (5X)</Option>
                                <Option value="x10">{t("Add Line")} (10X)</Option>
                            </Select>
                    </Col>
            </PageHeader>
                <Row type="flex" style={{marginBottom:30}}>
                    <Col style={{marginTop:15,marginRight:5}}><Button href="/journal" style={{backgroundColor:'#545961',color:'white',fontWeight:'bold'}}>{t("Back")}</Button></Col>
                    <Col style={{marginTop:15,marginRight:5}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t("Add Delivery")}</Button></Col>
                    <Col style={{marginTop:15,marginRight:5}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t("Save & Input Again")}</Button></Col>
                </Row>

            </Layout.Content>
        )
    }
}

export default withTranslation() (AddJournal)