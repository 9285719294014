import React from 'react'
import {Row, Col, Icon, Breadcrumb, Layout, PageHeader, Button, DatePicker, Select, Input, Divider, Table} from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next';

class Reconciliation extends React.Component{
    render(){
    const Option = Select
    const dateFormat = 'DD/MM/YYYY'
    const {TextArea} = Input
    const { t } = this.props
    const columns = [
        { 
          title: `${t("Transaction Date")}`,
          dataIndex: 'produk',
          key: 'produk',
        },
        {
          title: `${t("Description")}`,
          dataIndex: 'deskripsi',
          key: 'deskripsi',
        },
        {
          title: 'Debit',
          dataIndex: 'dikembalikan',
          key: 'dikembalikan',
        },
        {
            title: `${t("Credit")}`,
            dataIndex: 'dikembalikan',
            key: 'dikembalikan',
          }
      ];
        return(
            <Layout.Content>
                <Row style={{marginLeft:10, marginTop:20}} type="flex" justify="space-around">
                    <Col span={12}><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}>{t("Bank Reconciliation")}</h1></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end" style={{fontWeight:'bold'}}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t("Accounting")}</Breadcrumb.Item>
                                    <Breadcrumb.Item><a href="/coa">{t("Bank cash")}</a></Breadcrumb.Item>
                                    <Breadcrumb.Item>{t("Bank Reconciliation")}</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                </Row>
            <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
                    <Row>
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("Account Reconciliation")}</label></Col>
                                <Col span={14}><Select defaultValue="1-1200 - Bank" style={{width:'100%'}}>
                                                    <Option value="01">1-1200 - Bank</Option>
                                                    <Option value="02">1-1100 - Kas</Option>
                                                    <Option value="03">1-1120 - Kas Bank POS</Option>
                                                    <Option value="04">1-1110 - Kas POS</Option>
                                                </Select></Col>                                
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("Date of Last Reconciliation")}</label></Col>
                                <Col span={14}><Input type="disabled" disabled defaultValue="-"/></Col>                                
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("Today's Reconciliation Date")}</label></Col>
                                <Col span={14}><div>
                                                    <DatePicker defaultValue={moment('02/03/2020', dateFormat)} style={{width:308}} format={dateFormat} />
                                                </div></Col>                                
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("Current Account Balance")}</label></Col>
                                <Col span={14}><Input placeholder="Rp. 0,00" style={{textAlign:'end'}}></Input></Col>                                
                            </Row>
                        </Col>   
                        <Col span={12}>
                            <PageHeader style={{border: '2px solid #ebedf0'}}>
                                <Row type="flex">
                                    <Col span={10} style={{fontWeight:'bold', marginLeft:10, fontSize:15}}><label>{t("BEGINNING BOOK BALANCE")}</label></Col>
                                </Row>
                                <Row type="flex">
                                    <Col span={10} style={{marginLeft:10, fontSize:15}}><label>Rp. 0,00</label></Col>                          
                                </Row>
                                <Row type="flex">
                                    <Col span={10} style={{fontWeight:'bold', marginLeft:10, marginTop:20}}><label>{t("Total Debits")}</label></Col>
                                    <Col span={10} style={{marginLeft:10, fontSize:15, marginTop:20}}><label>Rp. 0,00</label></Col> 
                                </Row>
                                <Row type="flex">
                                    <Col span={10} style={{fontWeight:'bold', marginLeft:10, marginTop:20}}><label>{t("Total Credit")}</label></Col>
                                    <Col span={10} style={{marginLeft:10, fontSize:15, marginTop:20}}><label>Rp. 0,00</label></Col> 
                                </Row>
                                <Row type="flex">
                                    <Col span={10} style={{fontWeight:'bold', marginLeft:10, marginTop:20}}><label>{t("Final Balance Count")}</label></Col>
                                    <Col span={10} style={{marginLeft:10, fontSize:15, marginTop:20}}><label>Rp. 0,00</label></Col> 
                                </Row>
                                <Row type="flex">
                                    <Col span={10} style={{fontWeight:'bold', marginLeft:10, marginTop:20}}><label>{t("Difference")}</label></Col>
                                    <Col span={10} style={{marginLeft:10, fontSize:15, marginTop:20}}><label>Rp. 0,00</label></Col> 
                                </Row>
                            </PageHeader>
                        </Col> 
                    </Row>
                    <div style={{marginTop:20, border: '1px solid #ebedf0'}}>
                        <Table columns={columns}></Table>
                    </div>
            </PageHeader>
                <Row type="flex" style={{marginBottom:30}}>
                <Row type="flex" style={{marginBottom:30}}>
                    <Col style={{marginTop:15,marginRight:10}}><Button href="/coa" style={{backgroundColor:'#545961',color:'white',fontWeight:'bold'}}>{t("Cancel")}</Button></Col>
                    <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t("Save Account Reconciliation")} </Button></Col>
                </Row>
                </Row>
            </Layout.Content>
        )
    }
}

export default withTranslation() (Reconciliation)