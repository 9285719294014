import React, { Component } from 'react'
import { Layout, Row, Col, Breadcrumb, Divider, Typography, Card, Avatar, Button, Icon, Upload, Input, DatePicker, Select, Tabs, List, Table, Checkbox } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

class EmployeeCreate extends Component{
    render(){
        const {Title} = Typography
        const dateFormat = 'DD/MM/YYYY'
        const Option = Select
        const {TextArea} = Input
        const {TabPane} = Tabs
        const {t} = this.props
      
        return(
            <Layout.Content>
                <div style={{margin:10,padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Create New Employee')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>Personalia</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/employee">{t('Employee Data')}</a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/employee/create">{t('Create New Employee')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider  type="horizontal" style={{marginTop:-10}} />
                <div style={{margin:20}}>
                    <Row type="flex">
                        <Col span={6}>
                            <Row type="flex" justify="space-around">
                                <Col span={24}><Card style={{width:'100%',borderWidth:2,borderRadius:5,borderColor:'#1890ff'}}>
                                        <Row type="flex" justify="space-around">
                                            <Col><Avatar style={{width:150,height:150}} src="https://library.kissclipart.com/20181114/oke/kissclipart-avatar-profile-png-clipart-computer-icons-avatar-ed68f86e7eead50a.jpg" /></Col>
                                        </Row>
                                    </Card>
                               </Col>
                               <Col style={{marginTop:-18}}>
                                   <Upload>
                                        <Button style={{backgroundColor:'#1890ff',fontWeight:'bold',color:'white'}}><Icon type="folder-open" />{t('Select File')}</Button>
                                   </Upload>
                                </Col>
                            </Row>
                            <Checkbox style={{marginTop:30,fontWeight:'bold'}}>{t('This Employee Active')}</Checkbox>
                        </Col>
                        <Col style={{marginLeft:20}} span={9}>
                            <Row type="flex" justify="space-between"> 
                                <Col span={5} style={{fontSize:14,fontWeight:'bold'}}>{t('Registration Number')}</Col>
                                <Col span={18}><Input/></Col>
                            </Row>
                            <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                <Col span={5} style={{fontSize:14,fontWeight:'bold'}}>{t('Full Name')}</Col>
                                <Col span={18}><Input/></Col>
                            </Row>
                            <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                <Col span={5} style={{fontSize:14,fontWeight:'bold'}}>{t('Birth')}</Col>
                                <Col span={18}>
                                    <Row type="flex" justify="space-between">
                                        <Col span={11} style={{marginRight:20}}><Input placeholder={t('Birth Place')} /></Col>
                                        <Col span={11}><DatePicker format={dateFormat} placeholder="DD/MM/YYYY" /></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                <Col span={5} style={{fontSize:14,fontWeight:'bold'}}>{t('Genre')}</Col>
                                <Col span={18}>
                                    <Row type="flex" justify="space-between">
                                        <Col span={11}>
                                            <Select style={{width:'100%'}} defaultValue={t('Pria')} >
                                                <Option value="Pria">{t('Man')}</Option>
                                                <Option value="Wanita">{t('Woman')}</Option>
                                            </Select>
                                        </Col>
                                        <Col span={11}></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                <Col span={5} style={{fontSize:14,fontWeight:'bold'}}>{t('Telephone')}</Col>
                                <Col span={18}>
                                    <Row type="flex" justify="space-between">
                                        <Col span={11} style={{marginRight:20}}><Input placeholder={t('Number 1')} /></Col>
                                        <Col span={11}><Input placeholder={t('Number 2')} /></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                <Col span={5} style={{fontSize:14,fontWeight:'bold'}}>{t('KTP/SIM Number')}</Col>
                                <Col span={18}><Input/></Col>
                            </Row>
                            <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                <Col span={5} style={{fontSize:14,fontWeight:'bold'}}>{t('Rekening Number')}</Col>
                                <Col span={18}><Input/></Col>
                            </Row>
                        </Col>
                        <Col style={{marginLeft:20}} span={8}>
                            <Row type="flex" justify="space-between"> 
                                <Col span={5} style={{fontSize:14,fontWeight:'bold'}}>{t('Home Address')}</Col>
                                <Col span={18}><TextArea rows={4} /></Col>
                            </Row>
                            <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                <Col span={5} style={{fontSize:14,fontWeight:'bold'}}>{t('Religion')}</Col>
                                <Col span={18}>
                                     <Select style={{width:'100%'}} defaultValue="Islam" >
                                        <Option value="Islam">Islam</Option>
                                        <Option value="Kristen">Kristen</Option>
                                        <Option value="Hindu">Hindu</Option>
                                        <Option value="Budha">Budha</Option>
                                        <Option value="Lainnya">{t('Other')}</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                <Col span={5} style={{fontSize:14,fontWeight:'bold'}}>{t('Married Status')}</Col>
                                <Col span={18}>
                                     <Select style={{width:'100%'}} defaultValue="Belum Menikah" >
                                        <Option value="Belum Menikah">{t('Single')}</Option>
                                        <Option value="Menikah">{t('Maried')}</Option>
                                        <Option value="Janda/Duda">{t('Widow/Widower')}</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                <Col span={5} style={{fontSize:14,fontWeight:'bold'}}>{t('Married Since')}</Col>
                                <Col span={18}><DatePicker style={{width:'100%'}} format={dateFormat} placeholder="DD/MM/YYYY" /></Col>
                            </Row>
                            <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                <Col span={5} style={{fontSize:14,fontWeight:'bold'}}>{t('Emergency Contact')}</Col>
                                <Col span={18}><Input placeholder="Misal: Nama Istri/Suami/Orang Tua" /></Col>
                            </Row>
                            <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                <Col span={5} style={{fontSize:14,fontWeight:'bold'}}>{t('Number Emergency Contact')}</Col>
                                <Col span={18}><Input placeholder={t('Telephone')} /></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Card style={{borderRadius:10,borderWidth:2,borderColor:'#1890ff',marginTop:20}}>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={t('Profession')} key="1">
                                <Row type="flex" justify="space-between">
                                    <Col span={12}>
                                        <Row type="flex" justify="space-between"> 
                                            <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Status')}</Col>
                                            <Col span={15}>
                                                <Select style={{width:'50%'}} defaultValue="Kontrak">
                                                    <Option value="Kontrak">Kontrak</Option>
                                                    <Option value="Percobaan">Percobaan</Option>
                                                    <Option value="Permanen">Permanen</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                            <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Come to work')}</Col>
                                            <Col span={15}><DatePicker style={{width:'100%'}} format={dateFormat} defaultValue={moment('07/02/2020',dateFormat)} /></Col>
                                        </Row>
                                        <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                            <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Work area')}</Col>
                                            <Col span={15}>
                                                <Select style={{width:'100%'}} defaultValue="Kantor Pusat">
                                                    <Option value="Kantor Pusat">Kantor Pusat</Option>
                                                    <Option value="new"><Icon type="plus"/>Tambah Area Kerja Baru</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                            <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Boss')}</Col>
                                            <Col span={15}>
                                                <Select style={{width:'100%'}} defaultValue="Tidak Ada">
                                                    <Option value="Tidak Ada">Tidak Ada</Option>
                                                    <Option value="Hidayaturrahman">Hidayaturrahman</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                            <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('May leave')}</Col>
                                            <Col span={15}>
                                                <Select style={{width:'100%'}} defaultValue="Boleh">
                                                    <Option value="Boleh">Boleh</Option>
                                                    <Option value="Tidak Boleh">Tidak Boleh</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col >
                                    <Col span={11}>
                                        <Row type="flex" justify="space-between"> 
                                            <Col span={10} style={{fontSize:14,fontWeight:'bold'}}>{t('BPJS Health Number')}</Col>
                                            <Col span={13}><Input  /></Col>
                                        </Row>
                                        <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                            <Col span={10} style={{fontSize:14,fontWeight:'bold'}}>{t('BPJS Employment Number')}</Col>
                                            <Col span={13}><Input  /></Col>
                                        </Row>
                                        <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                            <Col span={10} style={{fontSize:14,fontWeight:'bold'}}>{t('Attendance Machine Number')}<Icon type="question-circle" /></Col>
                                            <Col span={13}><Input  /></Col>
                                        </Row>
                                        <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                            <Col span={10} style={{fontSize:14,fontWeight:'bold'}}>{t('NPWP Number')}</Col>
                                            <Col span={13}><Input  /></Col>
                                        </Row>
                                        <Row style={{marginTop:15}} type="flex" justify="space-between"> 
                                            <Col span={10} style={{fontSize:14,fontWeight:'bold'}}>{t('PTKP Status')}</Col>
                                            <Col span={13}>
                                                <Select style={{width:'50%'}} defaultValue="K0">
                                                    <Option value="K0">K0</Option>
                                                    <Option value="K1">K1</Option>
                                                    <Option value="K2">K2</Option>
                                                    <Option value="k3">k3</Option>
                                                    <Option value="TK">TK</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>

                            </TabPane>
                            <TabPane tab={t('Salary')} key="2">
                                <Row type="flex" justify="space-between">
                                    <Col span={11}>
                                        <Row>
                                            <Col style={{fontSize:15,fontWeight:'bold'}}>{t('Addition')}</Col>
                                            <Divider type="horizontal"/>
                                            <Row type="flex" >
                                                <Col span={5} style={{fontSize:14,fontWeight:'bold'}}>{t('Group')}</Col>
                                                <Col span={8}>
                                                    <Select style={{width:'100%'}} defaultValue="BOD">
                                                        <Option value="BOD">BOD</Option>
                                                        <Option value="new"><Icon type="plus" />{t('Create New Golongan')}</Option>
                                                    </Select>   
                                                </Col>
                                                <Col span={5} style={{fontSize:14,fontWeight:'bold', marginLeft:20}}>Step</Col>
                                                <Col span={4}>
                                                    <Select style={{width:'100%'}} defaultValue="1">
                                                        <Option value="1">1</Option>
                                                    </Select>   
                                                </Col>
                                            </Row>
                                            <Row type="flex" style={{marginTop:15}} >
                                                <Col span={5} style={{fontSize:14,fontWeight:'bold'}}>{t('Position')}</Col>
                                                <Col span={19}>
                                                    <Select style={{width:'100%'}} defaultValue="CEO">
                                                        <Option value="CEO">CEO</Option>
                                                        <Option value="new"><Icon type="plus" />{t('Create New Position')}</Option>
                                                    </Select>   
                                                </Col>
                                            </Row>
                                        </Row>
                                        <List style={{marginTop:10,borderColor:'#faad14',borderWidth:2}}
                                           
                                           footer={ 
                                               <Row type="flex" justify="space-between">
                                                   <Col style={{fontSize:14}}>Total</Col>
                                                   <Col style={{fontSize:14}}>2,000,000.00</Col>
                                               </Row>
                                           } 
                                           dataSource={[
                                                {
                                                    salary_name: 'Gaji Pokok (BOD Step 1)',
                                                    salary_amount: '1,000,000.00'
                                                },
                                                {
                                                    salary_name: 'Tunjangan Jabatan',
                                                    salary_amount: '1.000.000,00'
                                                }
                                            ]}
                                            bordered

                                            renderItem={item => (
                                                <List.Item>
                                                   <List.Item.Meta
                                                       
                                                        title={<a href="https://ant.design"><Icon type="lock" />{item.salary_name}</a>}
                                                        
                                                        />
                                                        <div>{item.salary_amount}</div>
                                                </List.Item>
                                            )}
                                            />
                                    </Col>
                                    <Col span={11}>
                                        <Row>
                                            <Col style={{fontSize:15,fontWeight:'bold'}}>{t('Subtraction')}</Col>
                                        </Row>
                                        <Divider type="horizontal"/>
                                        <List style={{marginTop:10,borderColor:'#faad14',borderWidth:2}}
                                           
                                           footer={ 
                                               <Row type="flex" justify="space-between">
                                                   <Col style={{fontSize:14}}>Total</Col>
                                                   <Col style={{fontSize:14}}>Rp. 0,00</Col>
                                               </Row>
                                           } 
                                           dataSource={[
                                                {
                                                    costname: `${t('Health BPJS')}`
                                                },
                                                {
                                                    costname: `${t('BPJS of Employment')}`
                                                },
                                                {
                                                    costname: `${t('PPH tax 21')}`
                                                }
                                            ]}
                                            bordered

                                            renderItem={item => (
                                                <List.Item>
                                                   <List.Item.Meta
                                                       
                                                        title={<a href="https://ant.design"><Icon type="lock" />{item.costname}</a>}
                                                        
                                                        />
                                                        <div><Input placeholder="Rp. 0,00" /></div>
                                                </List.Item>
                                            )}
                                            />

                                    </Col>
                                    <Button style={{marginTop:10,color:'white',backgroundColor:'#1890ff'}}><Icon type="plus" />{t('Add Data Adders')}</Button>
                                    <a style={{fontSize:15,fontWeight:'bold',marginTop:10,width:'100%',color:'black'}}>{t('Salary Receipt Estimates')}: Rp. 2,000,000.00</a>
                                </Row>
                                
                            </TabPane>
                            <TabPane tab="Biografi" key="3">
                                <p style={{fontSize:16,fontWeight:'bold'}}>{t('Family')}</p>
                                <Table 
                                    columns={[
                                        {
                                            title: `${t('Name')}`,
                                            dataIndex : 'Nama',
                                            key : 'Nama'
                                        },
                                        {
                                            title: `${t('Relationship')}`,
                                            dataIndex : 'Hubungan',
                                            key : 'Hubungan'
                                        },
                                        {
                                            title: `${t('Genre')}`,
                                            dataIndex : 'Jenis_Kelamin',
                                            key : 'Jenis_Kelamin'
                                        },
                                        {
                                            title: `${t('Date of birth')}`,
                                            dataIndex : 'Tempat_Tanggal_Lahir',
                                            key : 'Tempat_Tanggal_Lahir'
                                        },
                                    ]} 
                                    style={{marginTop:10}}
                                />
                                <Button style={{marginTop:10,color:'white',backgroundColor:'#1890ff'}}><Icon type="plus" />{t('Add Family Data')}</Button>
                                <p style={{fontSize:16,fontWeight:'bold',marginTop:20}}>{t('Education')}</p>
                                <Table 
                                    columns={[
                                        {
                                            title: `${t('Level')}`,
                                            dataIndex : 'Level',
                                            key : 'Level'
                                        },
                                        {
                                            title: `${t('School Place')}`,
                                            dataIndex : 'Tempat_Sekolah',
                                            key : 'Tempat_Sekolah'
                                        },
                                        {
                                            title: `${t('Department / Faculty')}`,
                                            dataIndex : 'Jurusan/Fakultas',
                                            key : 'Jurusan/Fakultas'
                                        },
                                        {
                                            title: `${t('Graduate date')}`,
                                            dataIndex : 'Tanggal_Lulus',
                                            key : 'Tanggal_Lulus'
                                        },
                                        {
                                            title: 'IPK',
                                            dataIndex : 'IPK',
                                            key : 'IPK'
                                        },
                                    ]} 
                                    style={{marginTop:10}}
                                />
                                <Button style={{marginTop:10,color:'white',backgroundColor:'#1890ff'}}><Icon type="plus" />{t('Add Education Data')}</Button>


                            </TabPane>
                            <TabPane tab={t('Competition')} key="4">
                                <Row>
                                    <Col span={12}>
                                        <Row>
                                            <Col style={{fontSize:16,fontWeight:'bold'}}>Keahlian</Col>
                                            <Col>
                                                <Table
                                                    columns={[
                                                        {
                                                            title: `${t('Expertise')}`,
                                                            dataIndex : 'Keahlian',
                                                            key : 'Keahlian'
                                                        }, 
                                                        {
                                                            title: `${t('1-100 value')}`,
                                                            dataIndex : 'Nilai_1-100',
                                                            key : 'Nilai_1-100'
                                                        },
                                                    ]}
                                                />
                                            </Col>
                                            <Col>
                                                 <Button style={{marginTop:10,color:'white',backgroundColor:'#1890ff'}}><Icon type="plus" />{t('Add Competition Data')}</Button>
                                            </Col>
                                            <Col style={{fontSize:16,fontWeight:'bold',marginTop:10}}>{t('Project Followed')}</Col>
                                            <Col style={{fontSize:12,fontWeight:'bold',marginTop:10}}>{t('Will be filled if the employee is included in the Project Member')} </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </TabPane>
                            <TabPane tab={t('Access rights')} key="5">
                                <Row>
                                    <Col span={12}>
                                        <Row type="flex" justify="space-between">
                                            <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('User Login Email')}</Col>
                                            <Col span={15}>
                                                <Select style={{width:'100%'}} defaultValue="-Tidak Diset">
                                                    <Option value="-Tidak Diset">- {t('Not set')}</Option>
                                                    <Option value="new"><Icon type="plus" />Undang Pengguna Baru</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </TabPane>
                        </Tabs>
                    </Card>
                    <Row type="flex" style={{marginTop:10,marginLeft:20}}>
                        <Col style={{marginTop:15,marginRight:10}}><Button href="/employee" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                        <Col style={{marginTop:15}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Save')} </Button></Col>
                    </Row>
                </div>
            </Layout.Content>
        )
    }
}
export default withTranslation()(EmployeeCreate) 