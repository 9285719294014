import React, { Component } from 'react'
import { Layout, Typography, Select, Row, Col, Breadcrumb, Divider, Button, Icon, Table, Pagination, Card, Input } from 'antd'
import { withTranslation } from 'react-i18next'

class Evaluation extends Component{
    render(){
        const {t} = this.props
        const {Title} = Typography
        const {Option} = Select
        return(
            <Layout.Content>
                <div style={{padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Evaluation Question')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Training')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/course">{t('Evaluation Question')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider type="horizontal" style={{marginTop:0}} />
                <Row type="flex" justify="space-between">
                    <Col><Button href="/evaluation-question/create" style={{backgroundColor:'#ffc53d',color:'white'}}><Icon type="plus" />{t('Create Question')}</Button></Col>
                    <Col span={12}>
                        <Row type="flex" justify="space-between">
                            <Col style={{fontSize:14,marginTop:5,marginRight:20}}>{t('Course')} :</Col>
                            <Col span={10}>
                                <Select style={{width:'100%'}} defaultValue="All">
                                    <Option value="All">{t('All')}</Option>
                                </Select>
                            </Col>
                            <Col justify="end"><Button style={{backgroundColor:'#ffc53d',color:'white'}}>{t('Filter')}</Button></Col>
                        </Row>
                    </Col>
                </Row>
                <Card style={{borderWidth:2,borderRadius:10,marginTop:10}}>
                    <Row type="flex" justify="space-between">
                            <Col type="flex" span={12}>
                                <Row type="flex">
                                    <Col type="flex"><h3 style={{marginRight:5,marginTop:5}}>Per</h3></Col>
                                    <Col type="flex" span={4}>
                                        <Select defaultValue="25" style={{width:'100%'}}>
                                            <Option value="25">25</Option>
                                            <Option value="50">50</Option>
                                            <Option value="100">100</Option>
                                            <Option value="200">200</Option>
                                            <Option value="All">{t('All')}</Option>
                                        </Select>
                                    </Col>
                                    <Col type="flex"><h3 style={{marginTop:5,marginLeft:5}}>{t('Line')}</h3></Col>
                                </Row>
                            </Col>
                            <Col type="flex">
                                <Row type="flex">
                                    <Col><h3 style={{marginRight:5,marginTop:5}}>{t('Search')}</h3></Col>
                                    <Col><Input  style={{width:200}} /></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Table style={{marginTop:10}} columns={[
                            {
                                title:`${t('Course Name')}`
                            },
                            {
                                title:`${t('Question Text')}`
                            },
                            {
                                title:`${t('Answer Text')}`
                            },
                            {
                                title:`${t('Score')}`
                            },
                            {
                                title:`${t('Is Active')}`
                            },
                            {
                                title:`${t('View')}`
                            },
                        ]}/>
                        <div style={{marginTop:20}}>
                            <Pagination 
                                total={85}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                pageSize={1}
                                defaultCurrent={1}
                                />
                        
                        </div>
                    </Card>
            </Layout.Content>
        )
    }
}
export default withTranslation()(Evaluation)