import React from 'react'
import {Row, Col, Breadcrumb, Layout, Card, PageHeader, Button, Icon, DatePicker, Select, Input, Table, Divider, Avatar} from 'antd'
import { withTranslation } from 'react-i18next'

class Cashbank extends React.Component{
    render(){
    const { t } = this.props
    const columns = [
        {
          title: `${t('Account Code')}`,
          dataIndex: 'Kode_Akun',
          key: 'Kode_Akun',
        },
        {
          title: `${t('Account Name')}`,
          dataIndex: 'Nama_Akun',
          key: 'Nama_Akun',
        },
        {
            title: `${t('Balance Amount')}`,
            dataIndex: 'Jumlah_Saldo',
            key: 'Jumlah_Saldo',
          },
      ];
      const data = [
        {
          key: '1',
          Kode_Akun: '1-1200',
          Nama_Akun: 'Bank',
          Jumlah_Saldo: '0,00',
        },
        {
          key: '2',
          Kode_Akun: '1-1100',
          Nama_Akun: 'Kas',
          Jumlah_Saldo: '0,00',
        },
        {
          key: '3',
          Kode_Akun: '1-1120',
          Nama_Akun: 'Kas Bank POS',
          Jumlah_Saldo: '0,00',
        },
        {
            key: '4',
            Kode_Akun: '1-1110',
            Nama_Akun: 'Kas POS',
            Jumlah_Saldo: '0,00',
          },
      ];    
        return(
            <Layout.Content>
            <Row style={{marginTop:30}} type="flex" justify="space-around">
                <Col span={12}><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}> {t('Bank cash')} </h1></Col>
                    <Col span={12} >
                        <Row type="flex" justify="end" style={{fontWeight:'bold', marginRight:11}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>{t('Accounting')}</Breadcrumb.Item>
                                <Breadcrumb.Item>{t('Bank cash')}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Col>
            </Row>
            <Divider type="horizontal"></Divider>
            <Row style={{marginLeft:10}} type="flex">
                <Col>
                </Col>
            </Row>
            <Row type="flex" justify="space-between">
                <Card style={{border: '2px solid #ebedf0', width: 360, height: 130}}>
                    <Col span="12">
                            <Row><Avatar style={{width:80,height:80}} src="https://png2.cleanpng.com/sh/7561202df4dc1fc2f034dc48c0f62b43/L0KzQYm3U8IxN6JwfZH0aYP2gLBuTfNwdaF6jNd7LXnmf7B6TgBigZ5qhuY2aX7mf77sTfZqdpJze9c2c3n9dX76gfxieqoygdV4bj24cbO3hvY3PWQ5faUDMj68Q4GBUsM5QGI6SqMANEm0RYi5UcY3NqFzf3==/kisspng-computer-icons-payment-income-finance-size-salary-icon-5ab0ff6534e382.9308238815215491572166.png" /></Row>
                    </Col>
                    <Col>
                            <Row type="flex" justify="end" style={{fontSize:13, fontWeight:"bold", color:'#95de64', textAlign:'end'}}>{t("ENTRANCE 30 DAYS COMING")}</Row>
                            <Row type="flex" justify="end" style={{fontSize:28, fontWeight:"bold", color:'#595959'}}>0,00</Row>
                    </Col>
                </Card>
                <Card style={{border: '2px solid #ebedf0', width: 360, height: 130}}>
                    <Col span="12">
                            <Row><Avatar style={{width:80,height:80}} src="https://png2.cleanpng.com/sh/5cd5d6c86b5a875e8cd1f8f885403f17/L0KzQYm3U8E6N5ptj5H0aYP2gLBuTfFlfpJze9c2cHH8fbb1lL1kd551jeZucj3sc7F1k71ud59qkZ9sYYPrPcHomf1mdqUygdV4bj24cbO3VBIyPWlrTKUANz6zQIi9VMYzPWI6SqMAMUW1SIa8WMY5NqFzf3==/kisspng-advance-payment-computer-icons-money-cash-payment-icon-5ab07b158f4357.0076462515215152855868.png" /></Row>
                    </Col>
                    <Col>
                            <Row type="flex" justify="end" style={{fontSize:13, fontWeight:"bold", color:'#ff7875', textAlign:'end'}}>{t("EXPENDITURE 30 DAYS")}</Row>
                            <Row type="flex" justify="end" style={{fontSize:28, fontWeight:"bold", color:'#595959'}}>0,00</Row>
                    </Col>
                </Card>
                <Card style={{border: '2px solid #ebedf0', width: 360, height: 130}}>
                    <Col span="12">
                            <Row><Avatar style={{width:80,height:80}} src="https://png2.cleanpng.com/sh/0d3f81cf83af4981ef774acb11fe0b7f/L0KzQYm3U8E6N6V8iZH0aYP2gLBuTfNwdaF6jNd7LXnmf7B6TfZqdpJze9c2ZnnxcbBqifFtNaR5eeZubXXxhH7wjwZwcZRqRdhybnHxc7rojL1qa5CyTdNrMEfmSYjtUcE3aWozTqY5MEC4RYO4VcIyPWI6Tqk6OUi6RXB3jvc=/kisspng-computer-icons-finance-financial-statement-invoice-financial-ico-5ab07c97f116a9.6400055215215156719875.png" /></Row>
                    </Col>
                    <Col>
                            <Row type="flex" justify="end" style={{fontSize:13, fontWeight:"bold", color:'#85a5ff', textAlign:'end'}}>{t("CASH & BANK BALANCE")}</Row>
                            <Row type="flex" justify="end" style={{fontSize:28, fontWeight:"bold", color:'#595959'}}>0,00</Row>
                    </Col>
                </Card>
            </Row>
            <Row style={{marginTop:10}} type="flex">
                <Col><Button href="/coa/transfer" style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold', marginTop:10}}>{t("Transfer of Money")} </Button></Col>
                <Col><Button href="/coa/deposit" style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold', marginTop:10, marginLeft:5}}>{t("Receive Money")} </Button></Col>
                <Col><Button href="/coa/withdrawal" style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold', marginTop:10, marginLeft:5}}>{t("Send Money")} </Button></Col>
                <Col><Button href="/coa/reconcile" style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold', marginTop:10, marginLeft:5}}>{t("Bank Reconciliation")} </Button></Col>
            </Row>
            <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
                <Table
                        columns={columns}
                        dataSource={data}
                />
            </PageHeader>
            </Layout.Content>
        )
    }
}

export default withTranslation() (Cashbank)