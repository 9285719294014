import React from 'react'
import {Row, Col, Breadcrumb, Layout, Card, PageHeader, Button, Icon, DatePicker, Select, Input, Table, Divider} from 'antd'
import { withTranslation } from 'react-i18next'

class Presence extends React.Component{
    render(){
    const { t } = this.props
    const Option = Select
    const dateFormat = 'DD/MM/YYYY'
    const columns = [
        { 
          title: `${t("Time")}`,
          dataIndex: 'Time',
          key: 'Time',
        },
        {
          title: `${t("Name")}`,
          dataIndex: 'Name',
          key: 'Name',
        },
        {
          title: `${t("Position")}`,
          dataIndex: 'Position',
          key: 'Position',
        },
        {
          title: `${t("Group")}`,
          dataIndex: 'Group',
          key: 'Group',
        },
        {
            title: `${t("Total Work Hours")}`,
            dataIndex: 'Total Work Hours',
            key: 'Total Work Hours',
          }
      ];
        return(
            <Layout.Content>
            <Row style={{marginLeft:10, marginTop:30}} type="flex" justify="space-around">
                <Col span={12}><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}> {t('Presence')} </h1></Col>
                    <Col span={12} >
                        <Row type="flex" justify="end" style={{fontWeight:'bold', marginRight:11}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>{t('Personnel')}</Breadcrumb.Item>
                                <Breadcrumb.Item>{t('Presence')}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Col>
            </Row>
            <Divider type="horizontal"></Divider>
            <Row style={{marginLeft:10}} type="flex">
                <Col><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}> {t('Today')}, 03 Maret 2020  
                            <DatePicker placeholder="" size="large" format={dateFormat} style={{width:40, marginLeft:10  }}/>
                    </h1>
                </Col>
            </Row>
            <Row style={{marginLeft:10}} type="flex">
                <Col style={{marginTop:10}}>
                    <Card style={{border: '2px solid #ebedf0', width: 250 }}>
                        <Row type="flex" justify="space-around" style={{fontSize:22, fontWeight:"bold", color:'#595959'}}>{t("Come")}</Row>
                        <Row type="flex" justify="space-around" style={{fontSize:30, fontWeight:"bold", color:'#595959', marginTop:30, color:'#8c8c8c'}}>00:00:00</Row>
                        <Row type="flex" justify="space-around" style={{fontSize:18, fontWeight:"bold", color:'#595959', marginTop:10}}><Button style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold'}}>Clock In</Button></Row>
                    </Card>
                </Col>
                <Col style={{marginTop:10, marginLeft:20}}>
                    <Card style={{border: '2px solid #ebedf0', width: 250 }}>
                        <Row type="flex" justify="space-around" style={{fontSize:22, fontWeight:"bold", color:'#595959'}}>{t("Leave")}</Row>
                        <Row type="flex" justify="space-around" style={{fontSize:30, fontWeight:"bold", color:'#595959', marginTop:30, color:'#8c8c8c'}}>--:--:--</Row>
                        <Row type="flex" justify="space-around" style={{fontSize:18, fontWeight:"bold", color:'#595959', marginTop:10}}><Button type="disabled" disabled style={{backgroundColor:'#096dd9',color:'white',fontWeight:'bold'}}>Clock Out</Button></Row>
                    </Card>
                </Col>
                <Col style={{marginTop:10, marginLeft:20}}>
                    <Card style={{border: '2px solid #ebedf0', width: 250 }}>
                        <Row type="flex" justify="space-around" style={{fontSize:22, fontWeight:"bold", color:'#595959'}}>{t("Office Hours")}</Row>
                        <Row type="flex" justify="space-around" style={{color:'#595959', color:'#8c8c8c'}}>( Dalam Hitungan Jam )</Row>
                        <Row type="flex" justify="space-around" style={{fontSize:30, fontWeight:"bold", color:'#595959', marginTop:10, color:'#8c8c8c'}}>00:00:00</Row>
                        <Row style={{marginTop:41}}></Row>
                    </Card>
                </Col>
            </Row>
            <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
                        <Row type="flex" justify="space-between"> 
                                    <Col>
                                        <Row type="flex">
                                            <Col><h3 style={{marginRight:4, fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>Per</h3></Col>
                                                    <Select defaultValue="10" size='small'>
                                                        <Option value="10">10</Option>
                                                        <Option value="25">25</Option>
                                                        <Option value="50">50</Option>
                                                        <Option value="100">100</Option>
                                                        <Option value="200">200</Option>
                                                        <Option value="All">All</Option>
                                                    </Select>
                                            <Col><h3 style={{marginLeft:4, fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>{t("Line")}</h3></Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row type="flex">
                                            <Col style={{marginRight:5, }}><h3 style={{ fontWeight:'bold', color:'#8c8c8c', fontSize:14}}> {t("Search")} </h3></Col>
                                            <Col><Input size='small'placeholder="Berdasarkan Nama atau No. Reg" style={{width:220}}></Input></Col>
                                        </Row>
                                    </Col>
                        </Row>
                    <div style={{marginTop:20, border: '1px solid #ebedf0'}}>
                        <Table columns={columns}></Table>
                    </div>
            </PageHeader>
            </Layout.Content>
        )
    }
}

export default withTranslation() (Presence)