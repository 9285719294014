import React from 'react'
import {Row, Col, Breadcrumb, Layout, PageHeader, Button, Divider, Select, Input} from 'antd'
import { withTranslation } from 'react-i18next'

class AddWorkArea extends React.Component{
    render(){
        const Option = Select
        const { t } = this.props
        const {TextArea} = Input
        return(
            <Layout.Content>
                <Row style={{marginLeft:10, marginTop:20}} type="flex" justify="space-around">
                    <Col span={12}><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}>{t("Add Work Area")}</h1></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end" style={{fontWeight:'bold'}}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t("Personnel")}</Breadcrumb.Item>
                                    <Breadcrumb.Item>{t("Work Area")}</Breadcrumb.Item>
                                    <Breadcrumb.Item>{t("Add Work Area")}</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                </Row>
                <Divider type="horizontal"></Divider>
            <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
                    <Row>
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={7} style={{fontWeight:'bold'}}><label>{t("Name of Work Area")}</label></Col>
                                <Col span={16}><Input/></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={7} style={{fontWeight:'bold'}}><label>{t("Description")}</label></Col>
                                <Col span={16}><TextArea rows={4} style={{width:'100%', height:60}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={7} style={{fontWeight:'bold'}}><label>{t("Project")}</label></Col>
                                <Col span={16}><Select defaultValue={t("Nothing")} style={{width:'100%'}}>
                                        <Option value="tdk">{t("Nothing")}</Option>
                                        <Option value="kp">{t("Headquarters")}</Option>
                                    </Select></Col>
                            </Row>
                        </Col>   
                    </Row>
            </PageHeader>
            <Row type="flex" style={{marginBottom:30}}>
                    <Col style={{marginTop:15,marginRight:10}}><Button href="/work-unit" style={{backgroundColor:'#545961',color:'white',fontWeight:'bold'}}>{t("Back")}</Button></Col>
                    <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t("Add Work Area")}</Button></Col>
            </Row>

            </Layout.Content>
        )
    }
}

export default withTranslation () (AddWorkArea)