const initState = {
    lang: 'en',
    language: 'English'
}

const languageReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SET_LANGUAGE' :
            sessionStorage.setItem('lang', action.lang)
            sessionStorage.setItem('language', action.language)
            return {
                ...state,
                lang: action.lang,
                language: action.language
            }
        default :
            return state
    }
}

export default languageReducer