import Axios from "axios"
import { url } from "../../config/global"

const login = (data) => {
    return (dispatch, getState) => {
        const {
            email,
            password
        } = data

        dispatch({
            type: 'LOGIN_PENDING'
        })

        Axios.post(`${url}/login`,{
                email,
                password
            }, {
                headers: {
                    'X-localization': sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'en'
                }
            }).then(res => {
            dispatch({
                type: 'LOGIN_SUCCESS',
                token: res.data.token,
                data: res.data.data
            })
        }).catch(err => {
            dispatch({
                type: 'LOGIN_FAILED',
                errors: err.response.data
            })
        })
    }
}


const validatePersonal = (data) => {
    return (dispatch, getState) => {
        const {
            email,
            full_name,
            password,
            password_confirmation
        } = data

        dispatch({
            type: 'VALIDATE_PERSONAL_PENDING'
        })

        Axios.post(`${url}/register/validate/personal`,{
                email,
                password,
                full_name,
                password_confirmation
            }, {
                headers: {
                    'X-localization': sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'en'
                }
            }).then(res => {
            dispatch({
                type: 'VALIDATE_PERSONAL_SUCCESS',
                valid: res.data.valid
            })
        }).catch(err => {
            dispatch({
                type: 'VALIDATE_PERSONAL_FAILED',
                error: err.response
            })
        })
    }
}

const prev = (current) => {
    
}

export { login, validatePersonal }