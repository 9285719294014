import React, { Component } from 'react'
import { Layout, Row, Col, Breadcrumb, Typography, Select, Divider, Card, Table, Pagination, Input, Button } from 'antd'
import { withTranslation } from 'react-i18next'

class TrainingReport extends Component{
    render(){
        const {Title} = Typography
        const Option = Select
        const {t} = this.props
        return(
            <Layout.Content>
                <div style={{padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Training Report')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Training')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/course">{t('Training Report')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider type="horizontal" style={{marginTop:0}}/>
                <Row type="flex" justify="space-between" style={{marginBottom:10,marginTop:-10}}>
                    <Col span={7}>
                        <Select placeholder="Pilih" style={{width:'100%'}}>
                            <Option value="none">{t('No Result Found')}</Option>
                        </Select>
                    </Col>
                    <Col><Button style={{color:'white',backgroundColor:'#ffc53d'}}>{t('Export')}</Button></Col>
                </Row>
                <Card style={{borderWidth:2,borderRadius:10}}>
                    <Row type="flex" justify="space-between">
                        <Col type="flex">
                            <Row type="flex">
                                <Col type="flex"><h3 style={{marginRight:5,marginTop:5}}>Per</h3></Col>
                                <Col type="flex">
                                    <Select defaultValue="25" style={{width:'100%'}}>
                                        <Option value="25">25</Option>
                                        <Option value="50">50</Option>
                                        <Option value="100">100</Option>
                                        <Option value="200">200</Option>
                                        <Option value="All">All</Option>
                                    </Select>
                                </Col>
                                <Col type="flex"><h3 style={{marginTop:5,marginLeft:5}}>{t('Line')}</h3></Col>
                            </Row>
                        </Col>
                        <Col type="flex">
                            <Row type="flex">
                                <Col><h3 style={{marginRight:5,marginTop:5}}>{t('Search')}</h3></Col>
                                <Col><Input  style={{width:200}} /></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Table style={{marginTop:10}} columns={[
                        {
                            title:`${t('NRP')}`
                        },
                        {
                            title:`${t('Name')}`
                        },
                        {
                            title:`${t('Training')}`
                        },
                    ]}/>
                    <div style={{marginTop:20}}>
                        <Pagination 
                            total={85}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                            pageSize={1}
                            defaultCurrent={1}
                            />
                    
                    </div>
                </Card>
            </Layout.Content>
        )
    }
}
export default withTranslation()(TrainingReport) 