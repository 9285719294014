import React, { Component } from 'react'
import { Layout, 
         Row, 
         Col, 
         Breadcrumb, 
         Typography, 
         PageHeader, 
         Button, 
         Select, 
         Input, 
         Divider,
         Table} from 'antd'
import { Menu} from 'antd'
import { withTranslation } from 'react-i18next';

class Menu1 extends React.Component {
    rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];
    state = {
        openKeys: ['sub1'],
      };
      onOpenChange = openKeys => {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          this.setState({ openKeys });
        } else {
          this.setState({
            openKeys: latestOpenKey ? [latestOpenKey] : [],
          });
        }
      };
    render(){
        const {Title,Text} = Typography
        const {Option} = Select
        const { t } = this.props;
        const { SubMenu } = Menu
        
        const columns = [
            {
              title: `${t('Function Name')}`,
              dataIndex: 'FunctionName',
              key: 'FunctionName',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('Access rights')}`,
              dataIndex: 'Access rights',
              key: 'Access rights',
            },
          ];

          const data = [ 
              {
                key: '1',
                FunctionName: `${t('Business Dashboard')}`
              },
              {
                key: '2',
                FunctionName: `${t('Register Account')}`
              },
              {
                key: '3',
                FunctionName:`${t('Bank cash')}`
              },
              {
                key: '4',
                FunctionName:`${t('General journal')}`
              },
              {
                key: '5',
                FunctionName:`${t('Bank Reconciliation')}`
              },
              {
                key: '6',
                FunctionName:`${t('Ledger')}`
              },
              {
                key: '7',
                FunctionName:`${t('Trial Balance')}`
              },
              {
                key: '8',
                FunctionName:`${t('Profit and loss')}`
              },

          ];

          const data2 = [
            {
                key: '1',
                FunctionName:`${t('HR Dashboard')}`
            },
            {
                key: '2',
                FunctionName:`${t('Employee data')}`
            },
            {
                key: '3',
                FunctionName:`${t('Positions & Benefits')}`
            },
            {
                key: '4',
                FunctionName:`${t('Basic Groups & Salaries')}`
            },
            {
                key: '5',
                FunctionName:`${t('Work area')}`
            },
            {
                key: '6',
                FunctionName:`${t('Work Calendar')}`
            },
            {
                key: '7', 
                FunctionName:`${t('Bewara')}`
            },
            {
                key: '8',
                FunctionName:`${t('Presence')}`
            },
            {
                key: '9',
                FunctionName: `${t('Employee Profile')}`
            },
            {
                key: '10',
                FunctionName:`${t('Business trip')}`
            },
            {
                key: '11',
                FunctionName: `${t('Money Reimbursement')}`
            },
            {
                key: '12',
                FunctionName:`${t('Absent Submission')}`
            },
            {
                key: '13',
                FunctionName:`${t('Submission Overtime')}`
            }, 
            {
                key: '14',
                FunctionName:`${t('mployee Payroll')}`
            },
            {
                key: '15',
                FunctionName:`${t('Applicant Data')}`
            },
            {
                key: '16',
                FunctionName:`${t('Job vacancy')}`
            },
            {
                key: '17',
                FunctionName: `${t('The question master')}`
            },
            {
                key: '18',
                FunctionName:`${t('Training Dashboard')}`
            },
            {
                key: '19',
                FunctionName: `${t('Event Master')}`
            },
            {
                key: '20', 
                FunctionName: `${t('Master Course')}`
            },
            {
                key: '21',
                FunctionName: `${t('Evaluation Questions')}`
            },
            {
                key: '22',
                FunctionName: `${t('Training Report')}`
            },
        ];

        const data3 = [
            {
              key: '1',
              FunctionName: `${t('Customer')}`
            },
            {
              key: '2',
              FunctionName: `${t('Sales Offer')}`
            },
            {
              key: '3',
              FunctionName:`${t('Booking')}`
            },
            {
              key: '4',
              FunctionName: `${t('Delivery')}`
            },
            {
              key: '5',
              FunctionName:  `${t('Invoice')}`
            },
            {
              key: '6',
              FunctionName:`${t('Payment')}`
            },
            {
              key: '7',
              FunctionName:`${t('POS')}`
            },
            {
              key: '8',
              FunctionName:`${t('Change')}`
            },
            {
              key: '9',
              FunctionName:`${t('Sales report')}`
            },
            {
               key: '10',
               FunctionName:`${t('Sales Dashboard')}`
            },

        ];
        const data4 = [
            {
                key: '1',
                FunctionName:`${t('The supplier')}`
            },
            {
                key: '2',
                FunctionName:`${t('Purchase Request')}`
            },
            {
                key: '3',
                FunctionName:`${t('Booking')}`
            },
            {
                key: '4',
                FunctionName:`${t('Reception')}`
            },
            {
                key: '5',
                FunctionName:`${t('Payment')}`
            },
            {
                key: '6',
                FunctionName: `${t('Return')}`
            },
            {
                key: '7',
                FunctionName: `${t('List of items')}`
            },
            {
                key: '8',
                FunctionName:`${t('Warehouse List')}`
            },
            {
                key: '9',
                FunctionName: `${t('Transfer Warehouse')}`
            },
            {
                key: '10',
                FunctionName: `${t('Inventory taking')}`
            },
            {
                key: '11',
                FunctionName:`${t('Purchase Dashboard')}`
            },
            {
                key: '12',
                FunctionName: `${t('Purchase Report')}`
            },
            
        ];
        const data5 = [
            {
                key: '1',
                FunctionName: `${t('List of Projects')}`
            },
            {
                key: '2',
                FunctionName: `${t('My job')}`
            },
            {
                key: '3',
                FunctionName: `${t('Role of Members')}`
            },
            {
                key: '4',
                FunctionName: `${t('Project Status')}`
            },
            {
                key: '5',
                FunctionName: `${t('Daily Report')}`
            },
        ];
        const data6 = [

        ];
        const data7 = [
            
        ];
        const data8 = [
            {
                key: '1',
                FunctionName: `${t('User Group')}`
            },
            {
                key: '2',
                FunctionName: `${t('The User')}`
            },
            {
                key: '3',
                FunctionName: `${t('Setting')}`
            },
        ];
        const data9 = [ 
            {
                key: '1',
                FunctionName: `${t('Production List')}`
            },
            {
                key: '2',
                FunctionName: `${t('Proof of Order of Goods')}`
            },
            {
                key: '3',
                FunctionName: `${t('Bill Of Material')}`
            },
        ];
        // const btn = [
        //     {
        //         HakAkses: 'Pengaturan'
        //     },
        // ]
        return(
            <Layout.Content>
                <PageHeader style={{border: '1px solid rgb(235,237,340)'}}>
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Change Group Name')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Setting')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/role">{t('User Group')} </a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="">{t('Change Group Name')} </a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                </PageHeader>

                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                <Divider type="horizontal"/>
                    <Row type="flex" >
                        <Col span={12}>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={10} ><Text style={{size:14, fontWeight:'bold'}}>{t('Group Name')}</Text></Col>
                                <Col span={14}><Input></Input> </Col>  
                            </Row>
                            
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={10} ><Text style={{size:14, fontWeight:'bold'}}>{t('Start Page (After Entering)')}</Text></Col>
                                <Col span={14}>
                                    <Select style={{width:'100%',fontSize:14}} placeholder= {t('Select')}>
                                            <Option value=""><Input></Input></Option>
                                    </Select>
                            </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>
                    <h3>{t('Function List')}</h3>
                    </div>
                    <Menu
                        mode="inline"
                        openKeys={this.state.openKeys}
                        onOpenChange={this.onOpenChange}
                        style={{ width: "100%" }}
                    >
                        <SubMenu style={{backgroundColor:'#cccc', marginTop:10}}
                        key="sub1"
                        title={
                            <span >{t('FINANCE & ACCOUNTING')}</span>
                        }
                        >
                            <div style={{margin:5, padding:20}}>
                                <Table columns={columns} dataSource={data}></Table>
                            </div>
                           
                        </SubMenu>

                        <SubMenu style={{backgroundColor:'#cccc', marginTop:10}}
                        key="sub2"
                        title={
                            <span>{t('HUMAN RESOURCE')}</span>
                        }
                        >
                            <div style={{margin:5, padding:20}}>
                                <Table columns={columns} dataSource={data2} ></Table>
                            </div>
                        </SubMenu>
                        
                        <SubMenu style={{backgroundColor:'#cccc', marginTop:10}}
                        key="sub3"
                        title={
                            <span>{t('SALES & DISTRIBUTION')}</span>
                        }
                        >
                            <div style={{margin:5, padding:20}}>
                                <Table columns={columns} dataSource={data3}></Table>
                            </div>
                        </SubMenu>

                        <SubMenu style={{backgroundColor:'#cccc', marginTop:10}}
                        key="sub4"
                        title={
                            <span>{t('PURCHASE & INVENTORY')}</span>
                        }
                        >
                            <div style={{margin:5, padding:20}}>
                                <Table columns={columns} dataSource={data4}></Table>
                            </div>
                        </SubMenu>

                        <SubMenu style={{backgroundColor:'#cccc', marginTop:10}}
                        key="sub5"
                        title={
                            <span>{t('PROJECTS & ASSIGNMENTS')}</span>
                        }
                        >
                            <div style={{margin:5, padding:20}}>
                                <Table columns={columns} dataSource={data5}></Table>
                            </div>
                        </SubMenu>

                        <SubMenu style={{backgroundColor:'#cccc', marginTop:10}}
                        key="sub6"
                        title={
                            <span>{t('CRM')}</span>
                        }
                        >
                            <div style={{margin:5, padding:20}}>
                                <Table columns={columns} dataSource={data6}></Table>
                            </div>
                        </SubMenu>

                        <SubMenu style={{backgroundColor:'#cccc', marginTop:10}}
                        key="sub7"
                        title={
                            <span>{t('HELPDESK & BOOKING')}</span>
                        }
                        >
                            <div style={{margin:5, padding:20}}>
                                <Table columns={columns} dataSource={data7}></Table>
                            </div>
                        </SubMenu>

                        <SubMenu style={{backgroundColor:'#cccc', marginTop:10}}
                        key="sub8"
                        title={
                            <span>{t('GENERAL SETTINGS')}</span>
                        }
                        >
                            <div style={{margin:5, padding:20}}>
                                <Table columns={columns} dataSource={data8}></Table>
                            </div>
                        </SubMenu>

                        <SubMenu style={{backgroundColor:'#cccc', marginTop:10}}
                        key="sub9"
                        title={
                            <span>{t('PRODUCTION SYSTEM')}</span>
                        }
                        >
                            <div style={{margin:5, padding:20}}>
                                <Table columns={columns} dataSource={data9}></Table>
                            </div>
                        </SubMenu>


                    </Menu>
                    <Row type="flex" style={{marginBottom:30}}>
                        <Col style={{marginTop:15,marginLeft:10,marginRight:10}}><Button href="/role" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                    </Row>
            </Layout.Content>
        )
    }
}
export default withTranslation() (Menu1)