import React from 'react'
import {Row, Col, Breadcrumb, Divider, Layout, PageHeader, Button, Icon, Select, Input, Table, Modal, message, Upload} from 'antd'
import { withTranslation } from 'react-i18next';

  
class WorkArea extends React.Component{
//Modal
    state = { visible: false };

    showModal = () => {
    this.setState({
        visible: true,
    });
    };
    handleOk = e => {
    console.log(e);
    this.setState({
        visible: false,
    });
    };
    handleCancel = e => {
    console.log(e);
    this.setState({
        visible: false,
    });
    };
//EndModal

    render(){
        const Option = Select
        const { t } = this.props
        const props = {
            name: 'file',
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            headers: {
              authorization: 'authorization-text',
            },
            onChange(info) {
              if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            },
          };
        const columns = [
            { 
              title: `${t("Name")}`,
              dataIndex: 'nama',
              key: 'nama',
            },
            {
              title: `${t("Description")}`,
              dataIndex: 'deskripsi',
              key: 'deskripsi',
            },
            {
              title: `${t("Parent")}`,
              dataIndex: 'induk',
              key: 'induk',
            },
            {
              title: `${t("Number of Employees")}`,
              dataIndex: 'jumlah',
              key: 'jumlah',
            }
        ]
        return(
            <Layout.Content>
                <Row style={{marginLeft:10, marginTop:20}} type="flex" justify="space-around">
                    <Col span={12}><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}>{t("Work Area")}</h1></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end" style={{fontWeight:'bold'}}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t("Personnel")}</Breadcrumb.Item>
                                    <Breadcrumb.Item>{t("Work Area")}</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                </Row>
                <Divider type="horizontal"></Divider>
                    <Row type="flex" justify="space-between">
                        <Col><Button href="/work-unit/create" style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold'}}><Icon type="plus" /> {t("Add Work Area")} </Button></Col>
                            <Col style={{marginLeft:680}}><Button type="primary" onClick={this.showModal} style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold'}}><Icon type="upload" /> Upload</Button></Col>
                            <Modal
                            title="Upload Data" 
                            visible={this.state.visible}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            >    
                                <Upload {...props}>
                                <Col style={{marginLeft:10}}><Button style={{fontWeight:'bold'}}>Browse</Button></Col>
                                </Upload>
                                <Col style={{marginLeft:10, marginTop:10}}><h3 style={{color:'#8c8c8c', fontSize:15}}>Belum punya Template import Data..?? Klik Link ini : <a href>Template Import</a></h3></Col>
                        </Modal>
                            <Col ><Button style={{backgroundColor:'#003a8c',color:'white',fontWeight:'bold'}}><Icon type="download" /> Download</Button></Col>
                    </Row>
                    <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
                        <Row type="flex" justify="space-between"> 
                                    <Col>
                                        <Row type="flex">
                                            <Col><h3 style={{marginRight:4, fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>Per</h3></Col>
                                                    <Select defaultValue="25" size='small'>
                                                        <Option value="25">25</Option>
                                                        <Option value="50">50</Option>
                                                        <Option value="100">100</Option>
                                                        <Option value="200">200</Option>
                                                        <Option value="All">All</Option>
                                                    </Select>
                                            <Col><h3 style={{marginLeft:4, fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>{t("Line")}</h3></Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row type="flex">
                                            <Col style={{marginRight:5, }}><h3 style={{ fontWeight:'bold', color:'#8c8c8c', fontSize:14}}>{t("Search")}</h3></Col>
                                            <Col><Input size='small'></Input></Col>
                                        </Row>
                                    </Col>
                        </Row>
                        <div style={{marginTop:20, border: '1px solid #ebedf0'}}>
                            <Table columns={columns}></Table>
                        </div>
                    </PageHeader>
            </Layout.Content>
        )
    }
}

export default withTranslation() (WorkArea)