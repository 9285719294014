import React, { Component } from 'react'
import { Row, Menu, Col, Steps, Icon, Layout, Card, Button, message, PageHeader, Form, Input, Upload, Typography, notification } from 'antd'
import { withTranslation } from 'react-i18next';
import { setLanguage } from '../stores/actions/languageActions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { validatePersonal } from '../stores/actions/authActions';

const { Step } = Steps
const { Header, Content } = Layout
const { SubMenu } = Menu
const { TextArea } = Input
const { Text } = Typography

const steps = [
    {
      title: 'Informasi Pribadi',
      content: (props) => {
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 8 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        }

        const { t, handleChange, error } = props
        const errors = error && error.data.errors

        return (
              <Row type="flex">
                  <Col span={24}>
                    <PageHeader
                        title="Step 1:"
                        subTitle="Isi data diri anda"
                    />
                    
                    <Form layout="horizontal">
                        <Row type="flex">
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label={t('Full Name')} required validateStatus={ errors && errors.full_name ? 'error' : ''} help={ errors && errors.full_name && errors.full_name[0]} >
                                    <Input placeholder={t('Full Name')} onChange={handleChange('full_name')} />
                                </Form.Item>
                                <Form.Item {...formItemLayout} label={t('Email')} required validateStatus={ errors && errors.email ? 'error' : ''} help={ errors && errors.email && errors.email[0]}>
                                    <Input placeholder={t('Email')} onChange={handleChange('email')} />
                                </Form.Item>
                                <Form.Item {...formItemLayout} label={t('Password')} required validateStatus={ errors && errors.password ? 'error' : ''} help={ errors && errors.password && errors.password[0]}>
                                    <Input placeholder={t('Password')} onChange={handleChange('password')} />
                                </Form.Item>
                                <Form.Item {...formItemLayout} label={t('Repeat Password')}>
                                    <Input placeholder={t('Repeat Password')} onChange={handleChange('password_confirmation')} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label={t('Phone Number')} >
                                    <Input placeholder={t('Phone Number')} onChange={handleChange('phone_number')} />
                                </Form.Item>

                                <Form.Item {...formItemLayout} label={t('Address')} >
                                    <TextArea rows={5} placeholder={t('Address')} onChange={handleChange('address')} />
                                </Form.Item>



                            </Col>
                        </Row>
                    </Form>

                  </Col>
              </Row>
          )
      }
    },
    {
      title: 'Informasi Perusahaan',
      content: (props) => {
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 8 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        }

        const { t, handleChange, beforeUpload, imageUrl, loadingUpload, onRemove, error } = props
        const errors = error && error.data.errors

        const uploadButton = (
            <div>
              {loadingUpload ? <Icon type="loading" /> : <Icon type="plus" />}
              <div className="ant-upload-text">Upload</div>
            </div>
         )
        

        return (
              <Row type="flex">
                  <Col span={24}>
                    <PageHeader
                        title="Step 2:"
                        subTitle="Isi data perusahaan anda"
                    />
                    
                    <Form layout="horizontal">
                        <Row type="flex">
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label={t('Company Name')} required validateStatus={ errors && errors.full_name ? 'error' : ''} help={ errors && errors.full_name && errors.full_name[0]} >
                                    <Input placeholder={t('Company Name')} onChange={handleChange('company_name')} />
                                </Form.Item>
                                <Form.Item {...formItemLayout} label={t('Industry Type')} required validateStatus={ errors && errors.email ? 'error' : ''} help={ errors && errors.email && errors.email[0]}>
                                    <Input placeholder={t('Industry Type')} onChange={handleChange('industry')} />
                                </Form.Item>
                                <Form.Item {...formItemLayout} label={t('Company Email')} required validateStatus={ errors && errors.password ? 'error' : ''} help={ errors && errors.password && errors.password[0]}>
                                    <Input placeholder={t('Company Email')} onChange={handleChange('company_email')} />
                                </Form.Item>
                                <Form.Item {...formItemLayout} label={t('Company Phone Number')} >
                                    <Input placeholder={t('Company Phone Number')} onChange={handleChange('company_phone_number')} />
                                </Form.Item>
                                <Form.Item {...formItemLayout} label={t('Company Fax Number')} >
                                    <Input placeholder={t('Company Fax Number')} onChange={handleChange('company_fax_number')} />
                                </Form.Item>
                                <Form.Item {...formItemLayout} label={t('Company Website')} >
                                    <Input placeholder={t('Company Website')} onChange={handleChange('company_website')} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                
                                <Form.Item {...formItemLayout} label={t('Logo')}>

                                    {
                                        imageUrl && <Button type="danger" size="small" className="mt-10 button-absolute" onClick={onRemove}><Icon type="delete" /></Button>
                                    }

                                    <Upload
                                        beforeUpload={beforeUpload}
                                        onRemove={onRemove}
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        multiple={false}
                                    >
                                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                    </Upload>
                                </Form.Item>

                                <Form.Item {...formItemLayout} label={t('Currency')} >
                                    <Input placeholder={t('Currency')} onChange={handleChange('currency')} />
                                </Form.Item>


                                <Form.Item {...formItemLayout} label={t('Company Address')} >
                                    <TextArea rows="5" placeholder={t('Company Address')} onChange={handleChange('Company Address')} />
                                </Form.Item>
                                
                                <Form.Item {...formItemLayout} label={t('Correspondense Address ')} >
                                    <TextArea rows="5" placeholder={t('Correspondense Address')} onChange={handleChange('Correspondense Address')} />
                                </Form.Item>

                            </Col>
                        </Row>
                    </Form>

                  </Col>
              </Row>
          )
        }
    },
    {
      title: 'Pilih Paket',
      content: (props) => {
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 8 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        }

        const { t, handleChange } = props

        return (
              <Row type="flex">
                  <Col span={24}>
                    <PageHeader
                        title="Step 3:"
                        subTitle="Pilih Paket anda"
                    />
                    
                    <Row type="flex" justify="center">
                        <Col className="text-center">
                            <h2>Chosen Package: <Text type="primary">Personalia, Warehouse</Text></h2>
                            <p><Text type="secondary">*) You can choose more than one</Text></p>
                            <p><Text type="secondary">Total Purchase: <Text type="primary" strong level={2}>IDR 5,000,000</Text></Text></p>
                        </Col>
                    </Row>

                    <Row type="flex" justify="space-around" >

                        <Col span={7} className="text-center package">
                            <h1>Personalia</h1>
                            <h2>IDR 1,500,000</h2>
                            <p>Per month</p>
                            <p>Feature</p>
                            <ul>
                                <li>Finance &amp; Accounting</li>
                                <li>Human Resources</li>
                                <li>Task &amp; Project</li>
                                <li>Settings</li>
                            </ul>
                            <p>Employee</p>
                            <span>Unlimited</span>
                            <p>Company</p>
                            <span>Unlimited</span>
                            <p>User</p>
                            <span>10 User</span>
                            <p>Addition User</p>
                            <span>IDR 59K / User / Month</span>
                            <p>Free Updates</p>
                            <p>Without Support</p>
                            <Button type="primary" className="mt-20">Choose</Button>
                        </Col>

                        <Col span={7} className="text-center package">
                            <h1>Warehouse</h1>
                            <h2>IDR 1,500,000</h2>
                            <p>Per month</p>
                            <p>Feature</p>
                            <ul>
                                <li>Warehouse &amp; Inventory</li>
                                <li>Sales &amp; Distribution</li>
                                <li>Production System</li>
                                <li>Settings</li>
                            </ul>
                            <p>Employee</p>
                            <span>Unlimited</span>
                            <p>Company</p>
                            <span>Unlimited</span>
                            <p>User</p>
                            <span>10 User</span>
                            <p>Addition User</p>
                            <span>IDR 59K / User / Month</span>
                            <p>Free Updates</p>
                            <p>24x7</p>
                            <Button type="primary" className="mt-20">Choose</Button>
                        </Col>

                        <Col span={7} className="text-center package">
                            <h1>Others</h1>
                            <h2>IDR 2,500,000</h2>
                            <p>Per month</p>
                            <p>Feature</p>
                            <ul>
                                <li>Finance &amp; Accounting</li>
                                <li>Human Resources</li>
                                <li>Production System</li>
                                <li>Settings</li>
                            </ul>
                            <p>Employee</p>
                            <span>Unlimited</span>
                            <p>Company</p>
                            <span>Unlimited</span>
                            <p>User</p>
                            <span>10 User</span>
                            <p>Addition User</p>
                            <span>IDR 59K / User / Month</span>
                            <p>Free Updates</p>
                            <p>24x7 Support</p>
                            <Button type="primary" className="mt-20">Choose</Button>
                        </Col>

                    </Row>
                    

                  </Col>
              </Row>
          )
        }
    }
]

export class Register extends Component {

    state = {
        imageUrl: null,
        loadingUpload: false
    }

    next = () => {
        
        if (this.props.current === 0) {
            this.props.validatePersonal(this.state)
        }

    }
    
    prev = () => {
        this.props.prev(this.props.current)
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.validPersonal !== this.validPersonal){

            if (this.props.validPersonal) {

                
                
            } else {

                if (prevProps.message !== this.props.message) {
                    message.error(this.props.message)
                }

            }
        }
    }


    onRemoveUpload = () => {
        this.setState({
            ...this.state,
            imageUrl: null
        })
    }
      
    beforeUpload = (file)  => {
        
        this.setState({
            ...this.state,
            imageUrl: URL.createObjectURL(file)
        })

        return false;

    }

    changeLang = (lang) => {
        const { i18n } = this.props;
        i18n.changeLanguage(lang);
        this.props.setLanguage(lang)
    }

    handleChange = (name) => (e) => {

        this.setState({
            ...this.state,
            [name]: e.target.value
        })

    }

    render() {

        const { t, fetching, current } = this.props
        const { imageUrl, loadingUpload } = this.state
        
        return (

            <Content style={{ backgroundColor: '#f3f8ff', minHeight: '100vh' }}>
                <Header style={{ backgroundColor: '#f3f8ff' }} className="pt-10">
                    <Menu mode="horizontal" style={{ backgroundColor: '#f3f8ff', border: 'none' }}>
                        <Menu.Item key="logo" className="menu-logo">
                            <Link to="/">
                                <img src={require('../assets/img/logo3.png')} width="150px"  />
                            </Link>
                        </Menu.Item>
                        <SubMenu
                            style={{ float: 'right' }}
                            title={
                                <span className="submenu-title-wrapper">
                                    { !sessionStorage.getItem('language') ? 'English' : sessionStorage.getItem('language') }
                                </span>
                            }
                            >
                            <Menu.Item key="setting:1"  onClick={() => this.changeLang('en')}>English</Menu.Item>
                            <Menu.Item key="setting:2" onClick={() => this.changeLang('id')}>Bahasa Indonesia</Menu.Item>
                        </SubMenu>
                    </Menu>
                </Header>
                <Row type="flex" justify="center" className="mt-25">
                    <Col span={20}  className="mb-25">
                        <Card>
                            <Steps current={current}>
                                {steps.map(item => (
                                    <Step key={item.title} title={item.title} />
                                ))}
                            </Steps>
                            <div className="steps-content">
                                
                                {
                                    steps[current].content({...this.props, handleChange: this.handleChange, handleUpload: this.handleUpload, loadingUpload, imageUrl, onRemove: this.onRemoveUpload, fileList: this.state.imageUrl, beforeUpload:  this.beforeUpload })
                                }

                            </div>

                            <Row className="mt-25" justify="end" type="flex">
                                <Col>
                                    {current > 0 && (
                                        <Button className="mr-10" onClick={() => this.prev()}>
                                        Previous
                                        </Button>
                                    )}

                                    {current < steps.length - 1 && (
                                        <Button type="primary" onClick={() => this.next()} loading={fetching}>
                                        Next
                                        </Button>
                                    )}
                                    {current === steps.length - 1 && (
                                        <Button type="primary" onClick={() => message.success('Processing complete!')}>
                                        Done
                                        </Button>
                                    )}
                                </Col>
                            </Row>

                        </Card>
                    </Col>
                </Row>
            </Content>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        lang: state.lang.lang,
        language: state.lang.language,
        fetching: state.validate.fetching,
        fetched: state.validate.fetched,
        error: state.validate.error,
        validPersonal: state.validate.validPersonal,
        message: state.validate.message,
        current: state.validate.current,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLanguage: (lang) => dispatch(setLanguage(lang)),
        validatePersonal: (data) => dispatch(validatePersonal(data)),
        // prev: (current) => dispatch(prev(current))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Register))
