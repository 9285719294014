import React, { Component } from 'react'
import { Layout, 
    Row, 
    Col, 
    Breadcrumb, 
    Typography, 
    PageHeader, 
    Button, 
    Icon, 
    Select, 
    Input, 
    Divider, 
    Table,
    Modal,
    Upload,
    message,
    visible } from 'antd'
import { withTranslation } from 'react-i18next'

class AddClass extends React.Component {
    render(){
        const {Title, Text} = Typography
        const {Option} = Select
        const { t } = this.props
        const {TextArea} = Input
        const columns = [
            {
              title: `${t('Step (Working Period)')}`,
              dataIndex: 'Step (Working Period)',
              key: 'Step (Working Period)',
            },
            {
              title: `${t('Great Main Salary')}`,
              dataIndex: 'Great Main Salary',
              key: 'Great Main Salary',
            },
          ];
        return(
            <Layout.Content>
            <PageHeader style={{border: '1px solid rgb(235,237,340)'}}>
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Create Class')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Personnel')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/group">{t('Class & Basic Salary')} </a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/group/create">{t('Create Class')} </a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            
                            </Row>
                        </Col>
                    </Row>
                </div>
            </PageHeader>
                <div  style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                    <Row>
                        <Col span={12}>
                            <Row type="flex" style={{marginTop:10}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Group Name')}</label></Col>
                                <Col span={15}><Input rows={4} style={{width:'100%'}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Alamat Pemasok')}</label></Col>
                                <Col span={15}><TextArea rows={4} style={{width:'100%'}} /></Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <Divider type="horizontal"/>
                            <Text strong> {t('Basic salary')}</Text> <br />
                            <Text type="secondary"> {t('The amount of Gapok can be classified based on Step / Period of Work"')} </Text>
                        </Col>
                    </Row>

                    <Col span={12}>
                        <div style={{margin:5, padding:10}}>
                            <Table columns={columns}></Table>
                        </div>
                        <Row type='flex'justify='space-between'>
                        <Button href="" type="primary" style={{margin:10, backgroundColor:"#9966ff", color:"white"}}>
                            <Icon type="plus"/>{t('Add Line')}</Button>
                    </Row>
                    </Col>
                    </div>

                    <Divider type="horizontal"/>
                    <Row type="flex" style={{marginBottom:30}}>
                        <Col style={{marginTop:15,marginLeft:10,marginRight:10}}><Button href="/group" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                        <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Save Group')} </Button></Col>
                    </Row>
                    

            </Layout.Content>

        )
    }
}

export default withTranslation() (AddClass)