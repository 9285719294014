import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Layout, Row, Col, Divider, Typography, Select, Breadcrumb, Input, Checkbox, Radio, Button, DatePicker } from 'antd'

class EvaluationCreate extends Component{
    render(){
        const {Title} = Typography
        const {t} = this.props
        const {Option} = Select
        const {TextArea} = Input
        const dateFormat ='DD/MM/YYYY'
        return(
            <Layout.Content>
                <div style={{padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Create Question')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Training')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/course">{t('Evaluation Question')}</a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/course">{t('Create Question')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider type="horizontal" style={{marginTop:0}} />
                <Row type="flex">
                    <Col span={20}>
                        <Row type="flex">
                            <Col span={5}>{t('Course')}</Col>
                            <Col span={18}>
                                <Select placeholder="Pilih" style={{width:'100%'}}>
                                    <Option value="none">{t('No Result Found')}</Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row type="flex" style={{marginTop:15}}>
                            <Col span={5}>{t('Question Text')}</Col>
                            <Col span={18}><TextArea rows={4} /></Col>
                        </Row>
                        <Row type="flex" style={{marginTop:15}}>
                            <Col span={5}>{t('Score')}</Col>
                            <Col span={18}><Input /></Col>
                        </Row>
                        <Row type="flex" style={{marginTop:15}}>
                            <Col span={5}>{t('Is Active')}</Col>
                            <Col span={18}><Checkbox /></Col>
                        </Row>
                        <Row type="flex" style={{marginTop:15}}>
                            <Col span={5}>{t('A')}</Col>
                            <Col span={18}><Input /></Col>
                        </Row>
                        <Row type="flex" style={{marginTop:15}}>
                            <Col span={5}>{t('B')}</Col>
                            <Col span={18}><Input /></Col>
                        </Row>
                        <Row type="flex" style={{marginTop:15}}>
                            <Col span={5}>{t('C')}</Col>
                            <Col span={18}><Input /></Col>
                        </Row>
                        <Row type="flex" style={{marginTop:15}}>
                            <Col span={5}>{t('D')}</Col>
                            <Col span={18}><Input /></Col>
                        </Row>
                    </Col>
                    <Col span={4}>
                        <Row  style={{marginTop:245}}>
                            <Radio.Group >
                                <Col><Radio value={1}></Radio></Col>
                                <Col style={{marginTop:25}}><Radio value={2}></Radio></Col>
                                <Col style={{marginTop:25}}><Radio value={3}></Radio></Col>
                                <Col style={{marginTop:25}}><Radio value={4}></Radio></Col>
                            </Radio.Group>
                        </Row>
                    </Col>
                </Row>
                <Row style={{marginTop:15}}>
                    <Button style={{backgroundColor:'#ffc53d',color:'white',marginRight:10,width:100}}>{t('Cancel')}</Button>
                    <Button style={{backgroundColor:'#096dd9',color:'white',width:100}}>{t('Save')}</Button>
                </Row>
                
            </Layout.Content>
        )
    }
}
export default withTranslation()(EvaluationCreate)