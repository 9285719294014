import React from 'react'
import {Row, Col, Icon, Breadcrumb, Layout, PageHeader, Button, DatePicker, Select, Input, Divider, Table} from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next';

class AddReturnTrans extends React.Component{
    render(){
    const Option = Select
    const dateFormat = 'DD/MM/YYYY'
    const {TextArea} = Input
    const { t } = this.props
    const columns = [
        { 
          title: `${t("Product")}`,
          dataIndex: 'produk',
          key: 'produk',
        },
        {
          title: `${t("Description")}`,
          dataIndex: 'deskripsi',
          key: 'deskripsi',
        },
        {
          title: `${t("Amount Returned")}`,
          dataIndex: 'dikembalikan',
          key: 'dikembalikan',
        },
        {
          title: `${t("Amount Received")}`,
          dataIndex: 'diterima',
          key: 'diterima',
        },
        {
          title: `${t("Amount of Received")}`,
          dataIndex: 'terima',
          key: 'terima',
        },
        {
          title: `${t("Unit")}`,
          dataIndex: 'satuan',
          key: 'satuan',
        },
        {
          title: `${t("Price per Unit")}`,
          dataIndex: 'harga',
          key: 'harga',
        },
        {
          title: `${t("Amount")}`,
          dataIndex: 'jumlah',
          key: 'jumlah',
        },
      ];
        return(
            <Layout.Content>
                <Row style={{marginLeft:10, marginTop:20}} type="flex" justify="space-around">
                    <Col span={12}><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}>{t("Add Sales Return")}</h1></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end" style={{fontWeight:'bold'}}>
                                <Breadcrumb>
                                    <Breadcrumb.Item><a href="">{t("Selling")}</a></Breadcrumb.Item>
                                    <Breadcrumb.Item><a href="/sales-return">{t("Sales Return")}</a></Breadcrumb.Item>
                                    <Breadcrumb.Item><a href="">{t("Add Sales Return")}</a></Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                </Row>
            <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
                    <Row>
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("Delivery Number")}</label></Col>
                                <Col span={14}><Select placeholder={t("Choose")} style={{width:'100%'}}>
                                                    <Option value="01">GR2018050001</Option>
                                                </Select></Col>                                
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("Customer")}</label></Col>
                                <Col span={14}><Input type="disabled" disabled /></Col>                                
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("Address")}</label></Col>
                                <Col span={14}><TextArea></TextArea></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("To")}</label></Col>
                                <Col span={14}><Input/></Col>                                
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8}><Divider/></Col>
                                <Col span={14}><Divider/></Col>                                 
                            </Row>
                        </Col>   
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={9} style={{fontWeight:'bold', marginLeft:20}}><label>{t("Return Number")}</label></Col>
                                <Col span={14}><Input type="disabled" disabled></Input></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={9} style={{fontWeight:'bold', marginLeft:20}}><label>{t("Referencenum")}</label></Col>
                                <Col span={14}><Input type="disabled" disabled /></Col>                                
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={9} style={{fontWeight:'bold', marginLeft:20}}><label>{t("Transdate")}</label></Col>
                                <Col span={14}> <div>
                                                    <DatePicker defaultValue={moment('20/02/2020', dateFormat)} style={{width:280}} format={dateFormat} />
                                                </div></Col>
                            </Row>

                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={9} style={{fontWeight:'bold', marginLeft:20}}><label>{t("Validuntil")}</label></Col>
                                <Col span={14}> <div>
                                                    <DatePicker defaultValue={moment('21/03/2020', dateFormat)} style={{width:280}} format={dateFormat} />
                                                </div></Col>
                            </Row>
                        </Col> 
                    </Row>
                    <Col>
                        <Divider type="horizontal"/>
                    </Col>
                    <h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}>{t("Product")}</h1>
                    <div style={{marginTop:20, border: '1px solid #ebedf0'}}>
                            <Table columns={columns}></Table>
                    </div>
                    <Col span={12}>
                            <Divider/>
                            <Col>
                                <Row>
            
                                    <Col><TextArea placeholder={t("Message")} rows={4} style={{width:'50%'}} /></Col>
                                </Row>
                            </Col>
                        </Col>
                        
                        <Col span={12}>
                        <Divider/>
                            <Row type="flex" justify="end"style={{marginTop:20}} >
                                <Col span={5} style={{fontWeight:'bold'}}><label>Sub Total</label></Col>
                                <Col span={10}><Input/></Col>
                            </Row>

                            <Row type="flex" justify="end"style={{marginTop:10}} >
                                <Col span={5} style={{fontWeight:'bold'}}><label>{t("Tax")}</label></Col>
                                <Col span={10}>
                                    <Select placeholder={t("Choose")} style={{width:'100%'}}>
                                        <Option value="10">PPN 10%</Option>
                                    </Select>
                                </Col>                
                            </Row>

                            <Row type="flex" justify="end"style={{marginTop:10}} >
                                <Col span={5} style={{fontWeight:'bold'}}><label>Total</label></Col>
                                <Col span={10}><Input/></Col>
                            </Row>
                        </Col> 
            </PageHeader>
                <Row type="flex" style={{marginBottom:30}}>
                    <Col style={{marginTop:15,marginRight:10}}><Button href="/sales-return" style={{backgroundColor:'#545961',color:'white',fontWeight:'bold'}}>{t("Back")}</Button></Col>
                    <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t("Add Sales Return")} </Button></Col>
                </Row>
            </Layout.Content>
        )
    }
}

export default withTranslation() (AddReturnTrans)