import React from 'react'
import { Layout, 
         Row, 
         Col, 
         Breadcrumb, 
         Typography, 
         PageHeader, 
         DatePicker,
         Button, 
         Icon, 
         Select, 
         Input, 
         Divider, 
         Table, 
         Modal,
         Upload,
         message,
         visible } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next';


class Order extends React.Component {
    render (){
        const {Title, Text} = Typography
        const dateFormat =' DD/MM/YYYY'
        const { t } = this.props;
        const {Option} = Select
        const columns = [
            {
              title: `${t('Date')}`,
              dataIndex: 'Date',
              key: 'Date',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('Number')}`,
              dataIndex: 'Number',
              key: 'Number',
            },
            {
              title: `${t('Customers Name')}`,
              dataIndex: 'Customers Name',
              key: 'Customers Name',
            },
            {
                title: `${t('Title')}`,
                dataIndex: 'Title',
                key: 'Title',
            },
            {
                title: `${t('Amount')}`,
                dataIndex: 'Amount',
                key: 'Amount',
            },
            {
                title: `${t('Status')}`,
                dataIndex: 'Status',
                key: 'Status',
            },
            
          ];
        return (
            <Layout.Content>
                <PageHeader style={{border: '1px solid rgb(235,237,340)'}}>
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Sales Orders')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Sales')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="">{t('Sales Orders')} </a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                </PageHeader>

                <div style={{padding:20}}>
                <Row type='flex'justify='space-between'>
                
                <Button href="/sales-order/create" style={{margin:10, backgroundColor:"#505458", color:"white"}}><Icon type="plus"/>{t('Create Order')}</Button>
                <Col span={15} >
                    <DatePicker  style={{width:'25%', marginLeft:100}} defaultValue={moment('1/2/2020',dateFormat)} format={dateFormat} />
                    <DatePicker style={{width:'25%', marginLeft:10}} defaultValue={moment('1/3/2020',dateFormat)} format={dateFormat} />
                    <Select style={{width:'25%',margin:10}} placeholder={t('All Statuses')}>
                    <Option value="Open">{t('Open')}</Option>
                    <Option value="Delivery Partial">{t('Delivery Partial')}</Option>
                    <Option value="Canceled">{t('Canceled')}</Option>
                  </Select>
                    </Col>
                <Button href="" style={{margin:10, backgroundColor:"#505458", color:"white", width:100}}>Filter</Button>
                
                </Row>
                </div>

                <Row type="flex" justify="space-between" style={{padding:10, margin:10}}>
                    <Col > 
                        <Row type="flex">
                            <Col><h3>Per</h3></Col>
                            <Select defaultValue="25" >
                                <Option value="25">25</Option>
                                <Option value="50">50</Option>
                                <Option value="100">100</Option>
                                <Option value="200">200</Option>
                                <Option value="All">{t('All')}</Option>
                            </Select>
                            <Col><h3>{t('Line')}</h3></Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row type="flex">
                        <Col span={5} style={{marginRight:10}}><h3>{t('Search')}</h3></Col>
                        <Col span={15}><Input></Input></Col>
                        </Row>
                    </Col>
                </Row>
                <div style={{margin:5, padding:20}}>
                    <Table columns={columns}></Table>
                </div>
                
            </Layout.Content>
        )
    }
}

export default withTranslation() (Order)