import React, { Component } from 'react'
import { Layout, 
    Row, 
    Col, 
    Breadcrumb, 
    Typography, 
    PageHeader, 
    Button, 
    Select, 
    Table,
    Input, 
    Divider, 
    DatePicker
    } from 'antd'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

class AddRekonsiliasi extends React.Component {
    render(){
        const {Title, Text} = Typography
        const {Option} = Select
        const { t } = this.props
        const dateFormat =' DD/MM/YYYY'
        const {TextArea} = Input
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
              console.log(record, selected, selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              console.log(selected, selectedRows, changeRows);
            },
          };
        const columns = [
            {
                title: `${t('Saldo Awal Buku')}`,
                dataIndex: 'Saldo Awal Buku',
                key: 'Saldo Awal Buku',
            },
            {
                title: `${t('Transaction date')}`,
                dataIndex: 'Transaction date',
                key: 'Transaction date',
                
            },
            {
                title: `${t('Credit')}`,
                dataIndex: 'Credit',
                key: 'Credit',
              },
          ];
        return(
            <Layout.Content>
            <PageHeader style={{border: '1px solid rgb(235,237,340)'}}>
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Create Class')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Accounting')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/reconcile">{t('Bank of Reconciliation')} </a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/reconcile/create">{t('Create a Bank Reconciliation')} </a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            
                            </Row>
                        </Col>
                    </Row>
                </div>
            </PageHeader>
                <div  style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                    <Row>
                        <Col span={12}>
                            <Row type="flex" style={{marginTop:10}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Reconciled Account')}</label></Col>
                                <Col span={15}>
                                    <Select style={{width:'100%',fontSize:16}} placeholder= {t('Select')}>
                                        <Option value=""><Input></Input></Option>
                                        <Option value="1-1200 - Bank" >1-1200 - Bank</Option>
                                        <Option value="1-1100 - Kas" >1-1100 - Kas</Option>
                                        <Option value="1-1100 - Kas" >1-1120 - Kas Bank POS</Option>
                                        <Option value="1-1100 - Kas" >1-1110 - Kas POS</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Last Reconciliation Date')}</label></Col>
                                <Col span={15}><Input style={{width:'100%' }} readOnly placeholder='-' /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Todays Reconciliation Date')}</label></Col>
                                <Col span={15}><DatePicker style={{width:'100%'}} defaultValue={moment('1/2/2020',dateFormat)} format={dateFormat} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Current Account Balance')}</label></Col>
                                <Col span={15}><Input rows={4} style={{width:'100%'}} placeholder='0.00'/></Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <Col>
                                    <PageHeader style={{border:2}}>
                                        <h3>{t('Beginning Book Balance')}</h3>
                                        <h4>Rp.0</h4><br></br>
                                        <Row type="flex"><Col span={12}><h3>{t('Total Debit')}</h3></Col><Col span={12}>Rp.0,00</Col></Row>
                                        <Row type="flex"><Col span={12}><h3>{t('Total Credit')}</h3></Col><Col span={12}>Rp.0,00</Col></Row>
                                        <Row type="flex"><Col span={12}><h3>{t('Final Balance Count')}</h3></Col><Col span={12}>Rp.0,00</Col></Row>
                                        <Row type="flex"><Col span={12}><h3>{t('Final Balance Target')}</h3></Col><Col span={12}>Rp.0,00</Col></Row>
                                        <Row type="flex"><Col span={12}><h3>{t('Difference')}</h3></Col><Col span={12}>Rp.0,00</Col></Row>
                                    <Text></Text>
                                    </PageHeader>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    

                    <Col>
                        <div style={{margin:5, padding:10}}>
                            <Table columns={columns} rowSelection={rowSelection}></Table>
                        </div>
                    </Col>
                    </div>

                    <Divider type="horizontal"/>
                    <Row type="flex" style={{marginBottom:30}}>
                        <Col style={{marginTop:15,marginLeft:10,marginRight:10}}><Button href="" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Cancel')}</Button></Col>
                        <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Save Group')} </Button></Col>
                    </Row>
                    

            </Layout.Content>

        )
    }
}

export default withTranslation() (AddRekonsiliasi)