import React, { Component } from 'react'

class DashboardSdm extends Component{
    render(){
        return(
            <div>
                Skip => Grafik char
            </div>
        )
    }
}
export default DashboardSdm