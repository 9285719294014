import React, { Component } from 'react'
import { Layout, Row, Col, Breadcrumb, Typography, Card, Divider, Input, Select, Button } from 'antd'
import { withTranslation } from 'react-i18next'

class SuplierCreate extends Component{
    render(){
        const {Title} = Typography
        const {TextArea} = Input
        const Option = Select
        const { t } = this.props
        return(
            <Layout.Content>
                <div style={{margin:10,padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Suplier')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Another Data')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <a href="/supplier">{t('Suplier')}</a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <a href="/supplier/create">{t('Create Suplier')}</a>
                                    </Breadcrumb.Item>
                                   
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider  type="horizontal" style={{marginTop:-10}} />
                <Card style={{margin:10,padding:20,borderWidth:2,borderRadius:10}}>
                    <Row type="flex">
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={8}><h4 style={{fontWeight:'bold'}}>{t('Code')}</h4></Col>
                                <Col span={15}><Input /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}><h4 style={{fontWeight:'bold'}}>{t('Name')}</h4></Col>
                                <Col span={15}><Input /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}><h4 style={{fontWeight:'bold'}}>{t('Shipping Address')}</h4></Col>
                                <Col span={15}><TextArea rows={4} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}><h4 style={{fontWeight:'bold'}}>{t('Billing Address')}</h4></Col>
                                <Col span={15}><TextArea rows={4} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}><h4 style={{fontWeight:'bold'}}>{t('City')}</h4></Col>
                                <Col span={15}><Input /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}><h4 style={{fontWeight:'bold'}}>{t('The Province')}</h4></Col>
                                <Col span={15}><Input /></Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row type="flex" >
                                <Col span={8}><h4 style={{fontWeight:'bold'}}>{t('Pos Code')}</h4></Col>
                                <Col span={15}><Input /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}><h4 style={{fontWeight:'bold'}}>{t('Country')}</h4></Col>
                                <Col span={15}><Input /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}><h4 style={{fontWeight:'bold'}}>{t('Mobile')}</h4></Col>
                                <Col span={15}><Input /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}><h4 style={{fontWeight:'bold'}}>{t('Telephone')}</h4></Col>
                                <Col span={15}><Input /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}><h4 style={{fontWeight:'bold'}}>{t('Fax')}</h4></Col>
                                <Col span={15}><Input /></Col>
                            </Row>
                            <Divider type="horizontal"/>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}><h4 style={{fontWeight:'bold'}}>{t('NPWP Number')}</h4></Col>
                                <Col span={15}><Input /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}><h4 style={{fontWeight:'bold'}}>{t('NPWP Address')}</h4></Col>
                                <Col span={15}><TextArea rows={4} /></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>
                    <Row type="flex">
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={8}><h4 style={{fontWeight:'bold'}}>{t('Account Receivable')}</h4></Col>
                                <Col span={15}><Select style={{width:'100%',fontSize:16}} defaultValue="1-0000 - Asset">
                                        <Option value="1-0000 - Asset" >1-0000 - Asset</Option>
                                        <Option value="1-1000 - Harga Lancar" >1-1000 - Harga Lancar</Option>
                                        <Option value="1-2000 - Harga Tetap" >1-2000 - Harga Tetap</Option>
                                        <Option value="1-1100 - Kas" >1-1100 - Kas</Option>
                                        <Option value="1-1110 - Kas POS" >1-1110 - Kas POS</Option>
                                        <Option value="1-1120 - Kas Bank" >1-1120 - Kas Bank</Option>
                                        <Option value="1-1200 - Bank" >1-1200 - Bank</Option>
                                        <Option value="1-1300 - Piutang" >1-1300 - Piutang</Option>
                                        <Option value="1-1400 - PPN Masukan" >1-1400 - PPN Masukan</Option>
                                        <Option value="1-1500 - Persedian Barang" >1-1500 - Persedian Barang</Option>
                                        <Option value="1-2100 - Peralatan Kantor" >1-2100 - Peralatan Kantor</Option>
                                        <Option value="1-2200 - Akumulasi Penyusuatan Peralatan " >1-2200 - Akumulasi Penyusuatan Peralatan </Option>
                                        <Option value="1-2300 - Sewa Dibayar dimuka" >1-2300 - Sewa Dibayar dimuka</Option>
                                    </Select></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}><h4 style={{fontWeight:'bold'}}>{t('Debt Account')}</h4></Col>
                                <Col span={15}><Select style={{width:'100%',fontSize:16}} defaultValue="2-0000 - Hutang">
                                        <Option value="2-0000 - Hutang" >2-0000 - Hutang</Option>
                                        <Option value="2-1000 - Hutang Usaha" >2-1000 - Hutang Usaha</Option>
                                        <Option value="2-1100 - Hutang Gaji Karyawan" >2-1100 - Hutang Gaji Karyawan</Option>
                                        <Option value="2-1200 - Hutang Lainnya" >2-1200 - Hutang Lainnya</Option>
                                        <Option value="2-2000 - PPN Keluaran" >2-2000 - PPN Keluaran</Option>
                                        <Option value="2-2100 - PPN Pasal 21" >2-2100 - PPN Pasal 21</Option>
                                        <Option value="2-2100 - PPN Pasal 23" >2-2100 - PPN Pasal 23</Option>
                                    </Select></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col span={8}><h4 style={{fontWeight:'bold'}}>{t('Term of Payment')}</h4></Col>
                                <Col span={15}><Select style={{width:'100%',fontSize:16}} defaultValue="Cash On Delivery">
                                        <Option value="Cash On Delivery" >Cash On Delivery</Option>
                                        <Option value="Net 15" >Net 15</Option>
                                        <Option value="Net 30" >Net 30</Option>
                                        <Option value="Net 60" >Net 60</Option>
                                    </Select></Col>
                            </Row>
                            
                            
                        </Col>
                        <Col span={12}>
                            <Row type="flex" >
                                <Col span={8}><h4 style={{fontWeight:'bold'}}>{t('Contact Person')}</h4></Col>
                                <Col span={15}><Input /></Col>
                            </Row>
                            
                            
                        </Col>
                    </Row>
                </Card>
                <Row type="flex" style={{marginTop:10,marginLeft:20}}>
                    <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                    <Col style={{marginTop:15}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Create Suplier')}</Button></Col>
                </Row>
            </Layout.Content>
            
        )
    }
}
export default withTranslation()(SuplierCreate) 