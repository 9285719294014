import React, { Component } from 'react'
import { Row, Typography, Col, Breadcrumb, Layout, Divider, Input, Select, DatePicker, PageHeader, Button, Icon, Table } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

class AddStock extends Component{
    render(){
        const {Title} = Typography
        const Option = Select
        const dateFormat ='DD/MM/YYYY'
        const {TextArea} = Input
        const { t } = this.props;
        const columns = [
            {
              title: `${t('Product')}`,
              dataIndex: 'Produk',
              key: 'Produk',
            },
            {
              title: `${t('Description')}`,
              dataIndex: 'Deskripsi',
              key: 'Deskripsi',
            },
            {
              title: `${t('Quantity')}`,
              dataIndex: 'Kuantitas',
              key: 'Kuantitas',
            },
            {
                title: `${t('Unit')}`,
                dataIndex: 'Satuan',
                key: 'Satuan',
            },
            {
                title: `${t('Unit Price')}`,
                dataIndex: 'Harga_Satuan',
                key: 'Harga_Satuan',
            },
            {
                title: `${t('Total')}`,
                dataIndex: 'Jumlah',
                key: 'Jumlah',
            },
            {
                title: `${t('Tax')}`,
                dataIndex: 'Pajak',
                key: 'Pajak',
            },
          ];
        return(
            <Layout.Content>
            <PageHeader style={{ border: '1px solid #ebedf0'}}>
                <div>
                    <Row type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Make a Request')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Purchase')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/purchase-request">{t('Request')}</a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/purchase-request/Create">{t('Make a Request')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                </PageHeader>
                <PageHeader style={{ border: '1px solid #ebedf0'}}>
                    <Divider type="horizontal" style={{margin:-10}}/>
                    <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                        <Row>
                            <Col span={12}>
                                <Row type="flex">
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Supplier')}</label></Col>
                                    <Col span={15} >  <Select placeholder={t('Select')} style={{width:'100%'}}>
                                            <Option value="hwi">(HWI) - PT.HOWA INDONESIA</Option>
                                            <Option value="psi">(PSI) - PT. POSMI STEEL INDONESIA</Option>
                                            <Option value="sp">(SP-001-AAA) - PT. APM ARMADA AUTOPARTS</Option>
                                        </Select>
                                    </Col>     
                                </Row>
                                <Row type="flex" style={{marginTop:20}}>
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Billing Address')}</label></Col>
                                    <Col span={15}><TextArea rows={4} style={{width:'100%'}} /></Col>
                                </Row>
                                <Row type="flex" style={{marginTop:20}}>
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Addressed to')}</label></Col>
                                    <Col span={15}><Input/></Col>
                                </Row>
                                <Row type="flex" style={{marginTop:20}}>
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Project')}</label></Col>
                                    <Col span={15}><Input/></Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row type="flex">
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Request Number')}</label></Col>
                                    <Col span={15}><Input/></Col>
                                </Row>
                                <Row type="flex" style={{marginTop:20}}>
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Reference Number')}</label></Col>
                                    <Col span={15}><Input/></Col>
                                </Row>
                                <Row type="flex" style={{marginTop:20}}> 
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Transaction Date')}</label></Col>
                                    <Col span={15}> <DatePicker defaultValue={moment('18/02/2020', dateFormat)} format={dateFormat} /></Col>
                                </Row>
                                <Row type="flex" style={{marginTop:20}}>
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Valid Until')}</label></Col>
                                    <Col span={15}> <DatePicker defaultValue={moment('19/03/2020', dateFormat)} format={dateFormat} /></Col>
                                </Row>
                                <Row type="flex" style={{marginTop:20}}>
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Primary Payment Terms')}</label></Col>
                                    <Col span={15} >  <Select placeholder={t('Select')} style={{width:'100%'}}>
                                            <Option value="cash">Cash On Delivery</Option>
                                            <Option value="15">Net 15</Option>
                                            <Option value="30">Net 30</Option>
                                            <Option value="60">Net 60</Option>
                                        </Select>
                                    </Col>     
                                </Row>
                            </Col>
                        </Row>
                        
                        <Divider type="horizontal" style={{marginTop:40}} />
                        <h2>{t('Product')}</h2>
                        <Table columns ={columns}></Table>
                        
                        <Col span={12}>
                            <Button style={{marginTop:20, backgroundColor:'#391085',color:'white',fontWeight:'bold',marginBottom:10}} ><Icon type="plus"/>{t('Add Data')}</Button>
                            <Col>
                                <Row>
            
                                    <Col><TextArea placeholder={t('Message')} rows={4} style={{width:'50%'}} /></Col>
                                </Row>
                            </Col>
                        </Col>
                        
                        <Col span={12}>
                            <Row type="flex" justify="end"style={{marginTop:20}} >
                                <Col span={5} style={{fontWeight:'bold'}}><label>Sub Total</label></Col>
                                <Col span={10}><Input/></Col>
                            </Row>

                            <Row type="flex" justify="end"style={{marginTop:10}} >
                                <Col span={5} style={{fontWeight:'bold'}}><label>{t('Tax')}</label></Col>
                                <Col span={10}>
                                    <Select placeholder={t('Select')} style={{width:'100%'}}>
                                        <Option value="10">PPN 10%</Option>
                                    </Select>
                                </Col>                
                            </Row>

                            <Row type="flex" justify="end"style={{marginTop:10}} >
                                <Col span={5} style={{fontWeight:'bold'}}><label>{t('Total')}</label></Col>
                                <Col span={10}><Input/></Col>
                            </Row>
                        </Col>       
                    </div>
                </PageHeader>
                
                <Row type="flex" style={{marginBottom:30}}>
                    <Col style={{marginTop:15,marginLeft:43,marginRight:20}}><Button href="/purchase-request" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                    <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Make a Request')}</Button></Col>
                </Row> 

            </Layout.Content> 
        )
    }
}
export default withTranslation()(AddStock)