import React, { Component } from 'react';
import {Layout} from 'antd';

class AddReport6 extends Component{
    render(){
        return(
            <Layout.Content></Layout.Content>
        )
    }
}
export default AddReport6