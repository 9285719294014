import React from 'react'
import { Typography, Row, Col, Breadcrumb, Button, Layout, TextArea, Select, Input, Upload, Icon, message,PageHeader} from 'antd'


class Product extends React.Component {
    render() {
        const {Title} = Typography
        const {TextArea} = Input
        const Option = Select
        const { Dragger } = Upload

        const props = {
            name: 'file',
            multiple: true,
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            onChange(info) {
              const { status } = info.file;
              if (status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
              } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            },
          };

        return (
            <Layout.Content>
                <PageHeader style={{border: '1px solid #ebedf0'}}>
                <Row style={{marginLeft:30}} type="flex" justify="space-around">
                    <Col  span={12}><Title level={4}>Buat Produk Baru</Title></Col>
                    <Col span={12} >
                        <Row type="flex" justify="end" style={{fontWeight:'bold'}}>
                            <Breadcrumb>
                                <Breadcrumb.Item>Data Lainnya</Breadcrumb.Item>
                                <Breadcrumb.Item><a href="/item">Produk</a></Breadcrumb.Item>
                                <Breadcrumb.Item><a href="/item/create">Buat Produk Baru</a></Breadcrumb.Item>
                            </Breadcrumb>  
                        </Row>
                    </Col>
                </Row>
                </PageHeader>
                <PageHeader style={{border: '1px solid #ebedf0'}}>
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                    <Row>
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={6} style={{fontWeight:'bold'}}><label>Kode *</label></Col>
                                <Col span={16}><Input/></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={6} style={{fontWeight:'bold'}}><label>Nama *</label></Col>
                                <Col span={16}><Input/></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={6} style={{fontWeight:'bold'}}><label>Gudang</label></Col>
                                <Col span={16}><Select defaultValue="Gudang Finished Goods" style={{width:347}}>
                                        <Option value="Gudang Finished Goods">Gudang Finished Goods</Option>
                                        <Option value="Gudang Raw Material">Gudang Raw Material</Option>
                                    </Select></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={6} style={{fontWeight:'bold'}}><label>Satuan</label></Col>
                                <Col span={16}><Select defaultValue="Lot" style={{width:347}}>
                                        <Option value="Lot">Lot</Option>
                                        <Option value="Pack">Pack</Option>
                                        <Option value="Piece">Piece</Option>
                                        <Option value="Pcs">Pcs</Option>
                                        <Option value="Kg">Kg</Option>
                                        <Option value="Meter">Meter</Option>
                                        <Option value="Sheet">Sheet</Option>
                                        <Option value="Roll">Roll</Option>
                                    </Select></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={6} style={{fontWeight:'bold'}}><label>Minimum Stok</label></Col>
                                <Col span={16}><Input/></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={6} style={{fontWeight:'bold'}}><label>Kategori Produk</label></Col>
                                <Col span={16}>
                                <Select defaultValue="Produk" style={{width:347}}>
                                        <Option value="Produk">Produk</Option>
                                        <Option value="Proyek">Proyek</Option>
                                        <Option value="Resource">Resource</Option>
                                        <Option value="Raw Material">Raw Material</Option>
                                        <Option value="Finish Good">Finish Good</Option>
                                        </Select>
                                </Col>
                            </Row>
                        </Col>   
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={6} style={{fontWeight:'bold'}}><label>Deskripsi</label></Col>
                                <Col span={16}><TextArea rows={4} style={{width:'100%'}} /></Col>
                            </Row>
                        </Col> 
                        <Col span={12}>
                            <Row type="flex">
                                <Col>
                                    <Dragger {...props}>
                                        <p className="ant-upload-drag-icon">
                                        <Icon type="inbox" />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint">
                                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                        band files
                                        </p>
                                    </Dragger>,
                                </Col>
                            </Row>
                        </Col> 
                    </Row>
                </div>
                </PageHeader>
                <Row type="flex" style={{marginBottom:30}}>
                    <Col style={{marginTop:15,marginLeft:53,marginRight:10}}><Button href="/item" style={{backgroundColor:'#545961',color:'white',fontWeight:'bold'}}>Kembali</Button></Col>
                    <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>Buat Produk </Button></Col>
                </Row>
            </Layout.Content>
        )
    }
}


export default Product