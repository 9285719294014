import React, { Component } from 'react'
import { Layout, Row, Col, Icon, List, Button, Skeleton } from 'antd'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { fetchMenu } from '../stores/actions/menuActions';
export class Dashboard extends Component {

    componentDidMount = () => {
        this.props.fetchMenu(0)
    }

    render() {
        const data = [
            {
                type: 'Total Perusahaan',
                value:  '1/10'
            },
            {
                type: 'Terdaftar',
                value:  '17 April 2019'
            },
            {
                type: 'Jatuh Tempo',
                value:  '17 April 2029'
            },
            {
                type: 'Karyawan',
                value:  '0/100'
            }
        ]

        const { t, menus, menuFetching } = this.props;
        const lang = sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'en'
        if (!sessionStorage.getItem('token')) {
            return <Redirect to="/login" />
        }
        return (
            <Layout.Content className="content mt-25 mb-25">
                <Row type="flex" gutter={10}>
                    <Col span={16} className="mt-25">
                        <Skeleton active loading={menuFetching} />
                        <Row type="flex" gutter={20}>

                            {
                                !menuFetching && menus && menus.map(menu => {
                                    return (
                                        <Col key={menu._id} span={8} className="mb-20" onClick={() => this.props.fetchMenu(menu._id)}>
                                            <Link to={menu.url}>
                                                <div className={`card card-${menu.color}`}>
                                                    <h2 className="pr-25 pl-10">{menu[`name_${lang}`]}</h2>
                                                    <Icon className="card-placeholder" type={menu.icon} />
                                                </div>
                                            </Link>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>

                    <Col span={8}>
                        <Row type="flex" className="pl-25">
                            <Col span={24}>
                                <h2>{t('Enterprise Package')}</h2>
                                <List
                                    footer={<Row type="flex" justify="end"><Button type="primary" className="mr-10">Kelola Akun</Button><Button type="danger">Hapus Akun</Button></Row>}
                                    bordered
                                    dataSource={data}
                                    renderItem={item => (
                                    
                                    <List.Item>
                                        <List.Item.Meta
                                            
                                            title={item.type}
                                            
                                            />
                                            <div>{item.value}</div>
                                    </List.Item>
                                
                                    )}
                                />
                            </Col>
                        </Row>
                    </Col>
                   
                </Row>
            </Layout.Content>
        )
    }
}

const mapStateToProps = state => {
    return {
      ...state,
      menus: state.menu.menu,
      menuFetching: state.menu.fetching,
      menuFetched: state.menu.fetched,
      menuErrors: state.menu.errors
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchMenu: parent_id => dispatch(fetchMenu(parent_id))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Dashboard))
