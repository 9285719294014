import React from 'react'
import { PageHeader,
         Typography, 
         Layout, 
         Row, 
         Col, 
         Breadcrumb, 
         Button, 
         Icon, 
         Select, 
         Input, 
         Table } from 'antd';
import { withTranslation } from 'react-i18next';

class TransferWarehouse extends React.Component {
    render(){
      const {Title} = Typography
      const { Option } = Select
      const { t } = this.props
      const columns = [ 
        {
          title: `${t('Date')}`,
          dataIndex: 'Date',
          key: 'Date',
          render: text => <a>{text}</a>,
        },
        {
          title: `${t('Original Warehouse')}`,
          dataIndex: 'Original Warehouse',
          key: 'Original Warehouse',
        },
        {
          title: `${t('Destination Warehouse')}`,
          dataIndex: 'Destination Warehouse',
          key: 'Destination Warehouse',
        },
        {
            title: `${t('Information')}`,
            dataIndex: 'Information',
            key: 'Information',
        },
      ];
      return(
        <Layout.Content>
          <PageHeader style={{border: '1px solid rgb(235,237,340)'}}>
         <Row style={{margin:10}} type="flex" justify="space-around">
           <Col span={12}><h2 level={4}>{t('Transfer Warehouse')} </h2></Col>
           <Col span={12}>
              <Row type="flex" justify="end">
                <Breadcrumb >
                  <Breadcrumb.Item>{t('Inventory')}</Breadcrumb.Item>
                  <Breadcrumb.Item href="/warehouse-transfer">{t('Transfer Warehouse')} </Breadcrumb.Item>
                </Breadcrumb>
              </Row>
           </Col>
         </Row>
         </PageHeader>
         <Row ><Button href="/warehouse-transfer/create" style={{margin:10, backgroundColor:"#505458", color:"white"}}><Icon type="plus"/>{t('Create Transfer Warehouse')}</Button></Row>
         
         
         <Row type="flex" justify="space-between" style={{padding:20, margin:10}}>
           <Col > 
              <Row type="flex">
                <Col><h3>Per</h3></Col>
                  <Select defaultValue="10" >
                    <Option value="10">10</Option>
                    <Option value="25">25</Option>
                    <Option value="50">50</Option>
                    <Option value="100">100</Option>
                    <Option value="200">200</Option>
                    <Option value="All">{t('All')}</Option>
                  </Select>
                  <Col><h3>{t('Line')}</h3></Col>
              </Row>
           </Col>
           <Col>
             <Row type="flex">
               <Col span={5} style={{marginRight:10}}><h3>{t('Search')}</h3></Col>
               <Col span={15}><Input></Input></Col>
             </Row>
           </Col>
         </Row>

         <div style={{margin:5, padding:20}}>
           <Table columns={columns}></Table>
         </div>

        </Layout.Content>
        
      )
    }
  }
  
  export default withTranslation() (TransferWarehouse);