import React, { Component } from 'react'
import { Layout, Row, Col, Breadcrumb, Typography, Divider, Card, Input, Select, DatePicker, Table, Button } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

class PaymentCreate extends Component{
    render(){
        const {Title} = Typography
        const Option = Select
        const {t} = this.props
        const {TextArea} = Input
        const dateFormat = 'DD/MM/YYYY'
        const columns = [
            {
              title: `${t('Product')}`,
              dataIndex: 'Produk',
              key: 'Produk',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('Description')}`,
              dataIndex: 'Deskripsi',
              key: 'Deskripsi',
            },
            {
              title: `${t('Quantity')}`,
              dataIndex: 'Kuantitas',
              key: 'Kuantitas',
            },
            {
                title: `${t('Unit')}`,
                dataIndex: 'Satuan',
                key: 'Satuan',
            },
            {
                title: `${t('Unit Price')}`,
                dataIndex: 'Harga_Satuan',
                key: 'Harga_Satuan',
              },
              {
                title: `${t('Amount')}`,
                dataIndex: 'Jumlah',
                key: 'Jumlah',
              },
              {
                title: `${t('Tax')}`,
                dataIndex: 'Pajak',
                key: 'Pajak',
              },
          ];    
        return(
            <Layout.Content>
                <div style={{margin:10,padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Create Payment')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('The Sale')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/payment">{t('Payment')}</a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/payment/create">{t('Create Payment')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider  type="horizontal" style={{marginTop:-10,marginBottom:-10}} />
                <div style={{margin:10,padding:20}}>
                    <Card style={{borderRadius:10,borderWidth:2}}>
                        <Row type="flex">
                            <Col span={12}>
                                <Row>
                                    <Col span={8}><h4>{t('Receipt Number')}</h4></Col>
                                    <Col span={15}><Select style={{width:'100%'}} defaultValue="null">
                                            <Option value="null">null</Option>
                                        </Select></Col>
                                </Row>
                                <Row style={{marginTop:15}}>
                                    <Col span={8}><h4>{t('Suplier')}</h4></Col>
                                    <Col span={15}><Input readOnly style={{backgroundColor:'#bfbfbf'}} /></Col>
                                </Row>
                                <Row style={{marginTop:15}}>
                                    <Col span={8}><h4>{t('Billing Address')}</h4></Col>
                                    <Col span={15}><TextArea rows={2}/></Col>
                                </Row>
                                <Row style={{marginTop:15}}>
                                    <Col span={8}><h4>{t('Addressed To')}</h4></Col>
                                    <Col span={15}><Input  /></Col>
                                </Row>
                                <Row style={{marginTop:15}}>
                                    <Col span={8}><h4>{t('Project')}</h4></Col>
                                    <Col span={15}><Input readOnly style={{backgroundColor:'#bfbfbf'}} /></Col>
                                </Row>
                                <Row style={{marginTop:15}}>
                                    <Col span={8}><h4>{t('Post Journal Number')}</h4></Col>
                                    <Col span={5} style={{backgroundColor:'#bfbfbf'}}><h5 style={{color:'red',marginLeft:10,marginTop:3}}>{t('Not Yet Posted')}</h5></Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row style={{marginTop:15}}>
                                    <Col span={8}><h4>{t('Payment Number')}</h4></Col>
                                    <Col span={15}><Input  /></Col>
                                </Row>
                                <Row style={{marginTop:15}}>
                                    <Col span={8}><h4>{t('Reference Number')}</h4></Col>
                                    <Col span={15}><Input readOnly style={{backgroundColor:'#bfbfbf'}} /></Col>
                                </Row>
                                <Row style={{marginTop:15}}>
                                    <Col span={8}><h4>{t('Transaction Date')}</h4></Col>
                                    <Col span={15}><DatePicker style={{width:'100%'}} defaultValue={moment('18/02/2020',dateFormat)} format={dateFormat} /></Col>
                                </Row>
                                <Row style={{marginTop:15}}>
                                    <Col span={8}><h4>{t('Valid Until')}</h4></Col>
                                    <Col span={15}><DatePicker style={{width:'100%'}} defaultValue={moment('19/03/2020',dateFormat)} format={dateFormat} /></Col>
                                </Row>
                                <Row style={{marginTop:15}}>
                                    <Col span={8}><h4>{t('Main Payment Term')}</h4></Col>
                                    <Col span={15}><Select style={{width:'100%'}} defaultValue="Cash On Delivery">
                                            <Option value="Cash On Delivery">Cash On Delivery</Option>
                                            <Option value="Net 15">Net 15</Option>
                                            <Option value="Net 30">Net 30</Option>
                                            <Option value="Net 60">Net 60</Option>
                                        </Select></Col>
                                </Row>
                                <Row style={{marginTop:15}}>
                                    <Col span={8}><h4>{t('Payment Method')}</h4></Col>
                                    <Col span={15}><Select style={{width:'100%'}} defaultValue="Tunai">
                                            <Option value="Tunai">Tunai</Option>
                                            <Option value="Kredit">Kredit</Option>
                                        </Select></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider type="horizontal"/>
                        <h3 style={{fontWeight:'bold'}}>{t('Product')}</h3 >
                        <Table style={{marginTop:10}} columns={columns}/>
                        <Divider type="horizontal"/>
                        <Row type="flex" justify="space-between">
                            <Col span={6}><TextArea rows={4} placeholder={t('Message')} /></Col>
                            <Col span={11}>
                                <Row type="flex" justify="space-between">
                                    <Col ><h4>SubTotal</h4></Col>
                                    <Col ><h4>Rp. 2.650.000,00</h4></Col>
                                </Row>
                                <Row type="flex" justify="space-between">
                                    <Col >
                                        <Row>
                                            <Col><h4>{t('Tax')}</h4></Col>
                                            <Col><Select style={{width:130}} defaultValue="10%">
                                                    <Option value="10%">10%</Option>
                                                </Select></Col>
                                        </Row>
                                    </Col>
                                    <Col style={{marginTop:15}}><h4>Rp. 0,00</h4></Col>
                                </Row>
                                <Row style={{marginTop:15}} type="flex" justify="space-between">
                                    <Col ><h4>Total</h4></Col>
                                    <Col ><h4>Rp. 2.650.000,00</h4></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    <Row type="flex" style={{marginBottom:30}}>
                        <Col style={{marginTop:15,marginRight:10}}><Button href="/payment" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                        <Col style={{marginTop:15}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Create Payment')}</Button></Col>
                    </Row>
                </div>
            </Layout.Content>
        )
    }
}
export default withTranslation()(PaymentCreate) 