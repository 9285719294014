import React from 'react'
import { Layout, 
         Row, 
         Col, 
         Breadcrumb, 
         Typography, 
         PageHeader, 
         Button, 
         Icon, 
         Select, 
         Input, 
         Divider, 
         Table, 
         Modal,
         Upload,
         message,
         visible } from 'antd'
import { withTranslation } from 'react-i18next'

class ClassAndBasicSalary extends React.Component {
    state = { visible: false };

    showUpload = () => {
      this.setState({
        visible: true,
      });
    };
  
    handleOk = e => {
      console.log(e);
      this.setState({
        visible: false,
      });
    };
  
    handleCancel = e => {
      console.log(e);
      this.setState({
        visible: false,
      });
    };
    render(){
        const {Title, Text} = Typography
        const {Option} = Select
        const { t } = this.props
        const props = {
            name: 'file',
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            headers: {
              authorization: 'authorization-text',
            },
            onChange(info) {
              if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            },
          };
          
        const columns = [
            {
              title: `${t('Name')}`,
              dataIndex: 'Name',
              key: 'Name',
            },
            {
              title: `${t('Description')}`,
              dataIndex: 'Description',
              key: 'Description',
            },
            {
              title: `${t('Number of Employees')}`,
              dataIndex: 'Number of Employees',
              key: 'Number of Employees',
            },
          ];
        return (
            <Layout.Content>
                <Modal 
                title="Upload Data"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                > 
                    <Row type='flex'>
                        <Upload {...props}>
                            <Button>
                                Choose File
                            </Button> 
                        </Upload>
                        <Text>No File Chosen</Text>
                    </Row>
                    <Row style={{margin:10}}>
                    {t('Dont have Template Import data yet ... ?? Click this link: Template Import')}
                    </Row> 
                </Modal>

                <PageHeader style={{border: '1px solid rgb(235,237,340)'}}>
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Class & Basic Salary')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>Personnel</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/group">{t('Class & Basic Salary')} </a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            
                            </Row>
                        </Col>
                    </Row>
                </div>
                </PageHeader>

                <div style={{padding:20}}>
                    <Row type='flex'justify='space-between'>
                        <Button href="/group/create" type="primary" style={{margin:10, backgroundColor:"#505458", color:"white"}}>
                            <Icon type="plus"/>{t('Create a Group')}</Button>
                     <Row type="flex" justify="end">   
                        <Button onClick={this.showUpload} type="primary" style={{margin:10, backgroundColor:"#505458", color:"white"}}>
                            <Icon type="upload"/> Upload</Button>
                        <Button href="" type="primary" style={{margin:10, backgroundColor:"#505458", color:"white"}}>
                            <Icon type="download"/>Download</Button>
                     </Row>
                    </Row>
                </div>
                
                <Row type="flex" justify="space-between" style={{padding:20}}>
                    <Col > 
                        <Row type="flex">
                            <h3>Per</h3>
                            <Select defaultValue="25" >
                                <Option value="25">25</Option>
                                <Option value="50">50</Option>
                                <Option value="100">100</Option>
                                <Option value="200">200</Option>
                                <Option value="All">{t('All')}</Option>
                            </Select>
                            <h3>{t('Line')}</h3>
                        </Row>
                    </Col>
                    <Col>
                        <Row type="flex"  >
                       <h3 style={{marginRight:10}}>{t('Search')}</h3>
                        <Col><Input></Input></Col>
                        </Row>
                    </Col>
                </Row>

                <div style={{margin:5, padding:20}}>
                    <Table columns={columns}></Table>
                </div>

            </Layout.Content>
        )
    }
}

export default withTranslation() (ClassAndBasicSalary)