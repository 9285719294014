import React, { Component } from 'react'
import { Row,Typography, Col, Breadcrumb, Layout, Divider, Input, Select, DatePicker,Upload,PageHeader, Button, Icon, Table , placeholder} from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next';

class AddProof extends Component{
    render(){
        const { t } = this.props;
        const {Title,Text} = Typography
        const Option = Select
        const dateFormat ='DD/MM/YYYY'
        const {TextArea} = Input
        const columns = [
            {
                title: `${t('Product')}`,
                dataIndex: 'Produk',
                key: 'Produk',
                
            },
            {
                title: `${t('Description')}`,
                dataIndex: 'Deskripsi',
                key: 'Deskripsi',
            },
            {
                title: 'Unit',
                dataIndex: 'Unit',
                key: 'Unit',
            },
            {
                title: `${t('Quantity')}`,
                dataIndex: 'Kuantitas',
                key: 'Kuantitas',
            }
          ];
        return(
            <Layout.Content>
            <PageHeader style={{ border: '1px solid #ebedf0'}}>
                <div>
                    <Row type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Create Proof of Order')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Production')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/bob">{t('Proof of Order')}</a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/bob/create">{t('Create Proof of Order')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                </PageHeader>
                <PageHeader style={{ border: '1px solid #ebedf0'}}>
                    <Divider type="horizontal" style={{margin:-10}}/>
                    <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                        <Row>
                            <Col span={12}>
                                <Row type="flex">
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Transaction NUmber')}</label></Col>
                                    <Col span={15} defaultValue="BO2020020001"></Col>
                                </Row>
                                <Row type="flex" style={{marginTop:20}}> 
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Transaction Date')}</label></Col>
                                    <Col span={15}> <DatePicker defaultValue={moment('21/02/2020', dateFormat)} format={dateFormat} /></Col>
                                </Row>
                            </Col>        
                            <Col span={12}>
                                <Row type="flex">
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Addressed to')}</label></Col>
                                    <Col span={15}><Input/></Col>
                                </Row>
                                <Row type="flex" style={{marginTop:20}}>
                                    <Col span={7} style={{fontWeight:'bold'}}><label>{t('Original Warehouse')}</label></Col>
                                    <Col span={15} >  <Select placeholder={t('Select')} style={{width:'100%'}}>
                                            <Option value="grm">(GDG-0001) - Gudang Raw Material</Option>
                                            <Option value="gfg">(GDG-0003) - Gudang Finished Goods</Option>
                                        </Select>
                                    </Col>     
                                </Row>
                            </Col>
                        </Row>
                        
                        <Divider type="horizontal" style={{marginTop:20}} />
                        <Table style={{marginTop:10}} columns ={columns}></Table>
                        <Button style={{marginTop:20, backgroundColor:'#0d1933',color:'white',fontWeight:'bold',marginBottom:10}} ><Icon type="plus"/>{t('Add Line')}</Button>
                    </div>
                </PageHeader>
                <Row type="flex" style={{marginBottom:30}}>
                    <Col style={{marginTop:15,marginLeft:43,marginRight:20}}><Button href="/bob" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                    <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Save')}</Button></Col>
                </Row> 
            </Layout.Content> 
        )
    }
}
export default withTranslation()(AddProof)