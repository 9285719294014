import Axios from "axios"
import { url } from "../../config/global"

const fetchMenu = (parent_id) => {
    return (dispatch, getState) => {

        dispatch({
            type: 'FETCH_MENU_PENDING'
        })

        Axios.get(`${url}/menu/get/${parent_id}`,
            {
                headers: {
                    Authorization:  `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dispatch({
                type: 'FETCH_MENU_SUCCESS',
                data: res.data.data,
            })
        }).catch(err => {
            
            if (err.response.status === 401) {
                sessionStorage.removeItem('token')
            }

            dispatch({
                type: 'FETCH_MENU_FAILED',
                errors: err.response.data,
            })
        })
    }
}

export { fetchMenu }
