import React, { Component } from 'react'
import { Row, Typography, Col, Breadcrumb, Layout, Divider, Input, Select, Button,PageHeader} from 'antd'
import { withTranslation } from 'react-i18next'

class AddWarehouse extends Component{
    render(){
        const {Title} = Typography
        const {TextArea} = Input
        const {Option} = Select
        const { t } = this.props;
        return(
            <Layout.Content>
                <PageHeader style={{ border: '1px solid #ebedf0'}}>
                    <Row type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Add Warehouse')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>Inventory</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/Warehouse">{t('Warehouse')}</a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/Warehouse/Create">{t('Add Warehouse')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </PageHeader>
                <Divider type="horizontal"/>
                <div style={{marginLeft:25,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                    <Row>
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Warehouse Name')}</label></Col>
                                <Col span={16}><Input placeholder={t('Warehouse Name')}/></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Warehouse Code')}</label></Col>
                                <Col span={16}><Input placeholder={t('Warehouse Code')}/></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Address')}</label></Col>
                                <Col span={16}><TextArea rows={4} style={{width:'100%'}} placeholder={t('Address')}/></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Information')}</label></Col>
                                <Col span={16}><TextArea rows={4} style={{width:'100%'}} placeholder={t('Information')}/></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Warehouse Default')}</label></Col>
                                <Col span={16}>
                                    <Select style={{width:'100%',fontSize:16}} defaultValue="-">
                                        <Option value="-" > - </Option>
                                        <Option value="penjualan" >{t('Sales')}</Option>
                                        <Option value="pembelian" >{t('Purchase')}</Option>
                                        <Option value="produksi" >{t('Production')}</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </Col>    
                    </Row>
                </div>
                <Row type="flex" style={{marginBottom:30}}>
                    <Col style={{marginTop:15,marginLeft:43,marginRight:20}}><Button href="/Warehouse" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                    <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Save')}</Button></Col>
                </Row>
            </Layout.Content>
        )
    }
}
export default withTranslation()(AddWarehouse)