import React, { Component, Fragment } from 'react'
import { Menu, Icon, Layout } from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

const { SubMenu } = Menu
const { Sider } = Layout

export class NavbarComponent extends Component {

  render() {
    const { collapsed, sidebar, t, location } = this.props
    const path = location.pathname.split('/')[1]
    const lang = sessionStorage.getItem('lang') ?  sessionStorage.getItem('lang') : 'en'
    return (
      <Sider collapsed={collapsed} style={{backgroundColor: '#00317c'}}>
        <div className="logo">
          <Link to="/">
            {
              collapsed ? (
                <img src={require('../assets/img/logo.png')} alt="Kelolabiz Logo" />
              ) : (
                <img src={require('../assets/img/logo2.png')} alt="Kelolabiz Logo" />
              )
            }
            
          </Link>
        </div>
        <Menu 
          mode="inline"
          defaultSelectedKeys={path === '' ? '0' : path}
          theme="dark"
          className="sidebar"
          style={{
            backgroundColor: '#00317c'
          }}
          >
            <Menu.Item key='0'><Link to='/'><Icon type="appstore" /><span>{t('Go to app')}</span></Link></Menu.Item>
            {
              sidebar && sidebar.map(side => {
                    return side.type === 'page' ? side.children ? (
                      <SubMenu key="sub1"
                        key={side._id}
                        title={
                          <span>
                            <Icon type={side.icon} />
                            <span>{side[`name_${lang}`]}</span>
                          </span>
                        }>
                          {
                            side.children.map(child => {
                              return (
                                <Menu.Item key={child.url}><Link to={`/${child.url}`}>{child[`name_${lang}`]}</Link></Menu.Item>
                              )
                            })
                          }
                      </SubMenu>
                    ) : (
                      <Menu.Item key={side.url}><Link to={`/${side.url}`}><Icon type={side.icon} /> <span>{side[`name_${lang}`]}</span></Link></Menu.Item>
                    ) : null
                    
              })
            }
        </Menu>
      </Sider>
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state,
    sidebar: state.menu.menu,
    fetchingSidebar: state.menu.fetching
  }
}

export default withTranslation()(connect(mapStateToProps)(NavbarComponent))
