import React, { Component } from 'react'
import { Layout, Row, Col, Breadcrumb, Divider, Typography, Card, Select, Input, Tabs, Button, Icon, Upload, Checkbox, DatePicker, Table } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

class Setting extends Component{
    render(){
        const {Title} = Typography
        const Option = Select
        const {TextArea} = Input
        const {TabPane} = Tabs
        const dateFormat = 'DD/MM/YYYY'
        const {RangePicker} = DatePicker
        const {t} = this.props
        return(
            <Layout.Content>
                <div style={{marginTop:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Setting')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Setting')} </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider type="horizontal"/>
                <Card style={{borderWidth:2,borderRadius:10}}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={t('Company')} key="1">
                            <Row type="flex" justify="space-between">
                                <Col span={12}>
                                    <Row type="flex">
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Company Logo')}</Col>
                                        <Col span={9} style={{fontWeight:'bold'}}><Input style={{backgroundColor:'#d9d9d9'}} readOnly />{t('Company Logo Not available yet')} </Col>
                                        <Col span={5}>
                                            <Upload>
                                                <Button><Icon type="folder-open" theme="filled" />{t('Select File')}</Button>
                                            </Upload>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Logo In Report')}</Col>
                                        <Col span={15}><Checkbox/></Col>
                                    </Row>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Company Name')}</Col>
                                        <Col span={15}><Input/></Col>
                                    </Row>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Address')}</Col>
                                        <Col span={15}><TextArea rows={4} /></Col>
                                    </Row>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Industry Type')}</Col>
                                        <Col span={15}><Input/></Col>
                                    </Row>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Shipping Address')}</Col>
                                        <Col span={15}><TextArea rows={4} /></Col>
                                    </Row>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Telephone')}</Col>
                                        <Col span={15}><Input/></Col>
                                    </Row>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Fax')}</Col>
                                        <Col span={15}><Input/></Col>
                                    </Row>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('NPWP Number')}</Col>
                                        <Col span={15}><Input/></Col>
                                    </Row>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Website')}</Col>
                                        <Col span={15}><Input/></Col>
                                    </Row>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>Email</Col>
                                        <Col span={15}><Input defaultValue="eriza.m2182@gmail.com"/></Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col style={{fontWeight:'bold',fontSize:16}}>{t('Detail Akun Bank')}</Col>
                                    </Row>
                                    <Divider type="horizontal"/>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Bank Name')}</Col>
                                        <Col span={15}><Input /></Col>
                                    </Row>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Bank Branch')}</Col>
                                        <Col span={15}><Input /></Col>
                                    </Row>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Bank Address')}</Col>
                                        <Col span={15}><Input /></Col>
                                    </Row>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Rekening Number')}</Col>
                                        <Col span={15}><Input /></Col>
                                    </Row>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Rekening Name')}</Col>
                                        <Col span={15}><Input /></Col>
                                    </Row>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Swiff Code')}</Col>
                                        <Col span={15}><Input /></Col>
                                    </Row>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontWeight:'bold',fontSize:16}}>{t('Accounting')}</Col>
                                    </Row>
                                    <Divider type="horizontal"/>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Period Began')}</Col>
                                        <Col span={15}><DatePicker style={{width:'100%'}} defaultValue={moment('30/01/2020',dateFormat)} format={dateFormat} /></Col>
                                    </Row>
                                    <Row style={{marginTop:15}}>
                                        <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Final Period')}</Col>
                                        <Col span={15}><DatePicker style={{width:'100%'}}  defaultValue={moment('31/12/2020',dateFormat)} format={dateFormat} /></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Divider type="horizontal"/>
                            <Row type="flex" >
                                <Col span={3}><Button style={{height:40,color:'white',backgroundColor:'#595959',width:'100%'}}>{t('Cancel')}</Button></Col>
                                <Col span={3}><Button style={{height:40,color:'white',backgroundColor:'#096dd9',width:'100%',marginLeft:10}}>{t('Edit Setting')}</Button></Col>
                            </Row>
                        </TabPane>
                        <TabPane tab={t('Billing')} key="2">
                            <Row type="flex" justify="space-between">
                                <Col span={12}>
                                    <Card style={{borderRadius:10,borderWidth:2,marginRight:10}}>
                                        <p style={{fontSize:16,fontWeight:'bold'}}>{t('Subscripttion Package')}</p>
                                        <Divider type="horizontal"/>
                                        <Row type="flex" justify="space-between">
                                            <Col span={12}>
                                                <p style={{fontSize:14,fontWeight:'bold'}}>{t('Package Name')}</p>
                                                <p style={{fontSize:14,fontWeight:'bold'}}>{t('User Amount')}</p>
                                                <p style={{fontSize:14,fontWeight:'bold'}}>{t('Day Limit')}</p>
                                                <p style={{fontSize:14,fontWeight:'bold'}}>{t('Expire Date')}</p>
                                            </Col>
                                            <Col span={12}>
                                                <p style={{fontSize:14,fontWeight:'bold'}}>Paket ERP</p>
                                                <p style={{fontSize:14,fontWeight:'bold'}}>7 Pengguna dari 3</p>
                                                <p style={{fontSize:14,fontWeight:'bold'}}>71 Hari</p>
                                                <p style={{fontSize:14,fontWeight:'bold'}}>02 May 2020</p>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card style={{borderRadius:10,borderWidth:2,marginLeft:10}}>
                                        <p style={{fontSize:16,fontWeight:'bold'}}>{t('Bill Information')}</p>
                                        <Divider type="horizontal"/>
                                        <Row type="flex" justify="space-between">
                                            <Col span={12}>
                                                <p style={{fontSize:14,fontWeight:'bold'}}>{t('Package')}</p>
                                                <p style={{fontSize:14,fontWeight:'bold'}}>{t('User Additional')}</p>
                                                <p style={{fontSize:14,fontWeight:'bold'}}>{t('Amount Additional User')}</p>
                                                <p style={{fontSize:14,fontWeight:'bold'}}>{t('Amount')}</p>
                                            </Col>
                                            <Col span={12}>
                                                <p style={{fontSize:14,fontWeight:'bold',textAlign:'right'}}>Rp.</p>
                                                <p style={{fontSize:14,fontWeight:'bold',textAlign:'right'}}>Rp.</p>
                                                <p style={{fontSize:14,fontWeight:'bold',textAlign:'right'}}>Rp.</p>
                                                <p style={{fontSize:14,fontWeight:'bold',textAlign:'right'  }}>Rp.</p>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Button href="/setting/prosespayment" style={{marginTop:20,fontWeight:'bold',backgroundColor:'#595959',color:'white',marginLeft:10,height:40}}>{t('Payment Process')}</Button>
                                </Col>
                            </Row>
                            <Card style={{borderWidth:2,borderRadius:10,marginTop:10}}>
                                <p style={{fontWeight:'bold',fontSize:16}}>{t('Payment History')}</p>
                                <Divider type="horizontal"/>
                                <Row>
                                    <Col span={2} style={{fontSize:14,fontWeight:'bold'}}>{t('Date')}</Col>
                                    <Col span={6}><RangePicker defaultValue={[moment('21/02/2020',dateFormat),moment('21/02/2020',dateFormat)]} format={dateFormat} /></Col>
                                    <Col span={3}><Button style={{width:'100%',marginLeft:10,fontWeight:'bold',backgroundColor:'#595959',color:'white'}}>{t('Filter')}</Button></Col>
                                </Row>
                                <Table style={{marginTop:10}} columns={[
                                    {
                                        title:`${t('Payment Date')}`,
                                        dataIndex:'Tanggal_Bayar',
                                        Key:'Tanggal_Bayar'
                                    },
                                    {
                                        title:`${t('Bill Period')}`,
                                        dataIndex:'Periode_Tagihan',
                                        Key:'Periode_Tagihan'
                                    },
                                    {
                                        title:`${t('Period')}`,
                                        dataIndex:'Jangka',
                                        Key:'Jangka'
                                    },
                                    {
                                        title:`${t('Payment Total')}`,
                                        dataIndex:'Total_Bayar',
                                        Key:'Total_Bayar'
                                    },
                                    {
                                        title:`${t('Download Bill')}`,
                                        dataIndex:'Unduh_Tagihan',
                                        Key:'Unduh_Tagihan'
                                    },
                                    {
                                        title:`${t('Status')}`,
                                        dataIndex:'Status',
                                        Key:'Status'
                                    },
                                    
                                ]}/>
                            </Card>
                            <Divider type="horizontal"/>
                            <Row type="flex" >
                                <Col span={3}><Button style={{height:40,color:'white',backgroundColor:'#595959',width:'100%'}}>{t('Cancel')}</Button></Col>
                                <Col span={3}><Button style={{height:40,color:'white',backgroundColor:'#096dd9',width:'100%',marginLeft:10}}>{t('Pay')}</Button></Col>
                            </Row>
                        </TabPane>
                        <TabPane tab={t('The Sale')} key="3">
                            <p style={{fontWeight:'bold',fontSize:20}}>{t('The Sale')}</p>
                            <Row type="flex" justify="space-between">
                                <Col span={12}>
                                    <Row type="flex">
                                        <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Main Payment Term')}</Col>
                                        <Col span={15}>
                                            <Select style={{width:'100%'}} defaultValue="Net 30">
                                                <Option value="Net 30">Net 30</Option>
                                                <Option value="Net 15">Net 15</Option>
                                                <Option value="Cash On Delivery">Cash On Delivery</Option>
                                                <Option value="Net 60">Net 60</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row type="flex" style={{marginTop:15}}>
                                        <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Standard Sales Account')}</Col>
                                        <Col span={15}>
                                            <Select style={{width:'100%'}} defaultValue="Pendapatan">
                                                <Option value="Pendapatan">Pendapatan</Option>
                                                <Option value="Pendapatan Jasa">Pendapatan Jasa</Option>
                                                <Option value="Pendapatan Penjualan">Pendapatan Penjualan</Option>
                                                <Option value="Potongan Penjualan">Potongan Penjualan </Option>
                                                <Option value="Penjualan Belum Ditagih">Penjualan Belum Ditagih </Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row type="flex" style={{marginTop:15}}>
                                        <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Account Receivable')}</Col>
                                        <Col span={15}>
                                            <Select style={{width:'100%',fontSize:16}} defaultValue="Asset">
                                                <Option value="Asset" >Asset</Option>
                                                <Option value="Sewa Bayar Dimuka" >Sewa Bayar Dimuka</Option>
                                                <Option value="Akumulasi Penyusutan Peralatan" >Akumulasi Penyusutan Peralatan</Option>
                                                <Option value="Peralatan Kantor" >Peralatan Kantor</Option>
                                                <Option value="Persedian Barang" >Persedian Barang</Option>
                                                <Option value="PPN Masukan" >PPN Masukan</Option>
                                                <Option value="Piutang Belum Ditagih" >Piutang Belum Ditagih</Option>
                                                <Option value="Piutang" >Piutang</Option>
                                                <Option value="Bank" >Bank</Option>
                                                <Option value="Kas Bank POS" >Kas Bank POS </Option>
                                                <Option value="Kas POS" >Kas POS</Option>
                                                <Option value="Kas" >Kas</Option>
                                                <Option value="Harga Tetap" >Harga Tetap</Option>
                                                <Option value="Harga Lancar" >Harga Lancar</Option>
                                                <Option value="Saldo Awal" >Saldo Awal</Option>
                                                <Option value="Modal" >Modal</Option>
                                                <Option value="Ekuitas Saldo Awal" >Ekuitas Saldo Awal</Option>
                                                <Option value="Prive" >Prive</Option>
                                                <Option value="Biaya Gaji" >Biaya Gaji</Option>
                                                <Option value="Harga Pokok Penjualan" >Harga Pokok Penjualan</Option>
                                                <Option value="Biaya" >Biaya</Option>
                                                <Option value="Biaya Penyusutan dan Amortisasi" >Biaya Penyusutan dan Amortisasi</Option>
                                                <Option value="Biaya Sewa" >Biaya Sewa</Option>
                                                <Option value="Biaya Transportasi" >Biaya Transportasi</Option>
                                                <Option value="Biaya Lainnya" >Biaya Lainnya</Option>
                                                <Option value="Pendapatan" >Pendapatan</Option>
                                                <Option value="Pendapatan Jasa" >Pendapatan Jasa</Option>
                                                <Option value="Pendapatan Penjualan" >Pendapatan Penjualan</Option>
                                                <Option value="Potongan Penjualan" >Potongan Penjualan</Option>
                                                <Option value="Penjualan Belum Ditagih" >Penjualan Belum Ditagih</Option>
                                                <Option value="PPH Pasal 23" >PPH Pasal 23</Option>
                                                <Option value="Hutang" >Hutang</Option>
                                                <Option value="Hutang Usaha" >Hutang Usaha</Option>
                                                <Option value="Hutang Gaji Karyawan" >Hutang Gaji Karyawan</Option>
                                                <Option value="Hutang Lainnya" >Hutang Lainnya</Option>
                                                <Option value="PPH Pasal 21" >PPH Pasal 21</Option>
                                                <Option value="PPN Keluaran" >PPN Keluaran</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row type="flex" style={{marginTop:15}}>
                                        <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Account Kas POS Code')}</Col>
                                        <Col span={15}>
                                            <Select style={{width:'100%',fontSize:16}} defaultValue="Asset">
                                                <Option value="Asset" >Asset</Option>
                                                <Option value="Sewa Bayar Dimuka" >Sewa Bayar Dimuka</Option>
                                                <Option value="Akumulasi Penyusutan Peralatan" >Akumulasi Penyusutan Peralatan</Option>
                                                <Option value="Peralatan Kantor" >Peralatan Kantor</Option>
                                                <Option value="Persedian Barang" >Persedian Barang</Option>
                                                <Option value="PPN Masukan" >PPN Masukan</Option>
                                                <Option value="Piutang Belum Ditagih" >Piutang Belum Ditagih</Option>
                                                <Option value="Piutang" >Piutang</Option>
                                                <Option value="Bank" >Bank</Option>
                                                <Option value="Kas Bank POS" >Kas Bank POS </Option>
                                                <Option value="Kas POS" >Kas POS</Option>
                                                <Option value="Kas" >Kas</Option>
                                                <Option value="Harga Tetap" >Harga Tetap</Option>
                                                <Option value="Harga Lancar" >Harga Lancar</Option>
                                                <Option value="Saldo Awal" >Saldo Awal</Option>
                                                <Option value="Modal" >Modal</Option>
                                                <Option value="Ekuitas Saldo Awal" >Ekuitas Saldo Awal</Option>
                                                <Option value="Prive" >Prive</Option>
                                                <Option value="Biaya Gaji" >Biaya Gaji</Option>
                                                <Option value="Harga Pokok Penjualan" >Harga Pokok Penjualan</Option>
                                                <Option value="Biaya" >Biaya</Option>
                                                <Option value="Biaya Penyusutan dan Amortisasi" >Biaya Penyusutan dan Amortisasi</Option>
                                                <Option value="Biaya Sewa" >Biaya Sewa</Option>
                                                <Option value="Biaya Transportasi" >Biaya Transportasi</Option>
                                                <Option value="Biaya Lainnya" >Biaya Lainnya</Option>
                                                <Option value="Pendapatan" >Pendapatan</Option>
                                                <Option value="Pendapatan Jasa" >Pendapatan Jasa</Option>
                                                <Option value="Pendapatan Penjualan" >Pendapatan Penjualan</Option>
                                                <Option value="Potongan Penjualan" >Potongan Penjualan</Option>
                                                <Option value="Penjualan Belum Ditagih" >Penjualan Belum Ditagih</Option>
                                                <Option value="PPH Pasal 23" >PPH Pasal 23</Option>
                                                <Option value="Hutang" >Hutang</Option>
                                                <Option value="Hutang Usaha" >Hutang Usaha</Option>
                                                <Option value="Hutang Gaji Karyawan" >Hutang Gaji Karyawan</Option>
                                                <Option value="Hutang Lainnya" >Hutang Lainnya</Option>
                                                <Option value="PPH Pasal 21" >PPH Pasal 21</Option>
                                                <Option value="PPN Keluaran" >PPN Keluaran</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row type="flex" >
                                        <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Account Kas Bank POS Code')}</Col>
                                        <Col span={15}>
                                            <Select style={{width:'100%',fontSize:16}} defaultValue="Asset">
                                                <Option value="Asset" >Asset</Option>
                                                <Option value="Sewa Bayar Dimuka" >Sewa Bayar Dimuka</Option>
                                                <Option value="Akumulasi Penyusutan Peralatan" >Akumulasi Penyusutan Peralatan</Option>
                                                <Option value="Peralatan Kantor" >Peralatan Kantor</Option>
                                                <Option value="Persedian Barang" >Persedian Barang</Option>
                                                <Option value="PPN Masukan" >PPN Masukan</Option>
                                                <Option value="Piutang Belum Ditagih" >Piutang Belum Ditagih</Option>
                                                <Option value="Piutang" >Piutang</Option>
                                                <Option value="Bank" >Bank</Option>
                                                <Option value="Kas Bank POS" >Kas Bank POS </Option>
                                                <Option value="Kas POS" >Kas POS</Option>
                                                <Option value="Kas" >Kas</Option>
                                                <Option value="Harga Tetap" >Harga Tetap</Option>
                                                <Option value="Harga Lancar" >Harga Lancar</Option>
                                                <Option value="Saldo Awal" >Saldo Awal</Option>
                                                <Option value="Modal" >Modal</Option>
                                                <Option value="Ekuitas Saldo Awal" >Ekuitas Saldo Awal</Option>
                                                <Option value="Prive" >Prive</Option>
                                                <Option value="Biaya Gaji" >Biaya Gaji</Option>
                                                <Option value="Harga Pokok Penjualan" >Harga Pokok Penjualan</Option>
                                                <Option value="Biaya" >Biaya</Option>
                                                <Option value="Biaya Penyusutan dan Amortisasi" >Biaya Penyusutan dan Amortisasi</Option>
                                                <Option value="Biaya Sewa" >Biaya Sewa</Option>
                                                <Option value="Biaya Transportasi" >Biaya Transportasi</Option>
                                                <Option value="Biaya Lainnya" >Biaya Lainnya</Option>
                                                <Option value="Pendapatan" >Pendapatan</Option>
                                                <Option value="Pendapatan Jasa" >Pendapatan Jasa</Option>
                                                <Option value="Pendapatan Penjualan" >Pendapatan Penjualan</Option>
                                                <Option value="Potongan Penjualan" >Potongan Penjualan</Option>
                                                <Option value="Penjualan Belum Ditagih" >Penjualan Belum Ditagih</Option>
                                                <Option value="PPH Pasal 23" >PPH Pasal 23</Option>
                                                <Option value="Hutang" >Hutang</Option>
                                                <Option value="Hutang Usaha" >Hutang Usaha</Option>
                                                <Option value="Hutang Gaji Karyawan" >Hutang Gaji Karyawan</Option>
                                                <Option value="Hutang Lainnya" >Hutang Lainnya</Option>
                                                <Option value="PPH Pasal 21" >PPH Pasal 21</Option>
                                                <Option value="PPN Keluaran" >PPN Keluaran</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row type="flex" style={{marginTop:15}}>
                                        <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Account Tax POS Code')}</Col>
                                        <Col span={15}>
                                            <Select style={{width:'100%'}} defaultValue="PPN 10%">
                                                <Option value="PPN 10%">PPN 10%</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row type="flex" style={{marginTop:15}}>
                                        <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Sales Discounts')}</Col>
                                        <Col span={15}>
                                            <Select style={{width:'100%',fontSize:16}} defaultValue="Asset">
                                                <Option value="Asset" >Asset</Option>
                                                <Option value="Sewa Bayar Dimuka" >Sewa Bayar Dimuka</Option>
                                                <Option value="Akumulasi Penyusutan Peralatan" >Akumulasi Penyusutan Peralatan</Option>
                                                <Option value="Peralatan Kantor" >Peralatan Kantor</Option>
                                                <Option value="Persedian Barang" >Persedian Barang</Option>
                                                <Option value="PPN Masukan" >PPN Masukan</Option>
                                                <Option value="Piutang Belum Ditagih" >Piutang Belum Ditagih</Option>
                                                <Option value="Piutang" >Piutang</Option>
                                                <Option value="Bank" >Bank</Option>
                                                <Option value="Kas Bank POS" >Kas Bank POS </Option>
                                                <Option value="Kas POS" >Kas POS</Option>
                                                <Option value="Kas" >Kas</Option>
                                                <Option value="Harga Tetap" >Harga Tetap</Option>
                                                <Option value="Harga Lancar" >Harga Lancar</Option>
                                                <Option value="Saldo Awal" >Saldo Awal</Option>
                                                <Option value="Modal" >Modal</Option>
                                                <Option value="Ekuitas Saldo Awal" >Ekuitas Saldo Awal</Option>
                                                <Option value="Prive" >Prive</Option>
                                                <Option value="Biaya Gaji" >Biaya Gaji</Option>
                                                <Option value="Harga Pokok Penjualan" >Harga Pokok Penjualan</Option>
                                                <Option value="Biaya" >Biaya</Option>
                                                <Option value="Biaya Penyusutan dan Amortisasi" >Biaya Penyusutan dan Amortisasi</Option>
                                                <Option value="Biaya Sewa" >Biaya Sewa</Option>
                                                <Option value="Biaya Transportasi" >Biaya Transportasi</Option>
                                                <Option value="Biaya Lainnya" >Biaya Lainnya</Option>
                                                <Option value="Pendapatan" >Pendapatan</Option>
                                                <Option value="Pendapatan Jasa" >Pendapatan Jasa</Option>
                                                <Option value="Pendapatan Penjualan" >Pendapatan Penjualan</Option>
                                                <Option value="Potongan Penjualan" >Potongan Penjualan</Option>
                                                <Option value="Penjualan Belum Ditagih" >Penjualan Belum Ditagih</Option>
                                                <Option value="PPH Pasal 23" >PPH Pasal 23</Option>
                                                <Option value="Hutang" >Hutang</Option>
                                                <Option value="Hutang Usaha" >Hutang Usaha</Option>
                                                <Option value="Hutang Gaji Karyawan" >Hutang Gaji Karyawan</Option>
                                                <Option value="Hutang Lainnya" >Hutang Lainnya</Option>
                                                <Option value="PPH Pasal 21" >PPH Pasal 21</Option>
                                                <Option value="PPN Keluaran" >PPN Keluaran</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row type="flex" style={{marginTop:15}}>
                                        <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Standard Sales POS Description')}</Col>
                                        <Col span={15}><Input defaultValue="Penjualan POS"/></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Divider type="horizontal"/>
                            <p style={{fontWeight:'bold',fontSize:18}}>{t('Number Start')}</p>
                            <Row type="flex" justify="space-between">
                                <Col span={12}>
                                    <Row type="flex" >
                                        <Col span={8} style={{fontWeight:'bold',fontSize:14}}>{t('Offer')}</Col>
                                        <Col span={15}><Input defaultValue="SQ" /></Col>
                                    </Row>
                                    <Row type="flex" style={{marginTop:15}}>
                                        <Col span={8} style={{fontWeight:'bold',fontSize:14}}>{t('Booking')}</Col>
                                        <Col span={15}><Input defaultValue="SO" /></Col>
                                    </Row>
                                    <Row type="flex" style={{marginTop:15}}>
                                        <Col span={8} style={{fontWeight:'bold',fontSize:14}}>{t('Billing')}</Col>
                                        <Col span={15}><Input defaultValue="SI" /></Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row type="flex" >
                                        <Col span={8} style={{fontWeight:'bold',fontSize:14}}>{t('Delivery')}</Col>
                                        <Col span={15}><Input defaultValue="SD" /></Col>
                                    </Row>
                                    <Row type="flex" style={{marginTop:15}}>
                                        <Col span={8} style={{fontWeight:'bold',fontSize:14}}>{t('Payment')}</Col>
                                        <Col span={15}><Input defaultValue="PY" /></Col>
                                    </Row>
                                    <Row type="flex" style={{marginTop:15}} >
                                        <Col span={8} style={{fontWeight:'bold',fontSize:14}}>{t('Return')}</Col>
                                        <Col span={15}><Input defaultValue="SR" /></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Divider type="horizontal"/>
                            <Button style={{width:120,fontWeight:'bold',backgroundColor:'#595959',color:'white',height:40}}>{t('Cancel')}</Button>
                        </TabPane>
                        <TabPane tab={t('Purchase')} key="4">
                            <p style={{fontSize:20,fontWeight:'bold'}}>{t('Purchase')}</p>
                            <Row type="flex" justify="space-between">
                                <Col span={12}>
                                    <Row type="flex">
                                        <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Main Payment Term')}</Col>
                                        <Col span={15}>
                                            <Select style={{width:'100%'}} defaultValue="Net 30">
                                                <Option value="Net 30">Net 30</Option>
                                                <Option value="Net 15">Net 15</Option>
                                                <Option value="Cash On Delivery">Cash On Delivery</Option>
                                                <Option value="Net 60">Net 60</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row type="flex" style={{marginTop:15}}>
                                        <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Standard Purchase Account')}</Col>
                                        <Col span={15}>
                                            <Select style={{width:'100%'}} defaultValue="Biaya Gaji">
                                                <Option value="Biaya Gaji">Biaya Gaji</Option>
                                                <Option value="Harga Pokok Penjualan">Harga Pokok Penjualan</Option>
                                                <Option value="Biaya">Biaya</Option>
                                                <Option value="Biaya Penyusutan dan Amortisasi">Biaya Penyusutan dan Amortisasi</Option>
                                                <Option value="Biaya Sewa">Biaya Sewa</Option>
                                                <Option value="Biaya Transportasi">Biaya Transportasi</Option>
                                                <Option value="Biaya Lainnya">Biaya Lainnya</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row type="flex" style={{marginTop:15}}>
                                        <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Debt Account')}</Col>
                                        <Col span={15}>
                                            <Select style={{width:'100%',fontSize:16}} defaultValue="Asset">
                                                <Option value="Asset" >Asset</Option>
                                                <Option value="Sewa Bayar Dimuka" >Sewa Bayar Dimuka</Option>
                                                <Option value="Akumulasi Penyusutan Peralatan" >Akumulasi Penyusutan Peralatan</Option>
                                                <Option value="Peralatan Kantor" >Peralatan Kantor</Option>
                                                <Option value="Persedian Barang" >Persedian Barang</Option>
                                                <Option value="PPN Masukan" >PPN Masukan</Option>
                                                <Option value="Piutang Belum Ditagih" >Piutang Belum Ditagih</Option>
                                                <Option value="Piutang" >Piutang</Option>
                                                <Option value="Bank" >Bank</Option>
                                                <Option value="Kas Bank POS" >Kas Bank POS </Option>
                                                <Option value="Kas POS" >Kas POS</Option>
                                                <Option value="Kas" >Kas</Option>
                                                <Option value="Harga Tetap" >Harga Tetap</Option>
                                                <Option value="Harga Lancar" >Harga Lancar</Option>
                                                <Option value="Saldo Awal" >Saldo Awal</Option>
                                                <Option value="Modal" >Modal</Option>
                                                <Option value="Ekuitas Saldo Awal" >Ekuitas Saldo Awal</Option>
                                                <Option value="Prive" >Prive</Option>
                                                <Option value="Biaya Gaji" >Biaya Gaji</Option>
                                                <Option value="Harga Pokok Penjualan" >Harga Pokok Penjualan</Option>
                                                <Option value="Biaya" >Biaya</Option>
                                                <Option value="Biaya Penyusutan dan Amortisasi" >Biaya Penyusutan dan Amortisasi</Option>
                                                <Option value="Biaya Sewa" >Biaya Sewa</Option>
                                                <Option value="Biaya Transportasi" >Biaya Transportasi</Option>
                                                <Option value="Biaya Lainnya" >Biaya Lainnya</Option>
                                                <Option value="Pendapatan" >Pendapatan</Option>
                                                <Option value="Pendapatan Jasa" >Pendapatan Jasa</Option>
                                                <Option value="Pendapatan Penjualan" >Pendapatan Penjualan</Option>
                                                <Option value="Potongan Penjualan" >Potongan Penjualan</Option>
                                                <Option value="Penjualan Belum Ditagih" >Penjualan Belum Ditagih</Option>
                                                <Option value="PPH Pasal 23" >PPH Pasal 23</Option>
                                                <Option value="Hutang" >Hutang</Option>
                                                <Option value="Hutang Usaha" >Hutang Usaha</Option>
                                                <Option value="Hutang Gaji Karyawan" >Hutang Gaji Karyawan</Option>
                                                <Option value="Hutang Lainnya" >Hutang Lainnya</Option>
                                                <Option value="PPH Pasal 21" >PPH Pasal 21</Option>
                                                <Option value="PPN Keluaran" >PPN Keluaran</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                <Row type="flex" >
                                        <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Account Kas POS Code')}</Col>
                                        <Col span={15}>
                                            <Select style={{width:'100%',fontSize:16}} defaultValue="Asset">
                                                <Option value="Asset" >Asset</Option>
                                                <Option value="Sewa Bayar Dimuka" >Sewa Bayar Dimuka</Option>
                                                <Option value="Akumulasi Penyusutan Peralatan" >Akumulasi Penyusutan Peralatan</Option>
                                                <Option value="Peralatan Kantor" >Peralatan Kantor</Option>
                                                <Option value="Persedian Barang" >Persedian Barang</Option>
                                                <Option value="PPN Masukan" >PPN Masukan</Option>
                                                <Option value="Piutang Belum Ditagih" >Piutang Belum Ditagih</Option>
                                                <Option value="Piutang" >Piutang</Option>
                                                <Option value="Bank" >Bank</Option>
                                                <Option value="Kas Bank POS" >Kas Bank POS </Option>
                                                <Option value="Kas POS" >Kas POS</Option>
                                                <Option value="Kas" >Kas</Option>
                                                <Option value="Harga Tetap" >Harga Tetap</Option>
                                                <Option value="Harga Lancar" >Harga Lancar</Option>
                                                <Option value="Saldo Awal" >Saldo Awal</Option>
                                                <Option value="Modal" >Modal</Option>
                                                <Option value="Ekuitas Saldo Awal" >Ekuitas Saldo Awal</Option>
                                                <Option value="Prive" >Prive</Option>
                                                <Option value="Biaya Gaji" >Biaya Gaji</Option>
                                                <Option value="Harga Pokok Penjualan" >Harga Pokok Penjualan</Option>
                                                <Option value="Biaya" >Biaya</Option>
                                                <Option value="Biaya Penyusutan dan Amortisasi" >Biaya Penyusutan dan Amortisasi</Option>
                                                <Option value="Biaya Sewa" >Biaya Sewa</Option>
                                                <Option value="Biaya Transportasi" >Biaya Transportasi</Option>
                                                <Option value="Biaya Lainnya" >Biaya Lainnya</Option>
                                                <Option value="Pendapatan" >Pendapatan</Option>
                                                <Option value="Pendapatan Jasa" >Pendapatan Jasa</Option>
                                                <Option value="Pendapatan Penjualan" >Pendapatan Penjualan</Option>
                                                <Option value="Potongan Penjualan" >Potongan Penjualan</Option>
                                                <Option value="Penjualan Belum Ditagih" >Penjualan Belum Ditagih</Option>
                                                <Option value="PPH Pasal 23" >PPH Pasal 23</Option>
                                                <Option value="Hutang" >Hutang</Option>
                                                <Option value="Hutang Usaha" >Hutang Usaha</Option>
                                                <Option value="Hutang Gaji Karyawan" >Hutang Gaji Karyawan</Option>
                                                <Option value="Hutang Lainnya" >Hutang Lainnya</Option>
                                                <Option value="PPH Pasal 21" >PPH Pasal 21</Option>
                                                <Option value="PPN Keluaran" >PPN Keluaran</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row type="flex" style={{marginTop:15}}>
                                        <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Purchase Discounts')}</Col>
                                        <Col span={15}>
                                            <Select style={{width:'100%',fontSize:16}} defaultValue="Asset">
                                                <Option value="Asset" >Asset</Option>
                                                <Option value="Sewa Bayar Dimuka" >Sewa Bayar Dimuka</Option>
                                                <Option value="Akumulasi Penyusutan Peralatan" >Akumulasi Penyusutan Peralatan</Option>
                                                <Option value="Peralatan Kantor" >Peralatan Kantor</Option>
                                                <Option value="Persedian Barang" >Persedian Barang</Option>
                                                <Option value="PPN Masukan" >PPN Masukan</Option>
                                                <Option value="Piutang Belum Ditagih" >Piutang Belum Ditagih</Option>
                                                <Option value="Piutang" >Piutang</Option>
                                                <Option value="Bank" >Bank</Option>
                                                <Option value="Kas Bank POS" >Kas Bank POS </Option>
                                                <Option value="Kas POS" >Kas POS</Option>
                                                <Option value="Kas" >Kas</Option>
                                                <Option value="Harga Tetap" >Harga Tetap</Option>
                                                <Option value="Harga Lancar" >Harga Lancar</Option>
                                                <Option value="Saldo Awal" >Saldo Awal</Option>
                                                <Option value="Modal" >Modal</Option>
                                                <Option value="Ekuitas Saldo Awal" >Ekuitas Saldo Awal</Option>
                                                <Option value="Prive" >Prive</Option>
                                                <Option value="Biaya Gaji" >Biaya Gaji</Option>
                                                <Option value="Harga Pokok Penjualan" >Harga Pokok Penjualan</Option>
                                                <Option value="Biaya" >Biaya</Option>
                                                <Option value="Biaya Penyusutan dan Amortisasi" >Biaya Penyusutan dan Amortisasi</Option>
                                                <Option value="Biaya Sewa" >Biaya Sewa</Option>
                                                <Option value="Biaya Transportasi" >Biaya Transportasi</Option>
                                                <Option value="Biaya Lainnya" >Biaya Lainnya</Option>
                                                <Option value="Pendapatan" >Pendapatan</Option>
                                                <Option value="Pendapatan Jasa" >Pendapatan Jasa</Option>
                                                <Option value="Pendapatan Penjualan" >Pendapatan Penjualan</Option>
                                                <Option value="Potongan Penjualan" >Potongan Penjualan</Option>
                                                <Option value="Penjualan Belum Ditagih" >Penjualan Belum Ditagih</Option>
                                                <Option value="PPH Pasal 23" >PPH Pasal 23</Option>
                                                <Option value="Hutang" >Hutang</Option>
                                                <Option value="Hutang Usaha" >Hutang Usaha</Option>
                                                <Option value="Hutang Gaji Karyawan" >Hutang Gaji Karyawan</Option>
                                                <Option value="Hutang Lainnya" >Hutang Lainnya</Option>
                                                <Option value="PPH Pasal 21" >PPH Pasal 21</Option>
                                                <Option value="PPN Keluaran" >PPN Keluaran</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Divider type="horizontal"/>
                            <p style={{fontWeight:'bold',fontSize:18}}>{t('Number Start')}</p>
                            <Row type="flex" justify="space-between">
                                <Col span={12}>
                                    <Row type="flex" >
                                        <Col span={8} style={{fontWeight:'bold',fontSize:14}}>{t('Request')}</Col>
                                        <Col span={15}><Input defaultValue="PR" /></Col>
                                    </Row>
                                    <Row type="flex" style={{marginTop:15}}>
                                        <Col span={8} style={{fontWeight:'bold',fontSize:14}}>{t('Reception')}</Col>
                                        <Col span={15}><Input defaultValue="QR" /></Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row type="flex" >
                                        <Col span={8} style={{fontWeight:'bold',fontSize:14}}>{t('Booking')}</Col>
                                        <Col span={15}><Input defaultValue="PO" /></Col>
                                    </Row>
                                    <Row type="flex" style={{marginTop:15}}>
                                        <Col span={8} style={{fontWeight:'bold',fontSize:14}}>{t('Payment')}</Col>
                                        <Col span={15}><Input defaultValue="PY" /></Col>
                                    </Row>
                                    <Row type="flex" style={{marginTop:15}} >
                                        <Col span={8} style={{fontWeight:'bold',fontSize:14}}>{t('Return')}</Col>
                                        <Col span={15}><Input defaultValue="RG" /></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Divider type="horizontal"/>
                            <Button style={{width:120,fontWeight:'bold',backgroundColor:'#595959',color:'white',height:40}}>{t('Cancel')}</Button>
                        </TabPane>
                    </Tabs>
                </Card>
            </Layout.Content>
        )
    }
}
export default withTranslation()(Setting) 