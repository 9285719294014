import React, { Component } from 'react'
import { Layout, 
    Row, 
    Col, 
    Breadcrumb, 
    Typography, 
    PageHeader, 
    Button, 
    Select, 
    Table,
    Input, 
    Divider, 
    DatePicker,
    message,
    Upload
    } from 'antd'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

class AddRekonsiliasi extends React.Component {
    
    render(){
        const {Title, Text} = Typography
        const {Option} = Select
        const { t } = this.props
        const dateFormat =' DD/MM/YYYY'
        const {TextArea} = Input
        
        return(
            <Layout.Content>
            <PageHeader style={{border: '1px solid rgb(235,237,340)'}}>
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Make Submission Overtime')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Personnel')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/overtime">{t('Submission Overtime')} </a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/overtime/create">{t('Make Submission Overtime')} </a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            
                            </Row>
                        </Col>
                    </Row>
                </div>
            </PageHeader>
                <div  style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                    <Row>
                        <Col span={12}>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Date')}</label></Col>
                                <Col span={15}><DatePicker style={{width:'100%'}} defaultValue={moment('28/2/2020',dateFormat)} format={dateFormat} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Descriptions')}</label></Col>
                                <Col span={15}><TextArea rows={4} style={{width:'100%'}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Project')}</label></Col>
                                <Col span={15}><Select style={{width:'100%',fontSize:16}} placeholder={t('Not Set')}>
                                                 <Option value="" ><Input></Input></Option>
                                               </Select></Col>
                            </Row>
                            
                        </Col>
                        <Col span={12}>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Sumittd By')}</label></Col>
                                <Col span={15}><Select style={{width:'100%',fontSize:16}} placeholder={t('Select')}>
                                                 <Option value="" ><Input></Input></Option>
                                                 <Option value="Eriza Muhammad" >Eriza Muhammad</Option>
                                               </Select>
                                </Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8}  style={{fontWeight:'bold'}}><label>{t('Overtime Hours')}</label></Col>
                                <Col span={5}  ><Input placeholder='0:00' rows={4}  /></Col>
                                <Col span={5} style={{fontWeight:'bold', marginLeft:20}}><label>{t('Untill')}</label></Col>
                                <Col span={5}><Input placeholder='0:00' rows={4}  /></Col>
                            </Row>
                            
                        </Col>
                    </Row>
                    </div>

                    <Divider type="horizontal"/>
                    <Row type="flex" style={{marginBottom:30}}>
                        <Col style={{marginTop:15,marginLeft:10,marginRight:10}}><Button href="/overtime" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Cancel')}</Button></Col>
                        <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Make Submission Overtime')} </Button></Col>
                    </Row>
                    

            </Layout.Content>

        )
    }
}

export default withTranslation() (AddRekonsiliasi)