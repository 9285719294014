import React, { Component } from 'react'
import { Layout, 
         Row, 
         Col, 
         Breadcrumb, 
         Typography, 
         PageHeader, 
         DatePicker,
         Button, 
         Icon, 
         Select, 
         Input,  
         Table, } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next';

class ProfitLoss extends React.Component {
    render(){
        const {Title, Text} = Typography
        const {Option} = Select
        const dateFormat =' DD/MM/YYYY'
        const { t } = this.props;
        const columns = [
            {
              title: `${t('From')}`,
              dataIndex: 'From',
              key: 'From',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('To')}`,
              dataIndex: 'To',
              key: 'To',
            },
            {
              title: `${t(' Title')}`,
              dataIndex: ' Title',
              key: ' Title',
            },
            {
                title: 'Description',
                dataIndex: 'Description',
                key: 'Description',
            },
            
          ];
        return(
            <Layout.Content>
                <PageHeader style={{border: '1px solid rgb(235,237,340)'}}>
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Income statement')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Accounting')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/profit-loss">{t('Income statement')} </a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                </PageHeader>

                <div style={{padding:20}}>
                <Row type='flex'justify='space-between'>
                    <Col>
                        <Button href="/profit-loss/create" style={{margin:10, backgroundColor:"#505458", color:"white"}}><Icon type="plus"/>{t('Make Income')}</Button>
                    </Col>
                   
                    <Col>
                        <DatePicker style={{margin:10, }} placeholder={t('Period')} defaultValue={moment('1/2/2020',dateFormat)} format={dateFormat} />
                    
                        <DatePicker style={{margin:10 , }} placeholder={t('Until ')} defaultValue={moment('1/2/2020',dateFormat)} format={dateFormat} />
                    
                        <Button href="" style={{margin:10, backgroundColor:"#505458", color:"white"}}><Icon type="search"/>Filter</Button>
                    </Col>
                
                </Row>
                </div>

                <Row type="flex" justify="space-between" style={{padding:10, margin:10}}>
                    <Col > 
                        <Row type="flex">
                            <Col><h3>Per</h3></Col>
                            <Select defaultValue="25" >
                                <Option value="25">25</Option>
                                <Option value="50">50</Option>
                                <Option value="100">100</Option>
                                <Option value="200">200</Option>
                                <Option value="All">{t('All')}</Option>
                            </Select>
                            <Col><h3>{t('Line')}</h3></Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row type="flex">
                        <Col span={5} style={{marginRight:10}}><h3>{t('Search')}</h3></Col>
                        <Col span={15}><Input></Input></Col>
                        </Row>
                    </Col>
                </Row>
                <div style={{margin:5, padding:20}}>
                    <Table columns={columns}></Table>
                </div>
            </Layout.Content>
        )
    }

}
export default withTranslation() (ProfitLoss)