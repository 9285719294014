import React, { Component } from 'react'
import { Row, Col, Layout } from 'antd'

const { Footer } = Layout

export class FooterComponent extends Component {
    render() {
        return (
            <Footer className="footer">
                <Row type="flex">
                    <Col span={24}>&copy; 2020 Kelolabiz</Col>
                </Row>
          </Footer>
        )
    }
}

export default FooterComponent
