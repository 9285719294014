import React, { Component } from 'react'
import { Layout, Typography, Row, Col, Breadcrumb, Divider, Card, DatePicker, Select, Input, Radio, Button } from 'antd'
import { withTranslation } from 'react-i18next'

class ProsesPayment extends Component{
    render(){
        const {Title} = Typography
        const dataFormat = 'DD/MM/YYYY'
        const Option = Select
        const {t} = this.props
        return(
            <Layout.Content>
                <div style={{marginTop:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Setting')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Bill')} </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <a href="/setting/prosespayment">{t('Payment Process')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Card style={{borderRadius:10,borderWidth:2}}>
                    <p style={{fontWeight:'bold',fontSize:22}}>{t('Payment Process')}</p>
                    <Divider type="horizontal"/>
                    <Row type="flex">
                        <Col style={{fontSize:14,fontWeight:'bold'}} span={4}>{t('Payment Date')}</Col>
                        <Col span={7}><DatePicker style={{width:'100%'}} placeholder="dd/mm/yyyy" format={dataFormat}/></Col>
                    </Row>
                    <Row type="flex" justify="space-between">
                        <Col span={12}>
                            
                            <Row type="flex" style={{marginTop:15}}>
                                <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Package')}</Col>
                                <Col span={15}>
                                    <Select style={{width:'100%'}} defaultValue="Paket ERP">
                                        <Option value="Paket ERP">Paket ERP</Option>
                                        <Option value="Paket Personalia">Paket Personalia</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Payment Period')}</Col>
                                <Col span={15}>
                                    <Select style={{width:'100%'}} placeholder="pilih">
                                        <Option value="1 Bulan">1 Bulan</Option>
                                        <Option value="3 Bulan">3 Bulan</Option>
                                        <Option value="6 Bulan">6 Bulan</Option>
                                        <Option value="12 Bulan">12 Bulan</Option>
                                        <Option value="24 Bulan">24 Bulan</Option>
                                        <Option value="36 Bulan">36 Bulan</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Package Price')}</Col>
                                <Col style={{fontSize:14,fontWeight:'bold'}} span={2}>Rp.</Col>
                                <Col span={13}><Input readOnly style={{backgroundColor:'#e8e8e8'}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Price for 1 additional user')}</Col>
                                <Col style={{fontSize:14,fontWeight:'bold'}} span={2}>Rp.</Col>
                                <Col span={13}><Input readOnly style={{backgroundColor:'#e8e8e8'}} /></Col>
                            </Row>
                        </Col>
                    </Row>
                    
                    <p style={{fontWeight:'bold',fontSize:20,marginTop:20}}>{t('User')}</p>
                    <Divider type="horizontal"/>
                    <Row type="flex" justify="space-between">
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('Early User')}</Col>
                                <Col span={15}><Input defaultValue="0" readOnly style={{backgroundColor:'#e8e8e8',textAlign:'right'}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Total Package Price')}</Col>
                                <Col style={{fontSize:14,fontWeight:'bold'}} span={2}>Rp.</Col>
                                <Col span={13}><Input readOnly style={{backgroundColor:'#e8e8e8'}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Active Period')}</Col>
                                <Col span={15}>
                                    <Row type="flex" justify="space-between">
                                        <Col span={11}><DatePicker format={dataFormat} placeholder="dd/mm/yyyy" /></Col>
                                        <Col span={11}><DatePicker format={dataFormat} placeholder="dd/mm/yyyy" /></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Next Pay Date')}</Col>
                                <Col span={15}><DatePicker style={{width:'100%'}} placeholder="dd/mm/yyyy" format={dataFormat}/></Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={8} style={{fontSize:14,fontWeight:'bold'}}>{t('User Additional')}</Col>
                                <Col span={15}><Input defaultValue="0"  style={{backgroundColor:'#e8e8e8',textAlign:'right'}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}}>
                                <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('User Additional Fees')}</Col>
                                <Col style={{fontSize:14,fontWeight:'bold'}} span={2}>Rp.</Col>
                                <Col span={13}><Input readOnly style={{backgroundColor:'#e8e8e8'}} /></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row type="flex" style={{marginTop:15}} >
                        <Col span={4} style={{fontSize:14,fontWeight:'bold'}}>{t('Payment Method')}</Col>
                        <Col span={19}>
                                <Row type="flex" justify="space-between">
                                    <Col><Radio value={1}>Transfer</Radio></Col>
                                    <Col><Radio value={2}>{t('Credit Card')}</Radio></Col>
                                    <Col><Radio value={3}>Paypal</Radio></Col>
                                </Row>
                        </Col>
                    </Row>
                    <Row type="flex" justify="space-between">
                        <Col span={12}>
                             <Row type="flex" style={{marginTop:15}} >
                                <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Pay Total')}</Col>
                                <Col style={{fontSize:14,fontWeight:'bold'}} span={2}>Rp.</Col>
                                <Col span={13}><Input readOnly style={{backgroundColor:'#e8e8e8'}} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:15}} >
                                <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Payment Total')}</Col>
                                <Col style={{fontSize:14,fontWeight:'bold'}} span={2}>Rp.</Col>
                                <Col span={13}><Input readOnly style={{backgroundColor:'#e8e8e8'}} /></Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                        <Row type="flex" style={{marginTop:15}} >
                                <Col style={{fontSize:14,fontWeight:'bold'}} span={8}>{t('Unique Code')}</Col>
                                <Col span={15}><Input readOnly style={{backgroundColor:'#e8e8e8'}} /></Col>
                            </Row>
                        </Col>
                    </Row>

                    
                </Card>
                <Row type="flex" style={{marginTop:10}}>
                    <Col style={{marginTop:15,marginRight:20}}><Button  style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                    <Col style={{marginTop:15}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Pay Now')} </Button></Col>
                </Row>
                
            </Layout.Content>
        )
    }
}
export default withTranslation()(ProsesPayment) 