import React, { Component } from 'react'
import { Layout, 
    Row, 
    Col, 
    Breadcrumb, 
    Typography, 
    PageHeader, 
    Button, 
    Select, 
    Table,
    Input, 
    Divider, 
    DatePicker,
    message,
    Upload,
    List
    } from 'antd'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

class AddRekonsiliasi extends React.Component {
    render(){
        const {Title, Text} = Typography
        const {Option} = Select
        const { t } = this.props
        const { RangePicker } = DatePicker
        const dateFormat = ['DD/MM/YYYY', 'DD/MM/YYYY']
        const {TextArea} = Input
        const columns = [
            {
              title: 'Sick',
              dataIndex: 'sick',
              key: 'sick',
            },
            {
              title: 'Filed',
              dataIndex: 'filed',
              key: 'filed',
            },
            {
              title: 'Approved',
              dataIndex: 'approved',
              key: 'approved',
            },
            {
              title: 'The Rest Of It',
              dataIndex: 'therestofit',
              key: 'therestofit',
            },
          ];
          const data = [
            {
              key: 1,
              sick: 'Tahun ini',
              filed: 0,
              approved: 0,
              therestofit:'-',
            },
            {
                key: 2,
                sick: 'Keseluruhan',
                filed: 0,
                approved: 0,
                therestofit:' ',
            },
          ];
          
        
        return(
            <Layout.Content>
            <PageHeader style={{border: '1px solid rgb(235,237,340)'}}>
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Make Absence Submissions')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Personnel')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/leave">{t('Absent Submission')} </a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/leave/create">{t('Make Absence Submissions')} </a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            
                            </Row>
                        </Col>
                    </Row>
                </div>
            </PageHeader>
                <div  style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                    <Row>
                        <Col span={12}>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Date')}</label></Col>
                                <Col span={15}><RangePicker defaultValue={[moment('01/01/2020', dateFormat), moment('01/01/2020', dateFormat), <Button>OK</Button>, <Button>Cancel</Button>] }/></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Number of Days')}</label></Col>
                                <Col span={15}><h4>1 Hari</h4></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Descriptions')}</label></Col>
                                <Col span={15}><TextArea rows={4} style={{width:'100%'}} /></Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Sumittd By')}</label></Col>
                                <Col span={15}><Select style={{width:'100%',fontSize:16}} placeholder={t('Select')}>
                                                 <Option value="" ><Input></Input></Option>
                                                 <Option value="Eriza Muhammad" >Eriza Muhammad</Option>
                                               </Select>
                                </Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Absent Type')}</label></Col>
                                <Col span={15}><Select style={{width:'100%',fontSize:16}} placeholder={t('Tidak Diset')}>
                                                 <Option value="" ><Input></Input></Option>
                                                 <Option value="Sakit">{t('Sick')} </Option>
                                                 <Option value="Ijin">{t('Permission')}</Option>
                                                 <Option value="Cuti Haid" >{t('Menstruation leave')}</Option>
                                                 <Option value="Cuti Menikah (3 Hari)" >{t('Married Leave (3 Days)')}</Option>
                                                 <Option value="Cuti Tahunan (12 Hari)" >{t('Annual Leave (12 Days)')}</Option>
                                                 <Option value="Cuti Besar (12 Hari)" >{t('Large Leave (12 Days)')}</Option>
                                                 <Option value="Cuti Melahirkan (66 hari)" >{t('Childbirth Leave (66 Days)')}</Option>
                                               </Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table style={{marginTop:10}} columns={columns} dataSource={data}></Table>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    </div>

                    <Divider type="horizontal"/>
                    <Row type="flex" style={{marginBottom:30}}>
                        <Col style={{marginTop:15,marginLeft:10,marginRight:10}}><Button href="/leave" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Cancel')}</Button></Col>
                        <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Make Absence Submissions')} </Button></Col>
                    </Row>
                    

            </Layout.Content>

        )
    }
}

export default withTranslation() (AddRekonsiliasi)