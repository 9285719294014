import React, { Component } from 'react'
import { Layout, 
         Row, 
         Col, 
         Breadcrumb, 
         Typography, 
         PageHeader, 
         Button, 
         Icon, 
         Select, 
         Input, 
         Table } from 'antd'
import { List } from 'antd'
import { withTranslation } from 'react-i18next'

class UserGroup extends Component {
    render(){
        const {Title} = Typography
        const {Option} = Select
        const { t } = this.props
        const columns = [
            {
              title: `${t('Description')}`,
              dataIndex: 'Description',
              key: 'Description',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('Start Page (After Entering)')}`,
              dataIndex: 'Start Page (After Entering)',
              key: 'Start Page (After Entering)',
            },
          ];
          const data = [
            {
              title: `${t('Accounting & Finance')}`,
            },
            {
              title: 'Administrator',
            },
            {
              title: `${t('Finance')}`,
            },
            {
              title: `${t('Marketing')}`,
            },
            {
                title: `${t('Employee')}`,
              },
              {
                title: `${t('PPIC')}`,
              },
              {
                title: `${t('Purchasing & HRD')}`,
              },
          ];
        return(
            <Layout.Content>
                <PageHeader style={{border: '1px solid rgb(235,237,340)'}}>
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('User Group')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Settings')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/role">{t('User Group')} </a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                </PageHeader>

                <div style={{padding:20}}>
                <Row type='flex'justify='space-between'>
                <Button href="/role/create" style={{margin:10, backgroundColor:"#505458", color:"white"}}><Icon type="plus"/>{t('Create User Group')}</Button>
                </Row>
                </div>

                <Row type="flex" justify="space-between" style={{padding:10, margin:10}}>
                    <Col > 
                        <Row type="flex">
                            <Col><h3>Per</h3></Col>
                            <Select defaultValue="10" >
                                <Option value="10">10</Option>
                                <Option value="25">25</Option>
                                <Option value="50">50</Option>
                                <Option value="100">100</Option>
                                <Option value="200">200</Option>
                                <Option value="All">{t('All')}</Option>
                            </Select>
                            <Col><h3>{('Line')}</h3></Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row type="flex">
                        <Col span={5}><h3 style={{marginRight:10}}>{t('Search')}</h3></Col>
                        <Col span={15}><Input></Input></Col>
                        </Row>
                    </Col>
                </Row>
                
                <div style={{margin:5, padding:20}}>
                    <List
                      header={<Row type="flex" style={{fontWeight:'bold', fontSize:16, backgroundColor:'#595959',color:'white', lineHeight:'2'}}>
                                <Col span={12} style={{marginTop:2, paddingLeft:10}}>Deskripsi</Col>
                                <Col span={12}>{t('Start Page (After Entering)')}</Col>
                              </Row>}
                      dataSource={data}  renderItem={item => (
                      <List.Item >
                        <List.Item.Meta
                            title={<a href="/menu1">{item.title}</a>}/>
                      </List.Item>
                    )}> </List> 
                </div>
                    
                
            </Layout.Content>
        )
    }
}
export default withTranslation() (UserGroup)