import React, { Component, Fragment } from 'react'
import { Layout, Row, Col, Breadcrumb, Typography, Button, Table, Input, Icon, Select, Form, Menu, Dropdown, Modal, Skeleton } from 'antd'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchCustomer, deleteCustomer } from '../../stores/actions/customerActions'
import { withTranslation } from 'react-i18next'

const { Search } = Input
const { Option } = Select
const { Title } = Typography
const { confirm } = Modal

class Customer extends Component{

    state = {
        keyword: '',
        perpage: 10,
        sort: 'code',
        order: 'ascend'
    }

    componentDidMount = () => {
        
        this.props.fetchCustomer({
            page: 1,
            perpage: this.state.perpage,
            sort: this.state.sort,
            order: this.state.order
        })
    }

    handleSearch = (value) => {

        this.setState({
            ...this.state,
            keyword: value
        })

        this.props.fetchCustomer({
            page: 1,
            perpage: this.state.perpage,
            sort: this.state.sort,
            order: this.state.order,
            keyword: value
        })
    }

    handleTableChange = (pagination, filters, sorter) => {
        
        this.setState({
            ...this.state,
            sort: sorter.field,
            order: sorter.order,
        })

        this.props.fetchCustomer({
            page: pagination.current,
            perpage: this.state.perpage,
            keyword: this.state.keyword,
            sort: sorter.field,
            order: sorter.order
        })
    }

    handlePerPage = (value) => {
        
        this.setState({
            ...this.state,
            perpage: value
        })

        this.props.fetchCustomer({
            page: this.state.page,
            perpage: value,
            keyword: this.state.keyword,
            sort: this.state.sort,
            order: this.state.order
        })

    }

    deleteModal = async (id) => {
        const { t } = this.props

        confirm({
            title: t('Are you sure you want to delete this data?'),
            content: t('Warning! Data you delete cannot be restored!'),
            onOk: () => {
                this.props.deleteCustomer(id)
                this.props.fetchCustomer({
                    page: 1,
                    perpage: this.state.perpage,
                    sort: this.state.sort,
                    order: this.state.order,
                    keyword: this.state.keyword
                })
            },
            onCancel() {},
            okButtonProps: {
                loading: this.props.fetching
            },
            okText: t('Ok'),
            cancelText: t('Cancel')
        })
    }

    render(){

        if (!sessionStorage.getItem('token'))
            return <Redirect to="/login" />
        
        const { fetching, t } = this.props

        const columns = [
            {
                title: t('Code'),
                dataIndex: 'code',
                sorter: true,
                defaultSortOrder: 'ascend'
            },
            {
                title: t('Name'),
                dataIndex: 'name',
                sorter: true
            },
            {
                title: t('Telephone'),
                dataIndex: 'phone_1',
                sorter: true
            },
            {
                title: t('Address'),
                dataIndex: 'address',
                sorter: true
            },
            {
                title: '',
                dataIndex: '_id',
                align: 'right',
                sorter: false,
                fixed: 'right',
                width: 100,
                render: (id) => {
                    return (
                        <Fragment>
                            <Dropdown overlay={
                                 <Menu>
                                    <Menu.Item key="0">
                                        <Link to={`/customer/view/${id}`}><Icon type="eye" className="mr-10" />{t('View')}</Link>
                                    </Menu.Item>
                                    <Menu.Item key="1">
                                        <Link to={`/customer/edit/${id}`}><Icon type="edit" className="mr-10" />{t('Edit')}</Link>
                                    </Menu.Item>
                                    <Menu.Divider />
                                    <Menu.Item key="3">
                                        <span onClick={() => this.deleteModal(id) } to={`/customer/${id}`}><Icon type="delete" className="mr-10" />{t('Delete')}</span>
                                    </Menu.Item>
                                </Menu>
                            } trigger={['click']}>
                                <span className="pointer" onClick={e => e.preventDefault()}>
                                    <Icon type="more" />
                                </span>
                            </Dropdown>
                        </Fragment>
                    )
                }
            }
        ]


        return(
            <Layout.Content>
                <div style={{margin:10,padding:20}}>
                    <Row  type="flex" justify="space-around">
                        <Col span={12}><Title level={4}>{t('Customer')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Other Data')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>{t('Customer')}</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                </div>
                    <Skeleton active loading={fetching} />
                    {
                        !fetching && (        
                            <Row type="flex" gutter={20}>
                                <Col span={8} className="mb-20">
                                    <div className="card card-orange">
                                        <Col>
                                            <h5 className="pl-10 pr-25">{t('SALES ARE NOT PAID')}</h5>
                                            <Title className="pl-10 pr-25" level={2}>Rp. 27.500,00</Title>
                                        </Col>
                                        <Icon className="card-placeholder" type="book" />
                                    </div> 
                                </Col>

                                <Col span={8} className="mb-20">
                                    <div className="card card-blue">
                                        <Col>
                                            <h5 className="pl-10 pr-25">{t('SALES FALL TEMPO')}</h5>
                                            <Title className="pl-10 pr-25" level={2}>Rp. 27.500,00</Title>
                                        </Col>
                                        <Icon className="card-placeholder" type="book" />
                                    </div> 
                                </Col>

                                <Col span={8} className="mb-20">
                                    <div className="card card-green">
                                        <Col>
                                            <h5 className="pl-10 pr-25">{t('PAYMENT RECEIVED LAST 30 DAYS')}</h5>
                                            <Title className="pl-10 pr-25" level={2}>Rp. 0,00</Title>
                                        </Col>
                                        <Icon className="card-placeholder" type="book" />
                                    </div> 
                                </Col>

                            
                            </Row>
                        )
                    }

                    <Row>
                        <Col span={24}>
                            <Link to="/customer/create"><Button type="primary"><Icon type="plus" />{t('For customers')}</Button></Link>
                        </Col>
                        <Col span={24} className="mt-20">
                            <Row type="flex" justify="space-between">
                                <Col>
                                    <Form labelCol={{span: 12}} wrapperCol={{span: 12}}>
                                        <Form.Item label="Perbaris">
                                            <Select defaultValue="10" onChange={(value) => this.handlePerPage(value)}>
                                                <Option value="10">10</Option>
                                                <Option value="20">20</Option>
                                                <Option value="50">50</Option>
                                            </Select>
                                        </Form.Item>
                                    </Form>
                                </Col>
                               <Col>
                                    <Search
                                        placeholder="Pencarian ..."
                                        onSearch={(value) => this.handleSearch(value)}
                                    />
                                </Col> 
                            </Row>
                        </Col>
                        <Col span={24} className="mb-20">
                            <Table 
                                columns={columns} 
                                dataSource={this.props.customers} 
                                rowKey={(customer) => customer._id}
                                onChange={this.handleTableChange}
                                pagination={{ pageSize: parseInt(this.state.perpage), total: this.props.total }}
                                loading={this.props.fetching}
                            />
                        </Col>
                    </Row>
               
            </Layout.Content>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        fetching: state.customer.fetching,
        fetched: state.customer.fetched,
        customers: state.customer.customers,
        total: state.customer.total,
        errors: state.customer.errors,
        message: state.customer.message,
        deleted: state.customer.deleted
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCustomer: (filter) => dispatch(fetchCustomer(filter)),
        deleteCustomer: (id) => dispatch(deleteCustomer(id))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Customer))