import React, { Component } from 'react'
import { Layout, 
    Row, 
    Col, 
    Breadcrumb, 
    Typography, 
    PageHeader, 
    Button, 
    Select, 
    Table,
    Input, 
    Divider, 
    DatePicker
    } from 'antd'
import { withTranslation } from 'react-i18next'

class AddProfitLoss extends React.Component {
    render(){
        const {Title, Text} = Typography
        
        return(
            <Layout.Content>
                <Text>404 Page Not Found</Text>
            </Layout.Content>

        )
    }
}

export default withTranslation() (AddProfitLoss)