import React, { Component } from 'react'
import { Row, 
         Typography, 
         Col, 
         Breadcrumb, 
         Layout, 
         Divider, 
         Input, 
         Select, 
         DatePicker, 
         Button, 
         Icon, 
         Table,
         PageHeader } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next';

class AddTransfer extends Component{
    render(){
        const {Title} = Typography
        const {Option} = Select
        const dateFormat =' DD/MM/YYYY'
        const {TextArea} = Input
        const { t } = this.props
        const columns = [
            {
              title: `${t('Product')}`,
              dataIndex: 'Product',
              key: 'Product',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('Warehouse')}`,
              dataIndex: 'Warehouse',
              key: 'Warehouse',
            },
            {
              title: `${t('Quantity Before')}`,
              dataIndex: 'Quantity Before',
              key: 'Quantity Before',
            },
            {
                title: `${t('Quantity After')}`,
                dataIndex: 'Quantity After',
                key: 'Quantity After',
            },
            {
                title: `${t('Transfer Quantity')}`,
                dataIndex: 'Transfer Quantity',
                key: 'Transfer Quantity',
            },
          ];
        return(
            <Layout.Content>
                {/* <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20}}> */}
                <PageHeader style={{border: '1px solid rgb(235,237,340)'}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Create Transfer Warehouse')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Inventory')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/warehouse-transfer">{t('Transfer Warehouse')} </a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/warehouse-transfer/create">{t('Create Transfer Warehouse')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            
                            </Row>
                        </Col>
                    </Row>
                    </PageHeader>
                {/* </div> */}
                
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                <Divider type="horizontal"/>
                    <Row type="flex" >
                        <Col span={14}>
                            <Row type="flex" style={{marginTop:20}}>
                            <Col span={6} style={{fontWeight:'bold'}}><h3>{t('Original Warehouse')}</h3></Col>
                            <Col span={15}>
                                <Select style={{width:'100%',fontSize:16}} defaultValue= {t('Select')}>
                                        <Option value=""><Input></Input></Option>
                                        <Option value="(GDG-0001)-Gudang Raw Material" >(GDG-0001)-Gudang Raw Material</Option>
                                        <Option value="(GDG-0003)-Gudang Finished Goods" >(GDG-0003)-Gudang Finished Goods</Option>
                                        
                                </Select>
                            </Col>
                                
                            </Row>
                            
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={6} style={{fontWeight:'bold'}}><h3>{t('Destination Warehouse')}</h3></Col>
                                <Col span={15} ><Select style={{width:'100%',fontSize:16}} defaultValue=" ">
                                        <Option value="" ><Input></Input></Option>
                                    </Select></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={6} style={{fontWeight:'bold'}}><h3>{t('Date of Transfer')}</h3></Col>
                                <Col span={15}><DatePicker style={{width:'100%'}} defaultValue={moment('17/2/2020',dateFormat)} format={dateFormat} /></Col>
                            </Row>
                        </Col>
                        <Col span={10}>
                            <Row type="flex" justify="space-around"  style={{marginTop:20}}>
                                <Col span={5} style={{fontWeight:'bold'}}><h3>{t('Information')}</h3></Col>
                                <Col span={15}><TextArea rows={4} style={{width:'100%'}} /></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider type="horizontal" />
                    <div style={{margin:5, padding:20}}>
                        <Col style={{padding:20, color:'#505458', fontWeight:'bold'}}><h3>{t('Product')}</h3></Col>
                        <Table columns={columns} ></Table>
                    </div>
                    <Row ><Button href="" style={{margin:10,marginRight: 10, backgroundColor:"blue", color:"white"}}><Icon type="plus"/>{t('Add Data')}</Button></Row>
                    
                    
                </div>
                <Row type="flex" style={{marginBottom:30, padding:20}}>
                    <Col style={{marginTop:15,marginLeft:10,marginRight:10}}><Button href="/warehouse-transfer" style={{backgroundColor:'#505458',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                    <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Create Transfer Warehouse')} </Button></Col>
                </Row>
                
            </Layout.Content>
            
        )
    }

}
export default withTranslation() (AddTransfer);