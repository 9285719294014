import React from 'react'
import {Row, Col, Breadcrumb, Layout, PageHeader, Button, DatePicker, Select, Input, Divider, Table, Radio} from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next';

class AddDelivery extends React.Component{
//RadioGroup
    state = {
        value: 1,
      };
    
      onChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
          value: e.target.value,
        });
      };
//EndRadioGroup
    render(){
        const Option = Select
        const dateFormat = 'DD/MM/YYYY'
        const {TextArea} = Input
        const { t } = this.props
        const columns = [
            { 
              title: `${t("Product")}`,
              dataIndex: 'produk',
              key: 'produk',
            },
            {
              title: `${t("Description")}`,
              dataIndex: 'deskripsi',
              key: 'deskripsi',
            },
            {
              title: `${t("Unit")}`,
              dataIndex: 'satuan',
              key: 'satuan',
            },
            {
              title: `${t("Order Amount")}`,
              dataIndex: 'jumlah',
              key: 'jumlah',
            },
            {
              title: `${t("Already received")}`,
              dataIndex: 'diterima',
              key: 'diterima',
            },
            {
              title: `${t("Leftover")}`,
              dataIndex: 'sisa',
              key: 'sisa',
            }
          ];
        return(
            <Layout.Content>
                <Row style={{marginLeft:10, marginTop:20}} type="flex" justify="space-around">
                    <Col span={12}><h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}>{t("Add Delivery")}</h1></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end" style={{fontWeight:'bold'}}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t("Selling")}</Breadcrumb.Item>
                                    <Breadcrumb.Item><a href="/sales-delivery">{t("Delivery")}</a></Breadcrumb.Item>
                                    <Breadcrumb.Item>{t("Add Delivery")}</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                </Row>
                <Divider type="horizontal"></Divider>
            <PageHeader style={{border: '1px solid #ebedf0', marginTop:10}}>
                    <Row>
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("Method")}</label></Col>
                                <Radio.Group onChange={this.onChange} value={this.state.value}>
                                    <Radio value={1}>{t("From Ordering")}</Radio>
                                    <Radio value={2}>{t("From Refund")}</Radio>
                                </Radio.Group>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("Referencenum")}</label></Col>
                                <Col span={14}><Select placeholder={t("Choose")} style={{width:'100%'}}>
                                                    <Option value="01">SO2018040001</Option>
                                                    <Option value="02">SO2018050001</Option>
                                                    <Option value="03">SO2018050002</Option>
                                                </Select></Col>                                
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("Delivery Number")}</label></Col>
                                <Col span={14}><Input defaultValue="SD2020020001"/></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t("Delivery Date")}</label></Col>
                                <Col span={14}> <div>
                                                    <DatePicker defaultValue={moment('20/02/2020', dateFormat)} style={{width:280}} format={dateFormat} />
                                                </div></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label> {t("Original Warehouse")} </label></Col>
                                <Col span={14}><Select defaultValue="(GDG-003) - Gudang Finished Goods" style={{width:'100%'}}>
                                                    <Option value="01">(GDG-001) - Gudang Raw Material</Option>
                                                    <Option value="02">(GDG-003) - Gudang Finished Goods</Option>
                                                </Select></Col>                                
                            </Row>
                        </Col>   
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={8} style={{fontWeight:'bold', marginLeft:20}}><label>{t("Customer")}</label></Col>
                                <Col span={14}><Input type="disabled" disabled></Input></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold', marginLeft:20}}><label>{t("Address")}</label></Col>
                                <Col span={14}><TextArea></TextArea></Col>
                            </Row>

                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold', marginLeft:20}}><label>{t("To")}</label></Col>
                                <Col span={14}><Input></Input></Col>
                            </Row>
                        </Col> 
                    </Row>
                    <Col>
                        <Divider type="horizontal"/>
                    </Col>
                    <h1 style={{fontSize:18, fontWeight:"bold", color:'#595959'}}>{t("Product")}</h1>
                    <div style={{marginTop:20, border: '1px solid #ebedf0'}}>
                            <Table columns={columns}></Table>
                    </div>
                    <Col>
                        <Divider type="horizontal"/>
                    </Col>
                    <Row></Row>
                    <Row type="flex" justify="space-between">
                        <Col><TextArea placeholder={t("Message")} rows={4} style={{width:500, height:85}}></TextArea></Col>
                    </Row>
            </PageHeader>
                <Row type="flex" style={{marginBottom:30}}>
                    <Col style={{marginTop:15,marginLeft:53,marginRight:10}}><Button href="/sales-delivery" style={{backgroundColor:'#545961',color:'white',fontWeight:'bold'}}>{t("Back")}</Button></Col>
                    <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t("Add Delivery")}</Button></Col>
                </Row>
            </Layout.Content>
        )
    }
}

export default withTranslation() (AddDelivery)