import React, { Component } from 'react'
import '../assets/css/custom.css'
import { Layout } from 'antd'
import NavbarComponent from './NavbarComponent';
import  HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';

const { Content } = Layout;

export class DefaultLayoutComponent extends Component {

  state = {
    collapsed: false,
  }

  toggleCollapsed = () => {
    this.setState({
      ...this.state,
      collapsed: !this.state.collapsed,
    })
  }

  render() {
    const { collapsed } = this.state

    return (
      <Layout>
        <NavbarComponent collapsed={collapsed} {...this.props} />
        <Layout>
          <HeaderComponent collapsed={collapsed} toggleCollapsed={this.toggleCollapsed} {...this.props} />
          <Content className="content">
            {this.props.children}
          </Content>
          <FooterComponent />
        </Layout>
      </Layout>
    )
  }
}


export default DefaultLayoutComponent
