import React, { Component } from 'react';
import { Row, Typography, Col, Breadcrumb, Layout, Divider, Input, PageHeader, Button, Icon, Table } from 'antd';
import { withTranslation } from 'react-i18next';

class AddPosition extends Component{
    render (){
        const { t } = this.props;
        const {Title,Text} = Typography
        const {TextArea} = Input
        const columns = [
            {
                title: `${t('Benefit Name')}`,
                dataIndex: 'Nama_Tunjangan',
                key: 'Nama_Tunjangan',
            },
            {
                title: `${t('Great Allowance')}`,
                dataIndex: 'Besar_Tunjangan',
                key: 'Besar_Tunjangan',
            }
        ];
        return(
            <Layout.Content>
                <PageHeader style={{ border: '1px solid #ebedf0'}}>
                    <Row type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Add Position')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Personnel')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/position">{t('Positions & Benefits')}</a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/position/create">{t('Add Position')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                        </Col>
                    </Row>
                
                <Divider type="horizontal"/>
                <div style={{borderRadius:10, padding:10}}>
                    <Row>
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Position Name')}</label></Col>
                                <Col span={16}><Input/></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={8} style={{fontWeight:'bold'}}><label>{t('Description')}</label></Col>
                                <Col span={16}><TextArea rows={4} style={{width:'100%'}}/></Col>
                            </Row>
                        </Col>    
                    </Row>    
                </div>

                
                <Col span={12}>
                <Text strong >{t('Fixed allowances')}</Text><br />
                <Text type="secondary">{t('Determine the Benefits obtained by this Position')}</Text>
                    <Table columns ={columns}></Table>
                    <Button style={{marginTop:20, backgroundColor:'#391085',color:'white',fontWeight:'bold',marginBottom:10}} ><Icon type="plus"/>{t('Add Data')}</Button>
                </Col>
                </PageHeader>
                <Row type="flex" style={{marginBottom:30}}>
                    <Col style={{marginTop:15,marginLeft:43,marginRight:20}}><Button href="/position" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                    <Col style={{marginTop:15,marginRight:10}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Save')}</Button></Col>
                </Row>
            </Layout.Content>
        )  
    }
}
export default withTranslation()(AddPosition)