import React, { Component } from 'react'
import { Row, Typography, Col, Breadcrumb, Layout, Divider, Input, Select, DatePicker, Button, Icon, Table } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

class AddStock extends Component{
    render(){
        const {Title} = Typography
        const Option = Select
        const dateFormat =' DD/MM/YYYY'
        const {TextArea} = Input
        const {t} = this.props
        const columns = [
            {
              title: `${t('Product')}`,
              dataIndex: 'Produk',
              key: 'Produk',
              render: text => <a>{text}</a>,
            },
            {
              title: `${t('Product Code')}`,
              dataIndex: 'Kode_Produk',
              key: 'Kode_Produk',
            },
            {
              title: `${t('The Carrying Amount')}`,
              dataIndex: 'Jumlah_tercatat',
              key: 'Jumlah_tercatat',
            },
            {
                title: `${t('Actual Amount')}`,
                dataIndex: 'Jumlah_sebenarnya',
                key: 'Jumlah_sebenarnya',
            },
            {
                title: `${t('Difference')}`,
                dataIndex: 'Perbedaan',
                key: 'Perbedaan',
            },
            {
                title: `${t('Price')}`,
                dataIndex: 'Harga',
                key: 'Harga',
            },
            {
                title: `${t('Action')}`,
                dataIndex: 'Aksi',
                key: 'Aksi',
            },
          ];
        return(
            <Layout.Content>
                <div style={{margin:10,padding:20}}>
                    <Row style={{margin:10}} type="flex" justify="space-around">
                        <Col  span={12}><Title level={4}>{t('Stock Adjustment')}</Title></Col>
                        <Col span={12} >
                            <Row type="flex" justify="end">
                                <Breadcrumb>
                                    <Breadcrumb.Item>{t('Inventory')}</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/Stock">{t('Stock Opname')}</a>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                    <a href="/Stock/Create">{t('Stock Adjustment')}</a>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Divider type="horizontal" style={{margin:-10}}/>
                <div style={{marginLeft:10,marginRight:10,paddingLeft:20,paddingRight:20,padding:20,borderRadius:10}}>
                    <Row>
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={5} style={{fontWeight:'bold'}}><label>{t('Adjustment Number')}</label></Col>
                                <Col span={18}><Input value="INV202002010001" readOnly style={{backgroundColor:'#bfbfbf'}}/></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:10}}>
                                <Col span={5} style={{fontWeight:'bold'}}><label>{t('Preparation Account')}</label></Col>
                                <Col span={18}><Select style={{width:'100%',fontSize:16}} defaultValue="1-0000 - Asset">
                                        <Option value="1-0000 - Asset" >1-0000 - Asset</Option>
                                        <Option value="1-1000 - Harga Lancar" >1-1000 - Harga Lancar</Option>
                                        <Option value="1-2000 - Harga Tetap" >1-2000 - Harga Tetap</Option>
                                        <Option value="1-1100 - Kas" >1-1100 - Kas</Option>
                                        <Option value="1-1110 - Kas POS" >1-1110 - Kas POS</Option>
                                        <Option value="1-1120 - Kas Bank" >1-1120 - Kas Bank</Option>
                                        <Option value="1-1200 - Bank" >1-1200 - Bank</Option>
                                        <Option value="1-1300 - Piutang" >1-1300 - Piutang</Option>
                                        <Option value="1-1400 - PPN Masukan" >1-1400 - PPN Masukan</Option>
                                        <Option value="1-1500 - Persedian Barang" >1-1500 - Persedian Barang</Option>
                                        <Option value="1-2100 - Peralatan Kantor" >1-2100 - Peralatan Kantor</Option>
                                        <Option value="1-2200 - Akumulasi Penyusuatan Peralatan " >1-2200 - Akumulasi Penyusuatan Peralatan </Option>
                                        <Option value="1-2300 - Sewa Dibayar dimuka" >1-2300 - Sewa Dibayar dimuka</Option>
                                    </Select></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={5} style={{fontWeight:'bold'}}><label>{t('Cost Account')}</label></Col>
                                <Col span={18}><Select style={{width:'100%',fontSize:16}} defaultValue="5-0000 - Biaya">
                                        <Option value="5-0000 - Biaya" >5-0000 - Biaya</Option>
                                        <Option value="5-1000 - Harga Pokok Penjualan" >5-1000 - Harga Pokok Penjualan</Option>
                                        <Option value="5-2000 - Harga Gaji" >5-2000 - Harga Gaji</Option>
                                        <Option value="5-3000 - Biaya Trasportasi" >5-3000 - Biaya Trasportasi</Option>
                                        <Option value="5-4000 - Biaya Penyusutan dan Amortisasi" >5-4000 - Biaya Penyusutan dan Amortisasi</Option>
                                        <Option value="5-5000 - Biaya Sewa" >5-5000 - Biaya Sewa</Option>
                                        <Option value="5-6000 - Biaya Lainnya" >5-6000 - Biaya Lainnya</Option>
                                    </Select></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={5} style={{fontWeight:'bold'}}><label>{t('By Date')}</label></Col>
                                <Col span={18}><DatePicker style={{width:'100%'}} defaultValue={moment('1/2/2020',dateFormat)} format={dateFormat} /></Col>
                            </Row>
                            <Row type="flex" style={{marginTop:20}}>
                                <Col span={5} style={{fontWeight:'bold'}}><label>{t('The Carrying Amount')}</label></Col>
                                <Col span={18}><Select style={{width:'100%',fontSize:16}} defaultValue="(GDG-0001)- Gudang Raw Material">
                                        <Option value="(GDG-0001)- Gudang Raw Material" >(GDG-0001)- Gudang Raw Material</Option>
                                        <Option value="(GDG-0003) - Gudang Finished Goods)" >(GDG-0003) - Gudang Finished Goods)</Option>
                                    </Select></Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row type="flex">
                                <Col span={5} style={{fontWeight:'bold'}}><label>{t('Memo')}</label></Col>
                                <Col span={18}><TextArea rows={4} style={{width:'100%'}} /></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider type="horizontal" style={{marginTop:40}} />
                    <Button style={{backgroundColor:'#595959',color:'white',fontWeight:'bold',marginBottom:10}} ><Icon type="plus"/>{t('Add Goods')}</Button>
                    <Table columns ={columns}></Table>
                    <Row type="flex" justify="end"style={{marginTop:10}} >
                        <Col span={2} style={{marginRight:5,fontWeight:'bold'}}><label>{t('Total Difference')}</label></Col>
                        <Col span={5}><Input/></Col>
                    </Row>
                    <Row type="flex" style={{marginBottom:30}}>
                        <Col style={{marginTop:15,marginRight:10}}><Button href="/Stock" style={{backgroundColor:'#595959',color:'white',fontWeight:'bold'}}>{t('Back')}</Button></Col>
                        <Col style={{marginTop:15}}><Button style={{backgroundColor:'#1890ff',color:'white',fontWeight:'bold'}}>{t('Create Adjustment')}</Button></Col>
                    </Row>
                </div>
                
                
            </Layout.Content>
            
        )
    }

}
export default withTranslation()(AddStock) 